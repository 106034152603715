import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useStyles from "./style";
import { CircularProgress } from "@material-ui/core";
import NoRecords from "assets/images/norecord.svg";
import { noop } from "shared";

const StackedBarChart = ({
  entries = [],
  isFetching = false,
  isTabletView,
  handleClick = noop,
}) => {
  const classes = useStyles({ isTabletView: isTabletView });

  const handleOpenDialog = (entry = {}) => {
    handleClick(entry);
  };

  return (
    <div className={classes.barChartHeadrer}>
      {entries.length > 0 ? (
        <div
          style={{
            width: entries.length * 150,
            minWidth: "100%",
            height: isTabletView
              ? "calc(100vh - 390px)"
              : "calc(100vh - 340px)",
            position: "relative",
          }}
        >
          {isFetching && (
            <div className={classes.isFetchingData}>
              <CircularProgress size={46} className="p-absolute progress-btn" />
            </div>
          )}
          <ResponsiveContainer>
            <BarChart
              width={entries.length * 150}
              data={entries}
              margin={{ top: 20, bottom: 80, left: 50 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="kamName"
                tick={({ payload: { value }, x, y }) => (
                  <g transform={`translate(${x},${y})`}>
                    <foreignObject x={-60} width={125} height={90}>
                      <div className={classes.xAxisLabel}>{value}</div>
                    </foreignObject>
                  </g>
                )}
                interval={0}
                label={{
                  value: "KAM(S) ⟶",
                  position: "insideBottom",
                  offset: isTabletView ? -70 : -68,
                  fontWeight: "bold",
                }}
              />
              <YAxis yAxisId={0} allowDecimals={false} />
              <Tooltip
                content={({ label, payload }) => {
                  const entry = entries.find((item) => item.kamName === label);

                  return (
                    <div className={classes.customTooltip}>
                      <p style={{ marginBottom: 8, fontWeight: "bold" }}>
                        KAM: {label}
                      </p>
                      {entry && (
                        <>
                          <p style={{ color: "#4F7942", fontWeight: "bold" }}>
                            Total Records: {+entry.totalRecords}
                          </p>
                        </>
                      )}
                    </div>
                  );
                }}
              />
              <Bar
                dataKey="totalRecords"
                fill="#4F7942"
                name="Total Records"
                barSize={60}
                stackId="a"
                onClick={handleOpenDialog}
                style={{ cursor: "pointer" }}
                isAnimationActive={false}
              />
              <Legend
                align="center"
                verticalAlign="bottom"
                wrapperStyle={{
                  position: "relative",
                  paddingTop: "12px",
                  paddingLeft: "60px",
                  bottom: "50px",
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div
          className="d-flex f-justify-center"
          style={{ height: "26rem", position: "relative" }}
        >
          {isFetching && (
            <div className={classes.isFetchingData}>
              <CircularProgress size={46} className="p-absolute progress-btn" />
            </div>
          )}
          <img
            className={classes.noRecordsImg}
            alt="NoRecords"
            src={NoRecords}
          />
        </div>
      )}
    </div>
  );
};

export default StackedBarChart;
