import { Switch, Route } from "react-router-dom";
import InvoiceHomePage from "./home";
import ViewInvoice from "./viewInvoice";
import DraftInvoice from "./draftInvoice";
import ViewRecordPaymentHistory from "./recordPaymentHistory";
import ApproveInvoice from "./approveInvoice";
import { ROLE } from "modules/shared/constants";
import { getTokenData } from "utils";

const InvoiceModule = ({ match }) => {
  const currentUser = getTokenData() || {};
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;

  return (
    <Switch>
      <Route exact path={match.path} component={InvoiceHomePage} />
      <Route
        exact
        path={`${match.path}/view_invoice`}
        component={ViewInvoice}
      />
      <Route
        exact
        path={`${match.path}/draft_invoice`}
        component={DraftInvoice}
      />
      <Route
        exact
        path={`${match.path}/record_payment`}
        component={ViewRecordPaymentHistory}
      />
      {!isRoleAA && (
        <Route
          exact
          path={`${match.path}/approve_invoice`}
          component={ApproveInvoice}
        />
      )}
      <Route
        render={(props) => (
          <>
            <div className="m-10 pt-10 d-flex f-justify-center f-align-center flex-1">
              <div>
                <h2>Uh Oh! Something is not right...</h2>
                <h4>The page you are trying to access does not exist.</h4>
                <h4>
                  Maybe you want to go to{" "}
                  <span
                    className="color-primary c-pointer"
                    onClick={() => {
                      props.history.push("/");
                    }}
                  >
                    Home
                  </span>{" "}
                  page?
                </h4>
              </div>
            </div>
          </>
        )}
      />
    </Switch>
  );
};

export default InvoiceModule;
