import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { decodeToken, getToken } from "./token";
export * from "./date";
export * from "./token";
export { default as responseFormatter } from "./response-formatter";
export {
  default as queryStringBuilder,
  queryStringBuilderNew,
} from "./query-string-builder";
export * from "./api-communicator";
export * from "./file";
export * from "./role-authorizer";
export { default as exportBase64ToFile } from "./export-to-file";
export * from "./validator";
export * from "./use-screen";

let appConfig = {};

export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const updatePageConfig = (pageKey) => {
  const tokenData = decodeToken(getToken());

  const pageConfig = localStorage.getItem("page-config");

  const pageConfigObj = JSON.parse(pageConfig);

  if (pageConfigObj && pageConfigObj[tokenData.email]) {
    delete pageConfigObj[tokenData.email][pageKey];
  }

  const updatedPageConfig = JSON.stringify(pageConfigObj);

  localStorage.setItem("page-config", updatedPageConfig);
};

export const getPageConfig = (pageKey, useSessionStorage) => {
  let pageConfig = {};
  if (!pageKey) {
    return pageConfig;
  }

  let pageConfigs = localStorage.getItem("page-config");
  if (useSessionStorage) {
    pageConfigs = sessionStorage.getItem("page-config");
  }

  if (pageConfigs) {
    try {
      pageConfigs = JSON.parse(pageConfigs);
      const tokenData = decodeToken(getToken());
      const userConfig = pageConfigs[tokenData.email];
      if (userConfig) {
        pageConfig = userConfig[pageKey];
      }

      return pageConfig;
    } catch (exception) {
      console.log("CONFIG ISSUE");
      return pageConfig;
    }
  }
};

export const setPageConfig = (pageKey, config, useSessionStorage = false) => {
  if (!pageKey || !config) {
    return;
  }

  let pageConfig = {};
  let pageConfigs = localStorage.getItem("page-config");
  if (useSessionStorage) {
    pageConfigs = sessionStorage.getItem("page-config");
  }

  const tokenData = decodeToken(getToken());
  if (pageConfigs) {
    try {
      pageConfigs = JSON.parse(pageConfigs);
      const userConfig = pageConfigs[tokenData.email];
      if (userConfig) {
        pageConfig = userConfig[pageKey];
      }
      pageConfig = {
        ...pageConfig,
        ...config,
      };

      const dataToSave = JSON.stringify({
        ...pageConfigs,
        [tokenData.email]: {
          ...userConfig,
          [pageKey]: pageConfig,
        },
      });

      if (useSessionStorage) {
        sessionStorage.setItem("page-config", dataToSave);
      } else {
        localStorage.setItem("page-config", dataToSave);
      }
    } catch (exception) {
      console.log("INVALID CONFIG");
      const dataToSave = JSON.stringify({
        pageKey: config,
      });

      if (useSessionStorage) {
        sessionStorage.setItem("page-config", dataToSave);
      } else {
        localStorage.setItem("page-config", dataToSave);
      }
    }
  } else {
    const dataToSave = JSON.stringify({
      [tokenData.email]: {
        [pageKey]: config,
      },
    });
    if (useSessionStorage) {
      sessionStorage.setItem("page-config", dataToSave);
    } else {
      localStorage.setItem("page-config", dataToSave);
    }
  }
};

export const setAppConfig = (config) => {
  appConfig = config;
};

export const getAppConfig = () => JSON.parse(JSON.stringify(appConfig));

function compareObject(value1, value2) {
  let areValuesEqual = true;

  if (!(value1 && value2)) {
    return false;
  }

  if (Object.keys(value1).length !== Object.keys(value2).length) {
    return false;
  }

  Object.keys(value2).forEach((key2) => {
    if (areValuesEqual) {
      if (Object.prototype.hasOwnProperty.call(value1, key2)) {
        if (typeof value1[key2] === typeof value2[key2]) {
          if (typeof value1[key2] === "object") {
            if (!compareObject(value1[key2], value2[key2])) {
              areValuesEqual = false;
            }
          } else {
            if (typeof value1[key2] === "function") {
              if (
                JSON.stringify(value1[key2]) !== JSON.stringify(value2[key2])
              ) {
                areValuesEqual = false;
              }
            } else {
              if (value1[key2] !== value2[key2]) {
                areValuesEqual = false;
              }
            }
          }
        } else {
          areValuesEqual = false;
        }
      } else {
        areValuesEqual = false;
      }
    }
  });

  return areValuesEqual;
}

export const updatePagination = (count, paginationState, callback) => {
  if (typeof count === "undefined") return;
  if (
    paginationState.pageNumber !== 1 &&
    count <= paginationState.pageSize * (paginationState.pageNumber - 1)
  ) {
    callback(paginationState.pageNumber - 1);
  }
};

export function areEqual(value1, value2) {
  let areValuesEqual = true;
  if (
    typeof value1 === typeof value2 &&
    Array.isArray(value1) === Array.isArray(value2)
  ) {
    if (typeof value1 === "object") {
      if (Array.isArray(value1)) {
        if (value1.length === value2.length) {
          value1.forEach((element1) => {
            if (typeof element1 === "object") {
              const objectsInValue2 = value2.filter(
                (element2) => typeof element2 === "object"
              );
              if (objectsInValue2.length > 0) {
                const equalObject2 = objectsInValue2
                  .filter(
                    (element) =>
                      Object.keys(element1).length ===
                      Object.keys(element).length
                  )
                  .find((element2) => {
                    return compareObject(element1, element2);
                  });

                if (!equalObject2) {
                  areValuesEqual = false;
                }
              } else {
                areValuesEqual = false;
              }
            } else {
              if (typeof element1 === "function") {
                const sameElement2 = value2.find(
                  (element2) =>
                    JSON.stringify(element1) === JSON.stringify(element2)
                );

                if (!sameElement2) {
                  areValuesEqual = false;
                }
              } else {
                const sameElement2 = value2.find(
                  (element2) => element2 === element1
                );
                if (!sameElement2) {
                  areValuesEqual = false;
                }
              }
            }
          });
        } else {
          areValuesEqual = false;
        }
      } else {
        if (!compareObject(value1, value2)) {
          areValuesEqual = false;
        }
      }
    } else {
      if (typeof value1 === "function") {
        if (JSON.stringify(value1) !== JSON.stringify(value2)) {
          areValuesEqual = false;
        } else {
          if (value1 !== value2) {
            areValuesEqual = false;
          }
        }
      }
    }
  } else {
    areValuesEqual = false;
  }

  return areValuesEqual;
}
export function getTimeInSeconds(time) {
  if (!time) {
    return;
  }

  let durationArray = time.split(":");
  let hours = 0;
  let minutes = 0;
  if (durationArray.length === 2) {
    hours = parseInt(durationArray[0]);
    if (parseInt(durationArray[1])) {
      minutes = parseInt(durationArray[1]);
    }
  } else {
    minutes = parseInt(durationArray[0]);
  }

  if (durationArray.length === 2 && durationArray[1] !== "") {
    minutes = parseInt(durationArray[1]);
  }

  return hours * 3600 + minutes * 60;
}

export const preventInputKeyCodes = (evt, allow = {}) => {
  const KEYS = {
    EXPONENT: 69,
    HYPHEN: 189,
    ADD: 187,
    INCREMENT: 38,
    DECREMENT: 40,
  };

  if (
    Object.entries(KEYS).some(
      ([key, value]) => !allow[key] && value === evt.keyCode
    )
  ) {
    evt.preventDefault();
  }
};

export const preventInputDecimal = (evt) => {
  const KEY_EXPONENT = 69;
  const KEY_HYPHEN = 189;
  const KEY_ADD = 187;
  const KEY_DECIMAL = 190;
  const INCREMENT = 38;
  const DECREMENT = 40;

  if (
    evt.keyCode === KEY_EXPONENT ||
    evt.keyCode === KEY_HYPHEN ||
    evt.keyCode === KEY_ADD ||
    evt.keyCode === KEY_DECIMAL ||
    evt.keyCode === INCREMENT ||
    evt.keyCode === DECREMENT
  ) {
    evt.preventDefault();
  }
};

export const updateLayout = () => {
  const windowHeight = window.innerHeight;
  const pageRootElement = document.getElementById("numbers-page-wrapper");
  const gridElement = document.getElementsByClassName(
    "MuiTableContainer-root"
  )[0];
  if (pageRootElement && gridElement) {
    const pageRootDimensions = pageRootElement.getClientRects()[0];
    if (pageRootDimensions.height < windowHeight) {
      const gridDimensions = gridElement.getClientRects()[0];
      const pageUpperHeight = pageRootDimensions.height - gridDimensions.height;
      // console.log("******** Start ********");
      // console.log("RESULT", windowHeight - pageUpperHeight);
      // console.log("page upper", pageUpperHeight);
      // console.log("Grid", gridDimensions);
      // console.log("******** End ********");
      gridElement.style.maxHeight = `${windowHeight - pageUpperHeight}px`;
      return;
    }

    if (pageRootDimensions.height === windowHeight) {
      gridElement.style.maxHeight = `${
        gridElement.getClientRects()[0].height
      }px`;
      return;
    }

    if (pageRootDimensions.height > windowHeight) {
      const gridDimensions = gridElement.getClientRects()[0];
      const nonGridHeight = pageRootDimensions.height - gridDimensions.height;
      const remainingHeight = windowHeight - nonGridHeight;
      pageRootElement.style.height = `${windowHeight - 2}px`;
      gridElement.style.maxHeight = `${remainingHeight - 5}px`;
    }
  }
};

export const updateGridLayout = () => {
  const windowHeight = window.innerHeight;
  const pageRootElement = document.getElementById("numbers-page-wrapper");
  const gridElement = document.querySelector(".MuiTableContainer-root");

  if (!pageRootElement || !gridElement) return;

  const pageRootDimensions = pageRootElement.getBoundingClientRect();
  const gridDimensions = gridElement.getBoundingClientRect();

  if (pageRootDimensions.height < windowHeight) {
    const pageUpperHeight = pageRootDimensions.height - gridDimensions.height;
    gridElement.style.maxHeight = `${windowHeight - pageUpperHeight}px`;
  } else if (pageRootDimensions.height === windowHeight) {
    gridElement.style.maxHeight = `${gridDimensions.height}px`;
  } else {
    const nonGridHeight = pageRootDimensions.height - gridDimensions.height;
    const remainingHeight = windowHeight - nonGridHeight;
    pageRootElement.style.height = `${windowHeight - 2}px`;
    gridElement.style.maxHeight = `${remainingHeight - 5}px`;
  }
};

export const preventInputSpace = (evt) => {
  const KEY_SPACE = 32;

  if (evt.keyCode === KEY_SPACE) {
    evt.preventDefault();
  }
};

export const toPascalCase = (words = "") => {
  return words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(" ");
};

export const getReadableFileSizeString = (fileSizeInBytes) => {
  let i = -1;
  const byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const LoadingOverlay = ({ message }) => (
  <Backdrop open={true} style={{ zIndex: 1301 }}>
    <div style={{ textAlign: "center", color: "#fff" }}>
      <CircularProgress color="inherit" />
      <Typography variant="h6" style={{ marginTop: "16px" }}>
        {message}
      </Typography>
    </div>
  </Backdrop>
);
