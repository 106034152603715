import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

import NoRecords from "assets/images/norecord.svg";
import useStyles from "./style";

const MyBarChart = ({ entries = [] }) => {
  const classes = useStyles();

  const customLegend = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
          <div
            style={{
              width: 14,
              height: 10,
              backgroundColor: "#82ca9d",
              marginRight: 5,
            }}
          ></div>
          <div>MGR Reviews</div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: 14,
              height: 10,
              backgroundColor: "#8884d8",
              marginRight: 5,
            }}
          ></div>
          <div>AA Reviews</div>
        </div>
      </div>
    );
  };
  return (
    <div className={classes.barChartHeadrer}>
      {entries.length > 0 ? (
        <div
          style={{
            width: "100%",
            minWidth: "100%",
            height: "calc(100vh - 440px)",
            position: "relative",
          }}
        >
          <ResponsiveContainer>
            <BarChart
              width={150}
              height={300}
              data={entries}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                tick={({ payload: { value }, x, y }) => (
                  <g transform={`translate(${x},${y})`}>
                    <foreignObject x={-60} width={125} height={90}>
                      <div className={classes.xAxisLabel}>{value}</div>
                    </foreignObject>
                  </g>
                )}
                interval={0}
              />
              <YAxis yAxisId={0} allowDecimals={false} />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const data = payload[0].payload;
                    return (
                      <div className={classes.customTooltip}>
                        <p style={{ marginBottom: 8, fontWeight: "bold" }}>
                          {data.tooltipName}
                        </p>
                        <p
                          style={{
                            marginBottom: 8,
                            // color: "#9575cd",
                            fontWeight: "bold",
                          }}
                        >
                          No. of Reviewed Records: {data.recordCount}
                        </p>
                        <p
                          style={{
                            marginBottom: 8,
                            color: data.role === "AA" ? "#8884d8" : "#82ca9d",
                            fontWeight: "bold",
                          }}
                        >
                          No. of Reviews: {data.reviewCount}
                        </p>
                      </div>
                    );
                  }

                  return null;
                }}
              />
              <Legend
                content={customLegend}
                align="center"
                verticalAlign="bottom"
                wrapperStyle={{
                  position: "relative",
                  paddingTop: "30px",
                  paddingLeft: "60px",
                  bottom: "54px",
                }}
              />

              <Bar
                dataKey="reviewCount"
                barSize={60}
                style={{ cursor: "pointer" }}
              >
                {entries.map((entry, index) => (
                  <Cell fill={entry.role === "AA" ? "#8884d8" : "#82ca9d"} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div
          className="d-flex f-justify-center"
          style={{ height: "26rem", position: "relative" }}
        >
          <img
            className={classes.noRecordsImg}
            alt="NoRecords"
            src={NoRecords}
          />
        </div>
      )}
    </div>
  );
};

export default MyBarChart;
