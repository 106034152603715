/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Paper, Tooltip, Typography } from "@material-ui/core";
import useStyles from "./style";
import { Dialog, Grid, noop } from "shared";

import { getDateString, getFormattedTime } from "utils";
import { useCallback, useEffect, useState } from "react";

import Service from "modules/invoice/service";
import { toast } from "react-toastify";

const defaultState = {
  entries: [],
  isFetchingData: false,
};

const PaymentAudit = ({
  open = false,
  handleClose = noop,
  paymentId = null,
  paymentNumber = null,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const fetchEntries = useCallback(async (id) => {
    setState((prevState) => ({ ...prevState, isFetchingData: true }));

    let queryString = `?filter[where][and][0][or][0][payment_id]=${id}`;

    const { data, error } = await Service.getPaymentLogs(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, isFetchingData: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingData: false,
      entries: data.rows || defaultState.entries,
    }));
  }, []);

  const onClose = () => {
    handleClose(false);
    setState(defaultState);
  };

  const columnConfig = [
    {
      id: "action",
      label: "Action",
      field: "action",
      render: (row) => {
        const title = row.action.charAt(0).toUpperCase() + row.action.slice(1);
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "old_value",
      label: "Old Value",
      field: "old_value",
      render: (row) => {
        const title = row?.old_value || "-";
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "new_value",
      label: "New Value",
      field: "new_value",
      render: (row) => {
        const title = row?.new_value || "-";
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "credit_amount",
      label: "Credit Amount",
      field: "credit_amount",
      render: (row) => {
        const title = `$ ${row?.credit_amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "notes",
      label: "Notes",
      field: "notes",
      render: (row) => {
        const title = row?.notes || "-";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      id: "createdBy",
      label: "Created By",
      field: "createdBy",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        let user = "";
        if (row.createdBy) {
          user = `${row.createdBy?.first_name} ${
            row.createdBy?.last_name || ""
          } - (${row.createdBy?.username})`;
        }
        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "created_at",
      label: "Created At",
      fieldName: "created_at",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const createdAt =
          !!row.created_at && row?.createdBy
            ? `${getDateString(row.created_at)} ${getFormattedTime(
                new Date(row.created_at)
              )}`
            : "";
        return (
          <Tooltip title={createdAt ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {createdAt || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    fetchEntries(paymentId);
  }, [fetchEntries, paymentId]);

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
        <Dialog.Title hasClose>
          <Typography
            variant="h6"
            color="primary"
          >{`Payment No. #${paymentNumber}`}</Typography>
        </Dialog.Title>
        <Dialog.Content>
          <Paper>
            <Grid
              columns={columnConfig}
              rows={state.entries}
              actionBarConfig={null}
              hasSelection={false}
              isLoading={state.isFetchingData}
              hasPagination={false}
            />
          </Paper>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
              Cancel
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default PaymentAudit;
