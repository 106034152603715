/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useStyles from "./style";
import { ActionDialog, Dialog, Grid, noop } from "shared";

import { getDateString, getFormattedTime, getUTCDateString } from "utils";
import { useCallback, useEffect, useState } from "react";
import { modeOfPaymentList } from "modules/shared/constants";
import Service from "modules/invoice/service";
import { toast } from "react-toastify";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import RecordPayment from "modules/invoice/recordPayment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import clsx from "clsx";
import PaymentAudit from "modules/invoice/recordPayment/paymentAudit";

const defaultState = {
  entries: [],
  isFetchingData: false,
  paymentId: null,
  invoiceId: null,
  paymentNumber: null,
  isRevertLoading: false,
  showRevertInvoicePaymentPopup: false,
  selectedPayment: {},
  paymentLogs: false,
};

const PaymentGrid = ({
  open = false,
  handleClose = noop,
  invoiceNumber = null,
  handleFetch = noop,
  getInvoice = noop,
  customerList = [],
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const fetchEntries = useCallback(async (invoiceNo) => {
    setState((prevState) => ({ ...prevState, isFetchingData: true }));

    let queryString = `?filter[where][and][0][or][0][invoice_no][eq]=${invoiceNo}`;

    const { data, error } = await Service.getInvoicesPayments(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, isFetchingData: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingData: false,
      entries: data.rows || defaultState.entries,
    }));
  }, []);

  const onClose = () => {
    handleClose(false);
    setState(defaultState);
  };

  const handleRevertPayment = useCallback(async (id, invoiceId) => {
    setState((prevState) => ({
      ...prevState,
      isRevertLoading: true,
    }));

    const { error } = await Service.revertInvoicePayment(id);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isRevertLoading: false,
        showRevertInvoicePaymentPopup: false,
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    setState((prevState) => ({
      ...prevState,
      isRevertLoading: false,
      showRevertInvoicePaymentPopup: false,
    }));
    toast.success("Payment cancelled successfully.");
    handleFetch("invoiceLoading");
    getInvoice({ id: invoiceId }, true);
    onClose();
  }, []);

  const handlePaymentClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      showPaymentPopup: close,
    }));
  };
  const handlePaymentLogsDialog = (close) => {
    setState((prevState) => ({
      ...prevState,
      paymentLogs: close,
    }));
  };

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="Edit" placement="top-start">
          <IconButton
            color="primary"
            disabled={row.status === "reverted"}
            className="ml-2 mr-2 c-pointer"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                selectedPayment: row,
                showPaymentPopup: true,
              }));
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel" placement="top-start">
          <IconButton
            style={{ color: row.status !== "reverted" && "#f44336" }}
            className="ml-2 mr-2"
            disabled={row.status === "reverted"}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                showRevertInvoicePaymentPopup: true,
                paymentId: row.id,
                invoiceId: row.invoice_id,
              }))
            }
          >
            <ReplayOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="View payment history" placement="top-start">
          <VisibilityIcon
            className={clsx("ml-2 mr-2 c-pointer", classes.VisibilityIconColor)}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                paymentId: row?.id,
                paymentNumber: row?.payment_number,
              }));
              handlePaymentLogsDialog(true);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
    {
      id: "payment_number",
      label: "Payment No.",
      field: "payment_number",
      render: (row) => {
        const title = row.payment_number;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      field: "status",
      render: (row) => {
        const title =
          row.status && row.status === "reverted"
            ? "Cancelled"
            : row.status.charAt(0).toUpperCase() + row.status.slice(1);
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "date",
      label: "Payment Date",
      field: "date",
      render: (row) => {
        const title = getUTCDateString(row.date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },

    {
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      render: (row) => {
        const title = getUTCDateString(row.invoice.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "reference_number",
      label: "Reference Number",
      field: "reference_number",
      render: (row) => {
        const title = row.reference_number;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "amount",
      label: "Amount",
      field: "amount",
      render: (row) => {
        const title = `$${row?.amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "credit_amt",
      label: "Credit Amount",
      field: "credit_amt",
      render: (row) => {
        const title = `$ ${row?.credit_amt || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "invoice_deductible",
      label: "Amount Used For Payments",
      field: "invoice_deductible",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const title = `$${row?.invoice_deductible || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "mode",
      label: "Mode of Payment",
      field: "mode",
      render: (row) => {
        const title = modeOfPaymentList.find((ele) => ele.value === row?.mode);
        return (
          <Tooltip title={`${title.label || "-"}`} placement="top-start">
            <Typography variant="body2" noWrap>{`${
              title.label || "-"
            }`}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "payment_notes",
      label: "Payment Notes",
      field: "payment_notes",
      render: (row) => {
        const title = row?.payment_notes || "-";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "updatedBy",
      label: "Updated By",
      field: "updatedBy",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        let user = "";
        if (row.updatedBy) {
          user = `${row.updatedBy?.first_name} ${
            row.updatedBy?.last_name || ""
          } - (${row.updatedBy?.username})`;
        }
        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "updated_at",
      label: "Updated At",
      fieldName: "updated_at",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const updatedAt =
          !!row.updated_at && row?.updatedBy
            ? `${getDateString(row.updated_at)} ${getFormattedTime(
                new Date(row.updated_at)
              )}`
            : "";
        return (
          <Tooltip title={updatedAt ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {updatedAt || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "revertedBy",
      label: "Cancelled By",
      field: "revertedBy",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        let user = "";
        if (row.revertedBy) {
          user = `${row.revertedBy?.first_name} ${
            row.revertedBy?.last_name || ""
          } - (${row.revertedBy?.username})`;
        }
        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "reverted_at",
      label: "Cancelled At",
      fieldName: "reverted_at",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const revertedAt =
          !!row.reverted_at && row?.revertedBy
            ? `${getDateString(row.reverted_at)} ${getFormattedTime(
                new Date(row.reverted_at)
              )}`
            : "";
        return (
          <Tooltip title={revertedAt ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {revertedAt || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    fetchEntries(invoiceNumber);
  }, [fetchEntries, invoiceNumber]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={onClose}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose>
          <Typography
            variant="h6"
            color="primary"
          >{`Invoice No. #${invoiceNumber}`}</Typography>
        </Dialog.Title>
        <Dialog.Content>
          <Paper>
            <Grid
              columns={columnConfig}
              rows={state.entries}
              actionBarConfig={null}
              hasSelection={false}
              isLoading={state.isFetchingData}
              hasPagination={false}
            />
          </Paper>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
              Cancel
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>

      {state.showRevertInvoicePaymentPopup && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paper_Sizes,
          }}
          open={!!state.showRevertInvoicePaymentPopup}
          contentText="Once cancelled, this action cannot be undone. Are you sure you want to proceed?"
          onConfirm={() =>
            handleRevertPayment(state.paymentId, state.invoiceId)
          }
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              showRevertInvoicePaymentPopup: false,
            }))
          }
          isConfirmDisabled={state.isRevertLoading}
          positiveActionLabel={
            <>
              Confirm
              {state.isRevertLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}

      <RecordPayment
        handleClose={handlePaymentClose}
        open={state.showPaymentPopup}
        customerList={customerList}
        entry={state.selectedPayment}
        handleFetch={handleFetch}
        getInvoice={getInvoice}
        isEdit={true}
        onUpdateClose={onClose}
      />

      {state.paymentLogs && (
        <PaymentAudit
          open={state.paymentLogs}
          handleClose={handlePaymentLogsDialog}
          paymentId={state.paymentId}
          paymentNumber={state.paymentNumber}
        />
      )}
    </>
  );
};

export default PaymentGrid;
