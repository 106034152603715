/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import useStyles from "./style";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import {
  ActionDialog,
  AppContext,
  Datepicker,
  Dialog,
  Grid,
  noop,
} from "shared";
import { getDateString, getUTCDateString, updatePageConfig } from "utils";
import { toast } from "react-toastify";
import { SharedService } from "modules/shared";
import Service from "../service";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { INVOICE_STATUS } from "modules/shared/constants";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";

const defaultState = {
  isLoading: false,
  wantsToContinue: false,
  multipleInvoices: false,
  isRoutePresent: false,
  isinvoiceIdsPresent: false,
  isRecordPresent: false,
  pdxCompany: null,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
  },
  startDate: getDateString(new Date()),
  endDate: getDateString(new Date()),
  invoiceDate: getDateString(new Date()),
  customer: null,
  pdxCompanyList: [],
  routeIds: [],
  invoiceIds: [],
  presentInvoiceIds: [],
  entries: [],
  isDataFetching: false,
  branchIds: [],
  route: [],
  multipleLocation: [],
  location: null,
  noRecordBranch: false,
  presentBranchIds: [],
};

const GenerateInvoiceDialog = ({
  history = {},
  open = false,
  handleClose = noop,
}) => {
  const { appData, updateContextData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView });
  const [state, setState] = useState(defaultState);

  const appDataList = useMemo(() => {
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      customers,
    };
  }, [appData]);

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    // eslint-disable-next-line default-case
    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const customerList = useMemo(() => {
    return appDataList.customers.filter((customer) =>
      customer.customer_branches.some(
        (branch) =>
          branch.pdx_company_id === state.pdxCompany?.id &&
          branch.is_active &&
          !branch.is_deleted
      )
    );
  }, [state.pdxCompany]);

  const branchList = useMemo(() => {
    const customer = customerList.find(
      (customer) => customer.id === state.customer?.id
    );
    if (!customer) return [];

    const branchMap = new Map();

    customer.customer_branches.forEach((branch) => {
      if (
        branch.pdx_company_id === state.pdxCompany?.id &&
        branch.is_active &&
        !branch.is_deleted
      ) {
        const location = (branch.location || "").toLowerCase();
        if (!branchMap.has(location)) {
          branchMap.set(location, branch);
        }
      }
    });

    const uniqueBranches = Array.from(branchMap.values());

    uniqueBranches.sort((a, b) => {
      const valueA = (a.location || "").toLowerCase();
      const valueB = (b.location || "").toLowerCase();
      return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
    });

    return uniqueBranches;
  }, [state.customer, state.pdxCompany]);

  const routeList = useMemo(() => {
    const routes =
      branchList.find((location) => location.id === state.location?.id)
        ?.routes || [];

    const activeRoutes = routes?.filter((route) => route.is_active);
    const inactiveRoutes = routes?.filter((route) => !route.is_active);

    const sortedActiveRoutes = activeRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );
    const sortedInactiveRoutes = inactiveRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );

    return [...sortedActiveRoutes, ...sortedInactiveRoutes];
  }, [state.location]);

  useEffect(() => {
    fetchPDXCompanyList();
    fetchList("customers", noop);
  }, [open]);

  const onClose = () => {
    handleClose(false);
    setState(defaultState);
  };

  const handleSubmit = async (ignoreDupInvoices) => {
    const { startDate, endDate, location, route, invoiceDate } = state;
    if (new Date(invoiceDate) < new Date(startDate)) {
      return toast.error("Invoice date cannot be less than Start date.");
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const { data, error } = await Service.post({
      from_date: startDate,
      to_date: endDate,
      customer_branch_id: location?.id,
      route_ids: route.map((ele) => ele?.id) || null,
      ignoreDupInvoice: ignoreDupInvoices,
      invoice_date: invoiceDate,
    });

    if (data?.code === "PDX-IVC-001") {
      return setState((prevState) => ({
        ...prevState,
        isLoading: false,
        wantsToContinue: true,
      }));
    }
    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (error[0]?.code === "PDX-IVP-001") {
        fetchEntries();
        return setState((prevState) => ({
          ...prevState,
          isinvoiceIdsPresent: true,
          invoiceIds: error[0]?.invoiceIds,
        }));
      }
      if (error[0]?.code === "PDX-IVP-002") {
        fetchEntries();
        return setState((prevState) => ({
          ...prevState,
          isRecordPresent: true,
          presentInvoiceIds: error[0]?.invoiceIds,
        }));
      }

      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice generated successfully.");
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    onClose();
    history.push({
      pathname: "/invoice/draft_invoice",
      state: {
        invoiceId: data.invoiceDetails?.id,
        customerList: appDataList.customers,
      },
    });
  };

  const handleGenerateMultipleInvoices = async (ignoreDupInvoices) => {
    const {
      startDate,
      endDate,
      customer,
      multipleLocation,
      pdxCompany,
      invoiceDate,
    } = state;

    if (new Date(invoiceDate) < new Date(startDate)) {
      return toast.error("Invoice date cannot be less than Start date.");
    }

    setState((prevState) => ({ ...prevState, isLoading: true }));

    const { error } = await Service.generateMultipleInvoices({
      from_date: startDate,
      to_date: endDate,
      customer_id: multipleLocation.length > 0 ? null : customer?.id,
      pdx_company_id: pdxCompany?.id,
      branchIds:
        multipleLocation.length > 0
          ? multipleLocation.map((ele) => ele?.id)
          : null,
      ignoreDupInvoice: ignoreDupInvoices,
      invoice_date: invoiceDate,
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (error[0]?.code === "PDX-IVP-001") {
        fetchEntries();
        return setState((prevState) => ({
          ...prevState,
          isinvoiceIdsPresent: true,
          invoiceIds: error[0]?.invoiceIds,
        }));
      }
      if (error[0]?.code === "PDX-IVP-002") {
        fetchEntries();
        return setState((prevState) => ({
          ...prevState,
          isRecordPresent: true,
          presentInvoiceIds: error[0]?.invoiceIds,
        }));
      }
      if (error[0]?.code === "PDX-IVMC-002") {
        return setState((prevState) => ({
          ...prevState,
          noRecordBranch: true,
          presentBranchIds: error[0]?.branchIds,
        }));
      }
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice generated successfully.");
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    onClose();
    updatePageConfig("view-invoice");
    history.push({
      pathname: "/invoice/view_invoice",
      state: { pulsingEffect: true },
    });
  };

  const fetchEntries = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isDataFetching: true }));
    const { data, error } = await Service.getAllInvoice();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isDataFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isDataFetching: false,
      entries: data.rows || defaultState.entries,
    }));
    return data;
  }, []);

  const invoiceColumnConfig = [
    {
      id: "serial_no",
      label: "Invoice No.",
      field: "serial_no",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const title = row.serial_no;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2">{title}</Typography>
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      field: "status",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const title =
          INVOICE_STATUS.find((item) => item.value === row?.status)?.label ||
          "-";

        const getStatusLabel = () => {
          if (row.is_overdue) {
            return `${title} / Overdue`;
          }
          if (
            getUTCDateString(row?.payment_completed_date) >
            getUTCDateString(row?.payment_due_date)
          ) {
            return `${title} / Late Payment Received`;
          }
          return title;
        };

        const statusLabel = getStatusLabel();

        return (
          <Tooltip title={statusLabel} placement="top-start">
            <Typography variant="body2">{statusLabel}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const title = getUTCDateString(row.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  const branchColumnConfig = [
    {
      id: "location",
      label: "Location",
      field: "location",
      canSort: true,
      render: (row) => {
        const title = row.location;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2">{title}</Typography>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <Dialog.Title hasClose>Generate Invoice</Dialog.Title>
        <Dialog.Content>
          <FormControlLabel
            className=" ml-1 mb-4"
            labelPlacement="start"
            label="Generate multiple invoices for a customer:"
            control={
              <Checkbox
                color="primary"
                checked={state.multipleInvoices}
                onChange={(evt) => {
                  const isMultiple = evt.target.checked;
                  setState((prevState) => ({
                    ...prevState,
                    multipleInvoices: isMultiple,
                    location: defaultState.location,
                    route: defaultState.route,
                    multipleLocation: defaultState.multipleLocation,
                  }));
                }}
              />
            }
          />
          <div className="d-flex f-align-center">
            <Datepicker
              mask
              preventClear
              className="mb-4"
              label="Start date"
              selected={state.startDate}
              maxDate={new Date(state.endDate)}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
              onChange={(startDate) => {
                setState((prevState) => ({
                  ...prevState,
                  startDate: getDateString(startDate),
                }));
              }}
            />
            <Datepicker
              mask
              preventClear
              label="End date"
              className="mb-4"
              selected={state.endDate}
              minDate={new Date(state.startDate)}
              maxDate={new Date()}
              classes={{
                input: {
                  root: clsx("ml-2 mt-4", classes.datepickerWrapper),
                },
              }}
              onChange={(endDate) => {
                setState((prevState) => ({
                  ...prevState,
                  endDate: getDateString(endDate),
                }));
              }}
            />
          </div>
          <Datepicker
            mask
            preventClear
            label="Invoice Date"
            className="mb-4"
            selected={state.invoiceDate}
            minDate={new Date(state.startDate)}
            maxDate={new Date()}
            classes={{
              input: {
                root: clsx("mr-4 mt-4", classes.datepickerWrapper),
              },
            }}
            onChange={(date) => {
              setState((prevState) => ({
                ...prevState,
                invoiceDate: getDateString(date),
              }));
            }}
          />
          <div className="d-flex f-align-center">
            <Autocomplete
              disableClearable
              fullWidth
              className="mr-4 mt-4"
              size="small"
              value={state.pdxCompany}
              options={state.pdxCompanyList}
              getOptionLabel={(option) => option.value || ""}
              renderInput={(params) =>
                state.isFetchingList.pdxCompany ? (
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height="42px"
                    className="mb-4"
                  />
                ) : (
                  <TextField
                    {...params}
                    required
                    label="PDX Company"
                    variant="outlined"
                    helperText=" "
                  />
                )
              }
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  pdxCompany: value,
                  location: defaultState.location,
                  route: defaultState.route,
                  customer: defaultState.customer,
                  multipleLocation: defaultState.multipleLocation,
                }));
              }}
            />
            <Autocomplete
              disableClearable
              fullWidth
              className="mt-4"
              size="small"
              disabled={!state.pdxCompany}
              value={state.customer}
              options={customerList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Customer"
                  variant="outlined"
                  helperText=" "
                />
              )}
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  customer: value,
                  location: defaultState.location,
                  route: defaultState.route,
                  multipleLocation: defaultState.multipleLocation,
                }));
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            {state.multipleInvoices ? (
              <Autocomplete
                className="mr-4 mt-4"
                size="small"
                fullWidth
                classes={{
                  paper: "mb-2",
                }}
                value={state.multipleLocation}
                multiple
                disabled={!state.customer}
                ChipProps={{
                  component: (a) => {
                    return (
                      <div>
                        <Tooltip
                          placement={"top-start"}
                          title={a.children[1]?.props?.children || ""}
                        >
                          <Chip
                            size="small"
                            className={classes.chip}
                            width={15}
                            label={
                              <Typography variant="body3" noWrap>
                                {a.children[1]?.props?.children.length > 4
                                  ? a.children[1]?.props?.children.slice(0, 4) +
                                    "..."
                                  : a.children[1]?.props?.children}
                              </Typography>
                            }
                            deleteIcon
                            onDelete={(evt) => {
                              a.children[2]?.props?.onClick(evt);
                            }}
                          />
                        </Tooltip>
                      </div>
                    );
                  },
                }}
                options={branchList}
                disableCloseOnSelect={true}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.location || option || ""}
                  </React.Fragment>
                )}
                getOptionLabel={(option) => option?.location || option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    variant="outlined"
                    helperText=" "
                  />
                )}
                onChange={(evt, multipleLocation) => {
                  setState((prevState) => ({
                    ...prevState,
                    multipleLocation,
                    route: defaultState.route,
                  }));
                }}
              />
            ) : (
              <Autocomplete
                fullWidth
                disableClearable
                className="mr-4 mt-4"
                disabled={!state.customer}
                size="small"
                classes={{
                  paper: "mb-2",
                }}
                value={state.location}
                options={branchList}
                getOptionLabel={(option) => option?.location || option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    variant="outlined"
                    required
                    helperText=" "
                  />
                )}
                onChange={(evt, location) => {
                  setState((prevState) => ({
                    ...prevState,
                    location,
                    route: defaultState.route,
                  }));
                }}
              />
            )}

            <Autocomplete
              className="mt-4"
              size="small"
              fullWidth
              classes={{
                paper: "mb-2",
              }}
              value={state.route}
              multiple
              disabled={!state.location || state.multipleInvoices}
              ChipProps={{
                component: (a) => {
                  return (
                    <div>
                      <Tooltip
                        placement={"top-start"}
                        title={a.children[1]?.props?.children || ""}
                      >
                        <Chip
                          size="small"
                          className={classes.chip}
                          width={15}
                          label={
                            <Typography variant="body3" noWrap>
                              {a.children[1]?.props?.children.length > 4
                                ? a.children[1]?.props?.children.slice(0, 4) +
                                  "..."
                                : a.children[1]?.props?.children}
                            </Typography>
                          }
                          deleteIcon
                          onDelete={(evt) => {
                            a.children[2]?.props?.onClick(evt);
                          }}
                        />
                      </Tooltip>
                    </div>
                  );
                },
              }}
              options={routeList}
              disableCloseOnSelect={true}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <span
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    {option?.name || option || ""}
                    {!option?.is_active && (
                      <Tooltip title="Inactive Route" placement="top-start">
                        <ToggleOffIcon style={{ color: "red", fontSize: 30 }} />
                      </Tooltip>
                    )}
                  </span>
                </React.Fragment>
              )}
              getOptionLabel={(option) => option?.name || option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Route"
                  variant="outlined"
                  helperText=" "
                />
              )}
              onChange={(evt, route) => {
                setState((prevState) => ({
                  ...prevState,
                  route,
                }));
              }}
            />
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              disabled={
                state.isLoading ||
                (!state.multipleInvoices && !state.location) ||
                (state.multipleInvoices && !state.customer)
              }
              onClick={() =>
                state.multipleInvoices
                  ? handleGenerateMultipleInvoices(false)
                  : handleSubmit(false)
              }
            >
              Generate
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
      {state.isRecordPresent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.isRecordPresent}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                For the selected date range, some invoices have been created.
                The list is shown below. Click Continue to create the remaining
                invoices.
              </Typography>
              <br></br>
              <Grid
                columns={invoiceColumnConfig}
                rows={state.entries.filter((entry) =>
                  state.presentInvoiceIds.includes(entry.id)
                )}
                actionBarConfig={null}
                isLoading={state.isDataFetching}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          onConfirm={() => {
            state.multipleInvoices
              ? handleGenerateMultipleInvoices(true)
              : handleSubmit(true);
            setState((prevState) => ({
              ...prevState,
              isRecordPresent: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              isRecordPresent: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              Continue
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.isinvoiceIdsPresent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.isinvoiceIdsPresent}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                Invoice(s) have already been created for this date range.
              </Typography>
              <br></br>
              <Grid
                columns={invoiceColumnConfig}
                rows={state.entries.filter((entry) =>
                  state.invoiceIds.includes(entry.id)
                )}
                actionBarConfig={null}
                isLoading={state.isDataFetching}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          onConfirm={() =>
            setState((prevState) => ({
              ...prevState,
              isinvoiceIdsPresent: false,
            }))
          }
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isinvoiceIdsPresent: false,
            }));
          }}
        />
      )}
      {state.noRecordBranch && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.noRecordBranch}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                The following location(s) have no records to create an invoice.
                Please unselect these location(s) first.
              </Typography>
              <br></br>
              <Grid
                columns={branchColumnConfig}
                rows={branchList.filter((obj) =>
                  state.presentBranchIds.includes(obj.id)
                )}
                actionBarConfig={null}
                isLoading={state.isDataFetching}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              noRecordBranch: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="Close"
        />
      )}
    </>
  );
};

export default GenerateInvoiceDialog;
