import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { VALIDATIONS, isTransferScheduleEnable, noop } from "shared/constants";
import { PERMISSION, ROLE } from "modules/shared/constants";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Service from "../service";
import { AppContext } from "shared/contexts";
import SharedService from "../../shared/service";
import {
  queryStringBuilderNew,
  getTokenData,
  getDateString,
  getPageConfig,
  hasFeatureAccess,
  getUTCDateString,
  getFormattedTime,
  setPageConfig,
  updatePageConfig,
  updateLayout,
  validator,
} from "utils";
import { ActionDialog, FilterComponent } from "shared/components";
import TransferView from "./transferView";
import FlagIcon from "@material-ui/icons/Flag";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { PAGE_KEYS } from "shared/constants";
import useStyles from "./style";
import WarningIcon from "@material-ui/icons/Warning";
import {
  Badge,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TransferWhiteIcon from "../../../assets/icons/transfer-white.svg";
import TransferYellowIcon from "../../../assets/icons/transfer-yellow.svg";
import ForceSubmitDialog from "modules/daily-schedule/forceSubmit";

let gridHelper = null,
  timeout = null;

const defaultState = {
  isFetching: false,
  isFetchingCautionFlagTypeList: false,
  isFetchingList: {
    customers: false,
    users: false,
    IC: false,
    pendingItems: false,
    submissionHistory: false,
    pdxCompany: false,
    standByRateTypes: false,
  },
  standByRateTypeList: [],
  rejectedNotes: "",
  errors: {
    rejectedNotes: " ",
  },
  pageFilters: [],
  customerlocationList: [],
  locationList: [],
  cautionFlagSubmitReasonList: [],
  stateList: [],
  pdxCompanyList: [],
  isFiltering: false,
  transferEntries: [],
  totalEntries: 0,
  selectedRows: [],
  scheduleDate: null,
  endDate: null,
  userRole: null,
  selectedKAM: null,
  selectedTransferToKAM: null,
  selectedLocation: [],
  vehicleTypeList: [],
  isTransferLoading: false,
  isTransferStatusDialog: false,
  isTransferType: "",
  offset: 0,
  reachedEnd: false,
  isInfiniteLoading: false,
  order: null,
  orderBy: null,
  openForceSubmitDialog: false,
  filters: {
    statusSubmitted: [],
    statusPending: [],
    acceptedType: [],
  },
  dynamicColumns: {
    select_all: {
      label: "Select All",
      value: "select_all",
      isSelected: true,
    },
    schedule_date: {
      label: "Schedule Date",
      value: "schedule_date",
      isSelected: true,
    },
    original_kam: {
      label: "Original KAM",
      value: "original_kam",
      isSelected: true,
    },
    transfer_to: {
      label: "Transfer To KAM",
      value: "transfer_to",
      isSelected: true,
    },
    transfer_notes: {
      label: "Transfer Notes",
      value: "transfer_notes",
      isSelected: true,
    },
    is_accepted: {
      label: "Is Accepted",
      value: "is_accepted",
      isSelected: true,
    },
    accepted_by: {
      label: "Accepted By",
      value: "accepted_by",
      isSelected: true,
    },
    status: {
      label: "Status",
      value: "status",
      isSelected: true,
    },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    state: { label: "State", value: "state", isSelected: true },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    vehicle: { label: "Vehicle", value: "vehicle", isSelected: true },
    icid: { label: "ICID/IC Name", value: "icid", isSelected: true },

    pay_rate_type: {
      label: "Pay Rate Type",
      value: "pay_rate_type",
      isSelected: true,
    },
    pay_rate: { label: "Pay Rate", value: "pay_rate", isSelected: true },
    pay_quantity: {
      label: "Pay Quantity",
      value: "pay_quantity",
      isSelected: true,
    },
    pay_fuel_surcharge: {
      label: "Pay Fuel Surcharge%",
      value: "pay_fuel_surcharge",
      isSelected: true,
    },
    pay_toll_amount: {
      label: "Pay Toll Amount",
      value: "pay_toll_amount",
      isSelected: true,
    },
    pay_extra_miles: {
      label: "Pay Extra Miles",
      value: "pay_extra_miles",
      isSelected: true,
    },
    kam_notes: { label: "KAM Notes", value: "kam_notes", isSelected: true },
    review_notes: {
      label: "Review Notes",
      value: "review_notes",
      isSelected: true,
    },
    review_reason: {
      label: "Review Reason",
      value: "review_reason",
      isSelected: true,
    },
    submitted_by: {
      label: "Submitted By",
      value: "submitted_by",
      isSelected: true,
    },
    submitted_at: {
      label: "Last Submitted At",
      value: "submitted_at",
      isSelected: true,
    },
  },
};

const ViewTransferSchedule = ({ match }) => {
  const classes = useStyles();
  const userData = getTokenData();
  const pageConfig = getPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE);
  const isRoleKAM = (userData?.role || "").toLowerCase() === ROLE.KAM;
  const history = useHistory();
  const { setHeaderElements, appData, updateContextData } =
    useContext(AppContext);

  const [state, setState] = useState({
    ...defaultState,
    scheduleDate:
      pageConfig && !!pageConfig?.scheduleDate
        ? pageConfig?.scheduleDate
        : (() => {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - 22);
            return getDateString(startDate);
          })(),
    endDate:
      pageConfig && !!pageConfig?.endDate
        ? pageConfig?.endDate
        : (() => {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() + 7);
            return getDateString(startDate);
          })(),
    selectedKAM:
      pageConfig && !!pageConfig?.kam
        ? pageConfig?.kam
        : defaultState.selectedKAM,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    selectedLocation:
      (pageConfig && pageConfig?.locationFilter) ||
      defaultState.selectedLocation,
    selectedTransferToKAM: isRoleKAM
      ? userData
      : pageConfig && !!pageConfig?.transferToKAM
      ? pageConfig?.transferToKAM
      : defaultState.selectedTransferToKAM,
    userRole: (userData.role || "").toLowerCase(),
    filters: {
      statusSubmitted: (pageConfig && pageConfig?.statusSubmitted) || [
        {
          field: "status",
          type: "iLike",
          value: "submitted",
        },
      ],
      statusPending: (pageConfig && pageConfig?.statusPending) || [
        {
          field: "status",
          type: "iLike",
          value: "pending",
        },
      ],
      acceptedType: (pageConfig && pageConfig?.acceptedType) || [],
    },
  });

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.transferEntries]);

  const kamId = isRoleKAM ? userData?.id : state.selectedKAM?.id;
  const canViewAllSchedules = hasFeatureAccess(PERMISSION.VIEW_ALL_SCHEDULES);

  const fetchCustomerLocation = async () => {
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
    }));
  };

  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
      }
    );

    const IC = appData.IC.filter((ic) => ic.is_active);
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    const getOptionList = (users = []) => {
      return users.map((list) => ({
        value: list,
        label:
          list?.name ||
          list?.full_name ||
          `${list.first_name || ""} ${list.last_name || ""}`,
      }));
    };

    const ICOptions = IC.map((list) => ({
      value: list,
      label: `${list?.icid || ""}/${list?.first_name || ""} ${
        list?.last_name || ""
      }`,
    }));

    return {
      kamUsers: users.kam,
      IC,
      customers,
      kamUsersOptions: getOptionList(users.kam),
      ICOptions,
    };
  }, [appData]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      rejectedNotes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const locationOPtions = Object.entries(state.customerlocationList)
    .map(([state, value]) =>
      value.map((item) => ({
        label: `${item}-(${state})`,
        value: item,
      }))
    )
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const filterConfig = useMemo(
    () => [
      {
        field: "route_name",
        fieldToDisplay: "Route",
        operatorType: "string",
        options: appDataList?.customers
          .map((customer) => customer?.customer_branches)
          .flat()
          .map((branches) => branches?.routes)
          .flat()
          .map((route) => ({ label: route.name, value: route.name }))
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },
      {
        field: "state",
        fieldToDisplay: "State",
        operatorType: "iLike",
        options: state.stateList?.map((state) => ({
          label: state.name,
          value: state.abbreviation,
        })),
      },
      {
        field: "pdx_company_name",
        fieldToDisplay: "PDX Company",
        operatorType: "string",
        options: state.pdxCompanyList?.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "vehicle_type",
        fieldToDisplay: "Vehicle",
        operatorType: "string",
        options: state.vehicleTypeList?.map((vehicle) => ({
          ...vehicle,
          label: `${vehicle.value}`,
          value: vehicle.value,
        })),
      },

      {
        field: "pay_rate_type",
        fieldToDisplay: "Pay Rate Type",
        operatorType: "string",
        options: Array.from(
          new Map(
            [
              { label: "Daily", value: "Daily" },
              { label: "Hourly", value: "Hourly" },
              { label: "Per Miles", value: "Per Miles" },
              { label: "Per Tire", value: "Per Tire" },
              { label: "Per Run", value: "Per Run" },
              ...state.standByRateTypeList?.map((standRateType) => ({
                label: standRateType.value,
                value: standRateType.value,
              })),
            ].map((item) => [item.value, item])
          ).values()
        ),
      },

      {
        field: "pay_rate",
        fieldToDisplay: "Pay Rate",
        operatorType: "number",
      },

      {
        field: "pay_quantity",
        fieldToDisplay: "Pay Quantity",
        operatorType: "number",
      },
      {
        field: "pay_fuel_surcharge",
        fieldToDisplay: "Pay Fuel Surcharge%",
        operatorType: "number",
      },

      {
        field: "pay_toll_amount",
        fieldToDisplay: "Pay Toll Amount",
        operatorType: "number",
      },

      {
        field: "pay_extra_miles",
        fieldToDisplay: "Pay Extra Miles",
        operatorType: "number",
      },
      {
        field: "submitted_by",
        fieldToDisplay: "Submitted By",
        operatorType: "eq",
        options: appData?.users?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""}-(${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "submitted_at",
        fieldToDisplay: "Last Submitted At",
        operatorType: "range",
        isDateField: true,
      },
    ],
    [
      state.vehicleTypeList,
      state.stateList,
      state.pdxCompanyList,
      appDataList.customers,
      appData?.users,
    ]
  );

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      transferEntries: [],
      offset: defaultState.offset,
      pageFilters,
    }));
    setPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE, {
      filters: pageFilters,
    });
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      transferEntries: defaultState.transferEntries,
      order: order,
      offset: defaultState.offset,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const fetchEntries = useCallback(
    async (
      filters,
      order,
      orderBy,
      selectedKAM,
      selectedTransferToKAM,
      selectedLocation,
      pageFilters = [],
      offset = 0,
      loading = "isFetching",
      hasLoader = true
    ) => {
      const sortObj = { field: orderBy, order };
      let filtersArr = [
        ...pageFilters.map((filter) => {
          if (filter?.field === "submitted_at" && filter?.type === "eq") {
            return [
              {
                ...filter,
                field: "submitted_at",
                type: "lte",
                value: `${filter?.value} 23:59:59.999999`,
              },
              {
                ...filter,
                field: "submitted_at_gte",
                type: "gte",
                value: `${filter?.value} 00:00:00`,
              },
            ];
          } else if (
            filter?.field === "submitted_at" &&
            filter?.type === "ra"
          ) {
            const [startDate, endDate] = filter?.value.split(" - ");
            return [
              {
                ...filter,
                field: "submitted_at",
                type: "gte",
                value: `${startDate} 00:00:00`,
              },
              {
                ...filter,
                field: "submitted_at_gte",
                type: "lte",
                value: `${endDate} 23:59:59.999999`,
              },
            ];
          } else {
            return {
              ...filter,
              value: filter?.value?.value || filter?.value,
            };
          }
        }),
      ].flat();

      if (selectedKAM?.id) {
        filtersArr.push({
          field: "kam_id",
          type: "=",
          value: selectedKAM.id,
          notIncludeInBuilder: true,
        });
      }

      if (selectedTransferToKAM?.id) {
        filtersArr.push({
          field: "transfer_to",
          type: "=",
          value: selectedTransferToKAM.id,
          notIncludeInBuilder: true,
        });
      }

      if (selectedLocation.length) {
        filtersArr.push({
          field: "location",
          notIncludeInBuilder: false,
          type: "=",
          value: selectedLocation?.map((item) => item.value),
        });
      }

      if (filters.acceptedType?.length) {
        filtersArr = [...filtersArr, ...filters.acceptedType];
      }
      if (filters.statusSubmitted?.length) {
        filtersArr = [...filtersArr, ...filters.statusSubmitted];
      }
      if (filters.statusPending?.length) {
        filtersArr = [...filtersArr, ...filters.statusPending];
      }

      let queryString = queryStringBuilderNew(
        0,
        0,
        [],
        filtersArr,
        sortObj,
        []
      );

      setState((prevState) => ({
        ...prevState,
        [loading]: hasLoader,
      }));

      if (queryString.includes("submitted_at_gte")) {
        queryString = queryString.replace(/submitted_at_gte/g, "submitted_at");
      }

      queryString += `${
        !filtersArr.length ? "?" : "&"
      }filter[limit]=50&filter[offset]=${offset}&${
        !selectedTransferToKAM?.id
          ? "filter[where][and][0][or][0][transfer_to][neq]=null"
          : ""
      }`;

      const { data, error } = await Service.get(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => {
        return {
          ...prevState,
          transferEntries:
            [...prevState.transferEntries, ...data.transferRecords] ||
            defaultState.transferEntries,
          totalEntries: data.count || defaultState.totalEntries,
          selectedRows: defaultState.selectedRows,
          [loading]: false,
        };
      });
      if (gridHelper) {
        gridHelper.resetSelection();
      }
    },
    []
  );

  const containerRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight &&
      state.totalEntries > 0
    ) {
      if (!state.isInfiniteLoading) {
        setState((prevState) => ({
          ...prevState,
          isInfiniteLoading: true,
        }));

        handleMoreEntries();
      }
    }
  }, [state.isInfiniteLoading, state.totalEntries]);

  const handleMoreEntries = () => {
    setState((prevState) => ({
      ...prevState,
      offset: state.offset + 50,
    }));

    fetchEntries(
      state.filters,
      state.order,
      state.orderBy,
      state.selectedKAM,
      state.selectedTransferToKAM,
      state.selectedLocation,
      state.pageFilters,
      state.offset + 50,
      "isInfiniteLoading"
    );
  };

  const handleApproveTransferSchedule = async (ids = []) => {
    setState((prevState) => ({ ...prevState, isTransferLoading: true }));

    const selectedRowsIds = state.transferEntries
      .filter((ele) => ids.includes(ele?.id) && !ele.is_accepted)
      .map((e) => e?.id);

    const { error } = await Service.approveTransferShedule({
      ds_ids: selectedRowsIds,
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isTransferLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(`Record(s) approved successfully.`);
    setState((prevState) => ({
      ...prevState,
      transferEntries: defaultState.transferEntries,
      isTransferLoading: false,
      selectedRows: defaultState.selectedRows,
    }));
    handleTransferStatusDialog(false);
    fetchList("transferRecords", noop);
    fetchEntries(
      state.filters,
      state.order,
      state.orderBy,
      state.selectedKAM,
      state.selectedTransferToKAM,
      state.selectedLocation,
      state.pageFilters
    );
    if (gridHelper) {
      gridHelper.resetSelection();
    }
  };
  const handleRejectTransferSchedule = async (ids = [], rejectedNotes) => {
    setState((prevState) => ({
      ...prevState,
      isTransferLoading: true,
    }));
    const { error } = await Service.rejectTransferShedule({
      ds_ids: ids,
      rejection_reason: rejectedNotes,
    });

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isTransferLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(`Record(s) rejected successfully.`);
    setState((prevState) => ({
      ...prevState,
      transferEntries: defaultState.transferEntries,
      rejectedNotes: defaultState.rejectedNotes,
      isTransferLoading: false,
      selectedRows: defaultState.selectedRows,
    }));
    handleTransferStatusDialog(false);
    fetchList("transferRecords", noop);
    fetchEntries(
      state.filters,
      state.order,
      state.orderBy,
      state.selectedKAM,
      state.selectedTransferToKAM,
      state.selectedLocation,
      state.pageFilters
    );
    if (gridHelper) {
      gridHelper.resetSelection();
    }
  };

  const handleTransferStatusDialog = (value, type) => {
    setState((prevState) => ({
      ...prevState,
      isTransferStatusDialog: value,
      isTransferType: type || defaultState.isTransferType,
    }));
  };

  useEffect(() => {
    return () => {
      if (isRoleKAM) {
        updateContextData("pendingItems", []);
      }
      if (canViewAllSchedules) {
        updateContextData("transferRecords", []);
      }
    };
  }, []);

  useEffect(() => {
    fetchEntries(
      state.filters,
      state.order,
      state.orderBy,
      state.selectedKAM,
      state.selectedTransferToKAM,
      state.selectedLocation,
      state.pageFilters
    );
  }, [
    fetchEntries,
    state.userRole,
    state.selectedKAM,
    state.selectedTransferToKAM,
    state.selectedLocation,
    state.pageFilters,
    state.filters,
    state.order,
    state.orderBy,
  ]);

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: true,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          submissionHistory: false,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: false,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  useEffect(() => {
    if (isTransferScheduleEnable && !isRoleKAM) {
      fetchList(
        "transferRecords",
        noop,
        kamId,
        state.selectedTransferToKAM?.id
      );
    }
    if (kamId && isRoleKAM) {
      fetchList("pendingItems", noop, kamId);
    }
  }, [kamId, state.selectedKAM, state.selectedTransferToKAM]);

  useEffect(() => {
    setHeaderElements([
      isTransferScheduleEnable &&
        canViewAllSchedules &&
        !!appData.transferRecords &&
        appData.transferRecords.length > 0 && (
          <div className="d-flex f-align-center" key="transferRecords">
            <Tooltip title="TRANSFER REQUEST RECEIVED" placement="top-start">
              <IconButton className="color-white">
                <Badge
                  color="error"
                  badgeContent={
                    appData.transferRecords.filter(
                      (item) => item.status === "PENDING"
                    ).length
                  }
                  className="c-pointer"
                >
                  <img
                    src={TransferWhiteIcon}
                    alt="reports"
                    height={30}
                    width={30}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        ),
      isRoleKAM && !!appData.pendingItems.length && (
        <div className="d-flex f-align-center m-2" key="pendingItems">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>
      ),
    ]);
    return () => setHeaderElements([]);
  }, [appData.pendingItems, appData.transferRecords]);

  const fetchCautionFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: true,
    }));

    const { data, error } = await SharedService.getCautionFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingCautionFlagTypeList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: false,
      cautionFlagSubmitReasonList: data?.rows || [],
    }));
  };

  useEffect(() => {
    fetchCautionFlagTypeList();
    fetchVehicleTypeList();
    fetchPDXCompanyList();
    fetchCustomerLocation();
    fetchStandByRateTypeList();
    fetchList("customers", noop);
    fetchList("IC");
    fetchList("users");
  }, [match.params.path]);

  const getDayClassName = useCallback(
    (date) => {
      const transferRecords = appData.transferRecords || [];

      const scheduleDateArray = transferRecords.map((item) =>
        getUTCDateString(item.schedule_date)
      );

      let dayClass;

      const dateString = getDateString(date);
      if (scheduleDateArray.includes(dateString)) {
        dayClass = clsx(classes.transferRecordRequest);
      }

      return state.isFetchingList.submissionHistory
        ? clsx("loading disabled", classes.colorNone)
        : clsx(classes.calendarDay, dayClass);
    },
    [state.isFetchingList.submissionHistory, appData.transferRecords, classes]
  );

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const fetchStandByRateTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        standByRateTypes: true,
      },
    }));
    const { data, error } = await SharedService.getstandByRateTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          standByRateTypes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      standByRateTypeList:
        data?.rows.filter((standByRateType) => standByRateType.is_active) ||
        defaultState.standByRateTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        standByRateTypes: false,
      },
    }));
  };

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleCalendarOpen = (type = "scheduleDate") => {
    setState((prevState) => ({
      ...prevState,
      isCalenderOpen: type !== "copyCalender",
    }));
  };

  const handleCalendarClose = () => {
    setState((prevState) => ({
      ...prevState,
      isCalenderOpen: false,
    }));
  };

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    state.cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center f-justify-end">
        <Tooltip
          title={`Initiate an action for the transfer schedule request scheduled for ${getUTCDateString(
            row.schedule_date
          )}`}
          placement="top-start"
        >
          <PlaylistAddIcon
            onClick={() => {
              let rowDate = getUTCDateString(row.schedule_date)
                .split("/")
                .join("-");
              const originalKam = row?.KAM?.id;
              let selectedKAM = appDataList.kamUsers.find(
                (kam) => kam?.id === originalKam
              );

              history.push({
                pathname: `/daily-schedule/day/${rowDate}/kam/${originalKam}`,
                state: {
                  rowDate,
                  selectedKAM: selectedKAM,
                  tabIndex: isRoleKAM ? 2 : 1,
                },
              });
            }}
            className="ml-2 mr-2 c-pointer"
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      id: "col-edit",
      render: (row) => moreActions(row),
    },
    {
      isHidden: !state.dynamicColumns?.schedule_date?.isSelected,
      id: "schedule_date",
      label: "Schedule Date",
      field: "schedule_date",
      canSort: true,
      render: (row) => {
        const scheduleDate = getUTCDateString(row.schedule_date);
        return (
          <Tooltip title={scheduleDate || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {scheduleDate || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.original_kam?.isSelected,
      id: "original_kam",
      label: "Original KAM",
      field: "original_kam",
      render: (row) => {
        const originalKam = appDataList.kamUsers.find(
          (data) => data?.id === row?.KAM?.id
        );
        let kamDetail;
        if (originalKam) {
          kamDetail = `${originalKam.first_name} ${
            originalKam.last_name || ""
          } - (${originalKam.username})`;
        }
        return (
          <Tooltip title={kamDetail ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamDetail || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.transfer_to?.isSelected,
      id: "transfer_to",
      label: "Transfer to KAM",
      field: "transfer_to",
      render: (row) => {
        const transferTo = appDataList.kamUsers.find(
          (data) => data?.id === row?.transfer_to
        );
        let kamDetail;
        if (transferTo) {
          kamDetail = `${transferTo.first_name} ${
            transferTo.last_name || ""
          } - (${transferTo.username})`;
        }
        return (
          <Tooltip title={kamDetail ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamDetail || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.transfer_notes,
      id: "transfer_notes",
      label: "Transfer Notes",
      field: "transfer_notes",
      render: (row) => {
        const flagLabel = row?.transfer_notes?.length ? (
          <img src={TransferYellowIcon} alt="reports" height={20} width={20} />
        ) : (
          "-"
        );

        return (
          <Tooltip title={row?.transfer_notes ?? ""} placement="top-start">
            <Typography noWrap variant="body2">
              {flagLabel}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.is_accepted?.isSelected,
      id: "is_accepted",
      label: "Is Accepted",
      field: "is_accepted",
      render: (row) => {
        return (
          <Tooltip
            title={(row.is_accepted ? "Yes" : "No") ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {(row.is_accepted ? "Yes" : "No") || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.accepted_by?.isSelected,
      id: "accepted_by",
      label: "Accepted By",
      field: "accepted_by",
      render: (row) => {
        const acceptedBy = appData.users.find(
          (data) => data?.id === row?.accepted_by
        );
        let kamDetail;
        if (acceptedBy) {
          kamDetail = `${acceptedBy.first_name} ${
            acceptedBy.last_name || ""
          } - (${acceptedBy.username})`;
        }
        return (
          <Tooltip title={kamDetail ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamDetail || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.status?.isSelected,
      id: "status",
      label: "Status",
      field: "status",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row?.status} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.status}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "customer_name",
      label: "Customer Name",
      fieldName: "customer_name",
      canSort: true,
      render: (row) => (
        <Tooltip
          title={row.customer_branch?.customer?.name || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.customer_branch?.customer?.name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.customer_branch?.location || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      id: "route_name",
      label: "Route",
      field: "route_name",
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip title={row?.routeDetails?.name ?? "-"} placement="top-start">
            <Typography noWrap variant="body2">
              {row?.routeDetails?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.state?.isSelected,
      id: "state",
      label: "State",
      field: "state",
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.state?.name ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.state?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdx_company?.isSelected,
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.pdxCompany?.value ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.pdxCompany?.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.vehicle?.isSelected,
      id: "vehicle_type",
      label: "Vehicle",
      field: "vehicle_type",
      canSort: true,
      render: (row) => {
        const title = `${row.vehicleType?.value || "-"}`;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.icid?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "individual_contractor_icid",
      label: "ICID/IC Name",
      field: "individual_contractor_icid",
      render: (row) => {
        let title = `
          IC Settlement Company Name: ${
            row.individual_contractor?.settlementCompany?.value || "-"
          }, 
        
          ICID: ${row.individual_contractor?.icid || "-"},
          IC Name: ${row.individual_contractor?.last_name || ""} ${
          row.individual_contractor?.first_name || ""
        },
        Contractor Type: ${
          row.individual_contractor?.contractorType?.value || "-"
        }
        ${row.ic_inactive ? ", Status: Inactive ICID" : ""}
        `;

        if (
          row.individual_contractor_changed &&
          row.original_individual_contractor_id
        ) {
          const orginalIC = appData.IC.find(
            (contractor) =>
              contractor?.id === row.original_individual_contractor_id
          );
          if (orginalIC) {
            title += `, Original IC Name: ${orginalIC.last_name || ""} ${
              orginalIC.first_name || ""
            }`;
          }
        }

        const label = row.individual_contractor?.icid
          ? `${row.individual_contractor?.icid || ""}/${
              row.individual_contractor?.last_name || ""
            } ${row.individual_contractor?.first_name || ""}`
          : "";

        const flagLabel =
          (!!row.individual_contractor?.is_flagged &&
            !!row.individual_contractor?.is_cautious_flagged) ||
          !!row.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.individual_contractor?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );
        const cautionFlaggedBy = userInfo(
          row?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row?.individual_contractor?.ctn_flag_rsn
        );
        const flagTitles = `Flagged Reason: ${
          row.individual_contractor?.flagReason?.value ||
          cautionFlaggedReason?.value
        }, Flagged By: ${
          row.individual_contractor?.flaggedBy?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          row.individual_contractor?.flaggedBy?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          row.individual_contractor?.flaggedBy?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          row.individual_contractor?.flaggedBy?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          row.individual_contractor?.flagged_at ||
            row?.individual_contractor?.ctn_flagged_at
        )}`;
        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {label}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_type?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "pay_rate_type",
      label: "Pay Rate Type",
      field: "pay_rate_type",
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate?.isSelected,
      id: "pay_rate",
      label: "Pay Rate",
      field: "pay_rate",
      startAdornment: "$",
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_quantity?.isSelected,
      id: "pay_quantity",
      label: "Pay Quantity",
      field: "pay_quantity",
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_fuel_surcharge?.isSelected,
      id: "pay_fuel_surcharge",
      label: "Pay Fuel Surcharge%",
      field: "pay_fuel_surcharge",
      canSort: true,
      render: (row) => {
        const label = row.pay_fuel_surcharge
          ? `${row.pay_fuel_surcharge || ""}%`
          : "0";

        return (
          <Tooltip title={label} placement="top-start">
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_toll_amount?.isSelected,
      id: "pay_toll_amount",
      label: "Pay Toll Amount",
      field: "pay_toll_amount",
      startAdornment: "$",
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_miles?.isSelected,
      id: "pay_extra_miles",
      label: "Pay Extra Miles",
      field: "pay_extra_miles",
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.kam_notes?.isSelected,
      id: "kam_notes",
      label: "KAM Notes",
      field: "kam_notes",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.kam_notes ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.kam_notes ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.review_notes?.isSelected,
      id: "review_notes",
      label: "Review Notes",
      field: "review_notes",
      canSort: true,
      render: (row) => {
        let finalNotes;
        if (row.review_notes !== null && !!row.review_notes?.length) {
          const splitNotes = row.review_notes?.split("||");

          finalNotes = splitNotes?.map((note) => {
            let str = note;

            const startIndexOfDate = str?.indexOf("-");
            const lastIndexOfDate = str?.indexOf(": ");
            const formattedDate = new Date(
                str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
              )?.toLocaleString(),
              str1 =
                str.substring(0, startIndexOfDate) +
                "-" +
                formattedDate +
                str.substring(lastIndexOfDate);
            return str1;
          });
        }

        const title =
          row.review_notes !== null && !!row.review_notes?.length ? (
            <div
              dangerouslySetInnerHTML={{
                __html: (finalNotes || []).join("<br>"),
              }}
            />
          ) : (
            "-"
          );

        return (
          <Tooltip title={title || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {finalNotes || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.review_reason?.isSelected,
      id: "review_reason",
      label: "Review Reason",
      field: "review_reason",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.review_reason || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.review_reason || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.submitted_by?.isSelected,
      id: "submitted_by",
      label: "Submitted By",
      field: "submitted_by",
      canSort: true,
      render: (row) => {
        const kam = appData.users.find(
          (ele) => ele?.id === row?.submitted_by || null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }
        return (
          <Tooltip title={submittedBy ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {submittedBy || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "submitted_at",
      label: "Last Submitted At",
      isHidden: !state.dynamicColumns?.submitted_at?.isSelected,
      fieldName: "submitted_at",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const submitAt = `${
          !!row.submitted_at
            ? `${getDateString(row.submitted_at)} ${getFormattedTime(
                new Date(row.submitted_at)
              )}`
            : "-"
        }`;
        return (
          <Tooltip title={submitAt || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {submitAt}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <TransferView
        state={state}
        classes={classes}
        PAGE_KEYS={PAGE_KEYS}
        columnConfig={columnConfig}
        appDataList={appDataList}
        containerRef={containerRef}
        handleScroll={handleScroll}
        isRoleKAM={isRoleKAM}
        locationOPtions={locationOPtions}
        setState={setState}
        updatePageConfig={updatePageConfig}
        handleCalendarClose={handleCalendarClose}
        handleCalendarOpen={handleCalendarOpen}
        getDayClassName={getDayClassName}
        handleSelectionChange={handleSelectionChange}
        handleGridHelper={(gridInstance) => (gridHelper = gridInstance)}
        handleTransferStatusDialog={handleTransferStatusDialog}
        rowDataFetched={state.rowDataFetched}
        handleSortChange={handleSortChange}
      />
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
      {state.isTransferStatusDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paperWidthSm: classes.paperWidthSm,
          }}
          open={state.isTransferStatusDialog}
          contentText={
            state.isTransferType === "reject" ? (
              <>
                <Typography variant="body2" className="mt-2">
                  Do you want to reject this transfer schedule?
                </Typography>
                <br></br>
                <TextField
                  className="mb-1"
                  fullWidth
                  name="rejectedNotes"
                  label="Rejection Reason"
                  variant="outlined"
                  size="small"
                  value={state.rejectedNotes}
                  error={!!state.errors.rejectedNotes?.trim()}
                  helperText={state.errors.rejectedNotes}
                  onChange={(evt) => handleFieldChange(evt)}
                />
              </>
            ) : (
              <Typography variant="body2" className="mt-2">
                Do you want to approve this transfer schedule?
              </Typography>
            )
          }
          onConfirm={() => {
            if (state.isTransferType === "approve") {
              handleApproveTransferSchedule(state.selectedRows);
            } else if (state.isTransferType === "reject") {
              handleRejectTransferSchedule(
                state.selectedRows,
                state.rejectedNotes
              );
            }
          }}
          onCancel={() => {
            handleTransferStatusDialog(false);
            if (state.isTransferType === "reject") {
              setState((prevState) => ({
                ...prevState,
                errors: {
                  ...prevState.errors,
                  rejectedNotes: " ",
                },
                rejectedNotes: defaultState.rejectedNotes,
              }));
            }
          }}
          isConfirmDisabled={
            state.isTransferLoading ||
            (state.isTransferType === "reject" &&
              !!state.errors.rejectedNotes?.trim())
          }
          positiveActionLabel={
            <>
              OK
              {state.isTransferLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.openForceSubmitDialog && (
        <ForceSubmitDialog
          open={state.openForceSubmitDialog}
          pendingItems={appData.pendingItems.map((date, index) => ({
            date,
            id: index,
          }))}
          forceSubmitReasonList={state.forceSubmitReasonList}
          onClose={handleClose}
          kamId={kamId}
          onForceSubmit={() => fetchList("pendingItems", kamId)}
        />
      )}
    </>
  );
};

export default ViewTransferSchedule;
