import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { noop } from "shared";
import { preventInputKeyCodes, validator } from "utils";
import { Dialog } from "shared/components";
import { VALIDATIONS } from "shared/constants";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {
  RATE_TYPES,
  INVOICE_TYPES,
  REDUCTION_TYPES,
  VEHICLE_TYPES,
} from "modules/shared/constants";
import Service from "../service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles from "./style";
import { Chip, Switch, Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

const billPriceMap = {};
const payPriceMap = {};
const dilaogDimension = {
  customer: {
    height: 370,
    width: 400,
  },
  branch: {
    height: 550,
    width: 550,
  },
  route: {
    height: 500,
    width: 720,
  },
};

let shouldShowAlert = {
  pay_rate: false,
  pay_quantity: false,
  bill_rate: false,
  bill_quantity: false,
};
let isFieldChange = false;
const defaultState = {
  isFormInvalid: false,
  filteredOptions: [],
  priceMatrixList: [],
  hasMatch: true,
  customer: {
    name: "",
    lookup: "",
    inv_pymt_terms: 0,
  },
  branch: {
    customer: null,
    location: null,
    state: null,
    invoiceType: null,
    pdxCompany: null,
    office: null,
    mgrUser: null,
    kamUser: null,
    fuelSurchargeSchedule: null,
    defaultBillDiscount: null,
    defaultPayDiscount: null,
    defaultFuelType: null,
    email: "",
    newEmail: [],
  },
  route: {
    customer: null,
    location: null,
    isPriceMatrixListFetched: false,
    priceMatrixName: null,
    name: "",
    vehicle_type: null,
    default_bill_rate_type: RATE_TYPES[0],
    default_bill_rate: "0",
    default_bill_quantity: "1",
    bill_rate_per_extra_mile: 0,
    default_pay_rate_type: RATE_TYPES[0],
    default_pay_rate: "0",
    default_pay_quantity: "1",
    pay_rate_per_extra_mile: 0,
    description: "",
    fuelSurchargeSchedule: null,
    defaultBillDiscount: null,
    defaultPayDiscount: null,
    defaultFuelType: null,
    pay_toll_amount: null,
    bill_toll_amount: null,
    is_weekday: true,
    rosterValue: "weekdays",
    price_type: "Standard",
    selectedBillRate: "",
    selectedPayRate: "",
    isRoasterPresent: false,
  },
  errors: {
    customer: {
      name: " ",
      type: " ",
      inv_pymt_terms: " ",
    },
    branch: {
      pdxCompany: " ",
      location: " ",
      state: " ",
      email: " ",
    },
    route: {
      customer: " ",
      location: " ",
      name: " ",
      vehicle_type: " ",
      default_bill_rate: " ",
      default_bill_quantity: " ",
      bill_rate_per_extra_mile: " ",
      default_pay_rate: " ",
      default_pay_quantity: " ",
      pay_rate_per_extra_mile: " ",
      description: " ",
      pay_toll_amount: " ",
      bill_toll_amount: " ",
    },
  },
};

const Form = ({
  isTabletView = false,
  open = false,
  customers = [],
  isNew = false,
  isPriceMatrixFetched = false,
  isDirect = false,
  priceMatrix = [],
  isButton = false,
  customerBranchEntry = null,
  customerRouteEntry = null,
  entry = null,
  copyEntry = null,
  stateList = [],
  locationList = [],
  customerlocationList = [],
  pdxCompanyList = [],
  routeBillFsList = [],
  routePayFsList = [],
  customerTypeList = [],
  kamUsersOptions = [],
  officeUsersOptions = [],
  managerUsersOptions = [],
  vehicleTypeList = [],
  customersList = [],
  formType = null,
  customer_branch_id = null,
  customer_id = null,
  customFuelSurchargeList = [],
  formAlertFields = {},
  onClose = noop,
  getPzriceMatrix = noop,
  fetchLocalResource = noop,
  showFieldAlert = noop,
}) => {
  const classes = useStyles({
    ...dilaogDimension[formType],
    isTabletView,
  });
  const [state, setState] = useState(defaultState);

  const handleFieldBlur = (evt) => {
    if (!isNew) {
      const field = evt.currentTarget?.name || evt.target?.name;
      const value = evt.currentTarget?.value || evt.target?.value;
      const {
        default_pay_rate,
        default_pay_quantity,
        default_bill_rate,
        default_bill_quantity,
      } = state.errors.route;
      showFieldAlert(
        field,
        {
          ...state.route,
          [field]: value,
        },
        {
          default_pay_rate,
          default_pay_quantity,
          default_bill_rate,
          default_bill_quantity,
        }
      );
    }
  };

  const location = (
    customersList.find((customer) => customer.id === state.route?.customer?.id)
      ?.customer_branches || []
  )
    .filter((location) => location.is_active)
    .sort((a, b) => {
      const valueA = (a.location || "").toLowerCase();
      const valueB = (b.location || "").toLowerCase();
      return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
    });

  const fetchPriceMatrixList = async () => {
    setState((prevState) => ({
      ...prevState,
      route: {
        ...prevState.route,
        isPriceMatrixListFetched: true,
      },
    }));
    const locationId = state.route.location.id;

    const { data, error } = await Service.getPriceMatrixNames(locationId);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        route: {
          ...prevState.route,
          isPriceMatrixListFetched: false,
        },
      }));
      return console.log(
        Array.isArray(error) ? error[0]?.message : error.message
      );
      // return toast.error(
      //   Array.isArray(error) ? error[0]?.message : error.message
      // );
    }

    setState((prevState) => ({
      ...prevState,
      priceMatrixList: data?.priceMatrixNames || defaultState.priceMatrixList,
      route: {
        ...prevState.route,
        isPriceMatrixListFetched: false,
      },
    }));
  };

  useEffect(() => {
    if (!!state.route.location) {
      fetchPriceMatrixList();
      getPzriceMatrix();
    }
  }, [state.route.location]);

  useEffect(() => {
    if (isNew && formType === "branch" && !isButton) {
      setState((prevState) => ({
        ...prevState,
        id: customerBranchEntry.id || defaultState?.id,
        branch: {
          customer:
            customers.find(
              (customer) => customer.id === customerBranchEntry.id
            ) || defaultState.branch.customer,
        },
      }));
    } else if (isNew && formType === "route" && !isButton) {
      setState((prevState) => ({
        ...prevState,
        id: customerRouteEntry.customer_id || defaultState?.id,
        route: {
          ...prevState.route,
          customer:
            customers.find(
              (customer) => customer.id === customerRouteEntry.customer_id
            ) || defaultState.route.customer,
          location: customerRouteEntry || defaultState?.route.location,
        },
      }));
    } else if (entry && !isNew) {
      const entryLocation = customers
        .map((customer) =>
          customer.customer_branches.find(
            (branch) => branch.id === entry.customer_branch_id
          )
        )
        .filter((item) => item?.location);

      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState?.id,
        customer: {
          name: entry.name || defaultState.customer?.name,
          lookup: entry.customerType || defaultState.customer?.lookup,
          inv_pymt_terms:
            entry.inv_pymt_terms || defaultState.customer?.inv_pymt_terms,
        },
        branch: {
          customer:
            customers.find((customer) => customer.id === entry.customer_id) ||
            defaultState.branch.customer,
          location: entry.location || defaultState.branch?.location,
          state: entry.state || defaultState.branch?.state,
          invoiceType:
            INVOICE_TYPES.find(
              (invoice) =>
                invoice.id === entry.invoice_type ||
                invoice.label === entry.invoice_type
            ) || defaultState.branch?.invoiceType,
          pdxCompany: entry.pdxCompany || defaultState.branch?.pdxCompany,
          office: entry.aaUser || defaultState.branch?.office,
          mgrUser: entry.mgrUser || defaultState.branch?.mgrUser,
          kamUser: entry.kamUser || defaultState.branch?.kamUser,
          email: defaultState.branch.email,
          fuelSurchargeSchedule:
            entry.fuel_surcharge_schedule ||
            defaultState.branch?.fuelSurchargeSchedule,
          defaultBillDiscount:
            entry.default_bill_discount ||
            defaultState.branch?.defaultBillDiscount,
          defaultPayDiscount:
            entry.default_pay_discount ||
            defaultState.branch?.defaultPayDiscount,
          defaultFuelType:
            entry.default_fuel_type || defaultState.branch?.defaultFuelType,
        },

        route: {
          customer:
            customers.find((customer) =>
              (customer.customer_branches || []).find(
                (branches) => branches?.id === entry.customer_branch_id
              )
            ) || defaultState.route.customer,
          location: entryLocation[0] || defaultState?.route.location,
          name: entry.name || defaultState.route?.name,
          vehicle_type: entry.vehicleType || defaultState.route.vehicle_type,
          default_bill_rate_type:
            entry.default_bill_rate_type ||
            defaultState.route.default_bill_rate_type,
          default_bill_rate:
            entry.default_bill_rate || defaultState.route.default_bill_rate,
          default_bill_quantity:
            entry.default_bill_quantity ||
            defaultState.route.default_bill_quantity,
          description: entry.description || defaultState.route?.description,
          default_pay_rate_type:
            entry.default_pay_rate_type ||
            defaultState.route.default_pay_rate_type,
          default_pay_rate:
            entry.default_pay_rate || defaultState.route.default_pay_rate,
          selectedBillRate:
            entry.default_bill_rate || defaultState.route.selectedBillRate,
          selectedPayRate:
            entry.default_pay_rate || defaultState.route.selectedPayRate,
          default_pay_quantity:
            entry.default_pay_quantity ||
            defaultState.route.default_pay_quantity,
          priceMatrixName:
            entry.matrix_name || defaultState.route.priceMatrixName,

          fuelSurchargeSchedule:
            entry.fs_schedule || defaultState.route?.fuelSurchargeSchedule,
          defaultBillDiscount:
            entry.bill_discount || defaultState.route?.defaultBillDiscount,
          defaultPayDiscount:
            entry.pay_discount || defaultState.route?.defaultPayDiscount,
          defaultFuelType:
            entry.fuel_type || defaultState.route?.defaultFuelType,
          bill_rate_per_extra_mile:
            entry.bill_rate_per_extra_mile ||
            defaultState.route.bill_rate_per_extra_mile,
          pay_rate_per_extra_mile:
            entry.pay_rate_per_extra_mile ||
            defaultState.route.pay_rate_per_extra_mile,
          bill_toll_amount:
            entry.bill_toll_amount || defaultState.route.bill_toll_amount,
          isRoasterPresent:
            entry.is_roaster_present || defaultState.route.isRoasterPresent,
          pay_toll_amount:
            entry.pay_toll_amount || defaultState.route.pay_toll_amount,
          rosterValue:
            (entry.is_weekday === true ? "weekdays" : "weekend") ||
            defaultState.route.rosterValue,
          is_weekday: entry.is_weekday ?? defaultState.route.is_weekday,
          price_type:
            (entry.price_type === "Matrix" ? "Matrix" : "Standard") ||
            defaultState.route.price_type,
        },
      }));
    } else if (!!copyEntry && !isNew) {
      const entryLocation = customers
        .map((customer) =>
          customer.customer_branches.find(
            (branch) => branch.id === copyEntry?.customer_branch_id
          )
        )
        .filter((item) => item?.location);

      setState((prevState) => ({
        ...prevState,
        id: copyEntry.id || defaultState?.id,
        route: {
          customer:
            customers.find((customer) =>
              (customer.customer_branches || []).find(
                (branches) => branches?.id === copyEntry.customer_branch_id
              )
            ) || defaultState.route.customer,
          location: entryLocation[0] || defaultState?.route.location,
          name: `${copyEntry.name} - Copied Route` || defaultState.route?.name,
          vehicle_type:
            copyEntry.vehicleType || defaultState.route.vehicle_type,
          default_bill_rate_type:
            copyEntry.default_bill_rate_type ||
            defaultState.route.default_bill_rate_type,
          rosterValue:
            (copyEntry.is_weekday === true ? "weekdays" : "weekend") ||
            defaultState.route.rosterValue,
          is_weekday: copyEntry.is_weekday ?? defaultState.route.is_weekday,
          selectedBillRate:
            copyEntry.default_bill_rate || defaultState.route.selectedBillRate,
          selectedPayRate:
            copyEntry.default_pay_rate || defaultState.route.selectedPayRate,
          price_type:
            (copyEntry.price_type === "Matrix" ? "Matrix" : "Standard") ||
            defaultState.route.price_type,
          default_bill_rate:
            copyEntry.default_bill_rate || defaultState.route.default_bill_rate,
          default_bill_quantity:
            copyEntry.default_bill_quantity ||
            defaultState.route.default_bill_quantity,
          description: copyEntry.description || defaultState.route?.description,
          default_pay_rate_type:
            copyEntry.default_pay_rate_type ||
            defaultState.route.default_pay_rate_type,
          default_pay_rate:
            copyEntry.default_pay_rate || defaultState.route.default_pay_rate,
          default_pay_quantity:
            copyEntry.default_pay_quantity ||
            defaultState.route.default_pay_quantity,

          fuelSurchargeSchedule:
            copyEntry.fs_schedule || defaultState.route?.fuelSurchargeSchedule,
          defaultBillDiscount:
            copyEntry.bill_discount || defaultState.route?.defaultBillDiscount,
          defaultPayDiscount:
            copyEntry.pay_discount || defaultState.route?.defaultPayDiscount,
          defaultFuelType:
            copyEntry.fuel_type || defaultState.route?.defaultFuelType,
          bill_rate_per_extra_mile:
            copyEntry.bill_rate_per_extra_mile ||
            defaultState.route.bill_rate_per_extra_mile,
          pay_rate_per_extra_mile:
            copyEntry.pay_rate_per_extra_mile ||
            defaultState.route.pay_rate_per_extra_mile,
          pay_toll_amount:
            copyEntry.pay_toll_amount || defaultState.route.pay_toll_amount,
          bill_toll_amount:
            copyEntry.bill_toll_amount || defaultState.route.bill_toll_amount,
        },
      }));
    }
  }, [entry, copyEntry]);

  useEffect(() => {
    if (
      open === true &&
      !stateList.length &&
      !locationList.length &&
      formType === "branch"
    ) {
      fetchLocalResource();
    }
  }, [open]);

  useEffect(() => {
    fetchLocalResource();
  }, []);

  useEffect(() => {
    if (formType === "route" && !!Object.keys(formAlertFields).length) {
      setState((prevState) => ({
        ...prevState,
        route: {
          ...prevState.route,
          ...formAlertFields,
        },
        errors: {
          ...prevState.errors,
          route: {
            ...prevState.errors.route,
            ...formAlertFields.errors,
          },
        },
      }));
    }
  }, [formAlertFields]);

  useEffect(() => {
    const updateError = (field, value, condition) => {
      if (condition) {
        const errorMessage = validate(field, value);
        if (errorMessage) {
          setState((prevState) => ({
            ...prevState,
            errors: {
              ...prevState.errors,
              route: {
                ...prevState.errors.route,
                [field]: errorMessage,
              },
            },
          }));
        }
      }
    };

    if (entry) {
      updateError(
        "default_pay_quantity",
        +entry.default_pay_quantity,
        entry.default_pay_rate_type === "Daily" &&
          +entry.default_pay_quantity > 1.99
      );

      updateError(
        "default_bill_quantity",
        +entry.default_bill_quantity,
        entry.default_bill_rate_type === "Daily" &&
          +entry.default_bill_quantity > 1.99
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formType === "branch" && !isNew) {
      const formattedEmails =
        entry.email_addresses &&
        Object.keys(entry.email_addresses).length > 0 &&
        entry.email_addresses?.emailAddresses?.length > 0
          ? entry?.email_addresses?.emailAddresses
          : [];

      setState((prevState) => ({
        ...prevState,
        branch: {
          ...prevState.branch,
          newEmail:
            [...(prevState.branch?.newEmail || []), ...formattedEmails] ||
            defaultState.branch.newEmail,
        },
      }));
    }
  }, [formType, isNew, entry]);

  const getFieldValidatorMap = (state, field) => {
    const { route } = state;

    let fieldValidatorMap = {};
    if (formType === "customer") {
      fieldValidatorMap = {
        name: [
          { type: VALIDATIONS.REQUIRED, value: true },
          { type: VALIDATIONS.MAX_LENGTH, value: 100 },
        ],
        lookup: [{ type: VALIDATIONS.REQUIRED, value: true }],
        inv_pymt_terms: [{ type: VALIDATIONS.REQUIRED, value: false }],
      };
    } else if (formType === "branch") {
      fieldValidatorMap = {
        customer: [
          { type: VALIDATIONS.REQUIRED, value: true },
          { type: VALIDATIONS.MAX_LENGTH, value: 50 },
        ],
        pdxCompany: [{ type: VALIDATIONS.REQUIRED, value: true }],
        location: [
          { type: VALIDATIONS.REQUIRED, value: true },
          { type: VALIDATIONS.MAX_LENGTH, value: 30 },
        ],
        kamUser: [{ type: VALIDATIONS.REQUIRED, value: true }],
        office: [{ type: VALIDATIONS.REQUIRED, value: true }],
        mgrUser: [{ type: VALIDATIONS.REQUIRED, value: true }],
        state: [
          { type: VALIDATIONS.REQUIRED, value: true },
          { type: VALIDATIONS.MAX_LENGTH, value: 20 },
        ],
        email: [
          // { type: VALIDATIONS.REQUIRED, value: true },
          { type: VALIDATIONS.EMAIL, value: true },
        ],
        // fuelSurchargeSchedule: [{ type: VALIDATIONS.REQUIRED, value: true }],
        // defaultBillDiscount: [{ type: VALIDATIONS.REQUIRED, value: true }],
        // defaultPayDiscount: [{ type: VALIDATIONS.REQUIRED, value: true }],
        // defaultFuelType: [{ type: VALIDATIONS.REQUIRED, value: true }],
      };
    } else if (formType === "route") {
      fieldValidatorMap = {
        customer: [
          { type: VALIDATIONS.REQUIRED, value: true },
          { type: VALIDATIONS.MAX_LENGTH, value: 50 },
        ],
        name: [
          { type: VALIDATIONS.REQUIRED, value: true },
          { type: VALIDATIONS.MAX_LENGTH, value: 50 },
        ],
        location: [{ type: VALIDATIONS.REQUIRED, value: true }],
        description: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
        vehicle_type: [
          { type: VALIDATIONS.MIN_LENGTH, value: 3 },
          { type: VALIDATIONS.MAX_LENGTH, value: 30 },
        ],
        default_bill_rate: [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ],
        default_bill_quantity: [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ],
        default_pay_rate: [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ],
        default_pay_quantity: [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ],
        bill_rate_per_extra_mile: [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ],
        pay_rate_per_extra_mile: [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ],
        bill_toll_amount: [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ],
        pay_toll_amount: [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ],
      };
    }

    if (route?.default_pay_rate_type === "Hourly") {
      fieldValidatorMap["default_pay_quantity"] = [
        {
          type: VALIDATIONS.MAX,
          value: 24,
        },
        ...fieldValidatorMap["default_pay_quantity"],
      ];
    }

    if (route?.default_pay_rate_type === "Daily") {
      fieldValidatorMap["default_pay_quantity"] = [
        { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
        ...(fieldValidatorMap["default_pay_quantity"] || []),
      ];
    }

    if (route?.default_bill_rate_type === "Daily") {
      fieldValidatorMap["default_bill_quantity"] = [
        { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
        ...(fieldValidatorMap["default_bill_quantity"] || []),
      ];
    }

    if (route?.default_bill_rate_type === "Hourly") {
      fieldValidatorMap["default_bill_quantity"] = [
        {
          type: VALIDATIONS.MAX,
          value: 24,
        },
        ...fieldValidatorMap["default_bill_quantity"],
      ];
    }

    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";

    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[formType][key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const billPayPrice = priceMatrix?.filter((item) => {
    return (
      item.customer_branch_id === state.route?.location?.id &&
      item.name === state.route?.priceMatrixName
    );
  });

  const billingOptions = billPayPrice?.map((item) => item.bill_price);

  const settlementOptions = billPayPrice?.map((item) => item.pay_price);

  const handleBillRateChange = (event, value) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      route: {
        ...prevState.route,
        selectedBillRate: value,
      },
    }));
    const correspondingSettlement = billPayPrice.find(
      (item) => item.bill_price === value
    );
    if (correspondingSettlement) {
      isFieldChange = true;
      setState((prevState) => ({
        ...prevState,
        route: {
          ...prevState.route,
          selectedPayRate: correspondingSettlement.pay_price,
        },
      }));
    }
  };

  const handlePayRateChange = (event, value) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      route: {
        ...prevState.route,
        selectedPayRate: value,
      },
    }));
    const correspondingBilling = billPayPrice.find(
      (item) => item.pay_price === value
    );
    if (correspondingBilling) {
      isFieldChange = true;
      setState((prevState) => ({
        ...prevState,
        route: {
          ...prevState.route,
          selectedBillRate: correspondingBilling.bill_price,
        },
      }));
    }
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    shouldShowAlert[field] = true;
    let errorMessage = validate(field, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
        // value = value + "";
      }
    }

    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [formType]: {
        ...prevState[formType],
        [field]: value,
      },
      errors: {
        ...prevState.errors,
        [formType]: {
          ...prevState.errors[formType],
          [field]: errorMessage,
        },
      },
    }));
  };

  const handleFieldChange1 = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (state.branch?.newEmail?.some((email) => email === value?.trim())) {
      errorMessage = "This email has already been entered.";
    }

    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      branch: {
        ...prevState.branch,
        [field]: value,
      },
      errors: {
        ...prevState.errors,
        branch: {
          ...prevState.errors.branch,
          [field]: errorMessage,
        },
      },
    }));
  };

  const handleSubmit = async () => {
    if (state.route.price_type === "Matrix") {
      const billRate = +state.route.selectedBillRate;
      const payRate = +state.route.selectedPayRate;

      const billRateExists = billingOptions?.includes(billRate);
      const payRateExists = settlementOptions?.includes(payRate);

      if (!billRateExists && !payRateExists) {
        return toast.error(
          "Billing and Settlement values doesn't exist in price matrix for this customer."
        );
      } else if (!billRateExists) {
        return toast.error(
          "Billing value doesn't exist in price matrix for this customer."
        );
      } else if (!payRateExists) {
        return toast.error(
          "Settlement value doesn't exist in price matrix for this customer."
        );
      }
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));
    let payload = { ...state[formType] };

    if (formType === "customer") {
      const { lookup, ...remPayload } = payload;

      payload = {
        ...remPayload,
        type: lookup?.id || null,
      };
    }

    if (formType === "branch") {
      const {
        customer,
        pdxCompany,
        state,
        office,
        kamUser,
        mgrUser,
        invoiceType,
        fuelSurchargeSchedule,
        defaultBillDiscount,
        defaultPayDiscount,
        defaultFuelType,
        email,
        newEmail,
        ...remPayload
      } = payload;

      payload = {
        ...remPayload,
        invoice_type: invoiceType?.id || null,
        pdx_company_id: pdxCompany?.id || null,
        aa_id: office?.id || null,
        kam_id: kamUser?.id || null,
        mgr_id: mgrUser?.id || null,
        customer_id: isNew
          ? isDirect
            ? customer?.id
            : customer_id
          : entry?.customer_id,
        state: state?.abbreviation || null,
        fuel_surcharge_schedule: fuelSurchargeSchedule,
        default_bill_discount: defaultBillDiscount,
        default_pay_discount: defaultPayDiscount,
        default_fuel_type: defaultFuelType,
        email_addresses: !!newEmail
          ? {
              emailAddresses: newEmail,
            }
          : null,
      };
    }

    if (formType === "route") {
      const {
        fuelSurchargeSchedule,
        defaultBillDiscount,
        defaultPayDiscount,
        defaultFuelType,
        default_bill_rate,
        default_bill_quantity,
        default_pay_rate,
        default_pay_quantity,
        vehicle_type,
        bill_rate_per_extra_mile,
        pay_rate_per_extra_mile,
        pay_toll_amount,
        bill_toll_amount,
        customer,
        location,
        price_type,
        priceMatrixName,
        ...remPayload
      } = payload;

      const mainId = customer?.customer_branches?.filter(
        (branch) => branch.id === state.route?.location?.id
      );

      payload = {
        ...remPayload,
        vehicle_type: vehicle_type?.id || null,
        fs_schedule: fuelSurchargeSchedule,
        bill_discount: !!defaultBillDiscount?.value
          ? defaultBillDiscount?.value
          : defaultBillDiscount,
        pay_discount: !!defaultPayDiscount?.value
          ? defaultPayDiscount?.value
          : defaultPayDiscount,
        fuel_type: defaultFuelType,
        price_type: price_type || "Standard",
        default_bill_rate:
          state.route.price_type === "Standard"
            ? default_bill_rate || "0"
            : state.route.selectedBillRate,
        default_bill_quantity: default_bill_quantity || "0",
        default_pay_rate:
          state.route.price_type === "Standard"
            ? default_pay_rate || "0"
            : state.route.selectedPayRate,
        default_pay_quantity: default_pay_quantity || "0",
        customer_branch_id: isNew
          ? isDirect
            ? mainId[0]?.id
            : customer_branch_id
          : mainId[0]?.id,
        bill_rate_per_extra_mile: bill_rate_per_extra_mile || "0",
        pay_rate_per_extra_mile: pay_rate_per_extra_mile || "0",
        pay_toll_amount: pay_toll_amount || "0",
        bill_toll_amount: bill_toll_amount || "0",
        matrix_name: priceMatrixName,
      };
    }

    if (!isNew && !copyEntry) {
      payload = {
        ...payload,
        id: entry?.id,
      };
    }

    const capitalizeFormType =
      formType.charAt(0).toUpperCase() + formType.slice(1);
    const serviceMethod =
      isNew || copyEntry
        ? `post${capitalizeFormType}`
        : `put${capitalizeFormType}`;

    const { error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    if (formType === "route") {
      toast.success(
        `Route ${
          isNew ? "created" : !!copyEntry ? "copied" : "updated"
        } successfully.`
      );
    } else if (formType === "branch") {
      toast.success(`Branch ${isNew ? "created" : "updated"} successfully.`);
    } else {
      toast.success(`Customer ${isNew ? "created" : "updated"} successfully.`);
    }

    setState((prevState) => ({ ...prevState, isLoading: false }));
    handleClose(true);
    isFieldChange = false;
  };

  const handleClose = (isSubmitted = false) => {
    isFieldChange = false;
    setState(defaultState);
    onClose(
      isSubmitted,
      state?.branch?.customer || state?.route?.customer,
      state.route?.location?.id
    );
  };

  const handleAddEmail = () => {
    if (state.branch?.email?.trim()) {
      setState((prevState) => ({
        ...prevState,
        branch: {
          ...prevState.branch,
          newEmail: [
            ...(prevState.branch?.newEmail || []),
            state.branch?.email?.trim(),
          ],
          email: defaultState.branch?.email,
        },
      }));
    }
  };

  const handleDeleteEmail = (id) => {
    isFieldChange = true;
    setState((prevState) => {
      const filteredEmails = prevState.branch?.newEmail?.filter(
        (email) => email !== id
      );

      const emailExists = filteredEmails?.some(
        (email) => email === prevState.branch?.email
      );
      return {
        ...prevState,
        branch: {
          ...prevState.branch,
          newEmail: filteredEmails,
        },
        errors: {
          ...prevState.errors,
          branch: {
            ...prevState.errors.branch,
            email: emailExists ? prevState.errors.branch?.email : " ",
          },
        },
      };
    });
  };

  const handleKeyDown = (event) => {
    console.log(validate());
    if (event.key === "Enter") {
      if (
        (validate() === "" || validate() === "Required") &&
        state.errors?.branch?.email === " "
      ) {
        event.preventDefault();
        handleAddEmail();
      }
    }
  };

  const handleBlur = () => {
    if (
      (validate() === "" || validate() === "Required") &&
      state.errors?.branch?.email === " "
    ) {
      handleAddEmail();
    }
  };

  billPayPrice.forEach((billOption) => {
    if (billOption?.pay_price) {
      payPriceMap[billOption.pay_price] = {
        quantity: billOption.quantity,
      };
    }
  });

  billPayPrice.forEach((billOption) => {
    if (billOption?.bill_price) {
      billPriceMap[billOption.bill_price] = billOption;
    }
  });

  const calculateProfitPercentage = ({ bill_amount = 0, pay_amount = 0 }) => {
    let profit = +bill_amount - +pay_amount;
    profit = (+profit / Math.abs(+bill_amount)) * 100;

    return isFinite(profit) ? profit.toFixed(2) : 0;
  };

  const profitPercentage = calculateProfitPercentage({
    bill_amount:
      state.route.default_bill_rate * state.route.default_bill_quantity,
    pay_amount: state.route.default_pay_rate * state.route.default_pay_quantity,
  });

  return (
    <Dialog
      open={open}
      classes={{
        paper: state.route.isRoasterPresent
          ? classes.routePaper
          : classes.paper,
      }}
      onClose={handleClose}
    >
      <Dialog.Title hasClose>
        {`${isNew ? "Create" : !!copyEntry ? "Copy" : "Update"} ${
          formType.charAt(0).toUpperCase() + formType.slice(1)
        }`}
        {state.route.isRoasterPresent && (
          <p style={{ color: "red", margin: 0 }}>
            Note: The route is associated with the base roster.
          </p>
        )}
      </Dialog.Title>
      <Dialog.Content>
        {formType === "customer" && (
          <>
            <TextField
              fullWidth
              required
              name="name"
              label="Name"
              variant="outlined"
              size="small"
              value={state.customer?.name}
              error={!!state.errors?.customer?.name.trim()}
              helperText={state.errors?.customer?.name}
              onChange={handleFieldChange}
            />

            <Autocomplete
              disableClearable
              required
              className="mb-1"
              size="small"
              value={state.customer?.lookup}
              options={customerTypeList.filter((type) => type.is_active)}
              getOptionLabel={(option) => option.value || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Industry Type"
                  variant="outlined"
                  error={!!state.errors?.customer?.type.trim()}
                  helperText={state.errors?.customer?.type}
                />
              )}
              onChange={(evt, value) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  customer: {
                    ...prevState.customer,
                    lookup: value,
                  },
                }));
              }}
            />
            <TextField
              fullWidth
              name="inv_pymt_terms"
              label="Terms: Net Days"
              variant="outlined"
              type="number"
              size="small"
              value={state.customer?.inv_pymt_terms}
              error={!!state.errors?.customer?.inv_pymt_terms.trim()}
              helperText={state.errors?.customer?.inv_pymt_terms}
              onChange={handleFieldChange}
            />
          </>
        )}

        {formType === "branch" && (
          <>
            <div className="mb-1 d-flex f-align-center">
              <Autocomplete
                disableClearable
                className="w-50 mr-2"
                size="small"
                disabled={!isButton && isNew}
                value={state?.branch?.customer}
                options={customersList.filter((customer) => customer.is_active)}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Customer Name"
                    variant="outlined"
                    error={!!state.errors?.branch?.pdxCompany.trim()}
                    helperText={state.errors.branch?.pdxCompany}
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    branch: {
                      ...prevState.branch,
                      customer: value,
                    },
                  }));
                }}
              />
              <Autocomplete
                disableClearable
                className="w-50 ml-2"
                size="small"
                value={state?.branch?.pdxCompany}
                options={pdxCompanyList}
                getOptionLabel={(option) => option.value || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="PDX Company"
                    variant="outlined"
                    error={!!state.errors?.branch?.pdxCompany.trim()}
                    helperText={state.errors.branch?.pdxCompany}
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    branch: {
                      ...prevState.branch,
                      pdxCompany: value,
                    },
                  }));
                }}
              />
            </div>

            <div className="mb-1 d-flex f-align-center">
              <Autocomplete
                className="w-50 mr-2"
                size="small"
                disableClearable
                value={state?.branch?.state}
                options={stateList}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                    error={!!state.errors?.branch?.state.trim()}
                    helperText={state.errors?.branch?.state}
                    required
                  />
                )}
                onChange={(evt, state) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    branch: {
                      ...prevState.branch,
                      state: state,
                      location: null,
                    },
                  }));
                }}
              />

              <Autocomplete
                className="w-50 ml-2"
                size="small"
                value={state?.branch?.location}
                disabled={!state.branch?.state}
                options={
                  state.hasMatch
                    ? state.filteredOptions?.length > 0
                      ? state.filteredOptions
                      : customerlocationList[state.branch?.state?.name]?.sort(
                          (a, b) => Intl.Collator().compare(a || "", b || "")
                        ) || []
                    : []
                }
                filterOptions={(options, { inputValue }) =>
                  options?.filter((option) =>
                    option?.toLowerCase().includes(inputValue?.toLowerCase())
                  )
                }
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    variant="outlined"
                    required
                    error={!!state.errors?.branch?.location.trim()}
                    helperText={state.errors?.branch?.location}
                    // onChange={(evt) => {
                    //   isFieldChange = true;
                    //   const value = evt.currentTarget.value;
                    //   let errorMessage = validate("location", value) || " ";
                    //   setState((prevState) => ({
                    //     ...prevState,
                    //     isFormInvalid: !!errorMessage.trim(),
                    //     errors: {
                    //       ...prevState.errors,
                    //       [formType]: {
                    //         ...prevState.errors[formType],
                    //         location: errorMessage,
                    //       },
                    //     },
                    //   }));
                    // }}
                    // onBlur={(evt) => {
                    //   const value = evt.currentTarget.value;
                    //   setState((prevState) => ({
                    //     ...prevState,
                    //     isFormInvalid: false,
                    //     branch: {
                    //       ...prevState.branch,
                    //       location: value,
                    //     },
                    //   }));
                    // }}
                  />
                )}
                freeSolo
                onChange={(evt, location) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    branch: {
                      ...prevState.branch,
                      location: location,
                    },
                  }));
                }}
                onInputChange={(evt, value) => {
                  const filteredOptions = customerlocationList[
                    state.branch?.state?.name
                  ]
                    ?.sort((a, b) => Intl.Collator().compare(a || "", b || ""))
                    .filter((option) =>
                      option?.toLowerCase().includes(value?.toLowerCase())
                    );
                  let errorMessage = validate("location", value) || " ";
                  setState((prevState) => ({
                    ...prevState,
                    isFormInvalid: !!errorMessage.trim(),
                    errors: {
                      ...prevState.errors,
                      [formType]: {
                        ...prevState.errors[formType],
                        location: errorMessage,
                      },
                    },
                    hasMatch: filteredOptions?.length > 0,
                    branch: {
                      ...prevState.branch,
                      location: value,
                    },
                    filteredOptions,
                  }));
                  if (value !== state.branch?.location) {
                    isFieldChange = true;
                  }
                }}
              />
            </div>
            <div className="mb-1 d-flex f-align-center">
              <Autocomplete
                className="w-50 mr-2"
                size="small"
                value={state?.branch?.office}
                options={officeUsersOptions}
                getOptionLabel={(option) =>
                  option.label ||
                  `${option.first_name} ${
                    option.last_name ? option.last_name : ""
                  }-${option.email || ""}-(${option.username || ""})` ||
                  ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Office"
                    variant="outlined"
                    helperText=" "
                  />
                )}
                onChange={(evt, office) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    branch: {
                      ...prevState.branch,
                      office: office?.value,
                    },
                  }));
                }}
              />
              <Autocomplete
                className="w-50 ml-2"
                size="small"
                value={state?.branch?.kamUser}
                options={kamUsersOptions}
                getOptionLabel={(option) =>
                  option.label ||
                  `${option.first_name} ${
                    option.last_name ? option.last_name : ""
                  }-${option.email || ""}-(${option.username || ""})` ||
                  ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Default KAM"
                    variant="outlined"
                    required
                    helperText=" "
                  />
                )}
                onChange={(evt, kam) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    branch: {
                      ...prevState.branch,
                      kamUser: kam?.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="mb-1 d-flex f-align-center">
              <Autocomplete
                className="w-50 mr-2"
                size="small"
                value={state?.branch?.mgrUser}
                options={managerUsersOptions}
                getOptionLabel={(option) =>
                  option.label ||
                  `${option.first_name} ${
                    option.last_name ? option.last_name : ""
                  }-${option.email || ""}-(${option.username || ""})` ||
                  ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Manager"
                    required
                    variant="outlined"
                    helperText=" "
                  />
                )}
                onChange={(evt, mgr) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    branch: {
                      ...prevState.branch,
                      mgrUser: mgr?.value,
                    },
                  }));
                }}
              />
              <Autocomplete
                className="w-50 ml-2"
                size="small"
                value={state.branch?.invoiceType}
                options={INVOICE_TYPES}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Invoice Type"
                    variant="outlined"
                    helperText=" "
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    branch: {
                      ...prevState.branch,
                      invoiceType: value,
                    },
                  }));
                }}
              />
            </div>
            <TextField
              label="Add Email ID(s)"
              fullWidth
              variant="outlined"
              size="small"
              name="email"
              value={state.branch?.email}
              // className="mt-3"
              onChange={handleFieldChange1}
              error={!!state.errors.branch?.email?.trim()}
              helperText={state.errors.branch?.email}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              InputProps={{
                classes: { root: classes.inputRoot },
                startAdornment: (
                  <div className={classes.chipsContainer}>
                    {state.branch?.newEmail?.map((email) => (
                      <Chip
                        key={email}
                        label={email}
                        onDelete={() => handleDeleteEmail(email)}
                        className={classes.emailChip}
                      />
                    ))}
                  </div>
                ),
              }}
            />
            {/* <div className="mb-6 d-flex f-align-center">
              <Autocomplete
                className="w-50 mr-2"
                size="small"
                value={state.branch.fuelSurchargeSchedule}
                options={[...customFuelSurchargeList, "None"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="F/S Schedule"
                    variant="outlined"
                  />
                )}
                onChange={(evt, fuelSurchargeSchedule) => {
                  isFieldChange = true;
                  setState((prevState) => {
                    const isFuelScheduleNone =
                      (fuelSurchargeSchedule || "").toLowerCase() === "none";
                    const isPreviousFuelScheduleNone =
                      (
                        prevState.branch.fuelSurchargeSchedule || ""
                      ).toLowerCase() === "none";
                    return {
                      ...prevState,
                      branch: {
                        ...prevState.branch,
                        fuelSurchargeSchedule,
                        defaultBillDiscount: isFuelScheduleNone
                          ? "None"
                          : isPreviousFuelScheduleNone
                          ? null
                          : prevState.branch.defaultBillDiscount,
                        defaultPayDiscount: isFuelScheduleNone
                          ? "None"
                          : isPreviousFuelScheduleNone
                          ? null
                          : prevState.branch.defaultPayDiscount,
                        defaultFuelType: isFuelScheduleNone
                          ? "None"
                          : isPreviousFuelScheduleNone
                          ? null
                          : prevState.branch.defaultFuelType,
                      },
                    };
                  });
                }}
              />
              <Autocomplete
                className="w-50 ml-2"
                size="small"
                disabled={
                  (state.branch.fuelSurchargeSchedule || "").toLowerCase() ===
                  "none"
                }
                value={state.branch.defaultFuelType}
                options={["Diesel", "Regular"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Fuel Type"
                    variant="outlined"
                  />
                )}
                onChange={(evt, defaultFuelType) => {
                  isFieldChange = true;
                  setState((prevState) => {
                    return {
                      ...prevState,
                      branch: {
                        ...prevState.branch,
                        defaultFuelType,
                      },
                    };
                  });
                }}
              />
            </div>
            <div className="mb-1 d-flex f-align-center">
              <Autocomplete
                className="w-50 mr-2"
                size="small"
                disabled={
                  (state.branch.fuelSurchargeSchedule || "").toLowerCase() ===
                  "none"
                }
                value={state.branch.defaultBillDiscount}
                options={REDUCTION_TYPES}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Bill F/S%"
                    variant="outlined"
                  />
                )}
                onChange={(evt, defaultBillDiscount) => {
                  isFieldChange = true;
                  setState((prevState) => {
                    return {
                      ...prevState,
                      branch: {
                        ...prevState.branch,
                        defaultBillDiscount,
                      },
                    };
                  });
                }}
              />
              <Autocomplete
                className="w-50 ml-2"
                size="small"
                disabled={
                  (state.branch.fuelSurchargeSchedule || "").toLowerCase() ===
                  "none"
                }
                value={state.branch.defaultPayDiscount}
                options={REDUCTION_TYPES}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Pay F/S%"
                    variant="outlined"
                  />
                )}
                onChange={(evt, defaultPayDiscount) => {
                  isFieldChange = true;
                  setState((prevState) => {
                    return {
                      ...prevState,
                      branch: {
                        ...prevState.branch,
                        defaultPayDiscount,
                      },
                    };
                  });
                }}
              />
            </div> */}
          </>
        )}
        {formType === "route" && (
          <>
            <Box className="d-flex f-align-center mb-4">
              <Typography variant="body2" className="mr-2 text-bold">
                Route Type:{" "}
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  name="roster_type"
                  value={state.route.rosterValue}
                  onChange={(evt) => {
                    isFieldChange = true;
                    const value = evt.target.value;

                    setState((prevState) => ({
                      ...prevState,
                      route: {
                        ...prevState.route,
                        is_weekday: value === "weekdays" ? true : false,
                        rosterValue: value,
                      },
                    }));
                  }}
                >
                  <FormControlLabel
                    value="weekdays"
                    control={<Radio size="small" color="primary" />}
                    label="Weekdays"
                  />
                  <FormControlLabel
                    value="weekend"
                    control={<Radio size="small" color="primary" />}
                    label="Weekend"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <div className="d-flex">
              <Autocomplete
                disableClearable
                className="w-50 mr-2 mb-1"
                size="small"
                disabled={!isButton && isNew}
                value={state?.route?.customer}
                options={customersList.filter((customer) => customer.is_active)}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Customer Name"
                    variant="outlined"
                    error={!!state.errors?.route?.customer.trim()}
                    helperText={state.errors.route?.customer}
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    route: {
                      ...prevState.route,
                      customer: value,
                      location: null,
                    },
                  }));
                }}
              />

              <Autocomplete
                disableClearable
                className="w-50 mr-2 mb-1"
                size="small"
                value={state?.route?.location}
                disabled={!isButton && isNew}
                options={location}
                // getOptionLabel={(option) => option.location || ""}
                getOptionLabel={(option) =>
                  `${option.location || ""} (${option.state?.name || ""} ,${
                    option?.pdxCompany?.value || ""
                  })`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Branch"
                    variant="outlined"
                    error={!!state.errors?.branch?.pdxCompany.trim()}
                    helperText={state.errors.branch?.pdxCompany}
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    route: {
                      ...prevState.route,
                      location: value,
                      priceMatrixName: null,
                      selectedBillRate: "",
                      selectedPayRate: "",
                    },
                  }));
                }}
              />
            </div>

            <TextField
              className="mb-1"
              fullWidth
              required
              name="name"
              label="Name"
              variant="outlined"
              size="small"
              // disabled={!isNew}
              value={state.route?.name}
              error={!!state.errors?.route?.name.trim()}
              helperText={state.errors?.route?.name}
              onChange={handleFieldChange}
            />
            <div className="d-flex">
              <Autocomplete
                className="w-50 mr-2 mb-1"
                size="small"
                value={state.route.vehicle_type}
                getOptionLabel={(option) => option?.value}
                options={vehicleTypeList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vehicle"
                    variant="outlined"
                    name="vehicle_type"
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    route: {
                      ...prevState.route,
                      vehicle_type: value,
                    },
                  }));
                }}
              />

              <Autocomplete
                className="w-50 ml-2 mb-1"
                size="small"
                disabled={state.route.defaultFuelType === "None"}
                value={state.route.defaultFuelType}
                options={["Diesel", "Regular"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Route Fuel Type"
                    variant="outlined"
                  />
                )}
                onChange={(evt, defaultFuelType) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    route: {
                      ...prevState.route,
                      defaultFuelType,
                    },
                  }));
                }}
              />
            </div>

            <Box
              className="d-flex f-align-center pb-7"
              style={{ color: !copyEntry && !isNew ? "#bdbdbd" : "inherit" }}
            >
              <Typography variant="body2" className="mr-2 text-bold">
                Price Type:
              </Typography>
              <Typography variant="body2" className="mr-2 text-bold">
                Standard
              </Typography>
              <FormControl>
                <Tooltip
                  title={
                    state.route.price_type === "Matrix"
                      ? "Switch to Standard"
                      : "Switch to price matrix"
                  }
                  placement="top-start"
                >
                  <Switch
                    className="mr-2"
                    disabled={!copyEntry && !isNew}
                    size="small"
                    color="primary"
                    checked={state.route.price_type === "Matrix"}
                    onChange={(evt) => {
                      isFieldChange = true;
                      const checked = evt.target.checked;

                      setState((prevState) => ({
                        ...prevState,
                        route: {
                          ...prevState.route,
                          price_type: checked ? "Matrix" : "Standard",
                          selectedBillRate:
                            checked && copyEntry?.price_type === "Standard"
                              ? ""
                              : state.route.selectedBillRate ||
                                defaultState.route.selectedBillRate,
                          pay_rate_per_extra_mile: checked
                            ? 0
                            : state.route.pay_rate_per_extra_mile,
                          default_bill_rate_type: checked
                            ? RATE_TYPES[0]
                            : state.route.default_bill_rate_type,
                          default_pay_rate_type: checked
                            ? RATE_TYPES[0]
                            : state.route.default_pay_rate_type,
                          default_bill_quantity: checked
                            ? "1"
                            : state.route.default_bill_quantity,
                          default_pay_quantity: checked
                            ? "1"
                            : state.route.default_pay_quantity,
                          bill_rate_per_extra_mile: checked
                            ? 0
                            : state.route.bill_rate_per_extra_mile,
                          selectedPayRate:
                            checked && copyEntry?.price_type === "Standard"
                              ? ""
                              : state.route.selectedPayRate ||
                                defaultState.route.selectedPayRate,
                          default_bill_rate:
                            state.route?.default_bill_rate ||
                            defaultState.route.default_bill_rate,
                          default_pay_rate:
                            state.route?.default_pay_rate ||
                            defaultState.route.default_pay_rate,
                        },
                        errors: {
                          ...prevState.errors,
                          route: {
                            ...prevState.errors.route,
                            default_bill_rate: " ",
                            default_pay_rate: " ",
                          },
                        },
                      }));
                    }}
                  />
                </Tooltip>
              </FormControl>
              <Typography variant="body2" className="mr-2 text-bold">
                Price Matrix
              </Typography>
            </Box>
            <div>
              {state.route.price_type === "Matrix" && (
                <Autocomplete
                  disableClearable
                  fullWidth
                  className="mr-2"
                  size="small"
                  disabled={
                    state.route.price_type === "Standard" ||
                    !state.route.location
                  }
                  value={state?.route?.priceMatrixName}
                  options={state.priceMatrixList}
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) =>
                    state.route.isPriceMatrixListFetched ? (
                      <Skeleton
                        variant="rect"
                        width="100%"
                        height="36px"
                        className="mb-5"
                      />
                    ) : (
                      <TextField
                        {...params}
                        required
                        label="Select Price Matrix Name"
                        variant="outlined"
                        error={!!state.errors?.branch?.pdxCompany.trim()}
                        helperText={state.errors.branch?.pdxCompany}
                      />
                    )
                  }
                  onChange={(evt, value) => {
                    isFieldChange = true;
                    setState((prevState) => ({
                      ...prevState,
                      route: {
                        ...prevState.route,
                        priceMatrixName: value,
                        selectedBillRate: "",
                        selectedPayRate: "",
                      },
                    }));
                  }}
                />
              )}
            </div>

            <div className="d-flex mb-1">
              <Autocomplete
                className="mr-2 w-50"
                size="small"
                disableClearable
                disabled={state.route.price_type === "Matrix"}
                value={state.route.default_bill_rate_type}
                options={RATE_TYPES}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bill Rate Type"
                    variant="outlined"
                    helperText=" "
                  />
                )}
                onChange={(evt, value) => {
                  if (state.route.default_bill_rate_type === value) {
                    return false;
                  } else {
                    isFieldChange = true;
                    !isNew &&
                      showFieldAlert("default_bill_rate_type", {
                        ...state.route,
                        default_bill_quantity:
                          value === "Hourly"
                            ? "8"
                            : value === "Daily"
                            ? "1"
                            : "",
                        default_bill_rate_type: value,
                      });
                    setState((prevState) => ({
                      ...prevState,
                      route: {
                        ...prevState.route,
                        default_bill_rate_type: value,
                        default_bill_quantity:
                          value === "Hourly"
                            ? "8"
                            : value === "Daily"
                            ? "1"
                            : "",
                      },
                      errors: {
                        ...prevState.errors,
                        route: {
                          ...prevState.errors.route,
                          default_bill_rate_type: " ",
                          default_bill_quantity: " ",
                        },
                      },
                    }));
                  }
                }}
              />
              <TextField
                className="ml-2 w-50"
                type="number"
                name="default_bill_quantity"
                label="Bill Quantity"
                variant="outlined"
                size="small"
                onBlur={(evt) => {
                  const { name } = evt.target;
                  if (shouldShowAlert[name]) {
                    shouldShowAlert[name] = false;
                    handleFieldBlur(evt);
                  }
                }}
                disabled={state.route.price_type === "Matrix"}
                value={state.route.default_bill_quantity}
                error={!!state.errors.route.default_bill_quantity.trim()}
                helperText={state.errors.route.default_bill_quantity}
                onChange={handleFieldChange}
                onWheel={(event) => event.target.blur()}
                onKeyDown={preventInputKeyCodes}
              />
            </div>
            <div className="d-flex">
              {state.route.price_type === "Standard" ? (
                <TextField
                  className="w-50 mr-2 mb-1"
                  type="number"
                  name="default_bill_rate"
                  label="Bill Rate"
                  variant="outlined"
                  size="small"
                  onBlur={(evt) => {
                    const { name } = evt.target;
                    if (shouldShowAlert[name]) {
                      shouldShowAlert[name] = false;
                      handleFieldBlur(evt);
                    }
                  }}
                  onWheel={(event) => event.target.blur()}
                  InputProps={{
                    startAdornment: <InputAdornment>$</InputAdornment>,
                  }}
                  value={state.route.default_bill_rate}
                  error={!!state.errors.route.default_bill_rate.trim()}
                  helperText={state.errors.route.default_bill_rate}
                  onChange={handleFieldChange}
                  onKeyDown={preventInputKeyCodes}
                />
              ) : (
                <Autocomplete
                  disableClearable
                  size="small"
                  className="mb-1 w-50 mr-2"
                  value={state.route.selectedBillRate || ""}
                  defaultValue={state.route.selectedBillRate || ""}
                  options={billingOptions}
                  getOptionLabel={(option) => {
                    const matchingBillPayPrice = billPriceMap[option];
                    if (matchingBillPayPrice) {
                      return `$ ${option} (${matchingBillPayPrice.quantity})`;
                    } else {
                      return `$ ${option}`;
                    }
                  }}
                  renderInput={(params) =>
                    isPriceMatrixFetched ? (
                      <Skeleton variant="rect" width="100%" height="36px" />
                    ) : (
                      <TextField
                        {...params}
                        required
                        label="Bill Rate"
                        variant="outlined"
                      />
                    )
                  }
                  getOptionSelected={(option, value) => option === value}
                  onChange={handleBillRateChange}
                />
              )}
              <TextField
                className="ml-2 w-50 mb-1"
                type="number"
                name="bill_rate_per_extra_mile"
                label="Bill Rate Per Extra Mile"
                variant="outlined"
                size="small"
                onBlur={handleFieldBlur}
                onWheel={(event) => event.target.blur()}
                value={state.route.bill_rate_per_extra_mile}
                error={!!state.errors.route.bill_rate_per_extra_mile.trim()}
                helperText={state.errors.route.bill_rate_per_extra_mile}
                onChange={handleFieldChange}
                onKeyDown={preventInputKeyCodes}
              />
            </div>
            <TextField
              className="mb-1"
              fullWidth
              type="number"
              name="bill_toll_amount"
              label="Bill Toll Amount"
              variant="outlined"
              size="small"
              onBlur={handleFieldBlur}
              onWheel={(event) => event.target.blur()}
              value={state.route.bill_toll_amount}
              error={!!state.errors.route.bill_toll_amount.trim()}
              helperText={state.errors.route.bill_toll_amount}
              onChange={handleFieldChange}
              onKeyDown={preventInputKeyCodes}
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
            />
            <div className="d-flex">
              <Autocomplete
                className="mb-1 w-50 mr-2"
                size="small"
                disableClearable
                disabled={state.route.price_type === "Matrix"}
                value={state.route.default_pay_rate_type}
                options={RATE_TYPES}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pay Rate Type"
                    variant="outlined"
                    helperText=" "
                  />
                )}
                onChange={(evt, value) => {
                  if (state.route.default_pay_rate_type === value) {
                    return false;
                  } else {
                    isFieldChange = true;
                    !isNew &&
                      showFieldAlert("default_pay_rate_type", {
                        ...state.route,
                        default_pay_quantity:
                          value === "Hourly"
                            ? "8"
                            : value === "Daily"
                            ? "1"
                            : "",
                        default_pay_rate_type: value,
                      });
                    setState((prevState) => ({
                      ...prevState,
                      route: {
                        ...prevState.route,
                        default_pay_rate_type: value,
                        default_pay_quantity:
                          value === "Hourly"
                            ? "8"
                            : value === "Daily"
                            ? "1"
                            : "",
                      },
                      errors: {
                        ...prevState.errors,
                        route: {
                          ...prevState.errors.route,
                          default_pay_rate_type: " ",
                          default_pay_quantity: " ",
                        },
                      },
                    }));
                  }
                }}
              />
              <TextField
                className="ml-2 w-50 mb-1"
                type="number"
                name="default_pay_quantity"
                label="Pay Quantity"
                variant="outlined"
                onBlur={(evt) => {
                  const { name } = evt.target;
                  if (shouldShowAlert[name]) {
                    shouldShowAlert[name] = false;
                    handleFieldBlur(evt);
                  }
                }}
                onWheel={(event) => event.target.blur()}
                size="small"
                disabled={state.route.price_type === "Matrix"}
                value={state.route.default_pay_quantity}
                error={!!state.errors.route.default_pay_quantity.trim()}
                helperText={state.errors.route.default_pay_quantity}
                onChange={handleFieldChange}
                onKeyDown={preventInputKeyCodes}
              />
            </div>
            <div className="d-flex">
              {state.route.price_type === "Standard" ? (
                <TextField
                  className="w-50 mr-2 mb-1"
                  type="number"
                  name="default_pay_rate"
                  label="Pay Rate"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment>$</InputAdornment>,
                  }}
                  value={state.route.default_pay_rate}
                  error={!!state.errors.route.default_pay_rate.trim()}
                  helperText={state.errors.route.default_pay_rate}
                  onChange={handleFieldChange}
                  onBlur={(evt) => {
                    const { name } = evt.target;
                    if (shouldShowAlert[name]) {
                      shouldShowAlert[name] = false;
                      handleFieldBlur(evt);
                    }
                  }}
                  onWheel={(event) => event.target.blur()}
                  onKeyDown={preventInputKeyCodes}
                />
              ) : (
                <Autocomplete
                  disableClearable
                  size="small"
                  className="mb-1 w-50 mr-2"
                  value={state.route.selectedPayRate || ""}
                  defaultValue={state.route.selectedPayRate || ""}
                  options={settlementOptions}
                  getOptionLabel={(option) => {
                    const matchingBillPayPrice = payPriceMap[option];
                    if (matchingBillPayPrice) {
                      return `$ ${option} (${matchingBillPayPrice.quantity})`;
                    } else {
                      return `$ ${option}`;
                    }
                  }}
                  renderInput={(params) =>
                    isPriceMatrixFetched ? (
                      <Skeleton variant="rect" width="100%" height="36px" />
                    ) : (
                      <TextField
                        {...params}
                        required
                        label="Pay Rate"
                        variant="outlined"
                      />
                    )
                  }
                  getOptionSelected={(option, value) => option === value}
                  onChange={handlePayRateChange}
                />
              )}
              <TextField
                className="ml-2 w-50 mb-1"
                type="number"
                name="pay_rate_per_extra_mile"
                label="Pay Rate Per Extra Mile"
                variant="outlined"
                size="small"
                onBlur={handleFieldBlur}
                onWheel={(event) => event.target.blur()}
                value={state.route.pay_rate_per_extra_mile}
                error={!!state.errors.route.pay_rate_per_extra_mile.trim()}
                helperText={state.errors.route.pay_rate_per_extra_mile}
                onChange={handleFieldChange}
                onKeyDown={preventInputKeyCodes}
              />
            </div>
            <div className="mb-1 d-flex f-align-center">
              <TextField
                className="mr-2"
                fullWidth
                type="number"
                name="pay_toll_amount"
                label="Pay Toll Amount"
                variant="outlined"
                size="small"
                onBlur={handleFieldBlur}
                onWheel={(event) => event.target.blur()}
                value={state.route.pay_toll_amount}
                error={!!state.errors.route.pay_toll_amount.trim()}
                helperText={state.errors.route.pay_toll_amount}
                onChange={handleFieldChange}
                onKeyDown={preventInputKeyCodes}
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
              />
              <TextField
                disabled={true}
                fullWidth
                className="ml-2"
                size="small"
                type="number"
                InputProps={{
                  classes: {
                    root: clsx({
                      "color-error": profitPercentage < 0,
                    }),
                  },
                  endAdornment: <InputAdornment>%</InputAdornment>,
                }}
                name="profit_percentage"
                label="Profit Percentage"
                variant="outlined"
                value={profitPercentage}
                helperText=" "
              />
            </div>
            <Autocomplete
              className="mb-5"
              fullWidth
              size="small"
              value={state.route.fuelSurchargeSchedule}
              options={customFuelSurchargeList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Route F/S Schedule"
                  variant="outlined"
                />
              )}
              onChange={(evt, fuelSurchargeSchedule) => {
                isFieldChange = true;
                setState((prevState) => {
                  const isFuelScheduleNone =
                    (fuelSurchargeSchedule || "").toLowerCase() === "none";
                  const isPreviousFuelScheduleNone =
                    (
                      prevState.route.fuelSurchargeSchedule || ""
                    ).toLowerCase() === "none";
                  return {
                    ...prevState,
                    route: {
                      ...prevState.route,
                      fuelSurchargeSchedule,
                      defaultBillDiscount: isFuelScheduleNone
                        ? "None"
                        : isPreviousFuelScheduleNone
                        ? null
                        : prevState.route.defaultBillDiscount,
                      defaultPayDiscount: isFuelScheduleNone
                        ? "None"
                        : isPreviousFuelScheduleNone
                        ? null
                        : prevState.route.defaultPayDiscount,
                      defaultFuelType: isFuelScheduleNone
                        ? "None"
                        : isPreviousFuelScheduleNone
                        ? null
                        : prevState.route.defaultFuelType,
                    },
                  };
                });
              }}
            />
            <div className="mb-5 d-flex f-align-center">
              <Autocomplete
                className="w-50 mr-2"
                size="small"
                disabled={
                  (state.route.fuelSurchargeSchedule || "").toLowerCase() ===
                  "none"
                }
                value={state?.route?.defaultBillDiscount}
                options={routeBillFsList}
                getOptionLabel={(option) => option.value || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Route Bill F/S%"
                    variant="outlined"
                  />
                )}
                onChange={(evt, defaultBillDiscount) => {
                  isFieldChange = true;
                  setState((prevState) => {
                    return {
                      ...prevState,
                      route: {
                        ...prevState.route,
                        defaultBillDiscount,
                      },
                    };
                  });
                }}
              />
              <Autocomplete
                className="w-50 ml-2"
                size="small"
                disabled={
                  (state.route.fuelSurchargeSchedule || "").toLowerCase() ===
                  "none"
                }
                value={state?.route?.defaultPayDiscount}
                options={routePayFsList}
                getOptionLabel={(option) => option.value || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Route Pay F/S%"
                    variant="outlined"
                  />
                )}
                onChange={(evt, defaultPayDiscount) => {
                  isFieldChange = true;
                  setState((prevState) => {
                    return {
                      ...prevState,
                      route: {
                        ...prevState.route,
                        defaultPayDiscount,
                      },
                    };
                  });
                }}
              />
            </div>
            <TextField
              multiline
              rows={4}
              fullWidth
              name="description"
              label="Description"
              variant="outlined"
              size="small"
              value={state.route?.description}
              error={!!state.errors?.route?.description.trim()}
              helperText={state.errors?.route?.description}
              onChange={handleFieldChange}
            />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="ml-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={
              state.isLoading ||
              (state.route.price_type === "Matrix" &&
                (!state.route.selectedBillRate ||
                  !state.route.selectedPayRate)) ||
              validate() ||
              state.isFormInvalid ||
              (!copyEntry && !isFieldChange)
            }
            onClick={handleSubmit}
          >
            {`${isNew ? "Create" : copyEntry ? "Save" : "Update"}`}
            {state.isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default Form;
