import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDateString, validator } from "utils";
import { noop, VALIDATIONS } from "shared/constants";
import { INVOICE_TYPES, RATE_TYPES, ROLE } from "modules/shared/constants";
import Service from "../service";

import Skeleton from "@material-ui/lab/Skeleton";
import { Dialog, ActionDialog, Grid } from "shared/components";
import {
  CustomersPanel,
  ContractorsPanel,
  GeneralPanel,
  MiscPanel,
} from "./tab-panels";
import { AppContext } from "shared/contexts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";
import { Backdrop, TextField, Tooltip } from "@material-ui/core";
import { SharedService } from "modules/shared";
import FlagIcon from "@material-ui/icons/Flag";
import WarningIcon from "@material-ui/icons/Warning";
import { Autocomplete } from "@material-ui/lab";
import PostAddIcon from "@material-ui/icons/PostAdd";

const NUMBER_TYPE_FIELDS = [
  "bill_rate",
  "bill_quantity",
  "bill_amount",
  "bill_fuel_surcharge",
  "bill_fuel_amount",
  "pay_toll_amount",
  "bill_toll_amount",
  "pay_rate",
  "pay_quantity",
  "pay_amount",
  "pay_fuel_surcharge",
  "pay_fuel_amount",
  "reimbursement",
  "deduction",
  "profit_percentage",
  "bill_rate_per_extra_mile",
  "pay_rate_per_extra_mile",
  "bill_extra_miles",
  "pay_extra_miles",
];

const TabMapping = ["general", "customers", "contractors", "misc"];
let isFieldChange = false;
let isDateChanged = false;
let showGeneralDate = false;
let temp_aaMgr_notes = "";

const defaultState = {
  isUnderReview: false,
  confirmDontBill: false,
  confirmDontBillValue: false,
  isAaMgrNotesAdded: false,
  isAaMgrNotesPresent: null,
  confirmDontPay: false,
  confirmDontBillToll: false,
  confirmDontBillTollValue: false,
  confirmDontPayToll: false,
  confirmDontPayTollValue: false,
  confirmDontBillExtraMiles: false,
  confirmDontBillExtraMilesValue: false,
  confirmDontPayExtraMiles: false,
  confirmDontPayExtraMilesValue: false,
  noteType: null,
  confirmDontPayValue: false,
  loader: false,
  iterations: 10,
  showAddLookupDialog: false,
  isLookupLoading: false,
  reviewNotesList: [],
  aa_mgrNotesList: [],
  isFetchingList: {
    reviewNotes: false,
  },
  aaMgrNotes: null,
  errors: {
    aaMgrNotes: "",
  },
  // isBFSChecked: true,
  // isPFSChecked: true,
  general: {
    schedule_date: getDateString(new Date()),
    kam: null,
    errors: {
      schedule_date: " ",
      kam: " ",
    },
  },
  customers: {
    customer: null,
    location: null,
    state: null,
    invoice_type: null,
    dont_bill: false,
    dont_bill_tolls: false,
    dont_bill_miles: false,
    route: null,
    bill_rate_type: RATE_TYPES[0],
    bill_rate: "0",
    bill_quantity: "1",
    bill_amount: "0",
    bill_fuel_surcharge: "0",
    bill_fuel_amount: "0",
    bill_rate_per_extra_mile: "0",
    bill_extra_mile_amount: "0",
    bill_toll_amount: "0",
    bill_extra_miles: "0",
    vehicle_type: null,
    errors: {
      customer: " ",
      location: " ",
      route: " ",
      bill_rate_type: " ",
      bill_rate: " ",
      bill_quantity: " ",
      bill_amount: " ",
      bill_fuel_surcharge: " ",
      bill_extra_miles: " ",
      bill_fuel_amount: " ",
      bill_rate_per_extra_mile: " ",
      bill_toll_amount: " ",
      vehicle_type: " ",
    },
  },
  contractors: {
    individual_contractor: null,
    settlement_company: null,
    pay_rate_type: RATE_TYPES[0],
    pay_rate: "0",
    pay_quantity: "1",
    pay_amount: "0",
    dont_pay: false,
    dont_pay_tolls: false,
    dont_pay_miles: false,
    pay_fuel_surcharge: "0",
    pay_fuel_amount: "0",
    pay_rate_per_extra_mile: "0",
    pay_extra_mile_amount: "0",
    reimbursement: "0",
    deduction: "0",
    pay_extra_miles: "0",
    pay_toll_amount: "0",
    deduction_reason: null,
    errors: {
      individual_contractor: " ",
      settlement_company: " ",
      deduction_reason: " ",
      pay_rate_type: " ",
      pay_rate: " ",
      pay_quantity: " ",
      pay_toll_amount: " ",
      pay_extra_miles: " ",
      pay_amount: " ",
      pay_fuel_surcharge: " ",
      pay_fuel_amount: " ",
      pay_rate_per_extra_mile: " ",
      reimbursement: " ",
      deduction: " ",
    },
  },
  misc: {
    office: null,
    manager: null,
    kam_notes: null,
    change_notes: null,
    isChangeNotes: false,
    review_notes: null,
    add_review_notes: "",
    profit_percentage: "0",
    errors: {
      office: " ",
      manager: " ",
      add_review_notes: " ",
      kam_notes: " ",
      change_notes: " ",
    },
  },
  selectedTab: 0,
  isLoading: false,
};

const Form = ({
  isTabletView = false,
  open = false,
  isPriceMatrixFetched = false,
  entry = null,
  priceMatrix = [],
  kamUsers = [],
  officeUsers = [],
  managerUsers = [],
  adminUsers = [],
  settlementCompanyList = [],
  customerList = [],
  contractorList = [],
  scheduleDate = null,
  isNew = false,
  formAlertFields = {},
  vehicleTypeList = [],
  deductionReasonList = [],
  cautionFlagSubmitReasonList = [],
  calculateProfitPercentage = noop,
  getStandByRates = noop,
  onClose = noop,
  showFieldAlert = noop,
  isRoleMGR,
  appData,
  gridHelper = null,
}) => {
  const classes = useStyles({ isTabletView });
  const [state, setState] = useState(defaultState);

  const getFieldValidatorMap = (state, field) => {
    const { customers, contractors } = state;
    const fieldValidatorMap = {
      schedule_date: [{ type: VALIDATIONS.REQUIRED, value: true }],
      kam: [{ type: VALIDATIONS.REQUIRED, value: true }],
      customer: [{ type: VALIDATIONS.REQUIRED, value: true }],
      location: [{ type: VALIDATIONS.REQUIRED, value: true }],
      route: [
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
        { type: VALIDATIONS.REQUIRED, value: true },
      ],
      bill_rate_type: [{ type: VALIDATIONS.REQUIRED, value: true }],
      bill_rate: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      bill_quantity: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],

      bill_fuel_surcharge: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.MAX, value: 100 },
      ],
      bill_fuel_amount: [],
      bill_rate_per_extra_mile: [
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      pay_rate_per_extra_mile: [
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      bill_toll_amount: [
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      pay_toll_amount: [
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      individual_contractor: [{ type: VALIDATIONS.REQUIRED, value: true }],
      settlement_company: [{ type: VALIDATIONS.REQUIRED, value: true }],
      pay_rate_type: [{ type: VALIDATIONS.REQUIRED, value: true }],
      pay_rate: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      pay_quantity: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      // pay_amount: [{ type: VALIDATIONS.MIN, value: 0 }],
      pay_fuel_surcharge: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.MAX, value: 100 },
      ],
      pay_fuel_amount: [
        // { type: VALIDATIONS.MIN, value: 0 },
        // { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      //pay_rate_per_extra_mile: [{ type: VALIDATIONS.MIN, value: 0 }],
      bill_extra_miles: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      pay_extra_miles: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      reimbursement: [
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      deduction: [
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      office: [{ type: VALIDATIONS.REQUIRED, value: true }],
      deduction_reason: [{ type: VALIDATIONS.REQUIRED, value: true }],
      manager: [{ type: VALIDATIONS.REQUIRED, value: true }],
      kam_notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
      change_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      aaMgrNotes: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      add_review_notes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 0 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      vehicle_type: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
    };

    if (contractors["pay_rate_type"] === "Hourly") {
      fieldValidatorMap["pay_quantity"].push({
        type: VALIDATIONS.MAX,
        value: 24,
      });
    }
    if (contractors["pay_rate_type"] === "Daily") {
      fieldValidatorMap["pay_quantity"].push({
        type: VALIDATIONS.DAILY_QUANTITY_LIMIT,
        value: 1.99,
      });
    }

    if (contractors["deduction"] == 0 || !contractors["deduction"]) {
      fieldValidatorMap["deduction_reason"].pop();
    }

    if (customers["bill_rate_type"] === "Hourly") {
      fieldValidatorMap["bill_quantity"].push({
        type: VALIDATIONS.MAX,
        value: 24,
      });
    }
    if (customers["bill_rate_type"] === "Daily") {
      fieldValidatorMap["bill_quantity"].push({
        type: VALIDATIONS.DAILY_QUANTITY_LIMIT,
        value: 1.99,
      });
    }

    return fieldValidatorMap;
  };

  const validate = (field, value, type) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      if (type === "all") {
        TabMapping.forEach((stateType) => {
          const message = validate(null, null, stateType);
          if (message) {
            errorMessage = message;
          }
        });

        return errorMessage;
      }

      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[type][key]);
        if (message) {
          if (key in state[type].errors) {
            errorMessage = message;
          }
        }
      });
    }

    return errorMessage;
  };

  // const handleBFSChecked = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     isBFSChecked: !state.isBFSChecked,
  //   }));
  // };

  // const handlePFSChecked = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     isPFSChecked: !state.isPFSChecked,
  //   }));
  // };

  const handleFieldChange = (
    field,
    value,
    type,
    fieldType,
    isChanging = false
  ) => {
    isFieldChange = true;

    if (field === "schedule_date") {
      isDateChanged = true;
    }

    if (isDateChanged && field === "route") {
      showGeneralDate = true;
    }
    if (field === "deduction" && (value == 0 || !value)) {
      setState((prevState) => ({
        ...prevState,
        contractors: {
          ...prevState.contractors,
          deduction_reason: defaultState.contractors.deduction_reason,
          errors: {
            ...prevState.contractors.errors,
            deduction_reason: " ",
          },
        },
      }));
    }

    let errorMessage = validate(field, value, type) || " ";

    if (
      (fieldType === "number" && value.indexOf(".") !== -1) ||
      field === "pay_toll_amount" ||
      field === "bill_toll_amount" ||
      field === "pay_fuel_amount" ||
      field === "reimbursement" ||
      field === "deduction"
    ) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
        // value = value + "";
      }
    }
    if (
      fieldType === "number" &&
      value?.length > 1 &&
      value.indexOf(".") === -1
    ) {
      value = value?.replace(/^0+/, "");
    }

    if (field === "bill_rate_type") {
      const defaultValue =
        value === "Hourly" ? "8" : value === "Daily" ? "1" : "";
      setState((prevState) => ({
        ...prevState,
        customers: {
          ...prevState.customers,
          bill_quantity: defaultValue,
          errors: {
            ...prevState.customers.errors,
            bill_quantity: " ",
          },
        },
      }));
    }

    if (field === "pay_rate_type") {
      const defaultValue = !!value?.StandByRates?.length
        ? "1"
        : value === "Hourly"
        ? "8"
        : value === "Daily"
        ? "1"
        : "";

      if (isChanging) {
        setState((prevState) => ({
          ...prevState,
          contractors: {
            ...prevState.contractors,
            pay_quantity: defaultValue,
            errors: {
              ...prevState.contractors.errors,
              pay_quantity: " ",
            },
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          contractors: {
            ...prevState.contractors,
            // pay_quantity: defaultValue,
            //CHNAGE DONE FOR BUG NO 14302
            pay_quantity: !prevState.customers?.route
              ? defaultValue
              : prevState.customers?.route?.default_pay_quantity ||
                entry.pay_quantity,
            errors: {
              ...prevState.contractors.errors,
              pay_quantity: " ",
            },
          },
        }));
      }
    }

    if (field === "bill_rate_per_extra_mile") {
      setState((prevState) => ({
        ...prevState,
        customers: {
          ...prevState.customers,
          bill_extra_mile_amount:
            +value * +prevState.customers.bill_extra_miles,
        },
      }));
    }

    if (field === "pay_rate_per_extra_mile") {
      setState((prevState) => ({
        ...prevState,
        contractors: {
          ...prevState.contractors,
          pay_extra_mile_amount:
            +value * +prevState.contractors.pay_extra_miles,
        },
      }));
    }

    if (field === "bill_extra_miles") {
      setState((prevState) => ({
        ...prevState,
        customers: {
          ...prevState.customers,
          bill_extra_mile_amount:
            +prevState.customers.bill_rate_per_extra_mile * +value,
        },
      }));
    }
    if (field === "pay_extra_miles") {
      setState((prevState) => ({
        ...prevState,
        contractors: {
          ...prevState.contractors,
          pay_extra_mile_amount:
            +prevState.contractors.pay_rate_per_extra_mile * +value,
        },
      }));
    }
    if (field === "individual_contractor") {
      setState((prevState) => ({
        ...prevState,
        contractors: {
          ...prevState.contractors,
          stanbyRateTypeArray: !!value?.StandByRates?.length
            ? value?.StandByRates
            : RATE_TYPES,
        },
      }));
    }

    setState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [field]: value,
        errors: {
          ...prevState[type].errors,
          [field]: errorMessage,
        },
      },
    }));
  };

  const billPayPrice = priceMatrix?.filter((item) => {
    return (
      item.customer_branch_id === state.customers?.location?.id &&
      item.name === state.customers?.route?.matrix_name
    );
  });

  const payValuesCalculate = ({
    pay_rate = 0,
    pay_quantity = 0,
    pay_fuel_amount = 0,
    pay_fuel_surcharge = 0,
  }) => {
    const totalAmount = +pay_rate * +pay_quantity;
    const fuelAmount = totalAmount * (+pay_fuel_surcharge / 100);
    const fuelSurcharge = totalAmount
      ? (pay_fuel_amount / totalAmount) * 100
      : 0;

    return {
      pay_amount: totalAmount,
      pay_fuel_amount: fuelAmount,
      pay_fuel_surcharge: fuelSurcharge,
    };
  };

  const billValuesCalculate = ({
    bill_rate = 0,
    bill_quantity = 0,
    bill_fuel_amount = 0,
    bill_fuel_surcharge = 0,
  }) => {
    const totalAmount = +bill_rate * +bill_quantity;
    const fuelAmount = totalAmount * (bill_fuel_surcharge / 100);
    const fuelSurcharge = totalAmount
      ? (bill_fuel_amount / totalAmount) * 100
      : 0;
    return {
      bill_amount: totalAmount,
      bill_fuel_amount: fuelAmount,
      bill_fuel_surcharge: fuelSurcharge,
    };
  };

  const billingOptions = billPayPrice?.map((item) => item.bill_price);

  const settlementOptions = billPayPrice?.map((item) => item.pay_price);

  const handleBillRateChange = (event, value) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      customers: {
        ...prevState.customers,
        bill_rate: value,
      },
    }));
    const correspondingSettlement = state.contractors.dont_pay
      ? { pay_price: "0.00" }
      : billPayPrice.find((item) => item.bill_price === value);
    if (correspondingSettlement) {
      isFieldChange = true;
      setState((prevState) => ({
        ...prevState,
        contractors: {
          ...prevState.contractors,
          pay_rate: correspondingSettlement.pay_price,
        },
      }));
      const payFuelAmount = (
        +payValuesCalculate({
          ...state.contractors,
          pay_rate: correspondingSettlement.pay_price,
        }).pay_fuel_amount || 0
      ).toFixed(2);
      handleFieldChange(
        "pay_fuel_amount",
        payFuelAmount,
        "contractors",
        "number"
      );
    }
  };

  const handlePayRateChange = (event, value) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      contractors: {
        ...prevState.contractors,
        pay_rate: value,
      },
    }));
    const correspondingBilling = state.customers.dont_bill
      ? { bill_price: "0.00" }
      : billPayPrice.find((item) => item.pay_price === value);
    if (correspondingBilling) {
      isFieldChange = true;
      setState((prevState) => ({
        ...prevState,
        customers: {
          ...prevState.customers,
          bill_rate: correspondingBilling.bill_price,
        },
      }));
      const billFuelAmount = (
        +billValuesCalculate({
          ...state.customers,
          bill_rate: correspondingBilling.bill_price,
        }).bill_fuel_amount || 0
      ).toFixed(2);
      handleFieldChange(
        "bill_fuel_amount",
        billFuelAmount,
        "customers",
        "number"
      );
    }
  };

  const handleFieldBlur = (evt) => {
    if (!isNew) {
      const field = evt.currentTarget?.name || evt.target?.name;
      const value = evt.currentTarget?.value || evt.target?.value;
      showFieldAlert(
        field,
        {
          ...state.customers,
          ...state.contractors,
          [field]: value,
        },
        {
          bill_quantity: state.customers.errors.bill_quantity,
          bill_rate: state.customers.errors.bill_rate,
          pay_rate: state.contractors.errors.pay_rate,
          pay_quantity: state.contractors.errors.pay_quantity,
        }
      );
    }
  };

  const calculateFuelSurcharge = async (route_id = "", schedule_date = "") => {
    setState((prevState) => ({
      ...prevState,
      loader: true,
    }));

    const date = !!schedule_date
      ? schedule_date
      : !showGeneralDate
      ? scheduleDate
      : state.general.schedule_date;

    const query = `?route_id=${route_id}&schedule_date=${date}`;

    const { data, error } = await Service.getFuelSurcharge(query);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        loader: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      loader: false,
    }));
    return data;
  };
  useEffect(() => {
    if (entry && !isNew) {
      const contractorDetails =
        contractorList.find(
          (item) => item.id === entry.individual_contractor?.id
        ) || {};

      const deductionReasonDetails =
        deductionReasonList.filter(
          (reason) => reason.id == entry.deduction_reason
        ) || [];

      const { contractorTypeValue, stand_by_rate_type, stand_by_rate } =
        contractorDetails;

      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState.id,
        general: {
          ...prevState.general,
          schedule_date:
            entry.schedule_date || defaultState.general.schedule_date,
          kam: entry.kamUser || defaultState.general.kam,
        },
        customers: {
          ...prevState.customers,
          customer:
            entry.customer_branch?.customer || defaultState.customers.customer,
          location: entry.customer_branch || defaultState.customers.location,
          state: entry.customer_branch?.state || defaultState.customers.state,
          invoice_type:
            INVOICE_TYPES.find(
              (invoice) =>
                invoice.id === entry.invoice_type ||
                invoice.label === entry.invoice_type
            ) || defaultState.customers.invoice_type,
          route: entry.routeDetails
            ? {
                name: entry?.routeDetails?.name,
                id: entry.route_id,
                price_type: entry?.routeDetails?.price_type,
                is_weekday: entry?.routeDetails?.is_weekday,
                matrix_name: entry?.routeDetails?.matrix_name,
              }
            : defaultState.customers.route,
          bill_rate_type:
            entry.bill_rate_type || defaultState.customers.bill_rate_type,
          bill_rate: entry.bill_rate || defaultState.customers.bill_rate,
          bill_quantity:
            entry.bill_quantity || defaultState.customers.bill_quantity,
          bill_amount: entry.bill_amount || defaultState.customers.bill_amount,
          bill_fuel_surcharge:
            entry.bill_fuel_surcharge ||
            defaultState.customers.bill_fuel_surcharge,
          bill_fuel_amount:
            entry.bill_fuel_amount || defaultState.customers.bill_fuel_amount,
          bill_rate_per_extra_mile:
            entry.bill_rate_per_extra_mile ||
            defaultState.customers.bill_rate_per_extra_mile,
          bill_extra_mile_amount:
            entry.bill_extra_mile_amount ||
            defaultState.customers.bill_extra_mile_amount,
          bill_extra_miles:
            entry.bill_extra_miles || defaultState.customers.bill_extra_miles,
          bill_toll_amount:
            entry.bill_toll_amount || defaultState.customers.bill_toll_amount,
          dont_bill: entry.dont_bill || defaultState.customers.dont_bill,
          dont_bill_tolls:
            entry.dont_bill_tolls || defaultState.dont_bill_tolls,
          dont_bill_miles:
            entry.dont_bill_miles || defaultState.dont_bill_miles,
          vehicle_type:
            entry.vehicleType || defaultState.customers.vehicle_type,
        },
        contractors: {
          ...prevState.contractors,
          individual_contractor: entry.individual_contractor
            ? {
                ...entry.individual_contractor,
                contractorTypeValue,
                stand_by_rate_type,
                stand_by_rate,
              }
            : defaultState.contractors.individual_contractor,
          settlement_company:
            entry.settlementCompany ||
            defaultState.contractors.settlement_company,
          pay_rate_type:
            entry.pay_rate_type || defaultState.contractors.pay_rate_type,
          pay_rate: entry.pay_rate || defaultState.contractors.pay_rate,
          pay_quantity:
            entry.pay_quantity || defaultState.contractors.pay_quantity,
          pay_amount: entry.pay_amount || defaultState.contractors.pay_amount,
          dont_pay: entry.dont_pay || defaultState.customers.dont_pay,
          dont_pay_tolls: entry.dont_pay_tolls || defaultState.dont_pay_tolls,
          dont_pay_miles: entry.dont_pay_miles || defaultState.dont_pay_miles,
          pay_fuel_surcharge:
            entry.pay_fuel_surcharge ||
            defaultState.contractors.pay_fuel_surcharge,
          pay_fuel_amount:
            entry.pay_fuel_amount || defaultState.contractors.pay_fuel_amount,
          pay_rate_per_extra_mile:
            entry.pay_rate_per_extra_mile ||
            defaultState.contractors.pay_rate_per_extra_mile,
          pay_toll_amount:
            entry.pay_toll_amount || defaultState.customers.pay_toll_amount,
          calculatePay:
            entry.calculatePay || defaultState.customers.calculatePay,
          pay_extra_mile_amount:
            entry.pay_extra_mile_amount ||
            defaultState.contractors.pay_extra_mile_amount,
          reimbursement:
            entry.reimbursement || defaultState.contractors.reimbursement,
          deduction: entry.deduction || defaultState.contractors.deduction,
          deduction_reason:
            deductionReasonDetails[0] ||
            defaultState.contractors.deduction_reason,
          pay_extra_miles:
            entry.pay_extra_miles || defaultState.customers.pay_extra_miles,
        },
        misc: {
          ...prevState.misc,
          office: entry.aaUser || defaultState.misc.manager,
          manager: entry.mgrUser || defaultState.misc.manager,
          kam_notes: entry.kam_notes || defaultState.misc.kam_notes,
          change_notes: entry.change_notes || defaultState.misc.change_notes,
          review_notes: entry.review_notes || defaultState.misc.review_notes,
          profit_percentage:
            entry.profit_percentage || defaultState.customers.profit_percentage,
        },
      }));
    }
  }, [entry]);

  const calculateBillValues = ({
    bill_rate = 0,
    bill_quantity = 0,
    bill_fuel_amount = 0,
    bill_fuel_surcharge = 0,
  }) => {
    const totalAmount = +bill_rate * +bill_quantity;
    const fuelAmount = totalAmount * (bill_fuel_surcharge / 100);
    const fuelSurcharge = totalAmount
      ? (bill_fuel_amount / totalAmount) * 100
      : 0;
    return {
      bill_amount: totalAmount,
      bill_fuel_amount: fuelAmount,
      bill_fuel_surcharge: fuelSurcharge,
    };
  };

  const handleConfirmDontBill = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBill: true,
      confirmDontBillValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontPay = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPay: true,
      confirmDontPayValue: isCheckBoxChecked,
    }));
  };
  const handleConfirmDontBillToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillToll: isCheckBoxChecked,
      confirmDontBillTollValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontPayToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayToll: isCheckBoxChecked,
      confirmDontPayTollValue: isCheckBoxChecked,
    }));
  };
  const handleConfirmDontBillExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillExtraMiles: isCheckBoxChecked,
      confirmDontBillExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontPayExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayExtraMiles: isCheckBoxChecked,
      confirmDontPayExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const calculateBill = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      customers: {
        ...prevState.customers,
        dont_bill: isCheckBoxChecked,
        bill_rate: defaultState.customers.bill_rate,
        bill_fuel_amount: defaultState.customers.bill_fuel_amount,
        bill_toll_amount: defaultState.customers.bill_toll_amount,
        bill_extra_miles: defaultState.customers.bill_extra_miles,
        bill_extra_mile_amount: defaultState.customers.bill_extra_mile_amount,
        dont_bill_tolls: defaultState.customers.dont_bill_tolls,
        dont_bill_miles: defaultState.customers.dont_bill_miles,
      },
    }));
  };

  const calculatePay = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      contractors: {
        ...prevState.contractors,
        dont_pay: isCheckBoxChecked,
        pay_rate: defaultState.contractors.pay_rate,
        pay_fuel_amount: defaultState.contractors.pay_fuel_amount,
        pay_toll_amount: defaultState.contractors.pay_toll_amount,
        pay_extra_miles: defaultState.contractors.pay_extra_miles,
        pay_extra_mile_amount: defaultState.contractors.pay_extra_mile_amount,
        dont_pay_tolls: defaultState.contractors.dont_pay_tolls,
        dont_pay_miles: defaultState.contractors.dont_pay_miles,
      },
    }));
  };
  const calculateBillToll = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      customers: {
        ...prevState.customers,
        dont_bill_tolls: isCheckBoxChecked,
        bill_toll_amount: defaultState.customers.bill_toll_amount,
      },
    }));
  };
  const calculatePayToll = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      contractors: {
        ...prevState.contractors,
        dont_pay_tolls: isCheckBoxChecked,
        pay_toll_amount: defaultState.contractors.pay_toll_amount,
      },
    }));
  };
  const calculateBillMiles = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      customers: {
        ...prevState.customers,
        dont_bill_miles: isCheckBoxChecked,
        bill_extra_miles: defaultState.customers.bill_extra_miles,
        bill_extra_mile_amount: defaultState.customers.bill_extra_mile_amount,
      },
    }));
  };
  const calculatePayMiles = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      contractors: {
        ...prevState.contractors,
        dont_pay_miles: isCheckBoxChecked,
        pay_extra_miles: defaultState.contractors.pay_extra_miles,
        pay_extra_mile_amount: defaultState.contractors.pay_extra_mile_amount,
      },
    }));
  };

  const calculatePayValues = ({
    pay_rate = 0,
    pay_quantity = 0,
    pay_fuel_amount = 0,
    pay_fuel_surcharge = 0,
  }) => {
    const totalAmount = +pay_rate * +pay_quantity;
    const fuelAmount = totalAmount * (+pay_fuel_surcharge / 100);
    const fuelSurcharge = totalAmount
      ? (pay_fuel_amount / totalAmount) * 100
      : 0;

    return {
      pay_amount: totalAmount,
      pay_fuel_amount: fuelAmount,
      pay_fuel_surcharge: fuelSurcharge,
    };
  };

  const handleShowAddLookupDialog = (noteType) => {
    setState((prevState) => ({
      ...prevState,
      showAddLookupDialog: true,
      noteType,
    }));
  };
  const fetchReviewNotesList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        reviewNotes: true,
      },
    }));

    const { data, error } = await SharedService.getReviewNotesList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          reviewNotes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      reviewNotesList:
        data?.rows.filter((reviewNotes) => reviewNotes.is_active) ||
        defaultState.reviewNotesList,
      isFetchingList: {
        ...prevState.isFetchingList,
        reviewNotes: false,
      },
    }));
  };

  const fetchAAMGRNotesList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        reviewNotes: true,
      },
    }));

    const { data, error } = await SharedService.getAAMGRNotesList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          reviewNotes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      aa_mgrNotesList:
        data?.rows.filter((reviewNotes) => reviewNotes.is_active) ||
        defaultState.aa_mgrNotesList,
      isFetchingList: {
        ...prevState.isFetchingList,
        reviewNotes: false,
      },
    }));
  };

  const addLookupValue = async () => {
    setState((prevState) => ({ ...prevState, isLookupLoading: true }));

    const payload = {
      type: state.noteType === "reviewNotes" ? "Review Notes" : "AA/MGR Notes",
      value:
        state.noteType === "reviewNotes"
          ? state.misc.add_review_notes
          : state.noteType === "AaMgrNotes"
          ? state.misc.change_notes
          : state.aaMgrNotes || state.misc.change_notes,
      is_active: true,
      description: "hii",
    };

    const { error } = await Service.addNotesInLookup(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLookupLoading: false }));
      if (error.message === "This record already exists.") {
        error.message = `This Value already exists in Lookup(${
          state.noteType === "reviewNotes" ? "Review" : "AA/MGR"
        } Notes).`;
      }
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(
      `Value added in Lookup(${
        state.noteType === "reviewNotes" ? "Review" : "AA/MGR"
      } Notes) successfully.`
    );
    setState((prevState) => ({
      ...prevState,
      isLookupLoading: false,
      showAddLookupDialog: false,
    }));
  };

  useEffect(() => {
    fetchReviewNotesList();
    fetchAAMGRNotesList();
  }, []);

  useEffect(() => {
    const validateQuantity = (fieldType, quantity, stateKey, errorKey) => {
      const errorMessage = validate(fieldType, quantity, stateKey);
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          [stateKey]: {
            ...prevState[stateKey],
            errors: {
              ...prevState[stateKey].errors,
              [errorKey]: errorMessage,
            },
          },
        }));
      }
    };
    if (
      state.customers.bill_rate_type === "Daily" &&
      +state.customers.bill_quantity > 1.99
    ) {
      validateQuantity(
        "bill_quantity",
        +state.customers.bill_quantity,
        "customers",
        "bill_quantity"
      );
    } else {
      setState((prevState) => ({
        ...prevState,
        customers: {
          ...prevState.customers,
          errors: {
            ...prevState.customers.errors,
            bill_quantity: " ",
          },
        },
      }));
    }

    if (
      state.contractors.pay_rate_type === "Daily" &&
      +state.contractors.pay_quantity > 1.99
    ) {
      validateQuantity(
        "pay_quantity",
        +state.contractors.pay_quantity,
        "contractors",
        "pay_quantity"
      );
    } else {
      setState((prevState) => ({
        ...prevState,
        contractors: {
          ...prevState.contractors,
          errors: {
            ...prevState.contractors.errors,
            pay_quantity: " ",
          },
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.contractors.pay_rate_type,
    state.contractors.pay_quantity,
    state.customers.bill_quantity,
    state.customers.bill_rate_type,
    state.contractors.errors.pay_quantity,
    state.contractors.errors.pay_rate_type,
    state.customers.errors.bill_quantity,
    state.customers.errors.bill_rate_type,
  ]);

  useEffect(() => {
    if (!!Object.keys(formAlertFields).length) {
      setState((prevState) => ({
        ...prevState,
        customers: {
          ...prevState.customers,
          ...formAlertFields,
          bill_fuel_amount: (
            +calculateBillValues({ ...prevState.customers, ...formAlertFields })
              .bill_fuel_amount || 0
          ).toFixed(2),
          errors: {
            ...prevState.customers.errors,
            ...formAlertFields.errors,
          },
        },
        contractors: {
          ...prevState.contractors,
          ...formAlertFields,
          pay_fuel_amount: (
            +calculatePayValues({
              ...prevState.contractors,
              ...formAlertFields,
            }).pay_fuel_amount || 0
          ).toFixed(2),
          errors: {
            ...prevState.contractors.errors,
            ...formAlertFields.errors,
          },
        },
      }));
    }
  }, [formAlertFields]);

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const reviewColumnConfig = [
    {
      id: "customer_name",
      label: "Customer Name",
      field: "customer_name",
      render: (row) => {
        return (
          <Tooltip
            title={
              row.customerName || row.customer_branch?.customer?.name || "-"
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customerName || row.customer_branch?.customer?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "customer_branch.location",
      label: "Location",
      field: "customer_branch.location",
      render: (row) => {
        return (
          <Tooltip
            title={row.location || row.customer_branch?.location || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.location || row.customer_branch?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "routeDetails.name",
      label: "Route",
      field: "routeDetails.name",
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={(row?.routeDetails?.name || row.route) ?? "-"}
            placement="top-start"
          >
            <Typography noWrap variant="body2">
              {row?.routeDetails?.name || row.route || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={
              (row.pdxCompany || row.customer_branch?.pdxCompany?.value) ?? ""
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.pdxCompany || row.customer_branch?.pdxCompany?.value || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "individual_contractor_icid",
      label: "ICID/IC Name",
      field: "individual_contractor_icid",
      render: (row) => {
        let title = `
      IC Settlement Company Name: ${
        row.individual_contractor?.settlementCompany?.value || "-"
      }, 
    
      ICID: ${row.individual_contractor?.icid || "-"},
      IC Name: ${row.individual_contractor?.last_name || ""} ${
          row.individual_contractor?.first_name || ""
        },
    Contractor Type: ${row.individual_contractor?.contractorTypeValue || "-"}
    ${row.ic_inactive ? ", Status: Inactive ICID" : ""}
    `;

        if (
          row.individual_contractor_changed &&
          row.original_individual_contractor_id
        ) {
          const orginalIC = appData.IC.find(
            (contractor) =>
              contractor?.id === row.original_individual_contractor_id
          );
          if (orginalIC) {
            title += `, Original IC Name: ${orginalIC.last_name || ""} ${
              orginalIC.first_name || ""
            }`;
          }
        }

        const label = row.individual_contractor?.icid
          ? `${row.individual_contractor?.icid || ""}/${
              row.individual_contractor?.last_name || ""
            } ${row.individual_contractor?.first_name || ""}`
          : "";

        const flagLabel =
          (!!row.individual_contractor?.is_flagged &&
            !!row.individual_contractor?.is_cautious_flagged) ||
          !!row.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.individual_contractor?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );

        const cautionFlaggedBy = userInfo(
          row?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row?.individual_contractor?.ctn_flag_rsn
        );
        const flagTitles = `Flagged Reason: ${
          row.individual_contractor?.flagReason?.value ||
          cautionFlaggedReason?.value
        }, Flagged By: ${
          row.individual_contractor?.flaggedBy?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          row.individual_contractor?.flaggedBy?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          row.individual_contractor?.flaggedBy?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          row.individual_contractor?.flaggedBy?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          row.individual_contractor?.flagged_at ||
            row?.individual_contractor?.ctn_flagged_at
        )}`;

        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {label}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },
    {
      id: "review_reason",
      label: "Review Reason",
      field: "review_reason",
      render: (row) => {
        return (
          <Tooltip title={row.reviewReason || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.reviewReason || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      headerClassName: classes.aaMgrNotesTableHeaderCell,
      id: "change_notes",
      label: "AA/MGR Note",
      field: "change_notes",
      render: (row) => {
        return (
          <div className="d-flex f-align-center">
            <Autocomplete
              fullWidth
              options={state.aa_mgrNotesList}
              freeSolo
              disableClearable
              getOptionLabel={(option) => option.value || option}
              defaultValue={
                row.temp_aaMgr_notes === undefined ||
                row.temp_aaMgr_notes === null
                  ? state.misc.change_notes || ""
                  : row.temp_aaMgr_notes
              }
              onChange={(evt, value) => {
                if (!isNew) {
                  entry.temp_aaMgr_notes = value.value || "";
                } else {
                  temp_aaMgr_notes = value.value || "";
                }
                setState((prevState) => ({
                  ...prevState,
                  errors: {
                    ...prevState.errors,
                    aaMgrNotes: "",
                  },
                  aaMgrNotes: value.value,
                }));
              }}
              getOptionSelected={(option, value) => option.value === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  variant="outlined"
                  name="aaMgrNotes"
                  size="small"
                  label="Add AA/MGR Note"
                  error={!!state.errors.aaMgrNotes?.trim()}
                  helperText={state.errors.aaMgrNotes}
                  onChange={(evt) => {
                    const field = evt.target.name;
                    const value = evt.target.value;
                    const errorMessage = validate(field, value) || "";
                    if (!isNew) {
                      entry.temp_aaMgr_notes = value || "";
                    } else {
                      temp_aaMgr_notes = value || "";
                    }
                    setState((prevState) => ({
                      ...prevState,
                      errors: {
                        ...prevState.errors,
                        aaMgrNotes: errorMessage,
                      },
                      aaMgrNotes: value,
                    }));
                  }}
                />
              )}
            />
            {!isRoleMGR && (
              <Tooltip title="Add To Lookup(AA/MGR Notes)" placement="top-end">
                <Button
                  type="submit"
                  onClick={() => handleShowAddLookupDialog("changeNotes")}
                  variant="contained"
                  color="primary"
                  disabled={
                    !(state.aaMgrNotes || state.misc.change_notes) ||
                    state.errors?.aaMgrNotes?.length > 1
                  }
                  className={classes.AaMgrbuttonStyle}
                >
                  <PostAddIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const handleSubmit = async (isChangeNotes, changeNotesValue) => {
    if (state.customers.route?.price_type === "Matrix") {
      const billRate = +state.customers?.bill_rate;
      const payRate = +state.contractors?.pay_rate;

      const billRateExists = billingOptions?.includes(billRate);
      const payRateExists = settlementOptions?.includes(payRate);

      if (
        !billRateExists &&
        !payRateExists &&
        !(state.contractors.dont_pay || state.customers.dont_bill)
      ) {
        return toast.error(
          "Billing and Settlement values doesn't exist in price matrix for this customer."
        );
      } else if (!billRateExists && !state.customers.dont_bill) {
        return toast.error(
          "Billing value doesn't exist in price matrix for this customer."
        );
      } else if (!payRateExists && !state.contractors.dont_pay) {
        return toast.error(
          "Settlement value doesn't exist in price matrix for this customer."
        );
      }
    }

    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { general, customers, contractors, misc } = state;
    let schedule_date = "";

    if (!isNew) {
      schedule_date = general.schedule_date;
    } else {
      schedule_date = !isDateChanged ? scheduleDate : general.schedule_date;
    }
    let mapFileds = {
      ...general,
      ...customers,
      ...contractors,
      ...misc,
      vehicle_type: customers?.vehicle_type?.id || null,
      deduction_reason: contractors?.deduction_reason?.id || null,
      route_id: customers?.route?.id,
      invoice_type: customers?.invoice_type?.id || null,
      customer_branch_id: customers.location?.id,
      individual_contractor_id: contractors.individual_contractor?.id,
      mgr_id: misc.manager?.id,
      aa_id: misc.office?.id,
      kam_id: general.kam?.id,
      settlement_co_id: contractors.settlement_company?.id || null,
      review_notes: misc.add_review_notes?.trim(),
      schedule_date,
      dont_pay: contractors?.dont_pay,
      dont_pay_tolls: contractors?.dont_pay_tolls,
      dont_pay_miles: contractors?.dont_pay_miles,
      dont_bill: customers?.dont_bill,
      dont_bill_tolls: customers?.dont_bill_tolls,
      dont_bill_miles: customers?.dont_bill_miles,
      dont_bill_notes:
        customers?.dont_bill === false ? null : entry?.dont_bill_notes,
      isChangeNotes: misc.isChangeNotes === false ? false : isChangeNotes,
      change_notes:
        misc.isChangeNotes === false
          ? state.misc.change_notes
          : changeNotesValue,
    };

    const {
      errors,
      individual_contractor,
      customer,
      manager,
      office,
      kam,
      settlement_company,
      location,
      profit_percentage,
      ...payload
    } = mapFileds;

    Object.keys(payload).map((key) => {
      if (NUMBER_TYPE_FIELDS.includes(key)) {
        payload[key] = Number.isInteger(+payload[key])
          ? +payload[key]
          : (+payload[key]).toFixed(2);
      }
    });

    const serviceMethod = isNew ? "post" : "put";

    if (!isNew) {
      payload.id = entry.id;
    }

    const { data, error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    if (data.code === "BS-U-RBS") {
      handleClose(true, true);
    } else if (data.code === "PDX-BSU-001") {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isAaMgrNotesAdded: true,
        isAaMgrNotesPresent: data || null,
      }));
    } else {
      toast.success(`Entry ${isNew ? "created" : "updated"} successfully.`);
      setState((prevState) => ({ ...prevState, isLoading: false }));
      handleClose(true);
    }
    if (isDateChanged && data.code === "PDX-BSU-001") {
      isDateChanged = true;
    } else {
      isDateChanged = false;
    }
    if (state.misc.isChangeNotes) {
      temp_aaMgr_notes = "";
    }
  };

  const handleClose = (isSubmitted = false, isUnderReview = false) => {
    if (isUnderReview === "backdropClick") {
      return false;
    }
    isFieldChange = false;
    setState(defaultState);
    onClose(isSubmitted, isUnderReview);
  };

  const selectedRoute = customerList
    .filter((customer) => customer.id === state.customers.customer?.id)[0]
    ?.customer_branches.map((item) => item.routes)
    .flat()
    .filter((route) => route.id === state.customers.route?.id);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose onClose={() => {}}>
          {`${isNew ? "New" : "Update"} Entry`}
        </Dialog.Title>
        <Dialog.Content>
          <div>
            <Typography variant="h6" className="mb-3">
              General
            </Typography>
            <div>
              <GeneralPanel
                isNew={isNew}
                routeDetails={state.customers.route}
                isTabletView={isTabletView}
                entry={state.general}
                scheduleDate={scheduleDate}
                calculateFuelSurcharge={calculateFuelSurcharge}
                KAMList={kamUsers.filter((kam) => kam.is_active)}
                onFieldChange={handleFieldChange}
              />
            </div>
          </div>

          <div>
            <Typography variant="h6" className="mb-3">
              Customer
            </Typography>
            {state.loader ? (
              <>
                <div className="d-flex flex-wrap">
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4 mb-4"
                    width={128}
                    height={28}
                  />
                </div>
                <div className="d-flex flex-wrap">
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={128}
                    height={28}
                  />
                </div>
              </>
            ) : (
              <CustomersPanel
                isTabletView={isTabletView}
                isNew={isNew}
                handleConfirmDontBill={handleConfirmDontBill}
                handleConfirmDontBillToll={handleConfirmDontBillToll}
                handleConfirmDontBillExtraMiles={
                  handleConfirmDontBillExtraMiles
                }
                calculateValues={billValuesCalculate}
                calculateBill={calculateBill}
                calculateBillToll={calculateBillToll}
                calculateBillMiles={calculateBillMiles}
                isCalculateBill={state.customers.dont_bill}
                isCalculateBillToll={state.customers.dont_bill_tolls}
                isCalculateBillMiles={state.customers.dont_bill_miles}
                isPriceMatrixFetched={isPriceMatrixFetched}
                scheduleDate={scheduleDate}
                // handleBFSChecked={handleBFSChecked}
                calculateFuelSurcharge={calculateFuelSurcharge}
                billingOptions={billingOptions}
                billPayPrice={billPayPrice}
                handleBillRateChange={handleBillRateChange}
                // isBFSChecked={state.isBFSChecked}
                vehicleTypeList={vehicleTypeList}
                defaultCustomerState={defaultState.customers}
                defaultContractorState={defaultState.contractors}
                entry={state.customers}
                customerList={customerList}
                standByRate={state.contractors.individual_contractor}
                isStandBy={
                  state.contractors.individual_contractor
                    ?.contractorTypeValue === "Stand-by"
                }
                onFieldChange={handleFieldChange}
                pay_rate_type={state.contractors?.pay_rate_type}
                onCustomerChange={() =>
                  setState((prevState) => ({
                    ...prevState,
                    customers: {
                      ...prevState.customers,
                      location: null,
                      state: null,
                      route: null,
                      vehicle_type: defaultState.customers.vehicle_type,
                      bill_rate_type: defaultState.customers.bill_rate_type,
                      bill_rate: defaultState.customers.bill_rate,
                      bill_quantity: defaultState.customers.bill_quantity,
                    },
                    contractors: {
                      ...prevState.contractors,
                      // pay_rate_type: defaultState.contractors.pay_rate_type,
                      // pay_rate: defaultState.contractors.pay_rate,
                      pay_quantity: defaultState.contractors.pay_quantity,
                    },
                  }))
                }
                handleFieldBlur={handleFieldBlur}
                showFieldAlert={showFieldAlert}
              />
            )}
          </div>

          <div>
            <Typography variant="h6" className="mb-3">
              Contractor
            </Typography>
            {state.loader ? (
              <>
                <div className="d-flex flex-wrap">
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4 mb-4"
                    width={128}
                    height={28}
                  />
                </div>
                <div className="d-flex flex-wrap">
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                  <Skeleton
                    variant="rect"
                    className="mr-4"
                    width={198}
                    height={28}
                  />
                </div>
              </>
            ) : (
              <ContractorsPanel
                isTabletView={isTabletView}
                getStandByRates={getStandByRates}
                appData={appData}
                isNew={isNew}
                isCalculatePay={state.contractors.dont_pay}
                isCalculatePayToll={state.contractors.dont_pay_tolls}
                isCalculatePayMiles={state.contractors.dont_pay_miles}
                calculateValues={payValuesCalculate}
                settlementOptions={settlementOptions}
                deductionReasonList={deductionReasonList}
                vehicleTypeList={vehicleTypeList}
                billPayPrice={billPayPrice}
                isPriceMatrixFetched={isPriceMatrixFetched}
                handlePayRateChange={handlePayRateChange}
                calculatePay={calculatePay}
                calculatePayToll={calculatePayToll}
                calculatePayMiles={calculatePayMiles}
                handleConfirmDontPay={handleConfirmDontPay}
                handleConfirmDontPayExtraMiles={handleConfirmDontPayExtraMiles}
                handleConfirmDontPayToll={handleConfirmDontPayToll}
                defaultContractorState={defaultState.contractors}
                entry={state.contractors}
                contractorList={contractorList}
                settlementCompanyList={settlementCompanyList}
                onFieldChange={handleFieldChange}
                handleFieldBlur={handleFieldBlur}
                showFieldAlert={showFieldAlert}
                calculateFuelSurcharge={calculateFuelSurcharge}
                selectedRoute={selectedRoute}
                pay_rate_type={state.contractors.pay_rate_type}
              />
            )}
          </div>

          <div>
            <Typography variant="h6" className="mb-3">
              Misc
            </Typography>
            <MiscPanel
              isTabletView={isTabletView}
              handleShowAddLookupDialog={handleShowAddLookupDialog}
              isNew={isNew}
              isRoleMGR={isRoleMGR}
              reviewNotesList={state.reviewNotesList}
              aa_mgrNotesList={state.aa_mgrNotesList}
              entry={state.misc}
              MGRList={managerUsers}
              AAList={officeUsers}
              AdminList={adminUsers}
              profitPercentage={calculateProfitPercentage({
                bill_amount:
                  state.customers.bill_rate * state.customers.bill_quantity,
                pay_amount:
                  state.contractors.pay_rate * state.contractors.pay_quantity,
              })}
              onFieldChange={handleFieldChange}
            />
          </div>

          {state.isUnderReview && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
              }}
              open={!!state.isUnderReview}
              contentText="Schedule will undergo for review process on billing & settlement review screen."
              onConfirm={() => {
                setState((prevState) => ({
                  ...prevState,
                  isUnderReview: false,
                }));
                handleClose(true);
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  isUnderReview: false,
                }));
                handleClose(true);
              }}
              isConfirmDisabled={state.isLoading}
              positiveActionLabel={
                <>
                  OK
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </>
              }
              negativeActionLabel={null}
            />
          )}
          {state.showAddLookupDialog && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
                paper: classes.addLookupEmailpaperSize,
              }}
              open={!!state.showAddLookupDialog}
              contentText={
                <>
                  <Typography variant="body2" className="mt-2 text-bold">
                    {`Would you also like to add this ${
                      state.noteType === "reviewNotes" ? "review" : "AA/MGR"
                    } note `}
                    <span style={{ color: "Black" }}>
                      {state.noteType === "reviewNotes"
                        ? state.misc.add_review_notes
                        : state.noteType === "AaMgrNotes"
                        ? state.misc.change_notes
                        : state.aaMgrNotes || state.misc.change_notes}
                    </span>
                    {` in the Lookup(${
                      state.noteType === "reviewNotes" ? "Review" : "AA/MGR"
                    } Notes)?`}
                  </Typography>
                </>
              }
              onConfirm={() => {
                addLookupValue(state.showAddLookupDialog);
                setTimeout(() => {
                  state.noteType === "reviewNotes"
                    ? fetchReviewNotesList()
                    : fetchAAMGRNotesList();
                }, 2000);
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  showAddLookupDialog: false,
                }));
              }}
              isConfirmDisabled={state.isLookupLoading}
              positiveActionLabel={
                <>
                  ADD TO LOOKUP
                  {state.isLookupLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </>
              }
              negativeActionLabel={<>CANCEL</>}
            />
          )}
          {state.confirmDontBill && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
              }}
              open={!!state.confirmDontBill}
              contentText="By Checking, bill rate will become 0. This action will not be reverted once done"
              onConfirm={() => {
                calculateBill(state.confirmDontBillValue);
                setState((prevState) => ({
                  ...prevState,
                  confirmDontBill: false,
                }));
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  confirmDontBill: false,
                }));
              }}
              isConfirmDisabled={state.isLoading}
              positiveActionLabel={
                <>
                  OK
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </>
              }
              negativeActionLabel={<>Cancel</>}
            />
          )}
          {state.confirmDontPay && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
              }}
              open={!!state.confirmDontPay}
              contentText="By Checking, pay rate will become 0. This action will not be reverted once done"
              onConfirm={() => {
                calculatePay(state.confirmDontPayValue);
                setState((prevState) => ({
                  ...prevState,
                  confirmDontPay: false,
                }));
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  confirmDontPay: false,
                }));
              }}
              isConfirmDisabled={state.isLoading}
              positiveActionLabel={
                <>
                  OK
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </>
              }
              negativeActionLabel={<>Cancel</>}
            />
          )}
          {state.confirmDontBillToll && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
              }}
              open={!!state.confirmDontBillToll}
              contentText=" By Checking, bill toll amount will become 0. This action will not be reverted once done."
              onConfirm={() => {
                calculateBillToll(state.confirmDontBillTollValue);
                setState((prevState) => ({
                  ...prevState,
                  confirmDontBillToll: false,
                }));
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  confirmDontBillToll: false,
                }));
              }}
              isConfirmDisabled={state.isLoading}
              positiveActionLabel="OK"
              negativeActionLabel={<>Cancel</>}
            />
          )}
          {state.confirmDontPayToll && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
              }}
              open={!!state.confirmDontPayToll}
              contentText=" By Checking, pay toll amount will become 0. This action will not be reverted once done."
              onConfirm={() => {
                calculatePayToll(state.confirmDontPayTollValue);
                setState((prevState) => ({
                  ...prevState,
                  confirmDontPayToll: false,
                }));
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  confirmDontPayToll: false,
                }));
              }}
              isConfirmDisabled={state.isLoading}
              positiveActionLabel="OK"
              negativeActionLabel={<>Cancel</>}
            />
          )}
          {state.confirmDontBillExtraMiles && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
              }}
              open={!!state.confirmDontBillExtraMiles}
              contentText=" By Checking, bill extra miles will become 0. This action will not be reverted once done."
              onConfirm={() => {
                calculateBillMiles(state.confirmDontBillExtraMilesValue);
                setState((prevState) => ({
                  ...prevState,
                  confirmDontBillExtraMiles: false,
                }));
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  confirmDontBillExtraMiles: false,
                }));
              }}
              isConfirmDisabled={state.isLoading}
              positiveActionLabel="OK"
              negativeActionLabel={<>Cancel</>}
            />
          )}
          {state.confirmDontPayExtraMiles && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
              }}
              open={!!state.confirmDontPayExtraMiles}
              contentText="By Checking, pay extra miles will become 0. This action will not be reverted once done."
              onConfirm={() => {
                calculatePayMiles(state.confirmDontPayExtraMilesValue);
                setState((prevState) => ({
                  ...prevState,
                  confirmDontPayExtraMiles: false,
                }));
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  confirmDontPayExtraMiles: false,
                }));
              }}
              isConfirmDisabled={state.isLoading}
              positiveActionLabel="OK"
              negativeActionLabel={<>Cancel</>}
            />
          )}
        </Dialog.Content>

        <Dialog.Actions>
          <div className="d-flex f-align-center f-justify-end p-4">
            <Button className="ml-2 mr-2" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="ml-2 mr-2"
              variant="contained"
              color="primary"
              disabled={
                (!state.misc.isChangeNotes && state.isLoading) ||
                (!!selectedRoute &&
                  selectedRoute[0]?.price_type === "Matrix" &&
                  !state.contractors?.pay_rate) ||
                (state.customers.route?.price_type === "Matrix" &&
                  !state.customers?.bill_rate) ||
                !isFieldChange ||
                validate(null, null, "all")
              }
              onClick={handleSubmit}
            >
              {`${isNew ? "Create" : "Update"}`}
              {!state.misc.isChangeNotes && state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
      {state.isAaMgrNotesAdded && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paperWidthSm: classes.paperWidthSm,
          }}
          open={!!state.isAaMgrNotesAdded}
          contentText={
            <>
              <Typography variant="body1" className="mt-2">
                <strong>
                  {" "}
                  Please add the AA/MGR notes for the schedule record.
                </strong>
              </Typography>
              <br></br>
              <Grid
                columns={reviewColumnConfig}
                rows={
                  !isNew
                    ? [
                        {
                          ...entry,
                          reviewReason: state.isAaMgrNotesPresent.reviewReason,
                        },
                      ]?.map((row) => {
                        const { className, ...rest } = row;
                        return rest;
                      })
                    : [
                        {
                          ...entry,
                          reviewReason: state.isAaMgrNotesPresent.reviewReason,
                          change_notes: state.misc?.change_notes,
                          temp_aaMgr_notes: temp_aaMgr_notes,
                          pdxCompany:
                            state.customers?.location?.pdxCompany.value,
                          route: state.customers?.route?.name,
                          customerName: state.customers?.customer?.name,
                          location: state.customers?.state?.name,
                          individual_contractor:
                            state.contractors?.individual_contractor,
                        },
                      ]
                }
                actionBarConfig={null}
                isLoading={state.isFetching}
                hasSelection={false}
                hasPagination={false}
                onReady={(instance) => (gridHelper = instance)}
              />
            </>
          }
          onConfirm={async () => {
            await handleSubmit(
              (state.misc.isChangeNotes = true),
              !isNew
                ? entry.temp_aaMgr_notes === undefined ||
                  entry.temp_aaMgr_notes === null
                  ? state.misc.change_notes
                  : entry.temp_aaMgr_notes
                : temp_aaMgr_notes === undefined ||
                  temp_aaMgr_notes === null ||
                  temp_aaMgr_notes === ""
                ? state.misc.change_notes
                : temp_aaMgr_notes
            );
            handleClose(true, true);
            setState((prevState) => ({
              ...prevState,
              isAaMgrNotesAdded: false,
            }));
            gridHelper.resetSelection();
          }}
          onCancel={() => {
            if (!isNew) {
              entry.temp_aaMgr_notes = null;
            } else {
              temp_aaMgr_notes = null;
            }
            setState((prevState) => ({
              ...prevState,
              isAaMgrNotesAdded: false,
              errors: {
                aaMgrNotes: "",
              },
            }));
          }}
          isConfirmDisabled={
            !isNew
              ? state.isLoading ||
                !(
                  entry.temp_aaMgr_notes?.trim() ||
                  state.misc.change_notes?.trim()
                ) ||
                state.errors.aaMgrNotes?.trim()
              : state.isLoading ||
                !(
                  temp_aaMgr_notes?.trim() || state.misc.change_notes?.trim()
                ) ||
                state.errors.aaMgrNotes?.trim()
          }
          positiveActionLabel={
            <>
              SUBMIT
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
    </>
  );
};

export default Form;
