import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
    width: "40%",
  },
  summaryPaperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
    width: "100%",
  },
  sidebar: {
    height: "100vh",
    borderRight: "1px solid #ddd",
    padding: theme.spacing(2),
  },
  paper: {
    minWidth: "600px !important",
    maxWidth: "1200px !important",
    minHeight: "600px !important",
    maxHeight: "1200px !important",
  },
  tabletView: {
    flexWrap: "wrap",
  },
  tableWidth: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "0px",
  },
  tunePaper: {
    minWidth: 156,
  },
  root: {
    display: "flex",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(2),
  },
  labelContent: {
    "& p": {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  iconButton: {
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  dropdownIcon: {
    fontSize: "xx-large  !important",
    color: "#285ee9  !important",
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  statusListItemText: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(2),
    alignItems: "flex-end",
  },
  amount: {
    fontWeight: "bold",
  },
  status: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    borderRadius: "4px",
    fontSize: "0.75rem",
  },
  draft: {
    backgroundColor: "#f0f0f0",
  },
  partiallyPaid: {
    backgroundColor: "#d1f2d1",
  },
  approved: {
    backgroundColor: "#cce5ff",
  },
  open: {
    backgroundColor: "#fff3cd",
  },
  paid: {
    backgroundColor: "#d4edda",
  },
  overdue: {
    backgroundColor: "#f8d7da",
  },
  latePayemnt: {
    backgroundColor: "#f5acb3",
  },
  list: {
    overflowY: "auto",
    height: "calc(100vh - 316px)",
    "&::-webkit-scrollbar": {
      width: "5px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
      borderRadius: "60px",
    },
  },
  invoice: {
    // height: "calc(100vh - 405px)",
    width: "100%",
  },
  paperSize: {
    width: 600,
    "& .MuiDialogContent-root": {
      paddingTop: "0px",
    },
    minHeight: 120,
    maxHeight: 500,
    overflowY: "auto",
  },
  buttonStyle: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "16px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  emailChip: {
    marginRight: "4px",
    marginBottom: "4px",
    marginTop: "6px",
  },
  messageStyles: {
    textAlign: "center",
    color: "#fff",
  },
  overlayStyles: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1301,
  },
  iframe: {
    border: "none",
    width: "100%",
  },
  noRecordsImg: {
    maxWidth: 500,
    maxHeight: 400,
  },
  listItemSelected: {
    backgroundColor: "#e3d5e6",
  },
  sentIcon: {
    border: "1px solid #004700",
    borderRadius: "4px",
    padding: " 4.2px",
    marginLeft: "12px",
  },
}));

export default useStyles;
