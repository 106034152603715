/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import useStyles from "./style";

import { AppContext, Datepicker, Dialog, Dropdown, Grid, noop } from "shared";
import { getDateString } from "utils";
import { toast } from "react-toastify";
import Service from "modules/invoice/service";
import clsx from "clsx";
import TuneIcon from "@material-ui/icons/Tune";
import { SharedService } from "modules/shared";
import WarningIcon from "@material-ui/icons/Warning";
import FlagIcon from "@material-ui/icons/Flag";
import { Alert } from "@material-ui/lab";

let gridHelper = null;

const defaultState = {
  isLoading: false,
  isFetching: false,
  entries: [],
  totalEntries: 0,
  unApprovedEntries: 0,
  selectedRows: [],
  date: null,
  stateList: [],
  cautionFlagSubmitReasonList: [],
  isFetchingList: {
    users: false,
    deductionReasonList: false,
    deductionReason: false,
  },
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    status: {
      label: "Status",
      value: "status",
      isSelected: true,
    },

    schedule_date: {
      label: "Schedule Date",
      value: "schedule_date",
      isSelected: true,
    },
    customer_name: {
      label: "Customer Name",
      value: "customer_name",
      isSelected: true,
    },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    state: { label: "State", value: "state", isSelected: true },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    vehicle: { label: "Vehicle", value: "vehicle", isSelected: true },
    icid: { label: "ICID/IC Name", value: "icid", isSelected: true },
    settlement_company: {
      label: "Settlement Company",
      value: "settlement_company",
      isSelected: true,
    },
    dont_bill: {
      label: "Do Not Bill",
      value: "dont_bill",
      isSelected: true,
    },
    dont_pay: {
      label: "Do Not Pay",
      value: "dont_pay",
      isSelected: true,
    },
    dont_bill_miles: {
      label: "Do Not Bill Extra Miles",
      value: "dont_bill_miles",
      isSelected: true,
    },
    dont_pay_miles: {
      label: "Do Not Pay Extra Miles",
      value: "dont_pay_miles",
      isSelected: true,
    },
    dont_bill_tolls: {
      label: "Do Not Bill Toll",
      value: "dont_bill_tolls",
      isSelected: true,
    },
    dont_pay_tolls: {
      label: "Do Not Pay Toll",
      value: "dont_pay_tolls",
      isSelected: true,
    },
    bill_rate_type: {
      label: "Billing Rate Type",
      value: "bill_rate_type",
      isSelected: true,
    },
    bill_rate: { label: "Billing Rate", value: "bill_rate", isSelected: true },
    bill_quantity: {
      label: "Billing Quantity",
      value: "bill_quantity",
      isSelected: true,
    },

    bill_amount: {
      label: "Billing Amount",
      value: "bill_amount",
      isSelected: true,
    },
    bill_fuel_surcharge: {
      label: "Billing Fuel Surcharge%",
      value: "bill_fuel_surcharge",
      isSelected: true,
    },
    bill_fuel_amount: {
      label: "Billing Fuel Amount",
      value: "bill_fuel_amount",
      isSelected: true,
    },
    bill_toll_amount: {
      label: "Billing Toll Amount",
      value: "bill_toll_amount",
      isSelected: true,
    },
    bill_extra_miles: {
      label: "Billing Extra Miles",
      value: "bill_extra_miles",
      isSelected: true,
    },
    bill_rate_per_extra_mile: {
      label: "Billing Rate Per Extra Mile",
      value: "bill_rate_per_extra_mile",
      isSelected: true,
    },
    bill_extra_mile_amount: {
      label: "Billing Extra Miles Amount",
      value: "bill_extra_mile_amount",
      isSelected: true,
    },
    pay_rate_type: {
      label: "Pay Rate Type",
      value: "pay_rate_type",
      isSelected: true,
    },
    pay_rate: { label: "Pay Rate", value: "pay_rate", isSelected: true },
    pay_quantity: {
      label: "Pay Quantity",
      value: "pay_quantity",
      isSelected: true,
    },
    pay_amount: { label: "Pay Amount", value: "pay_amount", isSelected: true },
    pay_fuel_surcharge: {
      label: "Pay Fuel Surcharge%",
      value: "pay_fuel_surcharge",
      isSelected: true,
    },
    pay_fuel_amount: {
      label: "Pay Fuel Amount",
      value: "pay_fuel_amount",
      isSelected: true,
    },
    pay_toll_amount: {
      label: "Pay Toll Amount",
      value: "pay_toll_amount",
      isSelected: true,
    },
    pay_extra_miles: {
      label: "Pay Extra Miles",
      value: "pay_extra_miles",
      isSelected: true,
    },
    pay_rate_per_extra_mile: {
      label: "Pay Rate Per Extra Mile",
      value: "pay_rate_per_extra_mile",
      isSelected: true,
    },
    pay_extra_mile_amount: {
      label: "Pay Extra Miles Amount",
      value: "pay_extra_mile_amount",
      isSelected: true,
    },

    reimbursement: {
      label: "Incentive",
      value: "reimbursement",
      isSelected: true,
    },
    deduction: {
      label: "Deduction",
      value: "deduction",
      isSelected: true,
    },
    deduction_reason: {
      label: "Deduction Reason",
      value: "deduction_reason",
      isSelected: true,
    },
    kam: {
      label: "KAM",
      value: "kam",
      isSelected: true,
    },
    office: {
      label: "Office",
      value: "office",
      isSelected: true,
    },
    manager: {
      label: "Manager",
      value: "manager",
      isSelected: true,
    },
    profit_percentage: {
      label: "Profit Percentage",
      value: "profit_percentage",
      isSelected: true,
    },
    change_notes: {
      label: "AA/MGR Notes",
      value: "change_notes",
      isSelected: true,
    },
    kam_notes: {
      label: "KAM Notes",
      value: "kam_notes",
      isSelected: true,
    },
    review_notes: {
      label: "Review Notes",
      value: "review_notes",
      isSelected: true,
    },
    review_reason: {
      label: "Review Reason",
      value: "review_reason",
      isSelected: true,
    },
  },
};

const AddBillingAndSettlemntInvoiceItemDialog = ({
  open = false,
  handleClose = noop,

  invoiceId,
  handleGetInvoice,
}) => {
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);

  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
        aa: [],
        mgr: [],
        admin: [],
      }
    );

    return {
      kamUsers: users.kam,
      officeUsers: users.aa,
      managerUsers: users.mgr,
      adminUsers: users.admin,
    };
  }, [appData]);

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    state.cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const [state, setState] = useState({
    ...defaultState,
    date: getDateString(new Date()),
  });

  const fetchEntries = useCallback(async (date, id) => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    let queryString = `?date=${date}&invoiceId=${id}`;

    const { data, error } = await Service.getBillingAndSettlementLineItem(
      queryString
    );
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: defaultState.entries,
        totalEntries: defaultState.totalEntries,
        unApprovedEntries: defaultState.unApprovedCount,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      entries: data?.approvedBsRecords || defaultState.entries,
      totalEntries: data?.approvedBsRecords.length || defaultState.totalEntries,
      unApprovedEntries: data.unapprovedCount || defaultState.unApprovedCount,
    }));

    return data;
  }, []);

  const fetchCautionFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    const { data, error } = await SharedService.getCautionFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      cautionFlagSubmitReasonList: data?.rows || [],
    }));
  };

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => {
          const valueA = (a.name || "").toLowerCase();
          const valueB = (b.name || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }
      ),
    }));
  };

  const fetchList = async (listType, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isIcFetching: true,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    const { data, error } = await SharedService.getUsersList(...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isIcFetching: false,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[listType] || [];

    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isIcFetching: false,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const fetchDeductionReasonList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        deductionReasonList: true,
      },
    }));
    const { data, error } = await SharedService.getDeductionReasonList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          deductionReasonList: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const mappedDeductionReasonList = data?.rows.map((row) => {
      const concatName = `${row?.value} (${
        row?.description ?? "Misc2Deduction"
      })`;
      return {
        ...row,
        concatName,
      };
    });

    setState((prevState) => ({
      ...prevState,
      deductionReasonList:
        mappedDeductionReasonList.filter((reason) => reason.is_active) ||
        defaultState.deductionReasonList,
      isFetchingList: {
        ...prevState.isFetchingList,
        deductionReason: false,
      },
    }));
  };

  useEffect(() => {
    fetchCautionFlagTypeList();
    fetchLocalResources();
    fetchDeductionReasonList();
  }, []);

  useEffect(() => {
    fetchList("users");
  }, []);

  useEffect(() => {
    fetchEntries(state.date, invoiceId);
  }, [fetchEntries, state.date]);

  const onClose = () => {
    handleClose(false);
    setState(defaultState);
  };

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSubmit = async (selectedRows, id) => {
    if (!selectedRows) return;
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const payload = {
      bs_ids: selectedRows,
      invoice_id: id,
    };

    const { error } = await Service.postBillingAndSettlementLineItem(payload);

    setState((prevState) => ({ ...prevState, isLoading: false }));

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const successMessage = "Invoice Item added successfully.";
    toast.success(successMessage);
    onClose();
    handleGetInvoice();
  };

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.customer_name?.isSelected,
      id: "customer_name",
      label: "Customer Name",
      field: "customer_name",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.customer_branch?.customer?.name || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.customer?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.customer_branch?.location || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "route_name",
      label: "Route",
      field: "route_name",
      canSort: true,
      hasEllipses: true,
      render: (row) => {
        return (
          <Tooltip title={row?.routeDetails?.name ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.routeDetails?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.state?.isSelected,
      id: "state",
      label: "State",
      field: "state",
      canSort: true,
      render: (row) => {
        const stateName = state.stateList.find(
          (item) => item.abbreviation === row.customer_branch?.state
        )?.name;
        return (
          <Tooltip title={stateName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {stateName ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdx_company?.isSelected,
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.customer_branch?.pdxCompany?.value ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.pdxCompany?.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.vehicle?.isSelected,
      id: "vehicle",
      label: "Vehicle",
      field: "vehicle",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.vehicleType?.value || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.vehicleType?.value || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.icid?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "contractor_first_name",
      label: "ICID/IC Name",
      field: "contractor_first_name",
      canSort: true,
      render: (row) => {
        let title = !!row.individual_contractor?.icid
          ? `
          IC Settlement Company Name: ${
            row.individual_contractor?.settlementCompany?.value || "-"
          },

          ICID: ${row.individual_contractor?.icid || "-"},
          IC Name: ${row.individual_contractor?.last_name || ""} ${
              row.individual_contractor?.first_name || ""
            },
        Contractor Type:${
          row.individual_contractor?.contractorType?.value || ""
        }
         `
          : "";
        if (
          row.individual_contractor_changed &&
          row.original_individual_contractor_id
        ) {
          const orginalIC = appData.icList.find(
            (contractor) =>
              contractor?.id === row.original_individual_contractor_id
          );
          if (orginalIC) {
            title += `, Original IC Name: ${orginalIC.last_name || ""} ${
              orginalIC.first_name || ""
            }`;
          }
        }
        const label = `${row.individual_contractor?.icid || ""}/${
          row.individual_contractor?.last_name || ""
        } ${row.individual_contractor?.first_name || ""}`;

        const flagLabel =
          (!!row.individual_contractor?.is_flagged &&
            !!row.individual_contractor?.is_cautious_flagged) ||
          !!row.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.individual_contractor?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );

        const cautionFlaggedBy = userInfo(
          row?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row?.individual_contractor?.ctn_flag_rsn
        );
        const flagTitles = `Flagged Reason: ${
          row.individual_contractor?.flagReason?.value ||
          cautionFlaggedReason?.value
        }, Flagged By: ${
          row.individual_contractor?.flaggedBy?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          row.individual_contractor?.flaggedBy?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          row.individual_contractor?.flaggedBy?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          row.individual_contractor?.flaggedBy?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          row.individual_contractor?.flagged_at ||
            row?.individual_contractor?.ctn_flagged_at
        )}`;

        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title ?? ""} placement="top-start">
                  <Typography
                    variant="body2"
                    noWrap
                    onClick={!!row.individual_contractor?.icid}
                  >
                    {label || "-"}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.settlement_company?.isSelected,
      id: "settlement_co_name",
      label: "Settlement Company",
      field: "settlement_co_name",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.settlementCompany?.value || ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.settlementCompany?.value || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_bill",
      label: "Do Not Bill",
      field: "dont_bill",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill ? (
                row.dont_bill_notes === null || row.dont_bill_notes === "" ? (
                  "This record will not be included in billing"
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_pay",
      label: "Do Not Pay",
      field: "dont_pay",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay ? (
                row.dont_pay_notes === null || row.dont_pay_notes === "" ? (
                  "This record will not be included in settlement"
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_miles?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_bill_miles",
      label: "Do Not Bill Extra Miles",
      field: "dont_bill_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill_miles ? (
                row?.dont_bill_miles_notes === null ||
                row?.dont_bill_miles_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill_miles && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill_miles}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_miles?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_pay_miles",
      label: "Do Not Pay Extra Miles",
      field: "dont_pay_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay_miles ? (
                row?.dont_pay_miles_notes === null ||
                row?.dont_pay_miles_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay_miles && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay_miles}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_tolls?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_bill_tolls",
      label: "Do Not Bill Toll",
      field: "dont_bill_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill_tolls ? (
                row?.dont_bill_tolls_notes === null ||
                row?.dont_bill_tolls_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill_tolls && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill_tolls}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_tolls?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_pay_tolls",
      label: "Do Not Pay Toll",
      field: "dont_pay_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay_tolls ? (
                row?.dont_pay_tolls_notes === null ||
                row?.dont_pay_tolls_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay_tolls && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay_tolls}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.bill_rate_type?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "bill_rate_type",
      label: "Billing Rate Type",
      field: "bill_rate_type",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_rate?.isSelected,
      id: "bill_rate",
      label: "Billing Rate",
      field: "bill_rate",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_quantity?.isSelected,
      id: "bill_quantity",
      label: "Billing Quantity",
      field: "bill_quantity",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_amount?.isSelected,
      id: "bill_amount",
      label: "Billing Amount",
      field: "bill_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_fuel_surcharge?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "bill_fuel_surcharge",
      label: "Billing Fuel Surcharge%",
      field: "bill_fuel_surcharge",
      endAdornment: "%",
      canSort: true,
      render: (row) => {
        const label = row.bill_fuel_surcharge
          ? `${row.bill_fuel_surcharge || ""}%`
          : "-";

        return (
          <Tooltip title={label} placement="top-start">
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.bill_fuel_amount?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "bill_fuel_amount",
      label: "Billing Fuel Amount",
      field: "bill_fuel_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.bill_fuel_amount} placement="top-start">
          <Typography variant="body2">
            {`$${(+row.bill_fuel_amount).toFixed(2)}`}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.bill_toll_amount?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "bill_toll_amount",
      label: "Billing Toll Amount",
      field: "bill_toll_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },

    {
      isHidden: !state.dynamicColumns?.bill_extra_miles?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "bill_extra_miles",
      label: "Billing Extra Miles",
      field: "bill_extra_miles",
      hasEllipses: true,
      canSort: true,
    },

    {
      isHidden: !state.dynamicColumns?.bill_rate_per_extra_mile?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "bill_rate_per_extra_mile",
      label: "Billing Rate Per Extra Mile",
      field: "bill_rate_per_extra_mile",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_extra_mile_amount?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "bill_extra_mile_amount",
      label: "Billing Extra Miles Amount",
      field: "bill_extra_mile_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_type?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "pay_rate_type",
      label: "Pay Rate Type",
      field: "pay_rate_type",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate?.isSelected,
      id: "pay_rate",
      label: "Pay Rate",
      field: "pay_rate",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_quantity?.isSelected,
      id: "pay_quantity",
      label: "Pay Quantity",
      field: "pay_quantity",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_amount?.isSelected,
      id: "pay_amount",
      label: "Pay Amount",
      field: "pay_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_fuel_surcharge?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "pay_fuel_surcharge",
      label: "Pay Fuel Surcharge%",
      field: "pay_fuel_surcharge",
      endAdornment: "%",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const label = row.pay_fuel_surcharge
          ? `${row.pay_fuel_surcharge || ""}%`
          : "-";

        return (
          <Tooltip title={label} placement="top-start">
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_fuel_amount?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "pay_fuel_amount",
      label: "Pay Fuel Amount",
      field: "pay_fuel_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.pay_fuel_amount} placement="top-start">
          <Typography variant="body2">
            {`$${(+row.pay_fuel_amount).toFixed(2)}`}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_toll_amount?.isSelected,
      id: "pay_toll_amount",
      label: "Pay Toll Amount",
      field: "pay_toll_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_miles?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "pay_extra_miles",
      label: "Pay Extra Miles",
      field: "pay_extra_miles",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_per_extra_mile?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "pay_rate_per_extra_mile",
      label: "Pay Rate Per Extra Mile",
      field: "pay_rate_per_extra_mile",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_mile_amount?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "pay_extra_mile_amount",
      label: "Pay Extra Miles Amount",
      field: "pay_extra_mile_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.reimbursement?.isSelected,
      id: "reimbursement",
      label: "Incentive",
      field: "reimbursement",
      hasEllipses: true,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.reimbursement || 0} placement="top-start">
          <Typography variant="body2">{`$${
            row.reimbursement || 0
          }`}</Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.deduction?.isSelected,
      id: "deduction",
      label: "Deduction",
      field: "deduction",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.deduction_reason?.isSelected,
      id: "deduction_reason",
      label: "Deduction Reason",
      field: "deduction_reason",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const deductionReason =
          state.deductionReasonList?.filter(
            (reason) => reason.id == row.deduction_reason
          ) || [];
        return (
          <Tooltip
            title={deductionReason[0]?.concatName || ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {deductionReason[0]?.concatName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.kam?.isSelected,
      id: "kam_first_name",
      label: "KAM",
      field: "kam_first_name",
      canSort: true,
      render: (row) => {
        const kam = appDataList.kamUsers?.find((kam) => kam?.id == row?.kam_id);

        const tooltip = `${kam?.first_name || ""} ${kam?.last_name || ""}-( ${
          kam?.username || ""
        })`;
        const title = `${kam?.first_name || ""} ${kam?.last_name || ""}`;
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.office?.isSelected,
      id: "aa_first_name",
      label: "Office",
      field: "aa_first_name",
      canSort: true,
      render: (row) => {
        const aaUser = appDataList.officeUsers?.filter(
          (aa) => aa.id == row?.aa_id
        )[0];
        const tooltip = `${aaUser?.first_name || ""} ${
          aaUser?.last_name || ""
        }-(${aaUser?.username || ""})`;
        const title = `${aaUser?.first_name || ""} ${aaUser?.last_name || ""}`;
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.manager?.isSelected,
      id: "mgr_first_name",
      label: "Manager",
      field: "mgr_first_name",
      canSort: true,
      render: (row) => {
        const mgrUser = appDataList.managerUsers?.find(
          (mgr) => mgr?.id == row?.mgr_id
        );
        const tooltip = `${mgrUser?.first_name || ""} ${
          mgrUser?.last_name || ""
        }-(${mgrUser?.username || ""})`;
        const title = `${mgrUser?.first_name || ""} ${
          mgrUser?.last_name || ""
        }`;
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.profit_percentage?.isSelected,
      id: "profit_percentage",
      label: "Profit Percentage",
      field: "profit_percentage",
      endAdornment: "%",
      hasEllipses: true,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.profit_percentage} placement="top-start">
          <Typography
            variant="body2"
            noWrap
            className={clsx({ "color-error": row.profit_percentage < 0 })}
          >
            {(+row.profit_percentage || 0).toFixed(2)}%
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.change_notes?.isSelected,
      id: "change_notes",
      label: "AA/MGR Notes",
      field: "change_notes",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.change_notes ?? ""} placement="top-start">
            <Typography variant="body2" noWrap style={{ whiteSpace: "pre" }}>
              {row.change_notes ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.kam_notes?.isSelected,
      id: "kam_notes",
      label: "KAM Notes",
      field: "kam_notes",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.kam_notes ?? ""}
            placement="top-start"
            // classes={{ tooltip: classes.customSpace }}
          >
            <Typography variant="body2" noWrap style={{ whiteSpace: "pre" }}>
              {row.kam_notes || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.review_notes?.isSelected,
      id: "review_notes",
      label: "Review Notes",
      field: "review_notes",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const splitNotes = row.review_notes?.split("||");

        const finalNotes = splitNotes?.map((note) => {
          let str = note;
          if (!str) {
            return "";
          }
          //const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const startIndexOfDate = str?.indexOf("-");
          const lastIndexOfDate = str?.indexOf(": ");

          const formattedDate = new Date(
            str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
          )?.toLocaleString();

          const str1 =
            str.substring(0, startIndexOfDate) +
            "-" +
            formattedDate +
            str.substring(lastIndexOfDate);
          return str1;
        });

        const title = (
          <div
            dangerouslySetInnerHTML={{
              __html: (finalNotes || []).join("<br>"),
            }}
          />
        );

        return (
          <Tooltip
            title={title || "-"}
            placement="top-start"
            // classes={{ tooltip: classes.customSpace }}
          >
            <Typography variant="body2" noWrap style={{ whiteSpace: "pre" }}>
              {finalNotes}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.review_reason?.isSelected,
      id: "review_reason",
      label: "Review Reason",
      field: "review_reason",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.review_reason ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.review_reason || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={onClose}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose>
          Add LineItems from Billing & Settlemnt
        </Dialog.Title>
        <Dialog.Content>
          <Typography variant="body2" color="error" className="pb-2">
            The following filters (Pdx Company, Customer, Location, Routes) are
            pre-applied from the draft screen and will remain active. No date
            filter is active from draft screen.
          </Typography>

          <div className="d-flex mt-4 mb-4 f-align-center">
            <Datepicker
              mask
              preventClear
              selected={state.date}
              label="Date"
              classes={{
                input: {
                  root: clsx("mr-4", classes.datepickerWrapper),
                },
              }}
              maxDate={new Date()}
              onChange={(date) => {
                setState((prevState) => ({
                  ...prevState,
                  selectedRows: [],
                  date: getDateString(date),
                }));

                if (gridHelper) {
                  gridHelper.resetSelection();
                  gridHelper.resetAllSelection();
                }
              }}
            />
            {state.unApprovedEntries > 0 && (
              <div className="ml-4">
                <Alert icon={false} severity="warning">
                  {`${state.unApprovedEntries} record(s) ${
                    state.unApprovedEntries > 1 ? "are" : "is"
                  } not approved on billing and settlement screen. ${
                    state.totalEntries > 0
                      ? "Below are the records that have been approved."
                      : "Please approve the records first."
                  }`}
                </Alert>
              </div>
            )}
          </div>
          <Paper>
            {state.entries.length > 0 && (
              <div className="d-flex f-justify-end">
                <Dropdown
                  classes={{
                    paper: classes.tunePaper,
                  }}
                  disablePortal={false}
                  remainOpen
                  isMultiSelect
                  placement="bottom-end"
                  options={Object.keys(state.dynamicColumns).map(
                    (key) => state.dynamicColumns[key]
                  )}
                  customToggle={({ anchorRef, onClick }) => (
                    <Tooltip title="Show/Hide column(s)" placement="top-start">
                      <IconButton
                        color="primary"
                        ref={anchorRef}
                        onClick={onClick}
                      >
                        <TuneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  onChange={(options) => {
                    const isSelectAll = options.some(
                      (item) => item.value === "select_all"
                    );
                    const isCurrentSelectAll =
                      state.dynamicColumns?.select_all?.isSelected;

                    const isClickedOnSelectAll =
                      isSelectAll !== isCurrentSelectAll;

                    const isAllOptionSelected =
                      Object.keys(state.dynamicColumns).length - 1 ===
                      options.filter((item) => item.value !== "select_all")
                        .length;
                    const updatedDynamicColumns = Object.keys(
                      state.dynamicColumns
                    ).reduce((acc, key) => {
                      const isSelected = isClickedOnSelectAll
                        ? isSelectAll
                        : key === "select_all"
                        ? isAllOptionSelected
                        : !!options.some((item) => item.value === key);
                      return {
                        ...acc,
                        [key]: {
                          ...state.dynamicColumns[key],
                          isSelected,
                        },
                      };
                    }, {});

                    setState((prevState) => ({
                      ...prevState,
                      dynamicColumns: {
                        ...prevState.dynamicColumns,
                        ...updatedDynamicColumns,
                      },
                    }));
                  }}
                />
              </div>
            )}
            <Grid
              columns={columnConfig}
              rows={state.entries}
              actionBarConfig={null}
              isLoading={state.isFetching}
              hasSelection
              onReady={(instance) => (gridHelper = instance)}
              totalRows={state.totalEntries}
              onSelectionChange={handleSelectionChange}
              hasPagination={false}
            />
          </Paper>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              disabled={state.isLoading || !state.selectedRows.length}
              onClick={() => handleSubmit(state.selectedRows, invoiceId)}
            >
              Add
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default AddBillingAndSettlemntInvoiceItemDialog;
