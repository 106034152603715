import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { noop } from "shared/constants";
import { validator, preventInputKeyCodes } from "utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { Dialog } from "shared/components";
import useStyles from "./style";
import { Typography } from "@material-ui/core";

let isFormUpdate = false;
const defaultState = {
  errors: {},
};

const DynamicForm = ({
  open = false,
  configs = [],
  title = "New Entry",
  subHeading = "",
  positiveLabel = "Submit",
  fieldErrors = {},
  negativeLabel = "Cancel",
  paperMinHeight = 300,
  paperminWidth = 500,
  isLoading = false,
  updateValidations = noop,
  onSubmit = noop,
  onClose = noop,
  onChange = noop,
}) => {
  const classes = useStyles({
    paperMinHeight,
    paperminWidth,
  });

  const fieldValidatorMap = useMemo(() => {
    return configs
      .filter((config) => !!config.validations)
      .reduce(
        (acc, config) => ({
          ...acc,
          [config.name]: config.validations,
        }),
        {}
      );
  }, [configs]);

  const [state, setState] = useState(defaultState);

  const validate = (field, value) => {
    let errorMessage = "";
    //TODO: optimize by using onchange prop
    const updatedFieldValidatorMap = updateValidations(state, {
      ...fieldValidatorMap,
    });

    if (updatedFieldValidatorMap[field]) {
      const validationError = updatedFieldValidatorMap[field].map(
        (validation) =>
          validator(
            validation.type,
            validation.value,
            value,
            validation.inputType || "string"
          )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(updatedFieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }
    return errorMessage;
  };

  const handleChange = (evt) => {
    isFormUpdate = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    const errorMessage = validate(field, value) || " ";

    if (type === "number") {
      if (value.indexOf(".") !== -1) {
        let valueArr = value.toString().split(".");
        if (valueArr[1]?.length > 2) {
          value = (Math.round((+value + Number.EPSILON) * 100) / 100).toFixed(
            2
          );
        }
      } else if (value?.length > 1 && value.indexOf(".") === -1) {
        value = value?.replace(/^0+/, "");
      }
    }

    setState((prevState) => {
      let updatedState = {
        ...prevState,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      };
      onChange(field, value, updatedState, (changedState) => {
        updatedState = changedState;
      });
      return updatedState;
    });
  };

  const handleSubmit = () => {
    let { errors, ...payload } = state;
    configs.map((item) => {
      if (item.inputType === "number") {
        payload[item.name] = Number.isInteger(+payload[item.name])
          ? +payload[item.name]
          : (+payload[item.name]).toFixed(2);
      }
    });

    onSubmit(payload, errors);
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setState((prevState) => {
      const configValues = configs.reduce(
        (acc, config) => ({
          ...acc,
          [config.name]: config.value || "",
        }),
        {}
      );
      return {
        ...prevState,
        ...configValues,
      };
    });
  }, [configs]);

  useEffect(() => {
    if (open) {
      let errors = {};

      Object.keys(fieldErrors).forEach((key) => {
        const errorMessage = fieldErrors[key];
        if (errorMessage) {
          errors[key] = errorMessage;
        }
      });

      configs.forEach((config) => {
        if (!errors[config.name]) {
          const fieldValue = state[config.name];
          const errorMessage = validate(config.name, fieldValue);
          if (errorMessage) {
            errors[config.name] = errorMessage;
          }
        }
      });

      setState((prevState) => {
        if (JSON.stringify(prevState.errors) !== JSON.stringify(errors)) {
          return {
            ...prevState,
            errors,
          };
        }
        return prevState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Dialog.Title hasClose>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2" className="mt-2">
          {subHeading}
        </Typography>
      </Dialog.Title>
      <Dialog.Content>
        {configs.map((config) => {
          return config.type === "textField" ? (
            <TextField
              fullWidth
              type={config.inputType || "string"}
              className={clsx("mb-2", config.className)}
              required={config.required}
              name={config.name}
              label={config.label}
              variant={config.variant || "outlined"}
              size={config.size || "small"}
              disabled={config.disabled}
              value={state[config.name]}
              onWheel={(event) => event.target.blur()}
              onKeyDown={(evt) => {
                if (
                  config.name === "pay_quantity" ||
                  config.name === "bill_quantity"
                ) {
                  console.log("if");
                  preventInputKeyCodes(evt, {});
                } else {
                  console.log("else");
                  preventInputKeyCodes(evt, { HYPHEN: true });
                }
              }}
              error={
                !!state.errors[config.name] && state.errors[config.name].trim()
              }
              helperText={state.errors[config.name] || " "}
              onChange={handleChange}
              InputProps={config.inputProps || {}}
            />
          ) : config.type === "autoComplete" ? (
            <Autocomplete
              disableClearable={config.disableClearable}
              className={clsx("mb-2", config.className)}
              size={config.size || "small"}
              name={config.name}
              value={state[config.name]}
              options={config.options || []}
              getOptionLabel={
                config.getOptionLabel ||
                ((option) => option.label || option || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={config.required}
                  label={config.label}
                  variant={config.variant || "outlined"}
                  error={
                    !!state.errors[config.name] &&
                    state.errors[config.name].trim()
                  }
                  helperText={state.errors[config.name] || " "}
                />
              )}
              onChange={(evt, value) => {
                isFormUpdate = true;
                setState((prevState) => {
                  let updatedState = {
                    ...prevState,
                    [config.name]: value,
                  };
                  onChange(config.name, value, updatedState, (changedState) => {
                    updatedState = changedState;
                  });
                  return updatedState;
                });
              }}
            />
          ) : null;
        })}
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="ml-2 mr-2"
          >
            {negativeLabel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={isLoading || validate()}
            onClick={handleSubmit}
          >
            {positiveLabel}
            {isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default DynamicForm;
