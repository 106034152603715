import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  highlightDisable: {
    background: theme.palette.grey[200],
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  paperSize: {
    width: 416,
  },
  colEdit: {
    width: "98px",
  },
  emptyCell: {
    width: "35px",
  },
  deleteAddress: {
    width: 370,
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  locationInputRoot: {
    // maxHeight: 190,
    overflow: "auto !important",
    maxWidth: 684,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 244,
    },
  },
  locationInput: {
    minWidth: 198,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
    },
  },
  addLocation: {
    width: 575,
  },
  gridPaper: { minHeight: 120, maxHeight: 240, overflow: "hidden" },
  paper: {
    minWidth: 720,
    maxWidth: 600,
    minHeight: 120,
    maxHeight: 404,
  },
  noRecordsImg: {
    maxHeight: 187,
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "7px !important",
    paddingBottom: "0px !important ",
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  fuelSurchargeHeader: {
    minWidth: "164px",
  },
  routeColumns: {
    minWidth: "92px",
    maxWidth: "92px",
  },
  editColumns: {
    minWidth: "180px",
    maxWidth: "180px",
  },
  routerateTypesColumns: {
    minWidth: "148px",
  },
  invoiceTypeHeader: {
    minWidth: "116px",
  },
  fuelTypeHeader: {
    minWidth: "148px",
  },
  fsReductionHeader: {
    minWidth: "164px",
  },
  extraMilesheader: {
    minWidth: "188px",
  },
  editAddress: { width: "20px", paddingRight: "0px !important" },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  dialogPaper: {
    "& .MuiDialogActions-root": {
      paddingBottom: "26px",
    },
  },
  isTotal: {
    backgroundColor: "#eaf6fb",
  },
}));

export default useStyles;
