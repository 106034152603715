import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    width: "16.2rem",

    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiOutlinedInput-root": {
      width: "16.2rem",
      marginBottom: "24px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  customerDropdown: {
    width: 160,
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
  },
  paper: {
    "& .MuiDialogActions-root": {
      paddingBottom: "26px",
    },
    width: 800,
  },
  VisibilityIconColor: { color: "#004700" },
  tableHeaderwidth: {
    minWidth: 144,
    maxWidth: 144,
  },
}));

export default useStyles;
