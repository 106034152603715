import { useEffect, useState, useMemo } from "react";
import { noop } from "shared/constants";
import {
  INVOICE_TYPES,
  RATE_TYPES,
  VEHICLE_TYPES,
} from "modules/shared/constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { preventInputKeyCodes } from "utils";
import useStyles from "../style";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import { Checkbox, Tooltip, Typography } from "@material-ui/core";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

let shouldShowAlert = {
  bill_rate: false,
  bill_quantity: false,
};

const CustomersPanel = ({
  entry = {},
  customerList = [],
  isTabletView = false,
  isPriceMatrixFetched = false,
  isCalculateBill = false,
  isCalculateBillToll = false,
  isCalculateBillMiles = false,
  isNew = false,
  // isBFSChecked = true,
  // handleBFSChecked = noop,
  calculateFuelSurcharge = noop,
  handleBillRateChange = noop,
  billingOptions = noop,
  billPayPrice = noop,
  defaultCustomerState = {},
  defaultContractorState = {},
  vehicleTypeList = [],
  pay_rate_type = null,
  isStandBy = false,
  standByRate = {},
  handleConfirmDontBill = noop,
  handleConfirmDontBillToll = noop,
  handleConfirmDontBillExtraMiles = noop,
  calculateBill = noop,
  calculateBillToll = noop,
  calculateBillMiles = noop,
  onFieldChange = noop,
  onCustomerChange = noop,
  handleFieldBlur = noop,
  showFieldAlert = noop,
  calculateValues = noop,
}) => {
  const classes = useStyles({ isTabletView });
  const handleFieldChange = (evt) => {
    const { name, value, type } = evt.currentTarget;

    onFieldChange(name, value, "customers", type);
  };

  // const calculateValues = ({
  //   bill_rate = 0,
  //   bill_quantity = 0,
  //   bill_fuel_amount = 0,
  //   bill_fuel_surcharge = 0,
  // }) => {
  //   const totalAmount = +bill_rate * +bill_quantity;
  //   const fuelAmount = totalAmount * (bill_fuel_surcharge / 100);
  //   const fuelSurcharge = totalAmount
  //     ? (bill_fuel_amount / totalAmount) * 100
  //     : 0;
  //   return {
  //     bill_amount: totalAmount,
  //     bill_fuel_amount: fuelAmount,
  //     bill_fuel_surcharge: fuelSurcharge,
  //   };
  // };

  const branchList = useMemo(
    () =>
      (
        customerList.find((customer) => customer.id === entry.customer?.id)
          ?.customer_branches || []
      )
        .filter((branch) => branch.is_active && !branch.is_deleted)
        .sort((a, b) => {
          const valueA = (a.location || "").toLowerCase();
          const valueB = (b.location || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }),
    [entry.customer]
  );
  const routeList = useMemo(() => {
    return (
      branchList
        .find((location) => location.id === entry.location?.id)
        ?.routes?.filter((route) => route?.is_active) || []
    );
  }, [entry.location]);

  return (
    <div className="d-flex f-align-center f-wrap">
      <Autocomplete
        disableClearable
        size="small"
        className={classes.field}
        value={entry.customer}
        options={customerList}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Name"
            variant="outlined"
            error={!!entry.errors.customer.trim()}
            helperText={entry.errors.customer}
          />
        )}
        onChange={(evt, value) => {
          onFieldChange("customer", value, "customers");
          onCustomerChange();
        }}
        classes={{
          root: classes.autocompletePaper,
          listbox: classes.autocompletePaper,
        }}
      />
      <Autocomplete
        size="small"
        disableClearable
        className={classes.field}
        value={entry.location}
        options={branchList}
        getOptionLabel={(option) =>
          `${option.location} (${option.state?.name || ""} ,${
            option?.pdxCompany?.value || ""
          })`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Location"
            variant="outlined"
            error={!!entry.errors.location.trim()}
            helperText={entry.errors.location}
          />
        )}
        onChange={(evt, value) => {
          onFieldChange("location", value, "customers");
          onFieldChange("state", value?.state, "customers");
          onFieldChange("invoice_type", value?.invoice_type, "customers");
          onFieldChange("route", null, "customers");
          onFieldChange(
            "vehicle_type",
            defaultCustomerState.vehicle_type,
            "customers"
          );
          onFieldChange(
            "bill_rate_type",
            defaultCustomerState.bill_rate_type,
            "customers"
          );
          onFieldChange(
            "bill_rate",
            defaultCustomerState.bill_rate,
            "customers"
          );
          onFieldChange(
            "bill_quantity",
            defaultCustomerState.bill_quantity,
            "customers"
          );
          onFieldChange("office", value?.aaUser, "misc");
          onFieldChange("manager", value?.mgrUser, "misc");
          // onFieldChange(
          //   "pay_rate_type",
          //   defaultContractorState.pay_rate_type,
          //   "contractors"
          // );
          // onFieldChange(
          //   "pay_rate",
          //   defaultContractorState.pay_rate,
          //   "contractors"
          // );
          // onFieldChange(
          //   "pay_quantity",
          //   defaultContractorState.pay_quantity,
          //   "contractors"
          // );
        }}
      />
      <TextField
        disabled={true}
        className={classes.field}
        fullWidth
        label="PDX Company"
        variant="outlined"
        size="small"
        helperText=" "
        value={entry.location?.pdxCompany?.value || ""}
      />
      <TextField
        fullWidth
        className={classes.field}
        disabled={true}
        name="state"
        label="State"
        variant="outlined"
        size="small"
        helperText=" "
        value={entry.location?.state?.name || ""}
        onChange={handleFieldChange}
      />
      <Autocomplete
        className={classes.field}
        fullWidth
        size="small"
        value={entry.route}
        options={routeList}
        // getOptionLabel={(option) => option.name || ""}
        getOptionLabel={(option) =>
          `${option.name} (${
            option.is_weekday === true ? "Weekday" : "Weekend"
          }, ${
            option.price_type === null || option.price_type === "Standard"
              ? "Standard"
              : "Price Matrix"
          } )`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Route"
            required
            variant="outlined"
            helperText=" "
            // onBlur={(evt) => {
            //   const { value } = evt?.target;
            //   onFieldChange("route", { name: value }, "customers");
            // }}
          />
        )}
        onChange={async (evt, value) => {
          if (!!value?.id) {
            const data = await calculateFuelSurcharge(value.id);

            if (!!data.pay_fuel_surcharge || !!data.bill_fuel_surcharge) {
              onFieldChange(
                "bill_fuel_surcharge",
                (Number(data.bill_fuel_surcharge) || 0).toFixed(2),
                "customers"
              );

              onFieldChange(
                "pay_fuel_surcharge",
                (Number(data.pay_fuel_surcharge) || 0).toFixed(2),
                "contractors"
              );

              const billTotalAmount =
                +value.default_bill_rate * +value.default_bill_quantity;
              const billFuelAmount =
                billTotalAmount * (Number(data.bill_fuel_surcharge) / 100);

              const payTotalAmount =
                +value.default_pay_rate * +value.default_pay_quantity;
              const payFuelAmount =
                payTotalAmount * (Number(data.pay_fuel_surcharge) / 100);

              onFieldChange(
                "bill_fuel_amount",
                Number(billFuelAmount || 0).toFixed(2),
                "customers"
              );
              onFieldChange(
                "pay_fuel_amount",
                Number(payFuelAmount || 0).toFixed(2),
                "contractors"
              );
            } else {
              onFieldChange("bill_fuel_amount", 0, "customers");
              onFieldChange("pay_fuel_amount", 0, "contractors");
              onFieldChange("bill_fuel_surcharge", 0, "customers");

              onFieldChange("pay_fuel_surcharge", 0, "contractors");
            }
          }
          const defaultValue =
            pay_rate_type === "Hourly"
              ? "8"
              : pay_rate_type === "Daily"
              ? "1"
              : "";

          onFieldChange(
            "bill_toll_amount",
            value?.bill_toll_amount || defaultCustomerState.bill_toll_amount,
            "customers"
          );

          onFieldChange(
            "pay_toll_amount",
            value?.pay_toll_amount || "0",
            "contractors"
          );

          onFieldChange("route", value, "customers");
          onFieldChange(
            "vehicle_type",
            value?.vehicleType || defaultCustomerState.vehicle_type,
            "customers"
          );
          onFieldChange(
            "pay_quantity",
            value === null ? defaultValue : value?.default_pay_quantity,
            "contractors"
          );

          onFieldChange(
            "bill_rate_type",
            value?.price_type === "Matrix"
              ? RATE_TYPES[0]
              : value?.default_bill_rate_type ||
                  defaultCustomerState.bill_rate_type,
            "customers"
          );
          onFieldChange(
            "bill_rate",
            value?.price_type === "Matrix"
              ? value?.default_bill_rate || ""
              : value?.default_bill_rate || defaultCustomerState.bill_rate,
            "customers"
          );
          onFieldChange(
            "bill_quantity",
            value?.price_type === "Matrix"
              ? "1"
              : value?.default_bill_quantity ||
                  defaultCustomerState.bill_quantity,
            "customers"
          );
          onFieldChange(
            "pay_rate_type",
            value?.default_pay_rate_type ||
              defaultContractorState.pay_rate_type,
            "contractors"
          );
          onFieldChange(
            "bill_rate_per_extra_mile",
            value?.bill_rate_per_extra_mile ||
              defaultCustomerState.bill_rate_per_extra_mile,
            "customers"
          );
          onFieldChange(
            "pay_rate_per_extra_mile",
            value?.pay_rate_per_extra_mile ||
              defaultContractorState.pay_rate_per_extra_mile,
            "contractors"
          );
          // if (value === null && !isStandBy) {
          //   onFieldChange("pay_rate", "0", "contractors");
          // }
          // if (value !== null && !isStandBy) {
          //   onFieldChange("pay_rate", value?.default_pay_rate, "contractors");
          // }

          if (!isStandBy) {
            onFieldChange(
              "pay_rate",
              value?.default_pay_rate || defaultContractorState.pay_rate,
              "contractors"
            );
          }

          if (isNew) {
            onFieldChange(
              "bill_rate_type",
              value?.default_bill_rate_type ||
                defaultCustomerState.bill_rate_type,
              "customers"
            );
            onFieldChange(
              "bill_rate",
              value?.default_bill_rate || defaultCustomerState.bill_rate,
              "customers"
            );
            onFieldChange(
              "bill_quantity",
              value?.default_bill_quantity ||
                defaultCustomerState.bill_quantity,
              "customers"
            );

            onFieldChange(
              "pay_quantity",
              // value?.default_pay_quantity ||
              // defaultContractorState.pay_quantity,
              value === null ? defaultValue : value?.default_pay_quantity,
              "contractors"
            );
            if (!isStandBy) {
              onFieldChange(
                "pay_rate",
                value?.default_pay_rate || defaultContractorState.pay_rate,
                // value === null ? defaultValue : value?.default_pay_rate,
                "contractors"
              );
              onFieldChange(
                "pay_rate_type",
                value?.default_pay_rate_type ||
                  defaultContractorState.pay_rate_type,
                "contractors"
              );
              // onFieldChange(
              //   "pay_rate_type",
              //   value === null ? defaultValue : value?.default_pay_rate_type,
              //   "contractors"
              // );
            }
            if (isStandBy) {
              onFieldChange(
                "pay_rate",
                value?.price_type === null || value?.price_type === "Standard"
                  ? standByRate?.stand_by_rate
                  : value?.default_pay_rate,
                "contractors"
              );
              onFieldChange(
                "pay_rate_type",
                value?.price_type === null || value?.price_type === "Standard"
                  ? standByRate?.stand_by_rate_type
                  : value?.default_pay_rate_type,
                "contractors"
              );
            }
          }

          if (isStandBy) {
            onFieldChange(
              "pay_rate",
              value?.price_type === null || value?.price_type === "Standard"
                ? standByRate?.stand_by_rate
                : value?.default_pay_rate,
              "contractors"
            );
            onFieldChange(
              "pay_rate_type",
              value?.price_type === null || value?.price_type === "Standard"
                ? standByRate?.stand_by_rate_type
                : value?.default_pay_rate_type,
              "contractors"
            );
          }
        }}
      />
      <Autocomplete
        className={classes.field}
        size="small"
        value={entry.vehicle_type}
        getOptionLabel={(option) => option?.value}
        options={vehicleTypeList}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Vehicle"
            name="vehicle_type"
            error={entry.errors?.vehicle_type.trim()}
            helperText={entry.errors?.vehicle_type}
            variant="outlined"
            onChange={(evt) => {
              handleFieldChange(evt);
            }}
          />
        )}
        onChange={(evt, value) =>
          onFieldChange("vehicle_type", value, "customers")
        }
        classes={{
          root: classes.autocompletePaper,
          listbox: classes.autocompletePaper,
        }}
      />
      <Autocomplete
        className={classes.field}
        size="small"
        value={entry.invoice_type}
        options={INVOICE_TYPES}
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Invoice"
            helperText=" "
          />
        )}
        onChange={(evt, value) => {
          onFieldChange("invoice_type", value, "customers");
        }}
      />
      <div className="d-flex f-align-center f-wrap">
        <Autocomplete
          disableClearable
          className={classes.field}
          size="small"
          value={entry.bill_rate_type}
          disabled={entry.route?.price_type === "Matrix"}
          options={RATE_TYPES}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Billing Rate Type"
              variant="outlined"
              error={!!entry.errors.bill_rate_type.trim()}
              helperText={entry.errors.bill_rate_type}
            />
          )}
          onChange={(evt, value) => {
            if (entry.bill_rate_type === value) {
              return false;
            } else {
              onFieldChange("bill_rate_type", value, "customers");
              !isNew &&
                showFieldAlert("bill_rate_type", {
                  ...entry,
                  bill_quantity:
                    value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
                  bill_rate_type: value,
                });
            }
          }}
        />
      </div>

      <TextField
        required
        className={classes.field}
        size="small"
        type="number"
        name="bill_quantity"
        disabled={entry.route?.price_type === "Matrix"}
        // classes={{
        //   root: clsx({
        //     [classes.inputGreen]: !!state.inputAlerts.bill_quantity,
        //   }),
        // }}
        label="Billing Quantity"
        variant="outlined"
        value={entry.bill_quantity}
        //onChange={handleFieldChange}
        onChange={(evt) => {
          const { name, value, type } = evt.currentTarget;
          shouldShowAlert[name] = true;
          onFieldChange(name, value, "customers", type);
          const billFuelAmount = (
            +calculateValues({
              ...entry,
              bill_quantity: value,
            }).bill_fuel_amount || 0
          ).toFixed(2);
          onFieldChange(
            "bill_fuel_amount",
            billFuelAmount,
            "customers",
            "number"
          );
        }}
        error={!!entry.errors.bill_quantity.trim()}
        helperText={entry.errors.bill_quantity}
        onWheel={(event) => event.target.blur()}
        onKeyDown={preventInputKeyCodes}
        onBlur={(evt) => {
          const { name } = evt.target;
          if (shouldShowAlert[name]) {
            shouldShowAlert[name] = false;
            handleFieldBlur(evt);
          }
        }}
      />
      {entry.route?.price_type === "Matrix" && (
        <TextField
          className={classes.field}
          disabled={true}
          label="Price Matrix Name"
          variant="outlined"
          size="small"
          value={entry.route?.matrix_name || ""}
          helperText=" "
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      {entry.route?.price_type === undefined ||
      entry.route?.price_type === null ||
      entry.route?.price_type === "Standard" ? (
        <TextField
          required
          className={classes.fieldRate}
          InputProps={{
            startAdornment: <InputAdornment>$</InputAdornment>,
          }}
          size="small"
          type="number"
          disabled={isCalculateBill}
          name="bill_rate"
          label="Billing Rate"
          variant="outlined"
          value={entry.bill_rate}
          onWheel={(event) => event.target.blur()}
          //onChange={handleFieldChange}
          onChange={(evt) => {
            const { name, value, type } = evt.currentTarget;
            shouldShowAlert[name] = true;
            onFieldChange(name, value, "customers", type);
            const billFuelAmount = (
              +calculateValues({
                ...entry,
                bill_rate: value,
              }).bill_fuel_amount || 0
            ).toFixed(2);
            onFieldChange(
              "bill_fuel_amount",
              billFuelAmount,
              "customers",
              "number"
            );
          }}
          error={!!entry.errors.bill_rate.trim()}
          helperText={entry.errors.bill_rate}
          onKeyDown={(evt) => preventInputKeyCodes(evt, { HYPHEN: true })}
          onBlur={(evt) => {
            const { name } = evt.target;
            if (shouldShowAlert[name]) {
              shouldShowAlert[name] = false;
              handleFieldBlur(evt);
            }
          }}
        />
      ) : (
        <Autocomplete
          disableClearable
          size="small"
          className={classes.fieldRate}
          style={{ marginBottom: "31px" }}
          disabled={isCalculateBill}
          value={entry.bill_rate || ""}
          defaultValue={entry.bill_rate || ""}
          options={billingOptions}
          getOptionLabel={(option) => {
            const matchingBillPayPrice = billPayPrice?.find(
              (billOption) => billOption?.bill_price == option
            );
            if (matchingBillPayPrice) {
              return `$ ${option} (${matchingBillPayPrice.quantity})`;
            } else {
              return `$ ${option}`;
            }
          }}
          renderInput={(params) =>
            isPriceMatrixFetched ? (
              <Skeleton variant="rect" width="100%" height="36px" />
            ) : (
              <TextField
                {...params}
                required
                label="Bill Rate"
                variant="outlined"
              />
            )
          }
          getOptionSelected={(option, value) => option === value}
          onChange={(event, value) => {
            handleBillRateChange(event, value);
            const billFuelAmount = (
              +calculateValues({
                ...entry,
                bill_rate: value,
              }).bill_fuel_amount || 0
            ).toFixed(2);
            onFieldChange(
              "bill_fuel_amount",
              billFuelAmount,
              "customers",
              "number"
            );
          }}
        />
      )}
      {/* <Tooltip title="Do not bill, if this is checked">
        <Checkbox 
        className="mb-6"
        checked={isCalculateBill}
         onChange={(evt)=>{

          if(evt.target.checked === true){
            handleConfirmDontBill(evt.target.checked)
          }
          else{
            calculateBill(evt.target.checked)
          }
        
         
        }} 
          name="Bill" />
       
      </Tooltip> */}

      <FormControlLabel
        value="top"
        // disabled={entry.route?.price_type === "Matrix"}
        control={
          <Checkbox
            className="mb-1 mr-8"
            checked={
              // entry.route?.price_type === "Matrix" ? false : isCalculateBill
              isCalculateBill
            }
            onChange={(evt) => {
              if (evt.target.checked === true) {
                handleConfirmDontBill(evt.target.checked);
              } else {
                calculateBill(evt.target.checked);
              }
            }}
            name="Bill"
          />
        }
        className="mb-8 mt-0 ml-6"
        label="Do not bill, if checked:"
        labelPlacement="start"
      />

      <TextField
        size="small"
        className={classes.field}
        disabled={true}
        type="number"
        name="bill_amount"
        label="Billing Amount"
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
        variant="outlined"
        value={(+calculateValues(entry).bill_amount || 0).toFixed(2)}
        helperText=" "
      />

      <TextField
        className={classes.field}
        size="small"
        type="number"
        name="bill_fuel_surcharge"
        label="Billing Fuel Surcharge%"
        onWheel={(event) => event.target.blur()}
        variant="outlined"
        value={entry.bill_fuel_surcharge}
        onKeyDown={preventInputKeyCodes}
        onChange={(evt) => {
          const { name, value, type } = evt.currentTarget;
          onFieldChange(name, value, "customers", type);
          const billFuelAmount = (
            +calculateValues({
              ...entry,
              bill_fuel_surcharge: value,
            }).bill_fuel_amount || 0
          ).toFixed(2);
          onFieldChange(
            "bill_fuel_amount",
            billFuelAmount,
            "customers",
            "number"
          );
        }}
        InputProps={{
          endAdornment: <InputAdornment>%</InputAdornment>,
        }}
        error={!!entry.errors.bill_fuel_surcharge.trim()}
        helperText={entry.errors.bill_fuel_surcharge}
      />

      <TextField
        className={classes.field}
        size="small"
        type="number"
        name="bill_fuel_amount"
        label="Billing Fuel Amount"
        onKeyDown={preventInputKeyCodes}
        variant="outlined"
        disabled
        value={(+entry.bill_fuel_amount).toFixed(2)}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
        error={!!entry.errors.bill_fuel_amount.trim()}
        helperText={entry.errors.bill_fuel_amount}
      />
      <FormControlLabel
        value="top"
        disabled={isCalculateBill}
        control={
          <Checkbox
            className="mb-1 mr-8"
            checked={isCalculateBillMiles}
            onChange={(evt) => {
              if (evt.target.checked === true) {
                handleConfirmDontBillExtraMiles(evt.target.checked);
              } else {
                calculateBillMiles(evt.target.checked);
              }
            }}
            name="do_not_bill_extra_miles"
          />
        }
        className="mb-8 mt-0 ml-0"
        label="Do not bill extra miles, if checked:"
        labelPlacement="start"
      />
      <TextField
        className={classes.field}
        size="small"
        type="number"
        name="bill_extra_miles"
        disabled={isCalculateBill || isCalculateBillMiles}
        onKeyDown={preventInputKeyCodes}
        label="Bill Extra Miles"
        onWheel={(event) => event.target.blur()}
        variant="outlined"
        value={entry.bill_extra_miles}
        onChange={handleFieldChange}
        error={!!entry.errors.bill_extra_miles.trim()}
        helperText={entry.errors.bill_extra_miles}
      />
      <TextField
        className={classes.field}
        size="small"
        type="number"
        name="bill_rate_per_extra_mile"
        onWheel={(event) => event.target.blur()}
        onKeyDown={(evt) => preventInputKeyCodes(evt, { HYPHEN: true })}
        label="Bill Rate Per Extra Mile"
        variant="outlined"
        value={entry.bill_rate_per_extra_mile}
        // value={
        //   Number.isInteger(+entry.bill_rate_per_extra_mile || 0)
        //     ? +entry.bill_rate_per_extra_mile
        //     : (
        //         Math.round(
        //           (+entry.bill_rate_per_extra_mile + Number.EPSILON) * 100
        //         ) / 100
        //       ).toFixed(2)
        // }
        onChange={handleFieldChange}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
        error={!!entry.errors.bill_rate_per_extra_mile.trim()}
        helperText={entry.errors.bill_rate_per_extra_mile}
      />
      <TextField
        disabled
        className={classes.field}
        size="small"
        type="number"
        name="bill_extra_mile_amount"
        label="Bill Extra Miles Amount"
        variant="outlined"
        value={entry.bill_extra_mile_amount}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
        helperText=" "
      />
      <FormControlLabel
        value="top"
        disabled={isCalculateBill}
        control={
          <Checkbox
            className="mb-1 mr-8"
            checked={isCalculateBillToll}
            onChange={(evt) => {
              if (evt.target.checked === true) {
                handleConfirmDontBillToll(evt.target.checked);
              } else {
                calculateBillToll(evt.target.checked);
              }
            }}
            name="do_not_bill_toll_amount"
          />
        }
        className="mb-8 mt-0 ml-0"
        label="Do not bill toll, if checked:"
        labelPlacement="start"
      />
      <TextField
        className={classes.field}
        size="small"
        type="number"
        name="bill_toll_amount"
        disabled={isCalculateBill || isCalculateBillToll}
        label="Bill Toll Amount"
        variant="outlined"
        onWheel={(event) => event.target.blur()}
        onKeyDown={(evt) => preventInputKeyCodes(evt, { HYPHEN: true })}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
        value={entry.bill_toll_amount}
        onChange={handleFieldChange}
        error={!!entry.errors.bill_toll_amount.trim()}
        helperText={entry.errors.bill_toll_amount}
      />
    </div>
  );
};

export default CustomersPanel;
