import { useMemo, useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { ROLE, PERMISSION } from "../../shared/constants";
import baserosterIcon from "../../../assets/icons/baseRoster.svg";
import billingandsettlement from "../../../assets/icons/billingSettlement.svg";
import settlement from "../../../assets/icons/settlement.svg";
import BillingsettlementReview from "../../../assets/icons/billingSettlementReview.svg";
import { NavLink } from "react-router-dom/";
import Lookup from "../../../assets/icons/lookup.svg";
import customers from "../../../assets/icons/customers.svg";
import priceMatrix from "../../../assets/icons/priceMatrix.svg";
import dailySchedule from "../../../assets/icons/dailySchedule.svg";
import dailyScheduleReview from "../../../assets/icons/dailyScheduleReview.svg";
import invoice from "../../../assets/icons/invoice.svg";
import reports from "../../../assets/icons/reports.svg";
import independentContractors from "../../../assets/icons/independentContractor.svg";
import TransferIcon from "../../../assets/icons/transfer.svg";
import users from "../../../assets/icons/users.svg";
import fuel from "../../../assets/icons/fuel.svg";
import uploadDownload from "../../../assets/icons/uploadDownload.svg";
import ScheduleCalendar from "../../../assets/icons/scheduleCalendar.svg";
import { getTokenData, hasFeatureAccess, getDateString } from "utils";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LocationIcon from "./../../../assets/images/location-icon.png";
import SharedService from "../../shared/service";
import NoteIcon from "@material-ui/icons/Note";
import Link from "@material-ui/core/Link";
import { toast } from "react-toastify";
import { AppContext } from "shared/contexts";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import useStyles from "./style";
import { isTransferScheduleEnable } from "shared";

const defaultState = {
  openForceSubmitDialog: false,
};

const ViewHome = ({ match, history }) => {
  const userData = getTokenData();
  const [state, setState] = useState(defaultState);
  const isRoleKAM = (userData?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleAA = (userData?.role || "").toLowerCase() === ROLE.AA;
  const isRoleMGR = (userData?.role || "").toLowerCase() === ROLE.MGR;
  const isRoleADMIN = (userData?.role || "").toLowerCase() === ROLE.ADMIN;
  const isRoleView = (userData?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const isRoleSalesView =
    (userData?.role || "").toLowerCase() === ROLE.SALES_VIEW;

  const classes = useStyles({ isRoleKAM });
  const { appData, setHeaderElements, updateContextData } =
    useContext(AppContext);

  const kamId = isRoleKAM ? userData?.id : state.selectedKAM?.id;
  const defaultPages = [
    {
      label: "Base Roster",
      route: "/base-roster",
      icon: (
        <img src={baserosterIcon} alt="baseroster" className={classes.icon} />
      ),
    },
    {
      label: "Customers",
      route: "/customers",
      icon: <img src={customers} alt="customers" className={classes.icon} />,
    },
    {
      label: "Daily Schedule",
      route: "/daily-schedule",
      icon: (
        <img src={dailySchedule} alt="dailyschedule" className={classes.icon} />
      ),
    },

    {
      label: "Fuel Price",
      route: "/fuel-price",
      icon: <img src={fuel} alt="fuel" className={classes.icon} />,
    },
    {
      label: "Independent Contractors",
      route: "/contractors",
      icon: (
        <img
          src={independentContractors}
          alt="independentContractors"
          className={classes.icon}
        />
      ),
    },
    {
      label: "Location",
      route: "/geo-location",
      icon: <img src={LocationIcon} alt="location" className={classes.icon} />,
    },
    {
      route: "/report",
      label: "Reports",
      icon: <img src={reports} alt="reports" className={classes.icon} />,
    },
    {
      label: "Transferred Schedule",
      route: "/transfer-schedule",
      icon: <img src={TransferIcon} alt="location" className={classes.icon} />,
    },

    {
      label: "Upload/Download",
      route: "/exchange",
      icon: (
        <img
          src={uploadDownload}
          alt="uploaddownload"
          className={classes.icon}
        />
      ),
    },

    // {
    //   label: "Schedule Calendar",
    //   route: "/schedule-calendar",
    //   icon: (
    //     <img
    //       src={ScheduleCalendar}
    //       alt="Schedule Calendar"
    //       className={classes.icon}
    //     />
    //   ),
    // },
  ];

  const defaultSalesViewPages = [
    {
      label: "Customers",
      route: "/customers",
      icon: <img src={customers} alt="customers" className={classes.icon} />,
    },
    {
      label: "Fuel Price",
      route: "/fuel-price",
      icon: <img src={fuel} alt="fuel" className={classes.icon} />,
    },
    {
      label: "Location",
      route: "/geo-location",
      icon: <img src={LocationIcon} alt="location" className={classes.icon} />,
    },
  ];

  const defaultViewOnlyPages = [
    {
      label: "Location",
      route: "/geo-location",
      icon: <img src={LocationIcon} alt="location" className={classes.icon} />,
    },
  ];

  const getPages = () => {
    if (isRoleKAM) {
      return defaultPages;
    } else if (isRoleView) {
      return defaultViewOnlyPages;
    } else if (isRoleSalesView) {
      return defaultSalesViewPages;
    }

    const pages = [];

    [
      "/base-roster",
      "/customers",
      "/daily-schedule",
      "/fuel-price",
      "/geo-location",
      "/report",
      "/exchange",
    ].forEach((route) => {
      let element = defaultPages.find((element) => element.route === route);
      if (!isRoleKAM && element?.route === "/daily-schedule") {
        let currentDate = getDateString(new Date())?.split("/");
        currentDate = `${currentDate[2]}-${currentDate[0]}-${currentDate[1]}`;
        element = {
          ...element,
          route: `/daily-schedule/create/day/${currentDate}`,
        };
      }
      pages.push(element);
    });

    pages.splice(1, 0, {
      route: "/billing",
      label: "Billing & Settlements",
      icon: (
        <img
          src={billingandsettlement}
          alt="billing and settlement"
          className={classes.icon}
        />
      ),
    });

    hasFeatureAccess(PERMISSION.REVIEWER) &&
      pages.splice(!isRoleAA ? 2 : 0, 0, {
        label: "Billing & Settlement Review",
        route: "/billing-settlement-review",
        icon: (
          <img
            src={BillingsettlementReview}
            alt="billing and settlement review"
            className={classes.icon}
          />
        ),
      });

    hasFeatureAccess(PERMISSION.MANAGE_DCN) &&
      pages.splice(isRoleADMIN ? 5 : 4, 0, {
        route: "/settlements",
        label: "Settlements",
        icon: (
          <img src={settlement} alt="settlement" className={classes.dcnIcon} />
        ),
      });
    hasFeatureAccess(PERMISSION.MANAGE_CONTRACTOR) &&
      pages.splice(isRoleADMIN ? 7 : 6, 0, {
        label: "Independent Contractors",
        route: "/contractors",
        icon: (
          <img
            src={independentContractors}
            alt="independentContractors"
            className={classes.icon}
          />
        ),
      });

    // pages.splice(isRoleAA ? 2 : 3, 0, {
    //   label: "Customers",
    //   route: "/customers",
    //   icon: <img src={customers} alt="customers" className={classes.icon} />,
    // });

    hasFeatureAccess(PERMISSION.REVIEWER) &&
      pages.splice(isRoleADMIN ? 9 : 8, 0, {
        label: "Manager Review",
        route: "/daily-schedule-review",
        icon: (
          <img
            src={dailyScheduleReview}
            alt="dailyScheduleReview"
            className={classes.icon}
          />
        ),
      });

    hasFeatureAccess(PERMISSION.MANAGE_INVOICE) &&
      pages.splice(isRoleADMIN ? 8 : 7, 0, {
        label: "Invoices",
        route: "/invoice",
        icon: <img src={invoice} alt="invoice" className={classes.icon} />,
      });

    isTransferScheduleEnable &&
      hasFeatureAccess(PERMISSION.MANAGE_TRANSFER_SCHEDULE) &&
      pages.splice(isRoleADMIN ? 11 : isRoleMGR ? 10 : 9, 0, {
        label: "Transferred Schedule",
        route: "/transfer-schedule",
        icon: (
          <img src={TransferIcon} alt="location" className={classes.icon} />
        ),
      });

    if ((userData?.role || "").toLowerCase() === ROLE.AA) {
      pages.splice(8, 0, {
        label: "Lookup",
        route: "/lookup",
        icon: <img src={Lookup} alt="lookup" className={classes.icon} />,
      });
      pages.splice(9, 0, {
        label: "Price Matrix",
        route: "/price-matrix",
        icon: (
          <img src={priceMatrix} alt="priceMatrix" className={classes.icon} />
        ),
      });
    }
    // pages.splice(isRoleADMIN ? 9 : 0, 0, {
    //   route: "/report",
    //   label: "Reports",
    //   icon: <img src={reports} alt="reports" className={classes.icon} />,
    // });

    if (
      (userData?.role || "").toLowerCase() === ROLE.ADMIN ||
      !userData?.role
    ) {
      pages.splice(9, 0, {
        label: "Lookup",
        route: "/lookup",
        icon: <img src={Lookup} alt="lookup" className={classes.icon} />,
      });
      // pages.splice(9, 0, {
      //   label: "Location",
      //   route: "/geo-location",
      //   icon: <img src={LocationIcon} alt="lookup" className={classes.icon} />,
      // });
      pages.splice(11, 0, {
        label: "Price Matrix",
        route: "/price-matrix",
        icon: (
          <img src={priceMatrix} alt="priceMatrix" className={classes.icon} />
        ),
      });
      pages.push({
        label: "Users",
        route: "/users",
        icon: <img src={users} alt="users" className={classes.icon} />,
      });
    }

    return pages;
  };

  const fetchList = async (listType = "customers", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;

      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  useEffect(() => {
    !!appData.pendingItems.length &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchList("pendingItems", kamId);
      fetchLeaveTypeList();
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => updateContextData("pendingItems", []);
  }, []);

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const pages = useMemo(() => getPages(), [userData?.role, classes]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="center"
      className={classes.wrapper}
    >
      {pages.map((page) => {
        return (
          <Grid item sm={isRoleKAM ? 6 : 3} md={2} className={classes.griddddd}>
            <div
              className="d-flex f-align-center flex-column "
              style={{ paddingTop: "180px" }}
            >
              <Link to={page.route} component={NavLink}>
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.iconButton}
                  onClick={() => history.push(page.route)}
                >
                  {page.icon}
                </Button>
              </Link>
              <Typography
                variant={isRoleKAM ? "body1" : "body2"}
                className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
              >
                {page.label}
              </Typography>
            </div>
          </Grid>
        );
      })}
      {state.openForceSubmitDialog && (
        <ForceSubmitDialog
          open={state.openForceSubmitDialog}
          pendingItems={appData.pendingItems.map((date, index) => ({
            date,
            id: index,
          }))}
          onClose={handleClose}
          kamId={kamId}
          forceSubmitReasonList={state.forceSubmitReasonList}
          onForceSubmit={() => fetchList("pendingItems", kamId)}
        />
      )}
    </Grid>
  );
};

export default ViewHome;
