/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useStyles from "./style";
import clsx from "clsx";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  ActionDialog,
  AppContext,
  Dropdown,
  Grid,
  VALIDATIONS,
  noop,
} from "shared";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import PrintIcon from "@material-ui/icons/Print";
import DraftsIcon from "@material-ui/icons/Drafts";
import {
  getReadableFileSizeString,
  getTokenData,
  getUTCDateString,
  validator,
} from "utils";
import GenerateInvoiceDialog from "modules/invoice/generateInvoice";
import GridLoader from "shared/components/grid/loader";
import NoRecords from "assets/images/norecord.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PictureAsPdf as PdfIcon } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import { INVOICE_STATUS, ROLE } from "modules/shared/constants";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import GenerateInvoicePDF from "modules/invoice/generateInvoicePdf";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import RecordPayment from "modules/invoice/recordPayment";
import Service from "modules/invoice/service";
import { toast } from "react-toastify";
import { Skeleton } from "@material-ui/lab";
import MailIcon from "@material-ui/icons/Mail";
import AddIcon from "@material-ui/icons/Add";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PaymentIcon from "@material-ui/icons/Payment";
import PaymentGrid from "./paymentGrid";
import UploadFiles from "modules/invoice/file-upload";

let isConfirmDisabled = false;

const defaultState = {
  selectedOption: null,
  open: false,
  selectedInvoices: [],
  resendEmailsArray: [],
  uploadedAttachment: [],
  emailHistory: [],
  sentEmailHistory: false,
  isInvoiceEmailHistoryLoading: false,
  anchorEl: null,
  anchorE2: null,
  isInvoiceSent: false,
  email: "",
  from: "",
  emailSubject: "",
  emailBody: "",
  temporaryEmail: [],
  permanentEmail: [],
  errors: {
    email: " ",
    from: " ",
    emailSubject: " ",
    emailBody: " ",
  },
  showPaymentPopup: false,
  isInvoiceSending: false,
  showSendInvoicePopup: false,
  isPreviousViewInvoice: null,
  isInvoiceDeleteDialog: false,
  isInvoiceDeleteLoading: false,
  thePdfUrl: "",
  pdfViewerDialog: false,
  showAddPermanentEmailDialog: false,
  isPermEmailLoading: false,
  paymentHistoryPopUp: false,
  attachmentFiles: [],
};

const InvoiceSummary = ({
  usersList,
  history,
  customerList,
  rows = [],
  selectedInvoice = null,
  entry = {},
  isInvoiceLoading = false,
  isMarkAsSentLoading = false,
  isLoading = false,
  totalEntries = 0,
  pageSize = 100,
  pageNumber = 1,
  pdfUrl = "",
  onPageSizeChange = noop,
  onPageNumberChange = noop,
  handleClose = noop,
  handleTitleClick = noop,
  getInvoice = noop,
  handleInvoiceFilter = noop,
  handleMarkInvoiceSent = noop,
  handleItemClick = noop,
  handleApprovalDialog = noop,
  handleFetch = noop,
  invoiceLoading = false,
  handleMultiplePdf = noop,
  isPdfPreviewLoading = false,
  isPdfDownloadLoading = false,
  invoiceFilterStatus = [],
  fetchInvoice = noop,
  emailLoading = false,
}) => {
  const classes = useStyles();
  const { appData } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const selectedRef = useRef(null);
  const currentUser = getTokenData() || {};
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;

  useEffect(() => {
    if (selectedRef.current && state.selectedInvoices.length === 0) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    const selectedCompanies =
      state.selectedInvoices.length > 0
        ? [
            ...new Set(
              rows
                .filter((obj) => state.selectedInvoices.includes(obj.id))
                .map((invoice) => invoice.pdx_company)
            ),
          ].join(", ")
        : null;

    const displayCompanyName = () => {
      if (selectedCompanies && selectedCompanies.split(",").length > 1) {
        return "Parts Distribution Xpress";
      } else if (state.selectedInvoices.length === 0) {
        return selectedInvoice?.pdx_company;
      } else {
        return selectedCompanies;
      }
    };
    setState((prevState) => ({
      ...prevState,
      emailSubject: `${
        !state.selectedInvoices.length
          ? `Invoice #${selectedInvoice?.serial_no || ""}`
          : state.selectedInvoices.length === 1
          ? `Invoice #${
              rows.filter((obj) => state.selectedInvoices.includes(obj.id))[0]
                ?.serial_no
            }`
          : "Invoices"
      } from ${selectedCompanies || selectedInvoice?.pdx_company || "PDX"}`,
      emailBody: `Dear ${
        !state.selectedInvoices.length
          ? selectedInvoice?.customer_branch?.customer?.name || ""
          : state.selectedInvoices.length === 1
          ? rows.filter((obj) => state.selectedInvoices.includes(obj.id))[0]
              ?.customer_branch?.customer?.name
          : "Customers"
      },\n\nYour ${
        state.selectedInvoices.length > 1 ? "invoices are" : "invoice is"
      } attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
    }));
  }, [selectedInvoice, state.selectedInvoices, rows]);

  const emailAddresses =
    state.selectedInvoices.length === 0
      ? entry?.invoiceDetails?.customer_branch?.email_addresses
          ?.emailAddresses || []
      : state.selectedInvoices.length === 1
      ? rows.find((obj) => state.selectedInvoices.includes(obj.id))
          ?.customer_branch?.email_addresses?.emailAddresses || []
      : [];

  useEffect(() => {
    if (
      !state.sentEmailHistory &&
      state.showSendInvoicePopup &&
      entry?.invoiceDetails?.status === "approved" &&
      emailAddresses.length
    ) {
      setState((prevState) => ({
        ...prevState,
        permanentEmail: emailAddresses,
      }));
    }
  }, [
    state.showSendInvoicePopup,
    entry?.invoiceDetails?.status,
    emailAddresses,
  ]);

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      from: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      email: [{ type: VALIDATIONS.EMAIL, value: true }],
      emailSubject: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      emailBody: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = " ";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (validate() === " " && state.errors?.email === " ") {
        event.preventDefault();
        handleAddEmail("temporaryEmail");
      }
    }
  };

  const handleBlur = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("temporaryEmail");
    }
  };

  const handleGeneratePDF = (data, preview, print) => {
    const invoiceData = {
      invoiceDetails: data?.lineItems,
      invoiceDescriptionDetails: data?.lineItemNotes,
      invoiceNo: data.invoiceDetails.serial_no,
      terms: data.invoiceDetails.terms,
      termsAndCondition: data.invoiceDetails.terms_and_condition,
      invoiceDate: data.invoiceDetails.invoice_date,
      fromDate: data.invoiceDetails.from_date,
      toDate: data.invoiceDetails.to_date,
      customerNotes: data.invoiceDetails.customer_notes,
      invoiceStatus: data.invoiceDetails.status,
      pdxCompany: data.invoiceDetails.customer_branch?.pdxCompany?.value,
      discount: data.invoiceDetails.discount_amt,
      usedCreditAmount: data.invoiceDetails?.used_credit_amt,
      balanceAmount: data?.invoiceDetails?.balance_amt,
      paidAmount: data?.invoiceDetails?.paid_amt,
      isOverdue: data?.invoiceDetails?.is_overdue,
      completePaymentDoneOn: data?.invoiceDetails?.complete_payment_done_on,
      isLatePayment:
        getUTCDateString(data?.invoiceDetails?.payment_completed_date) >
        getUTCDateString(data?.invoiceDetails?.payment_due_date)
          ? true
          : false,
      billTo: {
        customerName: data.invoiceDetails.bill_to?.customerName,
        branch: data.invoiceDetails.bill_to?.branch,
        streetAddress: data.invoiceDetails.bill_to?.street_address,
        state: data.invoiceDetails.bill_to?.state,
        city: data.invoiceDetails.bill_to?.city,
        country: data.invoiceDetails.bill_to?.country,
        zipCode: data.invoiceDetails.bill_to?.zip_code,
        phone: data.invoiceDetails.bill_to?.phone,
        primary_contact:
          data.invoiceDetails.bill_to?.primary_contact ||
          data.invoiceDetails.bill_to?.contact,
      },
      isPreview: preview,
      isPrinting: print,
    };

    GenerateInvoicePDF(invoiceData);
    handleMenuClose();
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (state.temporaryEmail.some((email) => email === value.trim())) {
      errorMessage = "This email has already been entered.";
    }
    if (state.permanentEmail.some((email) => email === value.trim())) {
      errorMessage = "This email has already been entered.";
    }

    isConfirmDisabled = !!errorMessage && errorMessage !== " ";
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleAddEmail = (type) => {
    if (state.email?.trim()) {
      setState((prevState) => ({
        ...prevState,
        [type]: [...(prevState[type] || []), state?.email?.trim()],

        ...(type === "temporaryEmail" && {
          showAddPermanentEmailDialog: false,
          email: defaultState.email,
        }),
      }));
    }
  };

  const handlePaymentClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      showPaymentPopup: close,
    }));
  };

  const uploadedAttachment = (attachment) => {
    setState((prevState) => ({
      ...prevState,
      uploadedAttachment: attachment,
    }));
  };

  const handleSubmit = async (
    id,
    tempEmailArray,
    permEmailArray,
    subject,
    body,
    attachment,
    isSingleInvoice = false
  ) => {
    setState((prevState) => ({ ...prevState, isInvoiceSending: true }));

    const emailArray =
      !state.selectedInvoices.length || state.selectedInvoices.length === 1
        ? [...tempEmailArray, ...permEmailArray]
        : [...tempEmailArray];

    const idArray = [];
    if (Array.isArray(id)) {
      idArray.push(...id);
    } else {
      idArray.push(id);
    }

    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("email_body", body);

    idArray.forEach((invoiceId, index) =>
      formData.append(`invoiceIds[${index}]`, invoiceId)
    );
    emailArray.forEach((email, index) =>
      formData.append(`emailIds[${index}]`, email)
    );

    attachment.forEach((file) => {
      formData.append("uploadedAttachment", file);
    });

    const { error } = await Service.sendEmail(formData);

    if (error) {
      setState((prevState) => ({ ...prevState, isInvoiceSending: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Email sent successfully.");
    const selectedCompanies =
      state.selectedInvoices.length > 0
        ? [
            ...new Set(
              rows
                .filter((obj) => state.selectedInvoices.includes(obj.id))
                .map((invoice) => invoice.pdx_company)
            ),
          ].join(", ")
        : null;

    const displayCompanyName = () => {
      if (selectedCompanies && selectedCompanies.split(",").length > 1) {
        return "Parts Distribution Xpress";
      } else if (state.selectedInvoices.length === 0) {
        return selectedInvoice?.pdx_company;
      } else {
        return selectedCompanies;
      }
    };
    setState((prevState) => ({
      ...prevState,
      isInvoiceSending: false,
      selectedInvoices: defaultState.selectedInvoices,
      showSendInvoicePopup: false,
      temporaryEmail: defaultState.temporaryEmail,
      permanentEmail: defaultState.permanentEmail,
      from: defaultState.from,
      emailSubject: `${
        !state.selectedInvoices.length
          ? `Invoice #${selectedInvoice?.serial_no || ""}`
          : state.selectedInvoices.length === 1
          ? `#${
              rows.filter((obj) => state.selectedInvoices.includes(obj.id))[0]
                ?.serial_no
            }`
          : "Invoices"
      } from ${selectedCompanies || selectedInvoice?.pdx_company || "PDX"}`,
      emailBody: `Dear ${
        !state.selectedInvoices.length
          ? selectedInvoice?.customer_branch?.customer?.name || ""
          : state.selectedInvoices.length === 1
          ? rows.filter((obj) => state.selectedInvoices.includes(obj.id))[0]
              ?.customer_branch?.customer?.name
          : "Customers"
      },\n\nYour invoice ${
        state.selectedInvoices.length > 1 ? "invoices are" : "invoice is"
      } attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
    }));
    if (isSingleInvoice) {
      getInvoice({ id: entry.invoiceDetails?.id }, true);
    }
    if (state.sentEmailHistory) {
      getInvoiceEmailHistory(selectedInvoice?.id);
    }
    handleFetch("invoiceLoading");
  };

  const handleDeleteEmail = (id, type) => {
    setState((prevState) => {
      const filteredEmails = prevState[type].filter((email) => email !== id);
      const emailExists = filteredEmails.some(
        (item) => item === prevState.email
      );
      isConfirmDisabled = emailExists;
      return {
        ...prevState,
        [type]: filteredEmails,
        errors: {
          ...prevState.errors,
          email: emailExists ? prevState.errors.email : " ",
          from: prevState.errors.from,
          emailSubject: prevState.errors.emailSubject,
          emailBody: prevState.errors.emailBody,
        },
      };
    });
  };

  const handleChange = (option) => {
    handleInvoiceFilter(option);
    setState((prevState) => ({
      ...prevState,
      selectedOption: option,
    }));
  };

  const handlePageChange = (event, pageNumber) => {
    event.stopPropagation();
    onPageNumberChange(pageNumber + 1);
  };

  const handlePageSizeChange = (event) => {
    onPageSizeChange(event.target.value);
  };

  const getStatusInfo = (status) => {
    switch (status) {
      case "draft":
        return { text: "Draft", className: classes.draft };
      case "partially-paid":
        return { text: "Partially Paid", className: classes.partiallyPaid };
      case "approved":
        return { text: "Approved", className: classes.approved };
      case "pending-approval":
        return { text: "Pending Approval", className: classes.open };
      case "paid":
        return { text: "Paid", className: classes.paid };
      case "overdue":
        return { text: "Overdue", className: classes.overdue };
      default:
        return { text: "", className: "" };
    }
  };

  const handleDialogClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      open: close,
    }));
  };

  const handleCheckboxChange = (invoiceId) => {
    setState((prevState) => ({
      ...prevState,
      selectedInvoices: prevState.selectedInvoices.includes(invoiceId)
        ? prevState.selectedInvoices.filter((id) => id !== invoiceId)
        : [...prevState.selectedInvoices, invoiceId],
    }));
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setState((prevState) => ({
        ...prevState,
        selectedInvoices: rows.map((invoice) => invoice.id),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedInvoices: defaultState.selectedInvoices,
      }));
    }
  };

  const handleMenuOpen = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget,
    }));
  };

  const handleClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorE2: event.currentTarget,
    }));
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: defaultState.anchorEl,
      anchorE2: defaultState.anchorEl,
    }));
  };

  const handleClearSelection = () => {
    setState((prevState) => ({
      ...prevState,
      selectedInvoices: defaultState.selectedInvoices,
    }));
    handleMenuClose();
  };

  const handleDeleteInvoiceDialog = (value) => {
    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteDialog: value,
    }));
    handleMenuClose();
  };

  const handleDeleteInvoices = async (ids) => {
    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteLoading: true,
    }));

    const invoiceIdsString = ids.join(",");

    const queryString = `?invoiceIds=${invoiceIdsString}`;

    const { error } = await Service.deleteInvoices(queryString);

    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteLoading: false,
      selectedInvoices: defaultState.selectedInvoices,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
    }
    handleDeleteInvoiceDialog(false);
    handleFetch("invoiceLoading");
  };

  const handleAddPermEmailDialog = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("permanentEmail");
    }

    setState((prevState) => ({
      ...prevState,
      showAddPermanentEmailDialog: true,
    }));
  };

  const addPermananentEmaiil = async (data, email) => {
    setState((prevState) => ({ ...prevState, isPermEmailLoading: true }));

    const payload = {
      id: data.id || null,
      invoice_type: data.invoiceType?.id || null,
      pdx_company_id: data.pdx_company_id || null,
      customer_id: data.customer_id,
      state: data.state || null,
      location: data.location || null,
      aa_id: data.aa_id || null,
      kam_id: data.kam_id || null,
      mgr_id: data.mgr_id || null,
      email_addresses: {
        emailAddresses: email,
      },
    };

    const { error } = await Service.addEmailInBranch(payload);

    if (error) {
      handleAddEmail("temporaryEmail");
      setState((prevState) => ({ ...prevState, isPermEmailLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Customer Email has been added successfully.`);
    setState((prevState) => ({
      ...prevState,
      isPermEmailLoading: false,
      showAddPermanentEmailDialog: false,
      email: defaultState.email,
    }));
    fetchInvoice({ id: entry.invoiceDetails?.id }, "emailLoading");
  };

  const handleViewerClose = () => {
    setState((prevState) => ({ ...prevState, pdfViewerDialog: false }));
  };

  const getInvoiceEmailHistory = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isInvoiceEmailHistoryLoading: true,
    }));
    const invoiceId = `?filter[where][and][0][or][0][invoice_ids][contains]=[${id}]`;

    const { data, error } = await Service.getInvoiceEmailHistory(invoiceId);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isInvoiceEmailHistoryLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isInvoiceEmailHistoryLoading: false,
      emailHistory: data.rows || defaultState.emailHistory,
    }));
  };

  const handlePaymentHistoryDialog = (close) => {
    setState((prevState) => ({
      ...prevState,
      paymentHistoryPopUp: close,
    }));
  };

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="View Detailed Sent Invoice" placement="top-start">
          <VisibilityIcon
            className={"ml-2 mr-2 c-pointer"}
            color="primary"
            onClick={() => {
              const resendEmail = state.emailHistory.find(
                (ele) => ele.id === row.id
              );

              setState((prevState) => {
                const resendEmailsArray =
                  resendEmail?.to?.customer_emails ||
                  defaultState.resendEmailsArray;
                const permanentEmail = resendEmailsArray.filter((email) =>
                  emailAddresses.includes(email)
                );
                const temporaryEmail = resendEmailsArray.filter(
                  (email) => !emailAddresses.includes(email)
                );

                return {
                  ...prevState,
                  showSendInvoicePopup: true,
                  isPreviousViewInvoice: row.invoice_pdf_url,
                  temporaryEmail: temporaryEmail || defaultState.temporaryEmail,
                  permanentEmail: permanentEmail || defaultState.permanentEmail,
                  emailSubject:
                    resendEmail?.subject || defaultState.emailSubject,
                  emailBody: resendEmail?.email_body || defaultState.emailBody,
                  attachmentFiles:
                    resendEmail?.attachments?.attachmentFiles ||
                    defaultState.attachmentFiles,
                };
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Resend Email" placement="top-start">
          <SendIcon
            className={"ml-6 mr-2 c-pointer"}
            color="primary"
            onClick={() => {
              const resendEmail = state.emailHistory.find(
                (ele) => ele.id === row.id
              );

              setState((prevState) => {
                const resendEmailsArray =
                  resendEmail?.to?.customer_emails ||
                  defaultState.resendEmailsArray;
                const permanentEmail = resendEmailsArray.filter((email) =>
                  emailAddresses.includes(email)
                );
                const temporaryEmail = resendEmailsArray.filter(
                  (email) => !emailAddresses.includes(email)
                );
                return {
                  ...prevState,
                  showSendInvoicePopup: true,
                  temporaryEmail: temporaryEmail || defaultState.temporaryEmail,
                  permanentEmail: permanentEmail || defaultState.permanentEmail,
                  emailSubject:
                    resendEmail?.subject || defaultState.emailSubject,
                  emailBody: resendEmail?.email_body || defaultState.emailBody,
                };
              });
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      id: "date",
      label: "Sent Date",
      render: (row) => {
        return (
          <Tooltip
            title={getUTCDateString(row.date) || ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {getUTCDateString(row.date)}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "sentBy",
      label: "Sent By",
      render: (row) => {
        const sentBy = usersList.find((ele) => ele?.id === row.created_by);
        let user = "";
        if (!!sentBy) {
          user = `${sentBy?.first_name}, ${sentBy?.last_name || ""} (${
            sentBy?.email
          } - ${sentBy?.username})`;
        }
        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "sentTo",
      label: "Sent To",
      render: (row) => {
        let emails;
        if (!!row?.to?.customer_emails) {
          emails = row.to.customer_emails?.join(", ");
        }
        return (
          <Tooltip title={emails ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {emails || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
  ];

  const LoadingOverlay = ({ message }) => (
    <div className={classes.overlayStyles}>
      <div className={classes.messageStyles}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" style={{ marginTop: "16px" }}>
          {message}
        </Typography>
      </div>
    </div>
  );

  return (
    <div className={clsx("d-flex", appData.isTabletView && classes.tabletView)}>
      <Paper
        elevation={2}
        className={clsx(
          "p-4 mt-10",
          classes.paperSpacing,
          appData.isTabletView && classes.tableWidth
        )}
      >
        <div
          className="d-flex f-align-center f-justify-between"
          style={{
            padding:
              state.selectedInvoices.length > 0 ? "12.25px 0px" : "18px 0px",
          }}
        >
          {state.selectedInvoices.length > 0 ? (
            <>
              <div className="d-flex f-align-center f-justify-between">
                <Checkbox
                  indeterminate={
                    state.selectedInvoices.length > 0 &&
                    state.selectedInvoices.length < rows.length
                  }
                  checked={
                    rows.length > 0 &&
                    state.selectedInvoices.length === rows.length
                  }
                  onChange={handleSelectAll}
                />
                <Tooltip title="Send Invoice" placement="top-start">
                  <IconButton
                    onClick={() => {
                      if (
                        rows.some(
                          (ele) =>
                            state.selectedInvoices.includes(ele.id) &&
                            (ele.status === "draft" ||
                              ele.status === "pending-approval")
                        )
                      ) {
                        return toast.error(
                          "Invoice(s) can only be marked as sent for customers whose status is approved, paid, or partially paid."
                        );
                      }
                      setState((prevState) => ({
                        ...prevState,
                        showSendInvoicePopup: true,
                      }));
                    }}
                    className={classes.sentIcon}
                  >
                    <SendIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={handleMenuOpen}
                  className={classes.sentIcon}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={state.anchorEl}
                  keepMounted
                  open={Boolean(state.anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    disabled={isPdfPreviewLoading}
                    onClick={() =>
                      handleMultiplePdf(
                        state.selectedInvoices,
                        "isPdfPreviewLoading",
                        true
                      )
                    }
                  >
                    Print
                    {isPdfPreviewLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleMultiplePdf(
                        state.selectedInvoices,
                        "isPdfDownloadLoading",
                        false
                      )
                    }
                    disabled={isPdfDownloadLoading}
                  >
                    Export as PDF
                    {isPdfDownloadLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      if (
                        rows.some(
                          (ele) =>
                            state.selectedInvoices.includes(ele.id) &&
                            ele.status !== "draft"
                        )
                      ) {
                        return toast.error(
                          "Deletion is not allowed for invoices unless they are in draft status."
                        );
                      }
                      handleDeleteInvoiceDialog(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                <Button
                  variant="outlined"
                  color="primary"
                  className="ml-2"
                  disabled={isMarkAsSentLoading}
                  onClick={() => {
                    if (
                      rows.some(
                        (ele) =>
                          state.selectedInvoices.includes(ele.id) &&
                          (ele.status === "draft" ||
                            ele.status === "pending-approval")
                      )
                    ) {
                      return toast.error(
                        "Invoice(s) can only be marked as sent for customers whose status is approved, paid, or partially paid."
                      );
                    }
                    setState((prevState) => ({
                      ...prevState,
                      isInvoiceSent: true,
                    }));
                  }}
                >
                  Mark as Sent
                  {isMarkAsSentLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </Button>
              </div>
              <IconButton onClick={handleClearSelection}>
                <ClearIcon />
              </IconButton>
            </>
          ) : (
            <>
              {!!invoiceFilterStatus.length ? (
                <Typography variant="h6" color="primary">
                  Invoice(s)
                </Typography>
              ) : (
                <Dropdown
                  classes={{
                    paper: clsx(classes.tunePaper),
                    dropdownIcon: classes.dropdownIcon,
                    labelContent: classes.labelContent,
                  }}
                  disablePortal={false}
                  labelEllipses
                  hasEllipses
                  placement="bottom-start"
                  options={INVOICE_STATUS}
                  labelClassName={classes.label}
                  label={`${
                    state.selectedOption ? state.selectedOption.label : "All"
                  } Invoices`}
                  onChange={handleChange}
                  renderOption={(option) => (
                    <div className={classes.dropdownItem}>{option.label}</div>
                  )}
                />
              )}
              <div></div>
            </>
          )}
        </div>
        <Divider />
        {isLoading ? (
          <GridLoader pageSize={8} columns={2} />
        ) : !!rows && rows.length > 0 ? (
          <>
            <List className={classes.list}>
              {rows.map((invoice) => (
                <Fragment key={invoice.id}>
                  <div
                    className={clsx("d-flex f-align-center", {
                      [classes.listItemSelected]:
                        selectedInvoice?.id === invoice?.id,
                    })}
                    ref={
                      selectedInvoice?.id === invoice?.id ? selectedRef : null
                    }
                  >
                    <Checkbox
                      checked={state.selectedInvoices.includes(invoice.id)}
                      onChange={() => handleCheckboxChange(invoice.id)}
                    />
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => {
                        handleItemClick(invoice);
                        getInvoice(invoice, true);
                      }}
                    >
                      <div className={classes.listItemText}>
                        <div className="d-flex f-align-center">
                          <Typography variant="body1">
                            {invoice.customer_branch?.customer?.name}
                          </Typography>
                          {!!invoice?.is_sent_to_customer && (
                            <MailIcon color="primary" className="ml-2" />
                          )}
                        </div>
                        <div className="d-flex f-align-center">
                          <Typography
                            variant="body1"
                            style={{ color: "#775edc" }}
                          >
                            {invoice.serial_no}
                          </Typography>
                          <div style={{ height: "20px", padding: "0px 5px" }}>
                            <Divider orientation="vertical" />
                          </div>
                          <Typography variant="body2" color="textSecondary">
                            {getUTCDateString(invoice.invoice_date)}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.statusListItemText}>
                        <Typography variant="body1" className={classes.amount}>
                          {`$ ${invoice.total_amount_finalized || "0.00"}`}
                        </Typography>
                        <div className="d-flex f-align-center">
                          <Tooltip
                            title={`Balance Amount - $${
                              invoice?.balance_amt || "$0.00"
                            }`}
                            placement="top-start"
                          >
                            <Typography
                              variant="body2"
                              className={`${classes.status} ${
                                getStatusInfo(invoice.status).className
                              }`}
                            >
                              {getStatusInfo(invoice.status).text}
                            </Typography>
                          </Tooltip>
                          {!!invoice.is_overdue && (
                            <Typography
                              variant="body2"
                              className={clsx("ml-2", classes.overdue)}
                            >
                              Overdue
                            </Typography>
                          )}
                          {!!(
                            getUTCDateString(invoice?.payment_completed_date) >
                            getUTCDateString(invoice?.payment_due_date)
                          ) && (
                            <Typography
                              variant="body2"
                              className={clsx("ml-2", classes.latePayemnt)}
                            >
                              Late Payment Received
                            </Typography>
                          )}
                        </div>
                      </div>
                    </ListItem>
                  </div>
                  <Divider />
                </Fragment>
              ))}
            </List>
            <Divider />
            <div
              className="d-flex f-align-center f-justify-center"
              style={{ margin: "2px 0px" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={totalEntries}
                rowsPerPage={pageSize}
                colSpan={3}
                page={pageNumber - 1}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                classes={{
                  select: "d-flex f-align-center f-justify-end",
                }}
              />
            </div>
          </>
        ) : (
          <div
            className="d-flex f-justify-center"
            style={{ paddingTop: "30%" }}
          >
            <img
              className={classes.noRecordsImg}
              src={NoRecords}
              alt="noRecord"
            />
          </div>
        )}
      </Paper>

      <Paper
        elevation={2}
        className={clsx("p-4 mt-10 ml-2", classes.summaryPaperSpacing)}
      >
        {invoiceLoading ? (
          <div
            className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8"
            style={{ height: "100%" }}
          >
            <CircularProgress />
          </div>
        ) : rows.some((item) => item.id === selectedInvoice?.id) ? (
          <>
            <div className="d-flex f-align-center f-justify-between">
              <Typography
                style={{
                  padding: "18px 0px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {`Invoice #${selectedInvoice.serial_no}`}
              </Typography>
              <IconButton onClick={() => handleClose(false)}>
                <ClearIcon />
              </IconButton>
            </div>
            <div>
              {isInvoiceLoading ? (
                <Skeleton
                  variant="rect"
                  width="50%"
                  height="36px"
                  style={{ marginBottom: "10px" }}
                />
              ) : (
                <>
                  <Button
                    startIcon={
                      entry.invoiceDetails?.status !== "draft" ? (
                        <VisibilityIcon />
                      ) : (
                        <EditIcon />
                      )
                    }
                    variant="outlined"
                    color="primary"
                    disabled={isLoading}
                    className={classes.iconButton}
                    onClick={() => handleTitleClick(entry.invoiceDetails)}
                  >
                    {entry.invoiceDetails?.status !== "draft" ? "View" : "Edit"}
                  </Button>
                  <Button
                    startIcon={<SendIcon />}
                    variant="outlined"
                    color="primary"
                    className={classes.iconButton}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        showSendInvoicePopup: true,
                      }));
                    }}
                    disabled={
                      entry.invoiceDetails?.status === "pending-approval" ||
                      entry.invoiceDetails?.status === "draft" ||
                      state.selectedInvoices.length
                    }
                  >
                    Send
                  </Button>
                  <Button
                    startIcon={<DescriptionIcon />}
                    variant="outlined"
                    color="primary"
                    className={classes.iconButton}
                    onClick={handleClick}
                    disabled={state.selectedInvoices.length > 0}
                  >
                    PDF/Print
                  </Button>
                  <Menu
                    anchorEl={state.anchorE2}
                    open={Boolean(state.anchorE2)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      onClick={() => handleGeneratePDF(entry, false, true)}
                    >
                      <ListItemIcon>
                        <PrintIcon />
                      </ListItemIcon>
                      Print
                    </MenuItem>
                    <MenuItem onClick={() => handleGeneratePDF(entry, false)}>
                      <ListItemIcon>
                        <PdfIcon />
                      </ListItemIcon>
                      PDF
                    </MenuItem>
                  </Menu>
                  <Button
                    startIcon={<AccountBalanceIcon />}
                    color="primary"
                    variant="outlined"
                    className={classes.iconButton}
                    onClick={() => {
                      if (!entry.invoiceDetails?.is_sent_to_customer) {
                        return toast.error(
                          "Kindly send the invoice first or mark it as sent."
                        );
                      }
                      setState((prevState) => ({
                        ...prevState,
                        showPaymentPopup: true,
                      }));
                    }}
                    disabled={
                      entry.invoiceDetails?.status === "pending-approval" ||
                      entry.invoiceDetails?.status === "draft" ||
                      entry.invoiceDetails?.status === "paid" ||
                      state.selectedInvoices.length > 0
                    }
                  >
                    Record Payment
                  </Button>
                  <Button
                    startIcon={<PaymentIcon />}
                    color="primary"
                    variant="outlined"
                    className={classes.iconButton}
                    onClick={() => handlePaymentHistoryDialog(true)}
                    disabled={
                      entry.invoiceDetails?.status === "pending-approval" ||
                      entry.invoiceDetails?.status === "draft" ||
                      state.selectedInvoices.length > 0
                    }
                  >
                    View Payments
                  </Button>
                </>
              )}
            </div>
            {isInvoiceLoading ? (
              <Skeleton variant="rect" width="100%" height="50px" />
            ) : entry.invoiceDetails?.status !== "pending-approval" &&
              entry.invoiceDetails?.status !== "draft" ? (
              <div className={classes.root}>
                <div className="d-flex f-align-center">
                  <DraftsIcon className="m-3" color="primary" />
                  <div>
                    <Typography variant="h6">Send the invoice</Typography>
                    <Typography variant="body1">
                      Invoice has been approved. You can email it to your
                      customer or mark it as sent.
                    </Typography>
                  </div>
                </div>
                <div className="d-flex f-align-center">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.iconButton}
                    disabled={state.selectedInvoices.length}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        showSendInvoicePopup: true,
                      }));
                    }}
                  >
                    Send Invoice
                  </Button>
                  {!!entry.invoiceDetails?.is_sent_to_customer ? (
                    <Button
                      startIcon={<MailIcon />}
                      variant="outlined"
                      color="primary"
                      disabled={state.selectedInvoices.length > 0}
                      className={classes.iconButton}
                      onClick={() => {
                        getInvoiceEmailHistory(selectedInvoice?.id);
                        setState((prevState) => ({
                          ...prevState,
                          sentEmailHistory: true,
                        }));
                      }}
                    >
                      Email History
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.iconButton}
                      disabled={
                        isMarkAsSentLoading || state.selectedInvoices.length > 0
                      }
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isInvoiceSent: true,
                        }))
                      }
                    >
                      Mark as Sent
                      {isMarkAsSentLoading && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.root}>
                {isRoleAA ? (
                  <Typography variant="h6" color="error" className="m-2">
                    {entry.invoiceDetails?.status === "draft"
                      ? "Please finalize the invoice before sending it for approval by clicking the edit button."
                      : " Approval for this invoice is pending."}
                  </Typography>
                ) : (
                  <>
                    <div className="d-flex f-align-center">
                      <ThumbsUpDownIcon
                        className="m-3"
                        color="primary"
                        style={{ fontSize: 28 }}
                      />
                      <div>
                        <Typography variant="h6">Approve/Reject</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              entry.invoiceDetails?.status === "draft"
                                ? "#f44336"
                                : null,
                          }}
                        >
                          {entry.invoiceDetails?.status === "draft"
                            ? "Please finalize the invoice before approving or rejecting it by clicking the edit button."
                            : "Kindly Please proceed with either approving or rejecting the invoice."}
                        </Typography>
                      </div>
                    </div>
                    <div className="d-flex f-align-center">
                      <Button
                        variant="outlined"
                        style={{
                          color:
                            entry.invoiceDetails?.status !==
                              "pending-approval" ||
                            state.selectedInvoices.length > 0
                              ? null
                              : "#f44336",
                          borderColor:
                            entry.invoiceDetails?.status !==
                              "pending-approval" ||
                            state.selectedInvoices.length > 0
                              ? null
                              : "#f44336",
                        }}
                        className={classes.iconButton}
                        onClick={() =>
                          handleApprovalDialog(
                            selectedInvoice?.id,
                            "reject",
                            true
                          )
                        }
                        disabled={
                          entry.invoiceDetails?.status !== "pending-approval" ||
                          state.selectedInvoices.length > 0
                        }
                      >
                        Reject
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.iconButton}
                        onClick={() =>
                          handleApprovalDialog(
                            selectedInvoice?.id,
                            "approve",
                            true
                          )
                        }
                        disabled={
                          entry.invoiceDetails?.status !== "pending-approval" ||
                          state.selectedInvoices.length > 0
                        }
                      >
                        Approve
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
            <div
              className={clsx("m-4 d-flex f-justify-center", classes.invoice)}
              style={{ position: "relative" }}
            >
              {isInvoiceLoading ? (
                <LoadingOverlay message="Processing, please wait..." />
              ) : (
                <iframe
                  // src={`${pdfUrl}#toolbar=0`}
                  src={pdfUrl}
                  title="Invoice PDF"
                  className={classes.iframe}
                ></iframe>
              )}
            </div>
          </>
        ) : (
          <div
            className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8"
            style={{ height: "100%" }}
          >
            <Typography variant="h6">
              Please Select the Invoice to view.
            </Typography>
          </div>
        )}
      </Paper>
      <GenerateInvoiceDialog
        open={state.open}
        handleClose={handleDialogClose}
        history={history}
      />
      {state.showSendInvoicePopup && (
        <ActionDialog
          classes={{
            confirm:
              state.isInvoiceSending || isConfirmDisabled
                ? "black"
                : "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.showSendInvoicePopup}
          contentText={
            <div style={{ overflow: "hidden" }} className="mr-4">
              <Typography
                variant="h5"
                className="text-bold mb-4"
                color="primary"
              >
                {`${
                  !!state.isPreviousViewInvoice
                    ? "Sent"
                    : state.sentEmailHistory
                    ? "Resend"
                    : "Send"
                } ${
                  !state.selectedInvoices.length
                    ? `Invoice #${selectedInvoice.serial_no}`
                    : state.selectedInvoices.length === 1
                    ? `Invoice #${
                        rows.filter((obj) =>
                          state.selectedInvoices.includes(obj.id)
                        )[0]?.serial_no
                      }`
                    : "Invoices"
                } `}
              </Typography>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  From
                </Typography>
                <TextField
                  label="From"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="from"
                  disabled
                  value="noreply@pdxdelivers.com"
                  className="mt-3"
                  helperText=" "
                />
              </div>
              {!state.selectedInvoices.length ||
              state.selectedInvoices.length === 1 ? (
                <>
                  {!state.isPreviousViewInvoice && (
                    <div className="d-flex">
                      <Typography
                        variant="h6"
                        className="text-bold mr-8 mt-4"
                        style={{ width: "8rem" }}
                      >
                        To
                      </Typography>
                      <div
                        className="d-flex"
                        style={{ gap: "6px", width: "100%" }}
                      >
                        <TextField
                          label="Add Email ID(s)"
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="email"
                          disabled={
                            !!state.isPreviousViewInvoice ||
                            state.isInvoiceSending
                          }
                          value={state.email}
                          required
                          className="mt-3"
                          onChange={handleFieldChange}
                          error={!!state.errors.email?.trim()}
                          helperText={state.errors.email}
                        />

                        <div>
                          <Tooltip
                            title="Add Email Temporarily"
                            placement="top-end"
                          >
                            <Button
                              type="submit"
                              onClick={() => {
                                if (
                                  validate() === " " &&
                                  state.errors?.email === " "
                                ) {
                                  handleAddEmail("temporaryEmail");
                                }
                              }}
                              variant="contained"
                              color="primary"
                              disabled={isConfirmDisabled || !state.email}
                              className={classes.buttonStyle1}
                            >
                              <AddIcon />
                            </Button>
                          </Tooltip>
                        </div>
                        <div>
                          <Tooltip
                            title="Add Email To Customer"
                            placement="top-end"
                          >
                            <Button
                              type="submit"
                              onClick={handleAddPermEmailDialog}
                              variant="contained"
                              color="primary"
                              disabled={isConfirmDisabled || !state.email}
                              className={classes.buttonStyle1}
                            >
                              <PostAddIcon />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  )}
                  {(state.temporaryEmail.length > 0 ||
                    state.permanentEmail.length > 0) && (
                    <div className="d-flex mb-4">
                      {!state.isPreviousViewInvoice && (
                        <div
                          variant="h6"
                          className="mr-8 mt-4"
                          style={{ width: "8rem" }}
                        ></div>
                      )}
                      {!!state.isPreviousViewInvoice && (
                        <Typography
                          variant="h6"
                          className="text-bold mr-8 mt-4"
                          style={{ width: "8rem" }}
                        >
                          To
                        </Typography>
                      )}
                      <div
                        className="d-flex f-justify-between flex-wrap"
                        style={{ width: "100%", gap: 20 }}
                      >
                        {state.permanentEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Customer Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {emailLoading ? (
                                      <ListItem>
                                        <Skeleton
                                          className="mr-2"
                                          variant="circle"
                                          width={15}
                                          height={15}
                                        />
                                        <ListItemText
                                          primary={
                                            <Skeleton
                                              variant="rect"
                                              width="50%"
                                              height="20px"
                                            />
                                          }
                                        />
                                      </ListItem>
                                    ) : (
                                      state.permanentEmail.map((item) => (
                                        <ListItem key={item}>
                                          <div className="mr-2">
                                            <FiberManualRecordIcon
                                              style={{ fontSize: 10 }}
                                            />
                                          </div>
                                          <ListItemText primary={item} />
                                          {!state.isPreviousViewInvoice && (
                                            <ListItemSecondaryAction>
                                              <IconButton
                                                disabled={
                                                  state.isInvoiceSending
                                                }
                                                onClick={() =>
                                                  handleDeleteEmail(
                                                    item,
                                                    "permanentEmail"
                                                  )
                                                }
                                                edge="end"
                                                aria-label="delete"
                                              >
                                                <CancelIcon fontSize="small" />
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          )}
                                        </ListItem>
                                      ))
                                    )}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                        {state.temporaryEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Temporary Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {state.temporaryEmail.map((item) => (
                                      <ListItem key={item}>
                                        <ListItemText primary={item} />
                                        {!state.isPreviousViewInvoice && (
                                          <ListItemSecondaryAction>
                                            <IconButton
                                              disabled={state.isInvoiceSending}
                                              onClick={() =>
                                                handleDeleteEmail(
                                                  item,
                                                  "temporaryEmail"
                                                )
                                              }
                                              edge="end"
                                              aria-label="delete"
                                            >
                                              <CancelIcon fontSize="small" />
                                            </IconButton>
                                          </ListItemSecondaryAction>
                                        )}
                                      </ListItem>
                                    ))}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex">
                  <Typography
                    variant="h6"
                    className="text-bold mr-8 mt-4"
                    style={{ width: "8rem" }}
                  >
                    To
                  </Typography>
                  <TextField
                    label="Add Email ID(s)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="email"
                    value={state.email}
                    disabled={state.isInvoiceSending}
                    required
                    className="mt-3"
                    onChange={handleFieldChange}
                    error={!!state.errors.email?.trim()}
                    helperText={state.errors.email}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <div className={classes.chipsContainer}>
                          {state.temporaryEmail.map((email) => (
                            <Chip
                              key={email}
                              label={email}
                              disabled={state.isInvoiceSending}
                              onDelete={() =>
                                handleDeleteEmail(email, "temporaryEmail")
                              }
                              className={classes.emailChip}
                            />
                          ))}
                        </div>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Subject
                </Typography>
                <TextField
                  label="Add Subject"
                  fullWidth
                  disabled={
                    !!state.isPreviousViewInvoice || state.isInvoiceSending
                  }
                  variant="outlined"
                  size="small"
                  name="emailSubject"
                  value={state.emailSubject}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailSubject?.trim()}
                  helperText={state.errors.emailSubject}
                />
              </div>
              <div
                className="d-flex f-align-center mb-4"
                style={{ marginLeft: 130 }}
              >
                {!!state.isPreviousViewInvoice && (
                  <>
                    <AttachFileIcon fontSize="small" className="mr-1" />
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-bold"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "black",
                      }}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          pdfViewerDialog: true,
                        }))
                      }
                    >
                      Invoice Preview
                    </Typography>
                  </>
                )}
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Email Body
                </Typography>
                <TextField
                  label="Email Body"
                  fullWidth
                  variant="outlined"
                  disabled={
                    !!state.isPreviousViewInvoice || state.isInvoiceSending
                  }
                  minRows={10}
                  maxRows={10}
                  multiline
                  size="small"
                  name="emailBody"
                  value={state.emailBody}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailBody?.trim()}
                  helperText={state.errors.emailBody}
                />
              </div>
              {state.isPreviousViewInvoice &&
                state.attachmentFiles &&
                state.attachmentFiles.length !== 0 && (
                  <div className="d-flex">
                    <Typography
                      variant="h6"
                      className="text-bold mr-8 mt-3"
                      style={{ width: "8rem" }}
                    >
                      Attachments
                    </Typography>
                    <List style={{ padding: 0, width: "90%" }} className="mt-3">
                      {state.attachmentFiles.map((attachment, index) => (
                        <ListItem
                          key={index}
                          className="mb-2"
                          style={{
                            padding: 3,
                            backgroundColor: "#f5f5f5",
                            borderRadius: "4px",
                            border: "1px solid transparent",
                          }}
                        >
                          <Link
                            href={attachment?.pdf_url}
                            download={attachment?.fileName}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-2"
                          >
                            <Typography
                              style={{
                                color: "#15c",
                                fontWeight: 400,
                                fontSize: 14,
                                fontStretch: "expanded",
                                textDecoration: "underline",
                              }}
                            >
                              {attachment?.fileName}
                            </Typography>
                          </Link>

                          <Typography
                            style={{
                              color: "black",
                              fontSize: 14,
                            }}
                          >
                            {`(${getReadableFileSizeString(attachment?.size)})`}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
              {!state.isPreviousViewInvoice && (
                <UploadFiles
                  uploadedAttachment={uploadedAttachment}
                  isLoading={state.isInvoiceSending}
                  sentHistory={state.sentEmailHistory}
                />
              )}
            </div>
          }
          onConfirm={() => {
            if (!!state.isPreviousViewInvoice) {
              return setState((prevState) => ({
                ...prevState,
                showSendInvoicePopup: false,
                isPreviousViewInvoice: null,
              }));
            }
            handleSubmit(
              !state.selectedInvoices.length
                ? entry.invoiceDetails?.id
                : state.selectedInvoices,
              state.temporaryEmail,
              state.permanentEmail,
              state.emailSubject,
              state.emailBody,
              state.uploadedAttachment,
              state.selectedInvoices.length > 0 &&
                state.selectedInvoices.includes(selectedInvoice?.id)
                ? selectedInvoice?.id
                : !state.selectedInvoices.length
            );
          }}
          hasClose={!!state.isPreviousViewInvoice ? false : true}
          onCancel={() => {
            isConfirmDisabled = false;
            const selectedCompanies =
              state.selectedInvoices.length > 0
                ? [
                    ...new Set(
                      rows
                        .filter((obj) =>
                          state.selectedInvoices.includes(obj.id)
                        )
                        .map((invoice) => invoice.pdx_company)
                    ),
                  ].join(", ")
                : null;

            const displayCompanyName = () => {
              if (
                selectedCompanies &&
                selectedCompanies.split(",").length > 1
              ) {
                return "Parts Distribution Xpress";
              } else if (state.selectedInvoices.length === 0) {
                return selectedInvoice?.pdx_company;
              } else {
                return selectedCompanies;
              }
            };
            setState((prevState) => ({
              ...prevState,
              showSendInvoicePopup: false,
              isPreviousViewInvoice: null,
              email: defaultState.email,
              temporaryEmail: defaultState.temporaryEmail,
              permanentEmail: defaultState.permanentEmail,
              emailSubject: `${
                !state.selectedInvoices.length
                  ? `Invoice #${selectedInvoice?.serial_no || ""}`
                  : state.selectedInvoices.length === 1
                  ? `Invoice #${
                      rows.filter((obj) =>
                        state.selectedInvoices.includes(obj.id)
                      )[0]?.serial_no
                    }`
                  : "Invoices"
              } from ${
                selectedCompanies || selectedInvoice?.pdx_company || "PDX"
              }`,
              emailBody: `Dear ${
                !state.selectedInvoices.length
                  ? selectedInvoice?.customer_branch?.customer?.name || ""
                  : state.selectedInvoices.length === 1
                  ? rows.filter((obj) =>
                      state.selectedInvoices.includes(obj.id)
                    )[0]?.customer_branch?.customer?.name
                  : "Customers"
              },\n\nYour ${
                state.selectedInvoices.length > 1
                  ? "invoices are"
                  : "invoice is"
              } attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
              errors: {
                email: " ",
                from: " ",
                emailSubject: " ",
                emailBody: " ",
              },
            }));
          }}
          isConfirmDisabled={
            state.isInvoiceSending ||
            isConfirmDisabled ||
            !!state.email ||
            (state.temporaryEmail.length === 0 &&
              state.permanentEmail.length === 0) ||
            (validate() && validate() !== " ") ||
            (state.selectedInvoices.length &&
              state.selectedInvoices.length !== 1 &&
              state.temporaryEmail.length === 0)
          }
          showPositiveActionLabel={!!state.isPreviousViewInvoice ? false : true}
          positiveActionLabel={
            <>
              {`${state.sentEmailHistory ? "RESEND" : "SEND"} INVOICE`}
              {state.isInvoiceSending && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel={
            !!state.isPreviousViewInvoice ? "Close" : "Cancel"
          }
        />
      )}
      {state.isInvoiceDeleteDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.deletePaperSizeDialog,
          }}
          open={state.isInvoiceDeleteDialog}
          contentText={`Are you sure you want to delete invoice(s)?`}
          onConfirm={() => handleDeleteInvoices(state.selectedInvoices)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isInvoiceDeleteDialog: false,
            }))
          }
          isConfirmDisabled={state.isInvoiceDeleteLoading}
          positiveActionLabel={
            <>
              Delete
              {state.isInvoiceDeleteLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.isInvoiceSent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={state.isInvoiceSent}
          contentText={`Are you sure you want to mark this invoice as sent?`}
          onConfirm={async () => {
            setState((prevState) => ({
              ...prevState,
              isInvoiceSent: false,
            }));

            await handleMarkInvoiceSent(
              !state.selectedInvoices.length
                ? selectedInvoice?.id
                : state.selectedInvoices,
              state.selectedInvoices.length > 0 &&
                state.selectedInvoices.includes(selectedInvoice?.id)
                ? selectedInvoice?.id
                : !state.selectedInvoices.length
            );

            setState((prevState) => ({
              ...prevState,
              selectedInvoices: defaultState.selectedInvoices,
            }));
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isInvoiceSent: false,
            }))
          }
          isConfirmDisabled={isMarkAsSentLoading}
          positiveActionLabel="Send"
          negativeActionLabel="Cancel"
        />
      )}
      {state.showAddPermanentEmailDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={!!state.showAddPermanentEmailDialog}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                {`Would you like to add this email address `}
                <span style={{ color: "Black" }}>{state.email}</span>
                {` to customer emails?`}
              </Typography>
            </>
          }
          onConfirm={() =>
            addPermananentEmaiil(
              entry?.invoiceDetails?.customer_branch,
              state.permanentEmail
            )
          }
          onCancel={() => {
            setState((prevState) => {
              return {
                ...prevState,
                showAddPermanentEmailDialog: false,
                permanentEmail: emailAddresses,
              };
            });
          }}
          isConfirmDisabled={state.isPermEmailLoading}
          positiveActionLabel={
            <>
              Add To Customer Email
              {state.isPermEmailLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>CANCEL</>}
        />
      )}
      {state.sentEmailHistory && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={state.sentEmailHistory}
          contentText={
            <>
              <Typography
                variant="h6"
                color="primary"
                className="mb-4 text-bold"
              >
                Invoice Email History
              </Typography>
              <Grid
                columns={columnConfig}
                rows={state.emailHistory}
                actionBarConfig={null}
                isLoading={state.isInvoiceEmailHistoryLoading}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          hasClose={false}
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              sentEmailHistory: false,
              temporaryEmail: defaultState.temporaryEmail,
            }));
          }}
          isConfirmDisabled={isMarkAsSentLoading}
          positiveActionLabel="Close"
        />
      )}
      <RecordPayment
        handleClose={handlePaymentClose}
        open={state.showPaymentPopup}
        customerList={customerList}
        entry={entry}
        handleFetch={handleFetch}
        getInvoice={getInvoice}
      />
      {state.pdfViewerDialog && (
        <Dialog
          onClose={handleViewerClose}
          aria-labelledby="customized-dialog-title"
          open={state.pdfViewerDialog}
          classes={{ paper: classes.paperIframe }}
        >
          <DialogContent dividers>
            <iframe
              type="application/pdf"
              src={state.isPreviousViewInvoice}
              title="Invoice PDF"
              width="1150px"
              height="700px"
              style={{ border: "none" }}
              // onLoad={handleIframeLoad}
            ></iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {state.paymentHistoryPopUp && (
        <PaymentGrid
          open={state.paymentHistoryPopUp}
          handleClose={handlePaymentHistoryDialog}
          invoiceNumber={selectedInvoice && selectedInvoice?.serial_no}
          handleFetch={handleFetch}
          getInvoice={getInvoice}
          customerList={customerList}
        />
      )}
    </div>
  );
};

export default InvoiceSummary;
