import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 42,
  },
  row: {
    border: `2px solid ${theme.palette.divider}`,
  },
  container: {
    minHeight: 200,
    maxHeight: 350,
    overflow: "auto",
  },
  tableContainer: {
    borderRadius: " 0.3rem",
    border: "1px solid #c4c4c4 !important",
  },
  customerMainSeparatorBorder: {
    boxShadow: " 4px -4px 14px #DDDDDD inset",
  },
  tableHeadPadding: { padding: "2px 16px 2px 0px !important" },
  tablePadding: {
    padding: "2px 16px 2px 0px !important",
  },
  tableCellWidth: {
    minWidth: "92px",
    maxWidth: "92px",
  },
  tableCell: {
    minWidth: 100,
    maxWidth: 200,
    "& .MuiTypography-root": {
      fontSize: "1rem",
    },
  },
}));

export default useStyles;
