import React from "react";
import { CircularProgress, Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getDateString, getFormattedTime, getUTCDateString } from "utils";
import { noop } from "shared";
import { modeOfPaymentList } from "modules/shared/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    margin: "auto",
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  label: {
    fontWeight: "bold",
  },
  value: {
    textAlign: "right",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
}));

const CustomerSummary = ({
  data,
  getInvoice = noop,
  isInvoiceLoading,
  stateList = [],
}) => {
  const classes = useStyles();

  const leftColumnData = [
    {
      label: "Customer Name",
      value: data?.customer_name || "-",
      tooltip: data?.customer_name || " ",
    },
    {
      label: "PDX Company",
      value: data?.customer_branch?.pdxCompany?.value || "-",
      tooltip: data?.customer_branch?.pdxCompany?.value || " ",
    },
    {
      label: "Location",
      value: data?.customer_branch?.location || "-",
      tooltip: data?.customer_branch?.location || " ",
    },
    {
      label: "State",
      value:
        (stateList &&
          stateList.find(
            (state) => state.abbreviation === data?.customer_branch?.state
          )?.name) ||
        "-",
      tooltip:
        (stateList &&
          stateList.find(
            (state) => state.abbreviation === data?.customer_branch?.state
          )?.name) ||
        " ",
    },
    {
      label: "Status",
      value:
        (data?.status && data?.status === "reverted"
          ? "Cancelled"
          : data?.status.charAt(0).toUpperCase() + data?.status.slice(1)) ||
        "-",
      tooltip:
        (data?.status && data?.status === "reverted"
          ? "Cancelled"
          : data?.status.charAt(0).toUpperCase() + data?.status.slice(1)) ||
        " ",
    },
    {
      label: "Created By",
      value: `${data?.createdBy?.first_name || ""} ${
        data?.createdBy?.last_name || ""
      }`,
      tooltip: `${data?.createdBy?.first_name || ""} ${
        data?.createdBy?.last_name || ""
      }`,
    },
  ];

  const getModeOfPaymentLabel = (value) => {
    const payment = modeOfPaymentList.find((item) => item.value === value);
    return payment ? payment.label : "-";
  };

  const rightColumnData = [
    {
      label: "Invoice No.",
      value: data?.invoice_number || "-",
      tooltip: data?.invoice_number || " ",
    },
    {
      label: "Date of Payment",
      value: getUTCDateString(data?.date) || "-",
      tooltip: getUTCDateString(data?.date) || " ",
    },
    {
      label: "Mode of Payment",
      value: getModeOfPaymentLabel(data?.mode),
      tooltip: getModeOfPaymentLabel(data?.mode),
    },
    {
      label: "Amount",
      value: `$${data?.amount || "0.00"}`,
      tooltip: `$${data?.amount || "0.00"}`,
    },
    {
      label: "Credit Amount",
      value: `$${data?.credit_amt || "0.00"}`,
      tooltip: `$${data?.credit_amt || "0.00"}`,
    },

    {
      label: "Created At",
      value: `${getDateString(data.created_at)} ${getFormattedTime(
        new Date(data.created_at)
      )}`,
      tooltip: `${getDateString(data.created_at)} ${getFormattedTime(
        new Date(data.created_at)
      )}`,
    },
  ];

  if (data?.updatedBy && data?.updated_at) {
    rightColumnData.push({
      label: "Last Updated At",
      value: `${getDateString(data.updated_at)} ${getFormattedTime(
        new Date(data.updated_at)
      )}`,
      tooltip: `${getDateString(data.updated_at)} ${getFormattedTime(
        new Date(data.updated_at)
      )}`,
    });
  }

  if (data?.updatedBy) {
    leftColumnData.push({
      label: "Last Updated By",
      value: `${data?.updatedBy?.first_name || ""} ${
        data?.updatedBy?.last_name || ""
      }`,
      tooltip: `${data?.updatedBy?.first_name || ""} ${
        data?.updatedBy?.last_name || ""
      }`,
    });
  }

  if (data?.status === "reverted" && data?.reverted_at && data?.revertedBy) {
    rightColumnData.push({
      label: "Cancelled At",
      value: `${getDateString(data.reverted_at)} ${getFormattedTime(
        new Date(data.reverted_at)
      )}`,
      tooltip: `${getDateString(data.reverted_at)} ${getFormattedTime(
        new Date(data.reverted_at)
      )}`,
    });
  }

  if (data?.status === "reverted" && data?.revertedBy) {
    leftColumnData.push({
      label: "Cancelled By",
      value: `${data?.revertedBy?.first_name || ""} ${
        data?.revertedBy?.last_name || ""
      }`,
      tooltip: `${data?.revertedBy?.first_name || ""} ${
        data?.revertedBy?.last_name || ""
      }`,
    });
  }
  leftColumnData.push({
    label: "Reference Number",
    value: `${data?.reference_number || "-"}`,
    tooltip: `${data?.reference_number || " "}`,
  });

  if (data?.payment_notes) {
    rightColumnData.push({
      label: "Payment Notes",
      // value: `${data?.payment_notes || "-"}`,
      value:
        data.payment_notes.length > 10
          ? `${data.payment_notes.slice(0, 10)}...`
          : `${data.payment_notes}` || "-",
      tooltip: `${data?.payment_notes || " "}`,
    });
  }

  return (
    <div className={classes.paper}>
      <Typography variant="h6" className={classes.title} color="primary">
        Summary:
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          {leftColumnData.map(({ label, value, tooltip }) => (
            <div className={classes.row} key={label}>
              <Typography className={classes.label}>{label}:</Typography>
              <Tooltip title={tooltip} placement="top-start">
                <Typography className={classes.value}>{value}</Typography>
              </Tooltip>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {rightColumnData.map(({ label, value, tooltip }) => (
            <div className={classes.row} key={label}>
              <Typography className={classes.label}>{label}:</Typography>
              <div className="d-flex f-align-center">
                {label === "Invoice No." && isInvoiceLoading && (
                  <CircularProgress size={14} style={{ marginRight: 8 }} />
                )}
                <Tooltip title={tooltip} placement="top-start">
                  <Typography
                    className={classes.value}
                    style={{
                      color: label === "Invoice No." && "#1e88e5",
                      cursor: label === "Invoice No." && "pointer",
                      textDecoration: label === "Invoice No." && "underline",
                    }}
                    onClick={() =>
                      label === "Invoice No." && getInvoice(data, true)
                    }
                  >
                    {value}
                  </Typography>
                </Tooltip>
              </div>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerSummary;
