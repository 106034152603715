/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Typography,
  TextField,
  Tooltip,
  Chip,
  IconButton,
  CircularProgress,
  Box,
  Drawer,
  Divider,
} from "@material-ui/core";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  pdxWestCoordinates,
  pdxSouthCoordinates,
  pdxNorthCoordinates,
} from "./coordinates";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStyles from "./style";
import clsx from "clsx";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Polyline,
} from "google-maps-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { Autocomplete } from "@material-ui/lab";
import Checkbox from "@material-ui/core/Checkbox";
import customerLocationIcon from "./../../../assets/images/1189458.png";
import afterMarket from "./../../../assets/images/Aftermarker.svg";
import oem from "./../../../assets/images/OEM.svg";
import tireDistributor from "./../../../assets/images/Tire-Distributor.svg";
import customerLocationPinIcon from "./../../../assets/images/pin.png";
import kamLocation from "./../../../assets/images/kamLocation.png";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import kamLocationPinPoint from "./../../../assets/images/kamLocationPinPoint.png";
import ICLocation from "./../../../assets/images/IcAddress.png";
import ICLocationPinPoint from "./../../../assets/images/ICAddressPin.png";
import Service from "./../service";
import { toast } from "react-toastify";
import { ActionDialog, Dialog, PAGE_KEYS } from "shared";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoWindowEx from "./infoWindow";
import {
  getPageConfig,
  queryStringBuilderNew,
  setPageConfig,
  updatePageConfig,
} from "utils";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { SharedService } from "modules/shared";
import NoRecords from "assets/images/norecord.svg";
import MarkerClusterer from "@google/markerclustererplus";
import aftermarketOrange from "./../../../assets/images/map-markers/aftermarketOrange.svg";
import aftermarketBlue from "./../../../assets/images/map-markers/aftermarketBlue.svg";
import aftermarketPurple from "./../../../assets/images/map-markers/aftermarketPurple.svg";
import aftermarketYellow from "./../../../assets/images/map-markers/aftermarketYellow.svg";
import aftermarketGreen from "./../../../assets/images/map-markers/aftermarketGreen.svg";
import oemYellow from "./../../../assets/images/map-markers/oemYellow.svg";
import oemGreen from "./../../../assets/images/map-markers/oemGreen.svg";
import oemBlue from "./../../../assets/images/map-markers/oemBlue.svg";
import oemPurple from "./../../../assets/images/map-markers/oemPurple.svg";
import oemOrange from "./../../../assets/images/map-markers/oemOrange.svg";
import TireDistributorOrange from "./../../../assets/images/map-markers/Tire-DistributorOrange.svg";
import TireDistributorBlue from "./../../../assets/images/map-markers/Tire-DistributorBlue.svg";
import TireDistributorPurple from "./../../../assets/images/map-markers/Tire-DistributorPurple.svg";
import TireDistributorYellow from "./../../../assets/images/map-markers/Tire-DistributorYellow.svg";
import TireDistributorGreen from "./../../../assets/images/map-markers/Tire-DistributorGreen.svg";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

let allCustomerLocation = [];
let allCustomerBranchLocation = [];
let allICLocation = [];
let kamICLocation = [];
let allKamLocation = [];
let customerDropdownList = [];
let goButtonIsClicked = false;
let branchDropdownList = [];
let kamDropdownList = [];
let ICDropdownList = [];
let hasData = true;

const initialRegionList = ["PDX", "PDX-NORTH", "PDX-SOUTH", "PDX-WEST"];

const defaultState = {
  showingInfoWindow: false,
  stateList: [],
  regionList: initialRegionList,
  kamStateList: [],
  kamLocationList: [],
  locationList: [],
  state: [],
  region: [],
  location: [],
  industryType: [],
  selectedKamPlace: {},
  selectedICPlace: {},
  isStateFilterApplied: false,
  isRadiusFilterApplied: false,
  showClusters: true,
  activeKamMarker: {},
  activeICMarker: {},
  selectedKamId: null,
  showingKamInfoWindow: false,
  showingICInfoWindow: false,
  customerTypeList: [],
  customer: {
    lookup: "",
  },
  isLoading: false,
  openKamNoAddressDialog: false,
  filteredCustomer: [],
  isFilterApplied: false,
  seeKamLocation: false,
  isDrawerOpen: true,
  filteredBranch: [],
  filteredKam: [],
  allLocation: [],
  allFilteredLocation: [],
  isKamLoading: false,
  openICNoAddressDialog: false,
  allkamFilteredLocation: [],
  allICFilteredLocation: [],
  kamFilterType: "state",
  customer_id: null,
  kam_id: null,
  customer_branch_id: null,
  zoomLevel: 5,
  kamZoomLevel: 4,
  icZoomLevel: 4,
  mainMapZoomLevel: 5,
  kamLocation: [],
  allBranchLocation: [],
  openKamAddressDialog: false,
  openICAddressDialog: false,
  showICLocations: false,
  customerLocation: [],
  isCustomerSelected: false,
  isCustomerBranchSelected: false,
  customerAddressTypeList: [],
  isViewingByStateCity: false,
  kamAddressByStateCity: [],
  isAutocompleteFocused: false,
  isBranchAutocompleteFocused: false,
  nearKamInstate: [],
  isgoButtonIsClicked: true,
  isKamGoButtonIsClicked: true,
  nearKamInCity: [],
  branchLocation: [],
  allFilteredBranchLocation: [],
  customerList: [],
  activeMarker: {},
  selectedPlace: {},
  showBranchLocations: true,
  showCustomerLocations: true,
  showKamLocations: false,
  selectedCustomer: [],
  selectedIC: [],
  icLocation: [],
  icKAMLocation: [],
  selectedBranchCustomer: [],
  stateCityFilterType: "stateCity",
  selectedPDXCompany: [],
  map: null,
  selectedKam: [],
  filters: {
    state: [],
    region: [],
    city: [],
    kamState: [],
    kamCity: [],
    industryType: [],
  },
  selectedAddress: null,
  customerAllLocation: null,
  radius: "",
  kamAddressByRadius: [],
  isViewingByRadius: false,
  customerBranchAllLocation: null,
  mapZoomEnabled: false,
  kamMapZoomEnabled: false,
  kamICMapZoomEnabled: false,
  searchYourLocation: false,
  showCustomerMarkers: false,
  showBranchMarkers: false,
  isFetchingList: {
    customerLocation: false,
    customerBranchLocation: false,
    customerAddressType: false,
  },
};

const CustomerLocation = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.LOCATION);
  let isGoButtonIsClicked =
    (pageConfig && pageConfig?.isGoButtonIsClicked) || false;
  const classes = useStyles();
  const [state, setState] = useState({
    ...defaultState,
    showICLocations:
      (pageConfig && pageConfig?.showICLocationsChecked) ||
      defaultState.showICLocations,
    stateCityFilterType:
      (pageConfig && pageConfig?.stateCityFilterType) ||
      defaultState.stateCityFilterType,
    showKamLocations:
      (pageConfig && pageConfig?.showKamLocationsChecked) ||
      defaultState.showKamLocations,
    showBranchLocations:
      pageConfig?.showBranchLocationsChecked === undefined ||
      pageConfig?.showBranchLocationsChecked === null
        ? defaultState.showBranchLocations
        : pageConfig?.showBranchLocationsChecked,
    state: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.stateFilter?.value) || defaultState.state
      : defaultState.state,
    region: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.regionFilter?.value) || defaultState.region
      : defaultState.region,
    location: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.cityFilter?.value) || defaultState.location
      : defaultState.location,
    industryType: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.industryTypeFilter?.value) ||
        defaultState.industryType
      : defaultState.industryType,
    selectedCustomer: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.customerFilter) ||
        defaultState.selectedCustomer
      : defaultState.selectedCustomer,
    selectedBranchCustomer: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.customerBranchFilter) ||
        defaultState.selectedBranchCustomer
      : defaultState.selectedBranchCustomer,
    selectedPDXCompany: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.PDXCompanyFilter) ||
        defaultState.selectedPDXCompany
      : defaultState.selectedPDXCompany,
    selectedKam: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.KAMFilter) || defaultState.selectedKam
      : defaultState.selectedKam,
    selectedIC: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.ICFilter) || defaultState.selectedIC
      : defaultState.selectedIC,
    filters: {
      ...defaultState.filters,
      state: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.stateFilter?.state) || []
        : [],
      region: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.regionFilter?.region) || []
        : [],
      city: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.cityFilter?.city) || []
        : [],
      industryType: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.industryTypeFilter?.industryType) || []
        : [],
    },
  });

  const [bounds, setBounds] = useState(null);
  const mapRef = useRef(null);
  const clustererRef = useRef(null);
  const kamClusterRef = useRef(null);
  const icKamClusterRef = useRef(null);
  const kamMapRef = useRef(null);
  const icMapRef = useRef(null);

  var markerIcon = {
    url: "marker_icon.png", // Replace with the path to your custom marker image
    size: new window.google.maps.Size(0, 0), // Set the size of the marker icon to zero
    labelOrigin: new window.google.maps.Point(0, 0), // Set the label origin to the top-left corner
  };

  const loadMarkerClusterer = useMemo(() => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src =
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }, []);

  const onMarkerClick = (props, marker, e) => {
    console.log(marker, "marker");
    setState((prevState) => ({
      ...prevState,
      selectedPlace: props,
      mapZoomEnabled: true,
      activeMarker: marker,
      showingInfoWindow: true,
    }));
  };

  const onKamMarkerClick = (props, marker, e) => {
    setState((prevState) => ({
      ...prevState,
      selectedKamPlace: props,
      activeKamMarker: marker,
      kamMapZoomEnabled: true,
      showingKamInfoWindow: true,
    }));
  };

  const onKamMarkerClose = (props) => {
    if (state.showingKamInfoWindow) {
      setState((prevState) => ({
        ...prevState,
        showingKamInfoWindow: false,
        kamMapZoomEnabled: true,
        activeKamMarker: null,
      }));
    }
  };
  const onICMarkerClick = (props, marker, e) => {
    setState((prevState) => ({
      ...prevState,
      selectedICPlace: props,
      activeICMarker: marker,
      showingICInfoWindow: true,
      kamICMapZoomEnabled: true,
    }));
  };

  const onICMarkerClose = (props) => {
    if (state.showingICInfoWindow) {
      setState((prevState) => ({
        ...prevState,
        showingICInfoWindow: false,
        kamICMapZoomEnabled: true,
        activeICMarker: null,
      }));
    }
  };

  const onClose = (props) => {
    if (state.showingInfoWindow) {
      setState((prevState) => ({
        ...prevState,
        showingInfoWindow: false,
        mapZoomEnabled: true,
        activeMarker: null,
      }));
    }
  };

  const fetchCustomerTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        customerType: true,
      },
    }));
    const { data, error } = await SharedService.getCustomerTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerTypeList:
        data?.rows?.filter((customer) => customer.is_active) ||
        defaultState.customerTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        customerTypeList: false,
      },
    }));
  };

  const fetchStateCityList = async () => {
    const { data, error } = await Service.stateCityDropdown();
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      stateList: (Object.keys(data?.states) || defaultState.stateList).sort(
        (a, b) => Intl.Collator().compare(a || "", b || "")
      ),
      locationList: data?.states || defaultState.locationList,
    }));
  };

  const fetchKamStateCityList = async () => {
    const { data, error } = await Service.kamStateCityDropdown();
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      kamStateList: (
        Object.keys(data?.states) || defaultState.kamStateList
      ).sort((a, b) => Intl.Collator().compare(a || "", b || "")),
      kamLocationList: data?.states || defaultState.kamLocationList,
    }));
  };

  const getFilteredOptions = (mainList, filterParam, atributeName) => {
    return mainList?.filter((item) => {
      const result = filterParam?.find(
        (item2) =>
          item2[atributeName] === item[atributeName] &&
          item[atributeName] !== undefined
      );
      return result;
    });
  };
  const getIndustryTypeFilteredOptions = (mainList) => {
    return mainList?.filter((item) => {
      const result = state.industryType?.find(
        (item2) =>
          (item2?.id === item.industryType &&
            item.industryType !== undefined) ||
          (Array.isArray(item.industryType) &&
            item.industryType?.includes(item2?.id))
      );
      return result;
    });
  };

  const getKamFilteredOptions = (mainList, filterParam, atributeName) => {
    return mainList?.filter((item) => {
      const result = filterParam?.find((item2) => {
        if (atributeName === "customer_id") {
          return item.customer?.some(
            (c) => c.customer_id === item2.customer_id
          );
        } else {
          return item.customerBranch?.some(
            (c) => c.customer_branch_id === item2.customer_branch_id
          );
        }
      });
      return result;
    });
  };

  const fetchAllLocations = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { data, error } = await Service.getAllLocations();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    customerDropdownList = data?.locations?.customers;

    branchDropdownList = data?.locations.customer_branches
      ?.flatMap((location) => {
        const customer = data?.locations?.customers?.find((c) =>
          c.customer_branch_ids?.includes(location.customer_branch_id)
        );
        if (!location?.addresses || !customer) return [];
        return JSON.parse(location.addresses).map((address) => ({
          ...address,
          customer_id: customer.customer_id,
          customer_branch_id: location?.customer_branch_id,
          default_kam: location?.default_kam,
          locations: location?.customer_branch_location,
          is_active: location?.is_active,
          customerName: customer?.customer_name,
          industryType: location?.industryType,
          industryTypeValue: location?.industryTypeValue,
          pdx_company_name: location?.pdx_company_name,
        }));
      })
      ?.filter((user) => !!user.is_active);

    const tempKam = data.locations.kams.map((kam) => {
      const kamCopy = { ...kam };
      const kamBranches = data.locations.customer_branches?.filter(
        (branch) => branch.default_kam[0] === kam.id
      );

      kamBranches.forEach((branch) => {
        let branchandCustAddressExist = true;

        if (
          !branch?.addresses ||
          branch?.addresses === null ||
          branch?.addresses === undefined
        ) {
          const branchCustomer = data.locations.customers?.find((customer) =>
            customer.customer_branch_ids?.includes(branch.customer_branch_id)
          );

          if (
            !branchCustomer?.addresses ||
            branchCustomer?.addresses === null ||
            branchCustomer?.addresses === undefined
          ) {
            branchandCustAddressExist = false;
          }
        }

        if (branchandCustAddressExist) {
          if (!kamCopy.customerBranch) {
            kamCopy.customerBranch = [];
          }
          kamCopy.customerBranch.push({
            customer_branch_id: branch.customer_branch_id,
            locations: branch.customer_branch_location,
          });

          if (!kamCopy.industryType) {
            kamCopy.industryType = [];
          }
          kamCopy.industryType.push(branch.industryType);

          if (!kamCopy.isBranchActive) {
            kamCopy.isBranchActive = [];
          }
          kamCopy.isBranchActive.push({
            branchId: branch.customer_branch_id,
            isActive: branch.is_active,
          });
        }
      });

      data.locations.customers.forEach((customer) => {
        if (
          customer.customer_branch_ids?.some((id) =>
            kamCopy.customerBranch?.some(
              (item) => item.customer_branch_id === id
            )
          )
        ) {
          if (!kamCopy.customer) {
            kamCopy.customer = [];
          }
          kamCopy.customer.push({
            customer_id: customer.customer_id,
            customerName: customer.customer_name,
          });
        }
      });

      return kamCopy;
    });

    kamDropdownList = tempKam?.flatMap((location) => {
      if (!location?.addresses) return [];
      return JSON.parse(location.addresses)
        .map((address) => ({
          ...address,
          kam_id: location?.id,
          first_name: location.first_name,
          last_name: location.last_name,
          username: location.username,
          is_active: location?.is_active,
          customerBranches: location?.customerBranches,
          isBranchActive: location?.isBranchActive,
          industryType: location?.industryType,
          customer: location?.customer,
          customerBranch: location?.customerBranch,
        }))
        ?.filter((user) => !!user.is_active);
    });

    ICDropdownList = data?.locations?.contractors
      ?.flatMap((location) => {
        const customer = allCustomerLocation?.find(
          (c) => c.customer_id === location.default_customer_id
        );
        if (!location?.addresses) return [];
        return JSON.parse(location.addresses).map((address) => ({
          ...address,
          customer_id: location?.default_customer_id,
          kam_id: location?.default_kam_id,
          ic_company_name: location?.ic_company_name,
          last_name: location?.last_name,
          phone: location?.phone,
          customerName: location?.default_customer_name,
          icid: location?.icid,
          email: location?.email,
          first_name: location?.first_name,
          is_active: location?.is_active,
          industryType: customer?.industryType,
        }));
      })
      ?.filter((user) => !!user.is_active);
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
  }, []);

  const filteredValues = (
    allCustomer,
    allBranch,
    allKam,
    allIc,
    customer,
    customerBranch,
    kam,
    IC,
    pdxCompany
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFilterApplied: true,
    }));

    let filteredCustomerOptions = [];
    let filteredBranchOptions = [];
    let filteredKamOptions = [];
    let filteredICOptions = [];
    hasData = true;

    if (
      state.industryType?.length > 0 &&
      customer?.length === 0 &&
      customerBranch?.length === 0 &&
      kam?.length === 0 &&
      IC?.length === 0 &&
      pdxCompany?.length === 0
    ) {
      filteredCustomerOptions = getIndustryTypeFilteredOptions(allCustomer);
      filteredBranchOptions = getIndustryTypeFilteredOptions(allBranch);
      filteredKamOptions = getIndustryTypeFilteredOptions(allKam);
      filteredICOptions = getIndustryTypeFilteredOptions(allIc);
      if (
        filteredCustomerOptions.length === 0 &&
        filteredBranchOptions.length === 0 &&
        filteredKamOptions.length === 0 &&
        filteredICOptions.length === 0
      ) {
        hasData = false;
      }
    } else if (customer?.length > 0) {
      filteredCustomerOptions = getFilteredOptions(
        allCustomer,
        customer,
        "customer_id"
      );
      filteredBranchOptions = getFilteredOptions(
        allBranch,
        customer,
        "customer_id"
      );
      filteredBranchOptions = getFilteredOptions(
        allBranch,
        customer,
        "customer_id"
      );
      filteredKamOptions = getKamFilteredOptions(
        allKam,
        customer,
        "customer_id"
      );
      filteredICOptions = getFilteredOptions(
        allIc,
        filteredCustomerOptions,
        "customer_id"
      );

      if (filteredCustomerOptions.length === 0) {
        hasData = false;
      }
      if (customerBranch?.length > 0) {
        filteredBranchOptions = getFilteredOptions(
          customerBranch,
          customer,
          "customer_id"
        );

        if (filteredBranchOptions.length === 0) {
          hasData = false;
        }

        const hasAllBranchesMismatched = state.allBranchLocation?.every(
          (branch) =>
            customerBranch?.every(
              (location) =>
                branch.customer_branch_id !== location.customer_branch_id
            )
        );
        if (hasAllBranchesMismatched) {
          hasData = false;
        }
        if (pdxCompany?.length > 0) {
          filteredBranchOptions = getFilteredOptions(
            state.state?.length > 0 || state.location?.length > 0
              ? allBranch
              : pdxCompany,
            state.state?.length > 0 || state.location?.length > 0
              ? pdxCompany
              : filteredBranchOptions,
            "customer_branch_id"
          );
          if (filteredBranchOptions.length === 0) {
            hasData = false;
          }
        } else {
          filteredBranchOptions = getFilteredOptions(
            allBranch,
            filteredBranchOptions,
            "customer_branch_id"
          );
        }
        if (kam?.length > 0) {
          filteredKamOptions = getKamFilteredOptions(
            kam,
            filteredBranchOptions,
            "customer_branch_id"
          );
          if (filteredKamOptions.length === 0) {
            hasData = false;
          }
        } else {
          filteredKamOptions = getKamFilteredOptions(
            allKam,
            filteredBranchOptions,
            "customer_branch_id"
          );
        }
      } else if (pdxCompany?.length > 0) {
        filteredBranchOptions = getFilteredOptions(
          pdxCompany,
          filteredBranchOptions,
          "customer_id"
        );
        if (filteredBranchOptions.length === 0) {
          hasData = false;
        }
        const hasAllBranchesMismatched = state.allBranchLocation?.every(
          (branch) =>
            pdxCompany?.every(
              (location) =>
                branch.pdx_company_name !== location.pdx_company_name
            )
        );
        if (hasAllBranchesMismatched) {
          hasData = false;
        }

        if (kam?.length > 0) {
          filteredKamOptions = getKamFilteredOptions(
            kam,
            filteredBranchOptions,
            "customer_branch_id"
          );
          if (filteredKamOptions.length === 0) {
            hasData = false;
          }
        } else {
          filteredKamOptions = getKamFilteredOptions(
            allKam,
            filteredBranchOptions,
            "customer_branch_id"
          );
        }
      } else if (kam?.length > 0) {
        filteredKamOptions = getKamFilteredOptions(
          kam,
          filteredCustomerOptions,
          "customer_id"
        );

        if (filteredKamOptions.length === 0) {
          hasData = false;
        }
      }

      if (IC?.length > 0) {
        filteredICOptions = getFilteredOptions(
          IC,
          filteredCustomerOptions,
          "customer_id"
        );

        if (filteredICOptions.length === 0) {
          hasData = false;
        }
      }
    } else if (customerBranch?.length > 0) {
      filteredBranchOptions = getFilteredOptions(
        allBranch,
        customerBranch,
        "customer_branch_id"
      );

      if (filteredBranchOptions.length === 0) {
        hasData = false;
      }

      if (pdxCompany?.length > 0) {
        filteredBranchOptions = getFilteredOptions(
          pdxCompany,
          filteredBranchOptions,
          "customer_branch_id"
        );
        if (filteredBranchOptions.length === 0) {
          hasData = false;
        }
        const hasAllBranchesMismatched = state.allBranchLocation?.every(
          (branch) =>
            pdxCompany?.every(
              (location) =>
                branch.pdx_company_name !== location.pdx_company_name
            )
        );
        if (hasAllBranchesMismatched) {
          hasData = false;
        }
      } else {
        filteredBranchOptions = getFilteredOptions(
          allBranch,
          filteredBranchOptions,
          "customer_branch_id"
        );
      }

      if (kam?.length > 0) {
        filteredKamOptions = getKamFilteredOptions(
          kam,
          filteredBranchOptions,
          "customer_branch_id"
        );
        if (filteredKamOptions.length === 0) {
          hasData = false;
        }
      } else {
        filteredKamOptions = getKamFilteredOptions(
          allKam,
          filteredBranchOptions,
          "customer_branch_id"
        );
      }
      if (IC?.length > 0) {
        hasData = false;
      }
    } else if (pdxCompany?.length > 0) {
      filteredBranchOptions = getFilteredOptions(
        allBranch,
        pdxCompany,
        "customer_branch_id"
      );

      if (filteredBranchOptions.length === 0) {
        hasData = false;
      }

      if (kam?.length > 0) {
        filteredKamOptions = getKamFilteredOptions(
          kam,
          filteredBranchOptions,
          "customer_branch_id"
        );
        if (filteredKamOptions.length === 0) {
          hasData = false;
        }
      } else {
        filteredKamOptions = getKamFilteredOptions(
          allKam,
          filteredBranchOptions,
          "customer_branch_id"
        );
      }
      if (IC?.length > 0) {
        hasData = false;
      }
    } else if (kam?.length > 0) {
      if (state.industryType?.length > 0) {
        kam = kam?.filter((kamOption) =>
          state.industryType?.some((type) =>
            kamOption.industryType?.includes(type.id)
          )
        );
      }
      filteredKamOptions = getFilteredOptions(allKam, kam, "kam_id");

      filteredICOptions = getFilteredOptions(allIc, kam, "kam_id");

      if (filteredKamOptions.length === 0) {
        hasData = false;
      }

      if (IC?.length > 0) {
        filteredICOptions = getFilteredOptions(allIc, IC, "icid");

        if (filteredICOptions.length === 0) {
          hasData = false;
        } else {
          filteredICOptions = filteredICOptions.filter((ic) =>
            filteredKamOptions.some((kam) => kam.kam_id === ic.kam_id)
          );

          if (filteredICOptions.length === 0) {
            hasData = false;
          }
        }
      }
    } else if (IC?.length > 0) {
      if (state.industryType?.length > 0) {
        IC = IC?.filter((icOption) =>
          allCustomer
            ?.map((customer) => customer.industryType)
            ?.includes(icOption.industryType)
        );
      }
      filteredICOptions = getFilteredOptions(allIc, IC, "icid");

      if (filteredICOptions.length === 0) {
        hasData = false;
      }
    }

    if (filteredCustomerOptions?.length > 0 && filteredKamOptions?.length > 0) {
      const customerBranchIds = filteredCustomerOptions?.flatMap(
        (option) => option.customer_branch_ids
      );

      filteredKamOptions = filteredKamOptions?.filter((kamOption) => {
        const matchingBranch = kamOption.isBranchActive?.find(
          (branch) =>
            customerBranchIds?.includes(branch.branchId) && branch.isActive
        );

        return matchingBranch !== undefined;
      });
    }

    setState((prevState) => ({
      ...prevState,
      allFilteredLocation:
        filteredCustomerOptions || defaultState.allFilteredLocation,
      allFilteredBranchLocation:
        filteredBranchOptions || defaultState.allFilteredBranchLocation,
      allkamFilteredLocation:
        filteredKamOptions || defaultState.allkamFilteredLocation,
      allICFilteredLocation:
        filteredICOptions || defaultState.allICFilteredLocation,
      isFilterApplied:
        state.industryType.length === 0 &&
        customer?.length === 0 &&
        customerBranch?.length === 0 &&
        pdxCompany?.length === 0 &&
        kam?.length === 0 &&
        IC?.length === 0
          ? false
          : true,
    }));
  };

  const getAllLocations = useCallback(
    async (filters, search, stateCityFilterType) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
        isLoading: true,
      }));
      if (stateCityFilterType === "stateCity") {
        filters.region = [];
      } else if (stateCityFilterType === "region") {
        filters.city = [];
        filters.state = [];
      }

      let filtersArr = [];
      if (filters?.state.length && filters?.state[0].value) {
        filtersArr = [...filtersArr, ...filters?.state];
      }
      if (filters?.city.length && filters?.city[0].value) {
        filtersArr = [...filtersArr, ...filters?.city];
      }

      let queryString = queryStringBuilderNew(
        0,
        0,
        [],
        filtersArr,
        {},
        search && search.length > 0
      );
      if (filters?.industryType.length && filters?.industryType[0].value) {
        const industryTypeIds = filters.industryType
          .map((item) => item.value)
          .join(",");
        queryString += `&industryType=${industryTypeIds}`;
      }

      if (filters?.region.length && filters?.region[0].value) {
        const region = filters.region.map((item) => item.value).join(",");
        queryString += `&filter[where][region]=${region}`;
      }

      const { data, error } = await Service.getAllLocations(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
          isLoading: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      allCustomerLocation = data?.locations?.customers;

      allCustomerBranchLocation = data?.locations.customer_branches?.flatMap(
        (location) => {
          const customer = data?.locations?.customers?.find((c) =>
            c.customer_branch_ids?.includes(location.customer_branch_id)
          );
          if (!location?.addresses || !customer) return [];
          return JSON.parse(location.addresses).map((address) => ({
            ...address,
            customer_id: customer.customer_id,
            customer_branch_id: location?.customer_branch_id,
            default_kam: location?.default_kam,
            locations: location?.customer_branch_location,
            is_active: location?.is_active,
            customerName: customer?.customer_name,
            industryType: location?.industryType,
            industryTypeValue: location?.industryTypeValue,
            pdx_company_name: location?.pdx_company_name,
          }));
        }
      );
      const tempKam = data.locations.kams.map((kam) => {
        const kamCopy = { ...kam };
        const kamBranches = data.locations.customer_branches?.filter(
          (branch) => branch.default_kam[0] === kam.id
        );

        kamBranches.forEach((branch) => {
          let branchandCustAddressExist = true;

          if (
            !branch?.addresses ||
            branch?.addresses === null ||
            branch?.addresses === undefined
          ) {
            const branchCustomer = data.locations.customers?.find((customer) =>
              customer.customer_branch_ids?.includes(branch.customer_branch_id)
            );

            if (
              !branchCustomer?.addresses ||
              branchCustomer?.addresses === null ||
              branchCustomer?.addresses === undefined
            ) {
              branchandCustAddressExist = false;
            }
          }

          if (branchandCustAddressExist) {
            //adding customer branch which will contain multiple associated branches containig addresses
            if (!kamCopy.customerBranch) {
              kamCopy.customerBranch = [];
            }
            kamCopy.customerBranch.push({
              customer_branch_id: branch.customer_branch_id,
              locations: branch.customer_branch_location,
            });

            if (!kamCopy.industryType) {
              kamCopy.industryType = [];
            }
            kamCopy.industryType.push(branch.industryType);

            if (!kamCopy.isBranchActive) {
              kamCopy.isBranchActive = [];
            }
            kamCopy.isBranchActive.push({
              branchId: branch.customer_branch_id,
              isActive: branch.is_active,
            });
          }
        });

        data.locations.customers.forEach((customer) => {
          if (
            customer.customer_branch_ids?.some((id) =>
              kamCopy.customerBranch?.some(
                (item) => item.customer_branch_id === id
              )
            )
          ) {
            if (!kamCopy.customer) {
              kamCopy.customer = [];
            }
            kamCopy.customer.push({
              customer_id: customer.customer_id,
              customerName: customer.customer_name,
            });
          }
        });

        return kamCopy;
      });

      allKamLocation = tempKam?.flatMap((location) => {
        if (!location?.addresses) return [];
        return JSON.parse(location.addresses)
          .map((address) => ({
            ...address,
            kam_id: location?.id,
            first_name: location.first_name,
            last_name: location.last_name,
            username: location.username,
            is_active: location?.is_active,
            isBranchActive: location?.isBranchActive,
            customerBranches: location?.customerBranches,
            industryType: location?.industryType,
            customer: location?.customer,
            customerBranch: location?.customerBranch,
            customerName: location?.customerName,
            locations: location?.locations,
          }))
          ?.filter((user) => !!user.is_active);
      });

      allICLocation = data?.locations?.contractors?.flatMap((location) => {
        const customer = allCustomerLocation?.find(
          (c) => c.customer_id === location.default_customer_id
        );
        const matchingKam = allKamLocation?.find(
          (kam) => kam.kam_id === location.default_kam_id
        );
        if (!location?.addresses) return [];
        return JSON.parse(location.addresses)?.map((address) => ({
          ...address,
          customer_id: location?.default_customer_id,
          kam_id: location?.default_kam_id,
          ic_company_name: location?.ic_company_name,
          last_name: location?.last_name,
          phone: location?.phone,
          customerName: location?.default_customer_name,
          icid: location?.icid,
          email: location?.email,
          first_name: location?.first_name,
          is_active: location?.is_active,
          industryType: customer?.industryType,
          kamDetail: matchingKam,
        }));
      });

      kamICLocation = data?.locations?.contractors
        ?.filter((location) =>
          allKamLocation?.some((kam) => kam.kam_id === location.default_kam_id)
        )
        ?.flatMap((location) => {
          if (!location?.addresses) return [];

          const parsedAddresses = JSON.parse(location.addresses) || [];

          const matchingKam = allKamLocation?.find(
            (kam) => kam.kam_id === location.default_kam_id
          );

          return parsedAddresses.map((address) => ({
            ...address,
            customer_id: location?.default_customer_id,
            kam_id: location?.default_kam_id,
            ic_company_name: location?.ic_company_name,
            last_name: location?.last_name,
            phone: location?.phone,
            customerName: location?.default_customer_name,
            icid: location?.icid,
            email: location?.email,
            first_name: location?.first_name,
            is_active: location?.is_active,
            kamDetails: matchingKam,
          }));
        });

      setState((prevState) => ({
        ...prevState,
        allLocation:
          allCustomerLocation?.filter((user) => !!user.is_active) ||
          defaultState.allLocation,
        allBranchLocation:
          allCustomerBranchLocation?.filter((user) => !!user.is_active) ||
          defaultState.allBranchLocation,
        kamLocation:
          allKamLocation?.filter((user) => !!user.is_active) ||
          defaultState.kamLocation,
        icLocation:
          allICLocation?.filter((user) => !!user.is_active) ||
          defaultState.icLocation,
        icKAMLocation:
          kamICLocation?.filter((user) => !!user.is_active) ||
          defaultState.icKAMLocation,
        isLoading: false,
      }));
    },
    []
  );

  const handleKamByLocation = async (filters, search) => {
    setState((prevState) => ({
      ...prevState,
      isKamLoading: true,
    }));
    let filtersArr = [];
    if (filters?.kamState.length && filters?.kamState[0].value) {
      filtersArr = [...filtersArr, ...filters?.kamState];
    }
    if (filters?.kamCity.length && filters?.kamCity[0].value) {
      filtersArr = [...filtersArr, ...filters?.kamCity];
    }

    let queryString = queryStringBuilderNew(
      0,
      0,
      [],
      filtersArr,
      {},
      search && search.length > 0
    );

    const { data, error } = await Service.getAllNearByKamLocations(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        isKamLoading: false,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    const kamAddresses = data?.locations?.kams?.flatMap((location) => {
      if (!location?.addresses) return [];
      return JSON.parse(location.addresses).map((address) => ({
        ...address,
        kam_id: location?.id,
        first_name: location.first_name,
        last_name: location.last_name,
        username: location.username,
        customerBranches: location?.customerBranches,
        is_active: location?.is_active,
      }));
    });

    setState((prevState) => ({
      ...prevState,
      isViewingByStateCity: true,
      kamAddressByStateCity:
        (state.nearKamInCity === null || state.nearKamInCity?.length === 0) &&
        (state.nearKamInstate === null || state.nearKamInstate?.length === 0)
          ? []
          : kamAddresses?.filter((user) => !!user.is_active) ||
            defaultState.kamAddressByStateCity,
      isKamLoading: false,
    }));
  };

  const handleNearbyKamByRadius = async () => {
    setState((prevState) => ({
      ...prevState,
      isKamLoading: true,
    }));
    const query = `?lat=${state.selectedPlace.position.lat}&lon=${state.selectedPlace.position.lng}&radius=${state.radius}&entity_type=kamUser`;

    const { data, error } = await Service.getByRadius(query);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isKamLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const nearByKamAddresses = data?.addresses?.filter(
      (address) => address.entity_type === "kamUser"
    );
    setState((prevState) => ({
      ...prevState,
      isViewingByRadius: true,
      kamAddressByRadius: nearByKamAddresses || defaultState.completeAddress,
      isKamLoading: false,
    }));
  };

  const kamLocationByStateCity = state.kamAddressByRadius?.flatMap(
    (location) => {
      const customer = allKamLocation?.find(
        (c) => c.kam_id === location.kam_id
      );
      return {
        ...location,
        customerBranches: customer?.customerBranches,
      };
    }
  );

  const handleKamAddressClose = () => {
    setState((prevState) => ({
      ...prevState,
      isViewingByRadius: false,
      isViewingByStateCity: false,
      radius: defaultState.radius,
      openKamAddressDialog: false,
      showClusters: false,
      showingKamInfoWindow: false,
      isStateFilterApplied: false,
      isRadiusFilterApplied: false,
      nearKamInstate: [],
      nearKamInCity: [],
      kamFilterType: "state",
      activeKamMarker: null,
      seeKamLocation: false,
    }));
  };

  const handleICAddressClose = () => {
    setState((prevState) => ({
      ...prevState,
      openICAddressDialog: false,
      showClusters: false,
      showingICInfoWindow: false,
      activeICMarker: null,
    }));
  };

  const getUniqueItemsByProperty = (arr, property) => {
    const uniqueItems = [];

    arr?.forEach((obj) => {
      const value = obj[property];

      if (!uniqueItems?.some((item) => item[property] === value)) {
        uniqueItems?.push(obj);
      }
    });

    return uniqueItems;
  };

  const stateNames =
    state.state.length > 0 ? state.state?.map((item) => item) : undefined;

  const matchedLocations = stateNames?.reduce((result, name) => {
    const locations = state.locationList[name] || [];
    return result.concat(locations);
  }, []);

  const kamStateNames =
    state.nearKamInstate.length > 0
      ? state.nearKamInstate?.map((item) => item)
      : undefined;

  const kamMatchedLocations = kamStateNames?.reduce((result, name) => {
    const locations = state.kamLocationList[name] || [];
    return result.concat(locations);
  }, []);

  useEffect(() => {
    getAllLocations(state.filters, state.search, state.stateCityFilterType);
    fetchCustomerTypeList();
    fetchStateCityList();
    fetchKamStateCityList();
  }, [getAllLocations]);

  useEffect(() => {
    fetchAllLocations();
  }, []);

  useEffect(() => {
    filteredValues(
      state.allLocation,
      state.allBranchLocation,
      state.kamLocation,
      state.icLocation,
      state.selectedCustomer,
      state.selectedBranchCustomer,
      state.selectedKam,
      state.selectedIC,
      state.selectedPDXCompany
    );
  }, [
    state.allLocation,
    state.allBranchLocation,
    state.kamLocation,
    state.icLocation,
  ]);

  const handleGoButtonClick = () => {
    getAllLocations(state.filters, state.search, state.stateCityFilterType);
  };

  const allKAMMarkers = useMemo(() => {
    if (!state.seeKamLocation && state.kamFilterType !== "state") {
      return [
        ...kamLocationByStateCity.map((locations) => ({
          position: {
            lat: locations.geo_location?.geometry.location.lat,
            lng: locations?.geo_location?.geometry.location.lng,
          },
          type: "state",
        })),
        ...state?.allBranchLocation
          ?.filter((kamLocation) => {
            const matchingLocation =
              kamLocation.customer_branch_id === state.customer_branch_id;
            return matchingLocation;
          })
          .map((locations) => ({
            position: {
              lat: locations.branchAddress.geo_location.geometry.location.lat,
              lng: locations.branchAddress.geo_location.geometry.location.lng,
            },
            type: "branch",
          })),
      ];
    } else if (state.seeKamLocation === true) {
      return [
        ...state.kamLocation
          ?.filter((kamLocation) => {
            const matchingLocation = kamLocation.customerBranch?.find(
              (item) => item.customer_branch_id === state.customer_branch_id
            );
            return matchingLocation;
          })
          ?.map((locations) => ({
            position: {
              lat: locations.kamAddress?.geo_location?.geometry.location.lat,
              lng: locations.kamAddress?.geo_location?.geometry.location.lng,
            },
            type: "kam",
          })),
        ...state?.allBranchLocation
          ?.filter((kamLocation) => {
            const matchingLocation =
              kamLocation.customer_branch_id === state.customer_branch_id;
            return matchingLocation;
          })
          .map((locations) => ({
            position: {
              lat: locations.branchAddress.geo_location.geometry.location.lat,
              lng: locations.branchAddress.geo_location.geometry.location.lng,
            },
            type: "branch",
          })),
      ];
    } else if (!state.seeKamLocation && state.kamFilterType === "state") {
      return [
        ...state.kamAddressByStateCity.map((locations) => ({
          position: {
            lat: locations.kamAddress?.geo_location?.geometry.location.lat,
            lng: locations.kamAddress?.geo_location?.geometry.location.lng,
          },
          type: "radius",
        })),
        ...state?.allBranchLocation
          ?.filter((kamLocation) => {
            const matchingLocation =
              kamLocation.customer_branch_id === state.customer_branch_id;
            return matchingLocation;
          })
          .map((locations) => ({
            position: {
              lat: locations.branchAddress.geo_location.geometry.location.lat,
              lng: locations.branchAddress.geo_location.geometry.location.lng,
            },
            type: "branch",
          })),
      ];
    }
  }, [
    kamLocationByStateCity,
    state.kamAddressByStateCity,
    state.kamFilterType,
    state.seeKamLocation,
    state.kamLocation,
    state.customer_branch_id,
    state.allBranchLocation,
  ]);

  const allICKAMMarkers = useMemo(() => {
    if (state.openICAddressDialog) {
      return [
        ...state.kamLocation
          ?.filter((kamLocation) => state.selectedKamId === kamLocation.kam_id)
          ?.map((locations) => ({
            position: {
              lat: locations.kamAddress?.geo_location?.geometry.location.lat,
              lng: locations.kamAddress?.geo_location?.geometry.location.lng,
            },
            type: "kam",
          })),
        ...state.icKAMLocation
          ?.filter((ele) => {
            const matchingLocation = ele.kam_id === state.selectedPlace?.kamID;

            return matchingLocation;
          })
          ?.map((locations) => ({
            position: {
              lat: locations?.contractorAddress?.geo_location?.geometry
                ?.location?.lat,
              lng: locations?.contractorAddress?.geo_location?.geometry
                ?.location?.lng,
            },
            type: "IC",
          })),
      ];
    }
  }, [
    state.kamLocation,
    state.icKAMLocation,
    state.selectedKamId,
    state.selectedPlace,
    state.openICAddressDialog,
  ]);

  const allMarkers = useMemo(() => {
    if (!state.isFilterApplied) {
      return [
        ...(state.showBranchLocations
          ? state.allBranchLocation.map((locations) => ({
              position: {
                lat: locations?.branchAddress?.geo_location?.geometry?.location
                  ?.lat,
                lng: locations?.branchAddress?.geo_location?.geometry?.location
                  ?.lng,
              },
              type: "branch",
            }))
          : []),
        ...(state.showKamLocations
          ? state.kamLocation.map((locations) => ({
              position: {
                lat: locations?.kamAddress?.geo_location?.geometry?.location
                  ?.lat,
                lng: locations?.kamAddress?.geo_location?.geometry?.location
                  ?.lng,
              },
              type: "kam",
            }))
          : []),
        ...(state.showICLocations
          ? state.icLocation.map((locations) => ({
              position: {
                lat: locations?.contractorAddress?.geo_location?.geometry
                  ?.location?.lat,
                lng: locations?.contractorAddress?.geo_location?.geometry
                  ?.location?.lng,
              },
              type: "IC",
            }))
          : []),
      ];
    } else {
      return [
        ...(state.showBranchLocations
          ? state.allFilteredBranchLocation.map((locations) => ({
              position: {
                lat: locations?.branchAddress?.geo_location?.geometry?.location
                  ?.lat,
                lng: locations?.branchAddress?.geo_location?.geometry?.location
                  ?.lng,
              },
              type: "filteredBranch",
            }))
          : []),
        ...(state.showKamLocations
          ? state.allkamFilteredLocation.map((locations) => ({
              position: {
                lat: locations?.kamAddress?.geo_location?.geometry?.location
                  ?.lat,
                lng: locations?.kamAddress?.geo_location?.geometry?.location
                  ?.lng,
              },
              type: "filteredKam",
            }))
          : []),
        ...(state.showICLocations
          ? state.allICFilteredLocation.map((locations) => ({
              position: {
                lat: locations?.contractorAddress?.geo_location?.geometry
                  ?.location?.lat,
                lng: locations?.contractorAddress?.geo_location?.geometry
                  ?.location?.lng,
              },
              type: "filteredIC",
            }))
          : []),
      ];
    }
  }, [
    state.isFilterApplied,
    state.allBranchLocation,
    state.showICLocations,
    state.showKamLocations,
    state.showBranchLocations,
    state.kamLocation,
    state.icLocation,
    state.allFilteredBranchLocation,
    state.allkamFilteredLocation,
    state.allICFilteredLocation,
  ]);

  const handleZoomChanged = () => {
    if (state.map) {
      const newZoomLevel = state.map.getZoom();
      setState((prevState) => ({
        ...prevState,
        zoomLevel: newZoomLevel,
      }));
    }
  };

  useEffect(() => {
    if (state.map && !state.zoomLevel) {
      setState((prevState) => ({
        ...prevState,
        zoomLevel: state.map.getZoom(),
      }));
    }
  }, [state.map, state.zoomLevel]);

  const onMapReady = (mapProps, map) => {
    setState((prevState) => ({
      ...prevState,
      map: map,
    }));
    handleZoomChanged();
  };

  const calculateBounds = () => {
    const newBounds = new window.google.maps.LatLngBounds();
    allMarkers?.forEach((marker) => {
      newBounds?.extend(marker.position);
    });
    setBounds(newBounds);
  };

  useEffect(() => {
    calculateBounds();
  }, [allMarkers]);

  const viewport = {
    center: bounds?.getCenter(),
    zoom: state.zoomLevel,
  };

  const calculateKamBounds = () => {
    const newBounds = new window.google.maps.LatLngBounds();
    allKAMMarkers?.forEach((marker) => {
      newBounds?.extend(marker.position);
    });
    return newBounds;
  };

  const kamBounds = calculateKamBounds();

  const kamViewport = {
    center: kamBounds?.getCenter(),
    zoom: state.kamZoomLevel,
  };

  const calculateICBounds = () => {
    const newBounds = new window.google.maps.LatLngBounds();
    allICKAMMarkers?.forEach((marker) => {
      newBounds?.extend(marker.position);
    });
    return newBounds;
  };

  const icBounds = calculateICBounds();

  const icViewport = {
    center: icBounds?.getCenter(),
    zoom: state.icZoomLevel,
  };

  useEffect(() => {
    loadMarkerClusterer
      ?.then(() => {
        const map = mapRef.current?.map;

        const customMarkers = allMarkers.map((marker) => {
          return new window.google.maps.Marker({
            position: marker.position,
            icon: {
              url: "",
              size: new window.google.maps.Size(1, 1),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(0, 0),
            },
          });
        });

        if (clustererRef.current) {
          clustererRef.current.clearMarkers();
          clustererRef.current.setMap(null);
        }

        clustererRef.current = new MarkerClusterer(map, customMarkers, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
          gridSize: 60,
        });
      })
      .catch((error) => {
        console.error("Error loading MarkerClusterer library:", error);
      });
  }, [allMarkers, state.isgoButtonIsClicked, loadMarkerClusterer]);

  useEffect(() => {
    loadMarkerClusterer
      ?.then(() => {
        const map = kamMapRef.current?.map;

        const customMarkers = allKAMMarkers.map((marker) => {
          return new window.google.maps.Marker({
            position: marker.position,
            icon: {
              url: "",
              size: new window.google.maps.Size(1, 1),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(0, 0),
            },
          });
        });

        if (kamClusterRef.current) {
          kamClusterRef.current.clearMarkers();
          kamClusterRef.current.setMap(null);
        }
        kamClusterRef.current = new MarkerClusterer(map, customMarkers, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
          gridSize: 60,
        });
      })
      .catch((error) => {
        console.error("Error loading MarkerClusterer library:", error);
      });
  }, [allKAMMarkers, state.isKamGoButtonIsClicked, loadMarkerClusterer]);

  useEffect(() => {
    loadMarkerClusterer
      ?.then(() => {
        const map = icMapRef.current?.map;

        const customMarkers = allICKAMMarkers.map((marker) => {
          return new window.google.maps.Marker({
            position: marker.position,
            icon: {
              url: "",
              size: new window.google.maps.Size(1, 1),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(0, 0),
            },
          });
        });

        if (icKamClusterRef.current) {
          icKamClusterRef.current.clearMarkers();
          icKamClusterRef.current.setMap(null);
        }
        icKamClusterRef.current = new MarkerClusterer(map, customMarkers, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
          gridSize: 60,
        });
      })
      .catch((error) => {
        console.error("Error loading MarkerClusterer library:", error);
      });
  }, [allICKAMMarkers, loadMarkerClusterer]);

  const calculateScaledSize = (baseSize) => {
    const minZoom = 4;
    const maxZoom = 30;
    const minSize = 50;
    const maxSize = 100;

    const sizeRange = maxSize - minSize;
    const zoomRange = maxZoom - minZoom;
    const zoomFactor = (baseSize - minZoom) / zoomRange;
    const scaledSize = minSize + sizeRange * zoomFactor;

    return Math.min(maxSize, Math.max(minSize, scaledSize));
  };
  const kamScaledSize = calculateScaledSize(state.zoomLevel);

  const getMarkerIcon = (locations, activeMarker, mapZoom) => {
    const { industryTypeValue, pdx_company_name } = locations;

    const calculateScaledSize = (baseSize) => {
      const minZoom = 4;
      const maxZoom = 30;
      const minSize = state.openKamAddressDialog ? 60 : 50;
      const maxSize = 100;

      const sizeRange = maxSize - minSize;
      const zoomRange = maxZoom - minZoom;
      const zoomFactor = (baseSize - minZoom) / zoomRange;
      const scaledSize = minSize + sizeRange * zoomFactor;

      return Math.min(maxSize, Math.max(minSize, scaledSize));
    };
    const scaledSize = calculateScaledSize(mapZoom);
    if (
      activeMarker &&
      activeMarker.location === locations.branchAddress.address
    ) {
      return {
        url: customerLocationPinIcon,
        scaledSize: new window.google.maps.Size(scaledSize, scaledSize),
      };
    } else if (
      industryTypeValue === "Aftermarket" ||
      industryTypeValue === "OEM" ||
      industryTypeValue === "Tire Distributor"
    ) {
      const getIconByCompany = (companyName) => {
        const companyMap = {
          "Auto Parts Xpress (APX)": {
            Aftermarket: aftermarketYellow,
            OEM: oemYellow,
            "Tire Distributor": TireDistributorYellow,
          },
          "Parts Distribution Xpress (PDX)": {
            Aftermarket: aftermarketGreen,
            OEM: oemGreen,
            "Tire Distributor": TireDistributorGreen,
          },
          "PDX North": {
            Aftermarket: aftermarketBlue,
            OEM: oemBlue,
            "Tire Distributor": TireDistributorBlue,
          },
          "PDX South": {
            Aftermarket: aftermarketPurple,
            OEM: oemPurple,
            "Tire Distributor": TireDistributorPurple,
          },
          "PDX West": {
            Aftermarket: aftermarketOrange,
            OEM: oemOrange,
            "Tire Distributor": TireDistributorOrange,
          },
        };

        return (
          companyMap[companyName]?.[industryTypeValue] || customerLocationIcon
        );
      };

      const markerIcon = getIconByCompany(pdx_company_name);

      return {
        url: markerIcon,
        scaledSize: new window.google.maps.Size(scaledSize, scaledSize),
      };
    }

    return {
      url: customerLocationIcon,
      scaledSize: new window.google.maps.Size(scaledSize, scaledSize),
    };
  };

  const getFormattedRegionLabel = (region) => {
    return region
      .replace(/^PDX-NORTH$/, "PDX North")
      .replace(/^PDX-SOUTH$/, "PDX South")
      .replace(/^PDX-WEST$/, "PDX West");
  };

  const toggleDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isDrawerOpen: !prevState.isDrawerOpen,
    }));
  };

  return (
    <>
      <div>
        <div className={clsx(" mr-10", classes.fixedHeader)}>
          <div className="mb-2">
            <Typography variant="h4" color="primary" className="ml-2">
              Customer Location
              <Button
                startIcon={<ArrowBackIcon />}
                style={{ float: "right" }}
                className="mt-1 mr-2"
                variant="contained"
                color="primary"
                onClick={() => history.push("/home")}
              >
                Back To Home
              </Button>
            </Typography>
            <Typography variant="body2" style={{ marginLeft: "10px" }}>
              View Customer Location.
            </Typography>
          </div>
        </div>
        <Drawer
          anchor="left"
          BackdropProps={{ invisible: true }}
          open={state.isDrawerOpen}
          onClose={toggleDrawer}
          className={classes.drawer}
          variant="persistent"
        >
          <div
            className="d-flex f-align-center"
            style={{
              flexWrap: "wrap",
              alignContent: "space-between",
            }}
          >
            <div className="d-flex f-align-center">
              <Typography
                variant="body2"
                text="bold"
                className="mr-3"
                style={{ color: "#fc4800" }}
              >
                Customer :
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.showBranchLocations}
                    onChange={(event) => {
                      setState((prevState) => ({
                        ...prevState,
                        showBranchLocations: event.target.checked,
                        showingInfoWindow: false,
                        activeMarker: null,
                      }));
                      setPageConfig(PAGE_KEYS.LOCATION, {
                        showBranchLocationsChecked: !!event.target.checked,
                      });
                    }}
                    name="Customer Branch"
                  />
                }
              />
              <Typography
                variant="body2"
                text="bold"
                className="ml-3 mr-3"
                style={{ color: "#095C6B" }}
              >
                KAM :
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.showKamLocations}
                    onChange={(event) => {
                      setState((prevState) => ({
                        ...prevState,
                        showKamLocations: event.target.checked,
                        showingInfoWindow: false,
                        activeMarker: null,
                      }));
                      setPageConfig(PAGE_KEYS.LOCATION, {
                        showKamLocationsChecked: event.target.checked,
                      });
                    }}
                    name="KAM"
                  />
                }
              />
              <Typography
                variant="body2"
                text="bold"
                className="ml-3 mr-3"
                style={{ color: "#0087ED" }}
              >
                IC :
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.showICLocations}
                    onChange={(event) => {
                      setState((prevState) => ({
                        ...prevState,
                        showICLocations: event.target.checked,
                        showingInfoWindow: false,
                        activeMarker: null,
                      }));
                      setPageConfig(PAGE_KEYS.LOCATION, {
                        showICLocationsChecked: event.target.checked,
                      });
                    }}
                    name="IC"
                  />
                }
              />
            </div>
            <Box className="d-flex f-align-center mb-2">
              <FormControl>
                <RadioGroup
                  row
                  name="state_city"
                  value={state.stateCityFilterType}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    setState((prevState) => ({
                      ...prevState,
                      stateCityFilterType: value,
                    }));
                  }}
                >
                  <FormControlLabel
                    value="stateCity"
                    control={<Radio size="small" color="primary" />}
                    label="Select State/City"
                  />
                  <FormControlLabel
                    value="region"
                    control={<Radio size="small" color="primary" />}
                    label="Select Region"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </div>
          {state.stateCityFilterType !== "stateCity" && (
            <Autocomplete
              className={classes.fields}
              size="small"
              defaultValue={state.region || []}
              ChipProps={{
                component: (a) => {
                  return (
                    <div>
                      <Tooltip
                        placement={"top-start"}
                        title={a.children[1]?.props?.children || ""}
                      >
                        <Chip
                          size="small"
                          className={classes.chip}
                          label={
                            <Typography variant="body3" noWrap>
                              {a.children[1]?.props?.children?.length > 4
                                ? a.children[1]?.props?.children.slice(0, 4) +
                                  "..."
                                : a.children[1]?.props?.children}
                            </Typography>
                          }
                          deleteIcon
                          onDelete={(evt) => {
                            a.children[2]?.props?.onClick(evt);
                          }}
                        />
                      </Tooltip>
                    </div>
                  );
                },
              }}
              disableCloseOnSelect={true}
              multiple
              options={state.regionList}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {getFormattedRegionLabel(option)}
                </React.Fragment>
              )}
              getOptionLabel={(option) => getFormattedRegionLabel(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Region"
                  variant="outlined"
                />
              )}
              onChange={(evt, value) => {
                if (value.length === 0) {
                  return setState((prevState) => ({
                    ...prevState,
                    region: value,
                    showingInfoWindow: false,
                    activeMarker: null,
                    filters: {
                      ...prevState.filters,
                      region: [],
                    },
                  }));
                }
                const regionName = value?.map((item) => item);
                setState((prevState) => ({
                  ...prevState,
                  region: value,
                  showingInfoWindow: false,
                  activeMarker: null,
                  filters: {
                    ...prevState.filters,
                    region: [
                      { field: "region", type: "iRegexp", value: regionName },
                    ],
                  },
                }));
              }}
            />
          )}
          {state.stateCityFilterType === "stateCity" && (
            <div
              className="d-flex f-align-center"
              style={{
                flexWrap: "wrap",
              }}
            >
              <Autocomplete
                className={classes.fields}
                size="small"
                // value={state.state || []}
                defaultValue={state.state || []}
                ChipProps={{
                  component: (a) => {
                    return (
                      <div>
                        <Tooltip
                          placement={"top-start"}
                          title={a.children[1]?.props?.children || ""}
                        >
                          <Chip
                            size="small"
                            className={classes.chip}
                            label={
                              <Typography variant="body3" noWrap>
                                {a.children[1]?.props?.children?.length > 4
                                  ? a.children[1]?.props?.children.slice(0, 4) +
                                    "..."
                                  : a.children[1]?.props?.children}
                              </Typography>
                            }
                            deleteIcon
                            onDelete={(evt) => {
                              a.children[2]?.props?.onClick(evt);
                            }}
                          />
                        </Tooltip>
                      </div>
                    );
                  },
                }}
                disableCloseOnSelect={true}
                multiple
                options={state.stateList}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </React.Fragment>
                )}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    variant="outlined"
                  />
                )}
                onChange={(evt, value) => {
                  if (value.length === 0) {
                    return setState((prevState) => ({
                      ...prevState,
                      state: value,
                      showingInfoWindow: false,
                      activeMarker: null,
                      filters: {
                        ...prevState.filters,
                        state: [],
                      },
                    }));
                  }
                  const stateName = value?.map((item) => item);

                  setState((prevState) => ({
                    ...prevState,
                    state: value,
                    location: null,
                    showingInfoWindow: false,
                    activeMarker: null,
                    filters: {
                      ...prevState.filters,
                      state: [
                        { field: "state", type: "iRegexp", value: stateName },
                      ],
                    },
                  }));
                }}
              />
              <Autocomplete
                className={classes.fields}
                size="small"
                ChipProps={{
                  component: (a) => {
                    return (
                      <div>
                        <Tooltip
                          placement={"top-start"}
                          title={a.children[1]?.props?.children || ""}
                        >
                          <Chip
                            size="small"
                            className={classes.chip}
                            label={
                              <Typography variant="body3" noWrap>
                                {a.children[1]?.props?.children?.length > 4
                                  ? a.children[1]?.props?.children.slice(0, 4) +
                                    "..."
                                  : a.children[1]?.props?.children}
                              </Typography>
                            }
                            deleteIcon
                            onDelete={(evt) => {
                              a.children[2]?.props?.onClick(evt);
                            }}
                          />
                        </Tooltip>
                      </div>
                    );
                  },
                }}
                defaultValue={state.location || []}
                disableCloseOnSelect={true}
                multiple
                options={
                  state.state.length > 0
                    ? [...new Set(matchedLocations)].sort((a, b) =>
                        Intl.Collator().compare(a || "", b || "")
                      ) || []
                    : [
                        ...new Set(
                          Object.values(state.locationList).flatMap(
                            (cityList) => cityList
                          )
                        ),
                      ].sort((a, b) =>
                        Intl.Collator().compare(a || "", b || "")
                      )
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </React.Fragment>
                )}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City"
                    variant="outlined"
                  />
                )}
                onChange={(evt, value) => {
                  if (value.length === 0) {
                    return setState((prevState) => ({
                      ...prevState,
                      location: value,
                      showingInfoWindow: false,
                      activeMarker: null,
                      filters: {
                        ...prevState.filters,
                        city: [],
                      },
                    }));
                  }
                  setState((prevState) => ({
                    ...prevState,
                    location: value,
                    showingInfoWindow: false,
                    activeMarker: null,
                    filters: {
                      ...prevState.filters,
                      city: [{ field: "city", type: "iRegexp", value: value }],
                    },
                  }));
                }}
              />
            </div>
          )}
          <Divider className="mt-1 mb-4" />
          <div
            className="d-flex f-align-center"
            style={{
              flexWrap: "wrap",
            }}
          >
            <div
              className="d-flex"
              style={{
                flexWrap: "wrap",
              }}
            >
              {" "}
              <Autocomplete
                className={classes.fields}
                size="small"
                // value={state.state || []}
                defaultValue={state.industryType || []}
                ChipProps={{
                  component: (a) => {
                    return (
                      <div>
                        <Tooltip
                          placement={"top-start"}
                          title={a.children[1]?.props?.children || ""}
                        >
                          <Chip
                            size="small"
                            className={classes.chip}
                            label={
                              <Typography variant="body3" noWrap>
                                {a.children[1]?.props?.children?.length > 4
                                  ? a.children[1]?.props?.children.slice(0, 4) +
                                    "..."
                                  : a.children[1]?.props?.children}
                              </Typography>
                            }
                            deleteIcon
                            onDelete={(evt) => {
                              a.children[2]?.props?.onClick(evt);
                            }}
                          />
                        </Tooltip>
                      </div>
                    );
                  },
                }}
                disableCloseOnSelect={true}
                multiple
                options={state.customerTypeList?.filter(
                  (type) => type.is_active
                )}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.value}
                  </React.Fragment>
                )}
                getOptionLabel={(option) => option.value || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Industry Type"
                    variant="outlined"
                  />
                )}
                onChange={(evt, value) => {
                  if (value.length === 0) {
                    return setState((prevState) => ({
                      ...prevState,
                      industryType: value,
                      showingInfoWindow: false,
                      activeMarker: null,
                      filters: {
                        ...prevState.filters,
                        industryType: [],
                      },
                    }));
                  }
                  const id = value?.map((item) => item.id);
                  setState((prevState) => ({
                    ...prevState,
                    industryType: value,
                    showingInfoWindow: false,
                    activeMarker: null,
                    filters: {
                      ...prevState.filters,
                      industryType: [
                        { field: "industryType", type: "=", value: id },
                      ],
                    },
                  }));
                }}
              />
              {state.showBranchLocations && (
                <Autocomplete
                  className={classes.fields}
                  disableCloseOnSelect={true}
                  size="small"
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.customer_name}
                    </React.Fragment>
                  )}
                  value={state.selectedCustomer}
                  multiple
                  ChipProps={{
                    component: (a) => {
                      return (
                        <div>
                          <Tooltip
                            placement={"top-start"}
                            title={a.children[1]?.props?.children || ""}
                          >
                            <Chip
                              size="small"
                              className={classes.chip}
                              label={
                                <Typography variant="body3" noWrap>
                                  {a.children[1]?.props?.children?.length > 4
                                    ? a.children[1]?.props?.children.slice(
                                        0,
                                        4
                                      ) + "..."
                                    : a.children[1]?.props?.children}
                                </Typography>
                              }
                              deleteIcon
                              onDelete={(evt) => {
                                a.children[2]?.props?.onClick(evt);
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                    },
                  }}
                  options={[...new Set(customerDropdownList)]?.sort((a, b) =>
                    a?.customer_name?.localeCompare(b?.customer_name)
                  )}
                  getOptionLabel={(location) => location.customer_name}
                  getOptionSelected={(option, value) =>
                    option.customer_name === value.customer_name
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Select Customer"
                        variant="outlined"
                      />
                    );
                  }}
                  onChange={(event, value) => {
                    if (value?.length === 0) {
                      setState((prevState) => ({
                        ...prevState,
                        selectedCustomer: value,
                        showCustomerMarkers: false,
                        showingInfoWindow: false,
                        activeMarker: null,
                      }));
                      return;
                    }
                    setState((prevState) => ({
                      ...prevState,
                      selectedCustomer: value,
                      showingInfoWindow: false,
                      activeMarker: null,
                    }));
                  }}
                />
              )}
              {state.showBranchLocations && (
                <Autocomplete
                  className={classes.fields}
                  disableCloseOnSelect={true}
                  size="small"
                  multiple
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.locations}
                    </React.Fragment>
                  )}
                  ChipProps={{
                    component: (a) => {
                      return (
                        <div>
                          <Tooltip
                            placement={"top-start"}
                            title={a.children[1]?.props?.children || ""}
                          >
                            <Chip
                              size="small"
                              className={classes.chip}
                              label={
                                <Typography variant="body3" noWrap>
                                  {a.children[1]?.props?.children?.length > 4
                                    ? a.children[1]?.props?.children.slice(
                                        0,
                                        4
                                      ) + "..."
                                    : a.children[1]?.props?.children}
                                </Typography>
                              }
                              deleteIcon
                              onDelete={(evt) => {
                                a.children[2]?.props?.onClick(evt);
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                    },
                  }}
                  options={getUniqueItemsByProperty(
                    branchDropdownList,
                    "locations"
                  )?.sort((a, b) => a?.locations?.localeCompare(b?.locations))}
                  getOptionLabel={(location) => location?.locations}
                  getOptionSelected={(option, value) =>
                    option?.locations === value?.locations
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Select Branch"
                        variant="outlined"
                      />
                    );
                  }}
                  value={getUniqueItemsByProperty(
                    state.selectedBranchCustomer,
                    "locations"
                  )}
                  onChange={(event, value) => {
                    if (value?.length === 0) {
                      setState((prevState) => ({
                        ...prevState,
                        selectedBranchCustomer: value,
                        showBranchMarkers: true,
                        showingInfoWindow: false,
                        activeMarker: null,
                      }));
                      return;
                    }
                    const uniqueValues = [
                      ...new Set(value.map((value) => value.locations)),
                    ];
                    const selectedLocations = branchDropdownList?.filter(
                      (obj) => uniqueValues?.includes(obj.locations)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      selectedBranchCustomer: selectedLocations,
                      showingInfoWindow: false,
                      activeMarker: null,
                      showBranchMarkers: false,
                    }));
                  }}
                />
              )}
              {state.showBranchLocations && (
                <Autocomplete
                  className={classes.fields}
                  disableCloseOnSelect={true}
                  size="small"
                  multiple
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.pdx_company_name}
                    </React.Fragment>
                  )}
                  ChipProps={{
                    component: (a) => {
                      return (
                        <div>
                          <Tooltip
                            placement={"top-start"}
                            title={a.children[1]?.props?.children || ""}
                          >
                            <Chip
                              size="small"
                              className={classes.chip}
                              label={
                                <Typography variant="body3" noWrap>
                                  {a.children[1]?.props?.children?.length > 4
                                    ? a.children[1]?.props?.children.slice(
                                        0,
                                        4
                                      ) + "..."
                                    : a.children[1]?.props?.children}
                                </Typography>
                              }
                              deleteIcon
                              onDelete={(evt) => {
                                a.children[2]?.props?.onClick(evt);
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                    },
                  }}
                  options={getUniqueItemsByProperty(
                    branchDropdownList,
                    "pdx_company_name"
                  )?.sort((a, b) =>
                    a?.pdx_company_name?.localeCompare(b?.pdx_company_name)
                  )}
                  getOptionLabel={(location) => location?.pdx_company_name}
                  getOptionSelected={(option, value) =>
                    option?.pdx_company_name === value?.pdx_company_name
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Select PDX Company"
                        variant="outlined"
                      />
                    );
                  }}
                  value={getUniqueItemsByProperty(
                    state.selectedPDXCompany,
                    "pdx_company_name"
                  )}
                  onChange={(event, value) => {
                    if (value?.length === 0) {
                      setState((prevState) => ({
                        ...prevState,
                        selectedPDXCompany: value,
                        showBranchMarkers: true,
                        showingInfoWindow: false,
                        activeMarker: null,
                      }));
                      return;
                    }
                    const uniqueValues = [
                      ...new Set(value.map((value) => value.pdx_company_name)),
                    ];
                    const selectedLocations = branchDropdownList?.filter(
                      (obj) => uniqueValues?.includes(obj.pdx_company_name)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      selectedPDXCompany: selectedLocations,
                      showingInfoWindow: false,
                      activeMarker: null,
                      showBranchMarkers: false,
                    }));
                  }}
                />
              )}
              {state.showKamLocations && (
                <Autocomplete
                  className={classes.fields}
                  size="small"
                  disableCloseOnSelect={true}
                  multiple
                  value={getUniqueItemsByProperty(
                    state.selectedKam,
                    "username"
                  )}
                  ChipProps={{
                    component: (a) => {
                      return (
                        <div>
                          <Tooltip
                            placement={"top-start"}
                            title={a.children[1]?.props?.children || ""}
                          >
                            <Chip
                              size="small"
                              className={classes.chip}
                              label={
                                <Typography variant="body3" noWrap>
                                  {a.children[1]?.props?.children?.length > 4
                                    ? a.children[1]?.props?.children.slice(
                                        0,
                                        4
                                      ) + "..."
                                    : a.children[1]?.props?.children}
                                </Typography>
                              }
                              deleteIcon
                              onDelete={(evt) => {
                                a.children[2]?.props?.onClick(evt);
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                    },
                  }}
                  options={getUniqueItemsByProperty(
                    kamDropdownList,
                    "username"
                  )?.sort((a, b) =>
                    a?.first_name?.localeCompare(b?.first_name)
                  )}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option.first_name} ${option.last_name}-(${option.username})`}
                    </React.Fragment>
                  )}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name}-(${option.username})`
                  }
                  getOptionSelected={(option, value) =>
                    option?.username === value?.username
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Select KAM"
                        variant="outlined"
                      />
                    );
                  }}
                  onChange={(event, value) => {
                    if (value?.length === 0) {
                      setState((prevState) => ({
                        ...prevState,
                        selectedKam: value,
                        showBranchMarkers: true,
                        activeMarker: null,
                      }));
                      return;
                    }
                    const uniqueValues = [
                      ...new Set(value.map((value) => value.username)),
                    ];
                    const selectedLocations = kamDropdownList?.filter((obj) =>
                      uniqueValues?.includes(obj.username)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      selectedKam: selectedLocations,
                      showingInfoWindow: false,
                      activeMarker: null,
                      showBranchMarkers: false,
                    }));
                  }}
                />
              )}
              {state.showICLocations && (
                <Autocomplete
                  className={classes.fields}
                  disableCloseOnSelect={true}
                  size="small"
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option.first_name} ${option.last_name} (${option.icid})`}
                    </React.Fragment>
                  )}
                  value={getUniqueItemsByProperty(state.selectedIC, "icid")}
                  multiple
                  ChipProps={{
                    component: (a) => {
                      return (
                        <div>
                          <Tooltip
                            placement={"top-start"}
                            title={a.children[1]?.props?.children || ""}
                          >
                            <Chip
                              size="small"
                              className={classes.chip}
                              label={
                                <Typography variant="body3" noWrap>
                                  {a.children[1]?.props?.children?.length > 4
                                    ? a.children[1]?.props?.children.slice(
                                        0,
                                        4
                                      ) + "..."
                                    : a.children[1]?.props?.children}
                                </Typography>
                              }
                              deleteIcon
                              onDelete={(evt) => {
                                a.children[2]?.props?.onClick(evt);
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                    },
                  }}
                  options={getUniqueItemsByProperty(
                    ICDropdownList,
                    "icid"
                  )?.sort((a, b) =>
                    a?.first_name?.localeCompare(b?.first_name)
                  )}
                  getOptionLabel={(location) =>
                    `${location.first_name} ${location.last_name} (${location.icid})`
                  }
                  getOptionSelected={(option, value) =>
                    option?.icid === value?.icid
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Select IC"
                        variant="outlined"
                      />
                    );
                  }}
                  onChange={(event, value) => {
                    if (value?.length === 0) {
                      setState((prevState) => ({
                        ...prevState,
                        selectedIC: value,
                        showCustomerMarkers: false,
                        showingInfoWindow: false,
                        activeMarker: null,
                      }));
                      return;
                    }
                    const uniqueValues = [
                      ...new Set(value.map((value) => value.icid)),
                    ];
                    const selectedLocations = ICDropdownList?.filter((obj) =>
                      uniqueValues?.includes(obj.icid)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      selectedIC: selectedLocations,
                      showingInfoWindow: false,
                      activeMarker: null,
                    }));
                  }}
                />
              )}
              <div className={classes.fixedGoButton}>
                <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    style={{ color: "#004700", padding: 6 }}
                    onClick={() => {
                      updatePageConfig("location");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
                <Button
                  className="ml-2"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (state.stateCityFilterType === "stateCity") {
                      setPageConfig(PAGE_KEYS.LOCATION, {
                        regionFilter: {},
                        stateFilter: {
                          value: state.state,
                          state: state.filters.state,
                        },
                        cityFilter: {
                          value: state.location,
                          city: state.filters.city,
                        },
                      });
                      setState((prevState) => ({
                        ...prevState,
                        region: [],
                        filters: {
                          ...prevState.filters,
                          region: [],
                        },
                      }));
                    } else if (state.stateCityFilterType === "region") {
                      setPageConfig(PAGE_KEYS.LOCATION, {
                        stateFilter: {},
                        cityFilter: {},
                        regionFilter: {
                          value: state.region,
                          region: state.filters.region,
                        },
                      });
                      setState((prevState) => ({
                        ...prevState,
                        city: [],
                        state: [],
                        filters: {
                          ...prevState.filters,
                          city: [],
                          state: [],
                        },
                      }));
                    }
                    setPageConfig(PAGE_KEYS.LOCATION, {
                      isGoButtonIsClicked: true,
                      KAMFilter: state.selectedKam,
                      ICFilter: state.selectedIC,
                      stateCityFilterType: state.stateCityFilterType,
                      PDXCompanyFilter: state.selectedPDXCompany,
                      customerBranchFilter: state.selectedBranchCustomer,
                      customerFilter: state.selectedCustomer,
                      industryTypeFilter: {
                        value: state.industryType,
                        industryType: state.filters.industryType,
                      },
                    });
                    handleGoButtonClick();
                    goButtonIsClicked = true;
                    setState((prevState) => ({
                      ...prevState,
                      showingInfoWindow: false,
                      activeMarker: null,
                      mapZoomEnabled: false,
                      isgoButtonIsClicked: true,
                    }));
                  }}
                >
                  GO
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
        <div
          className={clsx(
            "d-flex d-flex f-align-center",
            classes.drawerPosition
          )}
        >
          <IconButton
            onClick={toggleDrawer}
            style={{
              background: "#ffffff",
              left: state.isDrawerOpen ? 388 : 0,
              transition: state.isDrawerOpen
                ? "left 0.2s ease"
                : "left 0.1s ease",
            }}
            className={classes.toogleDrawer}
            size="large"
            disableRipple
            disableFocusRipple
          >
            {state.isDrawerOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </IconButton>
          <div
            style={{
              width: "100%",
              height: "calc(100vh - 218px)",
              position: "relative",
            }}
          >
            {state.isLoading && (
              <div
                className="loader-overlay"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  zIndex: 1000,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {((goButtonIsClicked === true &&
              !hasData &&
              state.isFilterApplied) ||
              (!hasData && state.isFilterApplied) ||
              (goButtonIsClicked === true &&
                state.allLocation?.length === 0 &&
                state.allBranchLocation?.length === 0 &&
                state.kamLocation?.length === 0 &&
                state.icLocation?.length === 0)) && (
              <div
                className="loader-overlay"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  zIndex: state.isLoading ? 0 : 1,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  variant="body2"
                  text="bold"
                  className="d-flex f-justify-center"
                  style={{ height: "28rem" }}
                >
                  <img
                    className={classes.noRecordsImg}
                    alt="No records"
                    src={NoRecords}
                  />
                </Typography>
              </div>
            )}
            <Map
              google={window.google}
              initialCenter={viewport.center}
              ref={mapRef}
              zoom={viewport.zoom}
              maxZoom={viewport.zoom + 14}
              minZoom={viewport.zoom - 2}
              className={classes.mapStyles}
              onReady={onMapReady}
              {...(!state.mapZoomEnabled &&
                allMarkers.length > 0 && { bounds })}
            >
              <Marker
                icon={markerIcon}
                label={{
                  text: "PDX WEST",
                  color: "black",
                  fontSize: "24px",
                }}
                position={{ lat: 43.303476, lng: -105.363003 }}
              />
              <Marker
                icon={markerIcon}
                label={{
                  text: "PDX NORTH",
                  color: "black",
                  fontSize: "24px",
                }}
                position={{ lat: 46.165219, lng: -69.711652 }}
              />
              <Marker
                icon={markerIcon}
                label={{
                  text: "PDX SOUTH",
                  color: "black",
                  fontSize: "24px",
                }}
                position={{ lat: 31.622284, lng: -81.892257 }}
              />
              <Marker
                icon={markerIcon}
                label={{
                  text: "PDX",
                  color: "black",
                  fontSize: "24px",
                }}
                position={{ lat: 41.749063, lng: -82.511439 }}
              />

              <Polyline
                path={pdxWestCoordinates}
                strokeColor="#000000"
                strokeOpacity={10}
                strokeWeight={3}
              />
              <Polyline
                path={pdxSouthCoordinates}
                strokeColor="#000000"
                strokeOpacity={10}
                strokeWeight={3}
              />
              <Polyline
                path={pdxNorthCoordinates}
                strokeColor="#000000"
                strokeOpacity={10}
                strokeWeight={3}
              />
              {state.showBranchLocations &&
                (state.isFilterApplied
                  ? state.allFilteredBranchLocation
                  : state.allBranchLocation
                )?.map((locations, index) => (
                  <Marker
                    key={index}
                    type="Customer Branch"
                    title={`Customer name - ${
                      locations.customerName
                    }\nCustomer branch location - ${
                      locations.locations
                    }\nAddress - ${[
                      locations.branchAddress?.street_address,
                      locations.branchAddress?.city,
                      locations.branchAddress?.state,
                      locations.branchAddress?.zip_code,
                      locations.branchAddress?.country,
                    ].join(", ")}`}
                    name={
                      locations?.branchAddress?.geo_location.geometry
                        ?.location_type
                    }
                    description={locations.customerName}
                    kam_id={locations.default_kam}
                    address_type={locations.type?.label}
                    phoneNumber={locations.branchAddress?.phone}
                    primaryContact={locations?.branchAddress?.primary_contact}
                    locations={locations.locations}
                    location={locations.branchAddress.address}
                    address={[
                      locations.branchAddress?.street_address,
                      locations.branchAddress?.city,
                      locations.branchAddress?.state,
                      locations.branchAddress?.zip_code,
                      locations.branchAddress?.country,
                    ].join(", ")}
                    position={{
                      lat: locations.branchAddress.geo_location.geometry
                        .location.lat,
                      lng: locations.branchAddress.geo_location.geometry
                        .location.lng,
                    }}
                    customer_branch_id={locations.customer_branch_id}
                    onClick={onMarkerClick}
                    options={{
                      icon: getMarkerIcon(
                        locations,
                        state.activeMarker,
                        state.zoomLevel
                      ),
                    }}
                  />
                ))}
              {state.showKamLocations &&
                (state.isFilterApplied
                  ? state.allkamFilteredLocation
                  : state.kamLocation
                )?.map((locations, index) => {
                  return (
                    <Marker
                      key={index}
                      type="KAM"
                      title={`KAM name - ${`${locations.first_name} ${locations.last_name} (${locations.username})`}\nAddress - ${[
                        locations.kamAddress?.street_address,
                        locations.kamAddress?.city,
                        locations.kamAddress?.state,
                        locations.kamAddress?.zip_code,
                        locations.kamAddress?.country,
                      ].join(", ")}`}
                      address={[
                        locations.kamAddress?.street_address,
                        locations.kamAddress?.city,
                        locations.kamAddress?.state,
                        locations.kamAddress?.zip_code,
                        locations.kamAddress?.country,
                      ].join(", ")}
                      customerName={Array.from(
                        new Set(
                          locations.customerBranches?.map(
                            (name) => name.customerName
                          )
                        )
                      )?.join(", ")}
                      branchLocation={Array.from(
                        new Set(
                          locations.customerBranches?.map(
                            (name) => name.location
                          )
                        )
                      )?.join(", ")}
                      kamName={`${locations.first_name} ${locations.last_name} (${locations.username})`}
                      location={locations.kamAddress?.address}
                      onClick={onMarkerClick}
                      position={{
                        lat: locations.kamAddress?.geo_location?.geometry
                          .location.lat,
                        lng: locations.kamAddress?.geo_location?.geometry
                          .location.lng,
                      }}
                      address_type={locations.type?.label}
                      kamID={locations.kam_id}
                      phoneNumber={locations.kamAddress?.phone}
                      primaryContact={locations.kamAddress?.primary_contact}
                      options={{
                        icon:
                          state.activeMarker &&
                          state.activeMarker?.location ===
                            locations.kamAddress?.address
                            ? {
                                url: kamLocationPinPoint,
                                scaledSize: new window.google.maps.Size(
                                  kamScaledSize,
                                  kamScaledSize
                                ),
                              }
                            : {
                                url: kamLocation,
                                scaledSize: new window.google.maps.Size(
                                  kamScaledSize,
                                  kamScaledSize
                                ),
                              },
                      }}
                    />
                  );
                })}
              {state.showICLocations &&
                (state.isFilterApplied
                  ? state.allICFilteredLocation
                  : state.icLocation
                )?.map((locations, index) => {
                  return (
                    <Marker
                      key={index}
                      type="IC"
                      title={`IC name - ${`${locations.first_name} ${locations.last_name} (${locations.icid})`}\nAddress - ${[
                        locations.contractorAddress?.street_address,
                        locations.contractorAddress?.city,
                        locations.contractorAddress?.state,
                        locations.contractorAddress?.zip_code,
                        locations.contractorAddress?.country,
                      ].join(", ")}`}
                      address={[
                        locations.contractorAddress?.street_address,
                        locations.contractorAddress?.city,
                        locations.contractorAddress?.state,
                        locations.contractorAddress?.zip_code,
                        locations.contractorAddress?.country,
                      ].join(", ")}
                      customerName={locations.customerName}
                      ICName={`${locations.first_name} ${locations.last_name} (${locations.icid})`}
                      kamName={
                        locations.kamDetail?.first_name
                          ? `${locations.kamDetail?.first_name} ${locations.kamDetail?.last_name} (${locations.kamDetail?.username})`
                          : null
                      }
                      location={locations.contractorAddress?.address}
                      primaryContact={
                        locations.contractorAddress?.primary_contact
                      }
                      onClick={onMarkerClick}
                      position={{
                        lat: locations.contractorAddress?.geo_location?.geometry
                          .location.lat,
                        lng: locations.contractorAddress?.geo_location?.geometry
                          .location.lng,
                      }}
                      address_type={locations.type?.label}
                      phoneNumber={locations.contractorAddress.phone}
                      options={{
                        icon:
                          state.activeMarker &&
                          state.activeMarker?.location ===
                            locations.contractorAddress?.address
                            ? {
                                url: ICLocationPinPoint,
                                scaledSize: new window.google.maps.Size(50, 50),
                              }
                            : {
                                url: ICLocation,
                                scaledSize: new window.google.maps.Size(50, 50),
                              },
                      }}
                    />
                  );
                })}
              <InfoWindowEx
                marker={state.activeMarker}
                visible={state.showingInfoWindow}
                onClose={onClose}
              >
                {state.selectedPlace?.type === "KAM" ? (
                  <div>
                    {state.selectedPlace?.customerName !== "" && (
                      <p>
                        <b style={{ color: "#fc4800" }}>Customer Name(s): </b>
                        {state.selectedPlace?.customerName}
                      </p>
                    )}

                    {state.selectedPlace?.branchLocation !== "" && (
                      <p>
                        <b style={{ color: "#6b32cc" }}>
                          Customer branch location(s):{" "}
                        </b>
                        {state.selectedPlace?.branchLocation}
                      </p>
                    )}
                    <p>
                      <b style={{ color: "#095C6B" }}>KAM Name: </b>
                      {state.selectedPlace?.kamName}
                    </p>
                    <p>
                      <b>KAM Address: </b>
                      {state.selectedPlace?.address}
                    </p>
                    {state.selectedPlace?.phoneNumber === null ||
                    state.selectedPlace?.phoneNumber === "" ? null : (
                      <p>
                        <b>Phone: </b>
                        {state.selectedPlace?.phoneNumber || "-"}
                      </p>
                    )}
                    {state.selectedPlace?.primaryContact === "" ||
                    state.selectedPlace?.primaryContact === null ? null : (
                      <p>
                        <b>Primary Contact Name: </b>
                        {state.selectedPlace?.primaryContact || "-"}
                      </p>
                    )}
                    <p className="d-flex f-align-center">
                      <b style={{ color: "#095C6B" }}>
                        View IC(s) for this KAM:{" "}
                      </b>
                      {
                        <IconButton
                          onClick={() => {
                            if (
                              state.icKAMLocation
                                ?.filter((ele) => {
                                  const matchingLocation =
                                    ele.kam_id === state.selectedPlace?.kamID;

                                  return matchingLocation;
                                })
                                ?.map(
                                  (locations) =>
                                    locations.addresses === null ||
                                    locations.addresses === "[]"
                                )
                                ?.every((value) => value)
                            ) {
                              setState((prevState) => ({
                                ...prevState,
                                openICAddressDialog: false,
                                openICNoAddressDialog: true,
                                selectedKamId: state.selectedPlace?.kamID,
                                showClusters: true,
                              }));
                            } else {
                              setState((prevState) => ({
                                ...prevState,
                                openICAddressDialog: true,
                                selectedKamId: state.selectedPlace?.kamID,
                                showClusters: true,
                              }));
                            }
                          }}
                          className={clsx(
                            "ml-2 mr-2 c-pointer",
                            classes.VisibilityIconColor
                          )}
                          style={{ padding: "2px" }}
                        >
                          <VisibilityIcon color="#004700" />
                        </IconButton>
                      }
                    </p>
                  </div>
                ) : state.selectedPlace?.type === "IC" ? (
                  <div>
                    {state.selectedPlace?.customerName !== null && (
                      <p>
                        <b style={{ color: "#fc4800" }}>Customer Name: </b>
                        {state.selectedPlace?.customerName}
                      </p>
                    )}
                    {state.selectedPlace?.kamName !== null && (
                      <p>
                        <b style={{ color: "#095C6B" }}>KAM Name: </b>
                        {state.selectedPlace?.kamName}
                      </p>
                    )}
                    <p>
                      <b style={{ color: "#0087ED" }}>IC Name: </b>
                      {state.selectedPlace?.ICName}
                    </p>
                    <p>
                      <b>IC Address: </b>
                      {state.selectedPlace?.address}
                    </p>
                    {state.selectedPlace?.phoneNumber === null ||
                    state.selectedPlace?.phoneNumber === "" ? null : (
                      <p>
                        <b>Phone: </b>
                        {state.selectedPlace?.phoneNumber || "-"}
                      </p>
                    )}
                    {state.selectedPlace?.primaryContact === "" ||
                    state.selectedPlace?.primaryContact === null ? null : (
                      <p>
                        <b>Primary Contact Name: </b>
                        {state.selectedPlace?.primaryContact || "-"}
                      </p>
                    )}
                  </div>
                ) : (
                  <div>
                    <p
                      style={{
                        marginTop: !!state.selectedPlace?.locations
                          ? "12px"
                          : "2px",
                      }}
                    >
                      <b style={{ color: "#fc4800" }}>Customer Name: </b>
                      {state.selectedPlace?.description}
                    </p>
                    {!!state.selectedPlace?.locations ? (
                      <p>
                        <b style={{ color: "#6b32cc" }}>
                          Customer branch location:{" "}
                        </b>
                        {state.selectedPlace?.locations}
                      </p>
                    ) : null}
                    <p
                      style={{
                        marginBottom: !!state.selectedPlace?.locations
                          ? "6px"
                          : "5px",
                      }}
                    >
                      <b>
                        {!state.selectedPlace?.locations
                          ? "Customer Address: "
                          : "Customer branch Address: "}{" "}
                      </b>
                      {state.selectedPlace?.address}
                    </p>
                    {state.selectedPlace?.locations && (
                      <p
                        className="d-flex f-align-center"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                      >
                        <b style={{ color: "#095C6B" }}>
                          View KAM for this customer branch:{" "}
                        </b>
                        {
                          <IconButton
                            onClick={() => {
                              if (
                                state.kamLocation
                                  ?.filter((kamLocation) => {
                                    const matchingLocation =
                                      kamLocation.customerBranch?.some(
                                        (item) =>
                                          item.customer_branch_id ===
                                          state.selectedPlace
                                            ?.customer_branch_id
                                      );
                                    return matchingLocation;
                                  })
                                  ?.map(
                                    (locations) =>
                                      locations.addresses === null ||
                                      locations.addresses === "[]"
                                  )
                                  ?.every((value) => value)
                              ) {
                                setState((prevState) => ({
                                  ...prevState,
                                  openKamAddressDialog: false,
                                  openKamNoAddressDialog: true,
                                  kam_id: state.selectedPlace?.kam_id[0],
                                  customer_id: null,
                                  customer_branch_id:
                                    state.selectedPlace?.customer_branch_id,
                                  showClusters: true,
                                }));
                              } else {
                                setState((prevState) => ({
                                  ...prevState,
                                  openKamAddressDialog: true,
                                  kam_id: state.selectedPlace?.kam_id[0],
                                  showClusters: true,
                                  customer_id: null,
                                  seeKamLocation: true,
                                  customer_branch_id:
                                    state.selectedPlace?.customer_branch_id,
                                }));
                              }
                            }}
                            className={clsx(
                              "ml-2 mr-2 c-pointer",
                              classes.VisibilityIconColor
                            )}
                            style={{ padding: "2px" }}
                          >
                            <VisibilityIcon color="#004700" />
                          </IconButton>
                        }
                      </p>
                    )}
                    {state.selectedPlace?.locations && (
                      <p
                        className="d-flex f-align-center"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                      >
                        <b style={{ color: "#095C6B" }}>
                          View nearby KAM(s) for this customer branch:{" "}
                        </b>
                        {
                          <IconButton
                            onClick={() => {
                              handleKamByLocation();
                              setState((prevState) => ({
                                ...prevState,
                                openKamAddressDialog: true,
                                kamMapZoomEnabled: false,
                                showClusters: false,
                                kam_id: state.selectedPlace?.kam_id[0],
                                customer_id: null,
                                customer_branch_id:
                                  state.selectedPlace?.customer_branch_id,
                              }));
                            }}
                            className={clsx(
                              "ml-2 mr-2 c-pointer",
                              classes.VisibilityIconColor
                            )}
                            style={{ padding: "2px" }}
                          >
                            <VisibilityIcon color="#004700" />
                          </IconButton>
                        }
                      </p>
                    )}
                    {state.selectedPlace?.phoneNumber === null ||
                    state.selectedPlace?.phoneNumber === "" ? null : (
                      <p
                        style={{
                          marginTop: !!state.selectedPlace?.locations
                            ? "6px"
                            : "5px",
                        }}
                      >
                        <b>Phone: </b>
                        {state.selectedPlace?.phoneNumber || "-"}
                      </p>
                    )}
                    {state.selectedPlace?.primaryContact === "" ||
                    state.selectedPlace?.primaryContact === null ? null : (
                      <p
                        style={{
                          marginTop: !!state.selectedPlace?.locations
                            ? "6px"
                            : "5px",
                        }}
                      >
                        <b>Primary Contact Name: </b>
                        {state.selectedPlace?.primaryContact || "-"}
                      </p>
                    )}
                  </div>
                )}
              </InfoWindowEx>
            </Map>
          </div>
        </div>
        <div
          className="d-flex mt-3 f-align-center f-justify-between"
          style={{ flexWrap: "wrap" }}
        >
          <div className="d-flex mt-3 f-align-center">
            {state.showBranchLocations && (
              <>
                <div className="d-flex f-align-center">
                  <img
                    src={afterMarket}
                    alt="afterMarket"
                    className={clsx("mr-2 ml-2", classes.legendHint)}
                  />
                </div>
                <Typography variant="body2">Aftermarket</Typography>
              </>
            )}
            {state.showBranchLocations && (
              <>
                <div className="d-flex f-align-center">
                  <img
                    src={oem}
                    alt="oem"
                    className={clsx("mr-2 ml-2", classes.OEMLegendHint)}
                  />
                </div>
                <Typography variant="body2">OEM</Typography>
              </>
            )}
            {state.showBranchLocations && (
              <>
                <div className="d-flex f-align-center">
                  <img
                    src={tireDistributor}
                    alt="tireDistributor"
                    className={clsx("mr-2 ml-2", classes.legendHint)}
                  />
                </div>
                <Typography variant="body2">Tire Distributor</Typography>
              </>
            )}
            {state.showKamLocations && (
              <>
                <div className="d-flex f-align-center">
                  <PersonPinCircleIcon
                    className={clsx("mr-2 ml-2", classes.kamLegendHint)}
                  />
                </div>
                <Typography variant="body2">KAM Location</Typography>
              </>
            )}
            {state.showICLocations && (
              <>
                <div className="d-flex f-align-center">
                  <DirectionsCarIcon
                    className={clsx("mr-2 ml-2", classes.ICLegendHint)}
                  />
                </div>
                <Typography variant="body2">IC Location</Typography>
              </>
            )}
          </div>
          {state.showBranchLocations && (
            <div
              className="d-flex mt-3 f-align-center"
              style={{ marginRight: "32px" }}
            >
              <>
                <div className={clsx("mr-2 ml-2", classes.PDXGreen)}></div>
                <Typography variant="body2">
                  Parts Distribution Xpress (PDX)
                </Typography>
              </>
              <>
                <div className={clsx("mr-2 ml-2", classes.PDXYellow)}></div>
                <Typography variant="body2">Auto Parts Xpress (APX)</Typography>
              </>
              <>
                <div className={clsx("mr-2 ml-2", classes.PDXBlue)}></div>
                <Typography variant="body2">PDX North</Typography>
              </>
              <>
                <div className={clsx("mr-2 ml-2", classes.PDXPurple)}></div>
                <Typography variant="body2">PDX South</Typography>
              </>
              <>
                <div className={clsx("mr-2 ml-2", classes.PDXOrange)}></div>
                <Typography variant="body2">PDX West</Typography>
              </>
            </div>
          )}
        </div>
        {state.openKamAddressDialog && (
          <Dialog
            open={true}
            onClose={handleKamAddressClose}
            classes={{ paper: classes.paperWidthSm }}
          >
            <Dialog.Title hasClose>
              <div>
                <Typography variant="h4" color="primary" className="ml-2">
                  KAM Locations
                </Typography>
                <Typography variant="body2" style={{ marginLeft: "10px" }}>
                  View KAM Locations.
                </Typography>
              </div>
            </Dialog.Title>
            <Dialog.Content>
              <div className="mb-2">
                {!state.seeKamLocation && (
                  <div
                    className="d-flex f-align-center"
                    style={{ flexWrap: "wrap" }}
                  >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          value={state.kamFilterType}
                          onChange={(evt) => {
                            const { value } = evt.target;
                            setState((prevState) => ({
                              ...prevState,
                              kamFilterType: value,
                              isStateFilterApplied: false,
                              radius:
                                state.kamAddressByRadius.length === 0
                                  ? ""
                                  : state.radius,
                              isRadiusFilterApplied: false,
                              showingKamInfoWindow: false,
                              activeKamMarker: null,
                            }));
                          }}
                        >
                          <FormControlLabel
                            value="state"
                            control={<Radio size="small" color="primary" />}
                            label="View By State/City"
                          />
                          <FormControlLabel
                            value="radius"
                            control={<Radio size="small" color="primary" />}
                            label="View By Radius"
                          />
                        </RadioGroup>
                      </FormControl>
                      {state.kamFilterType === "state" ? (
                        <div className="d-flex f-align-center">
                          <Autocomplete
                            className={classes.fields}
                            ChipProps={{
                              component: (a) => {
                                return (
                                  <div>
                                    <Tooltip
                                      placement={"top-start"}
                                      title={
                                        a.children[1]?.props?.children || ""
                                      }
                                    >
                                      <Chip
                                        size="small"
                                        className={classes.chip}
                                        label={
                                          <Typography variant="body3" noWrap>
                                            {a.children[1]?.props?.children
                                              ?.length > 4
                                              ? a.children[1]?.props?.children.slice(
                                                  0,
                                                  4
                                                ) + "..."
                                              : a.children[1]?.props?.children}
                                          </Typography>
                                        }
                                        deleteIcon
                                        onDelete={(evt) => {
                                          a.children[2]?.props?.onClick(evt);
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                );
                              },
                            }}
                            disableCloseOnSelect={true}
                            size="small"
                            value={state.nearKamInstate || []}
                            multiple
                            options={state.kamStateList}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon />}
                                  checkedIcon={<CheckBoxIcon />}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </React.Fragment>
                            )}
                            getOptionLabel={(option) => option || ""}
                            getOptionSelected={(option, value) =>
                              option === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                variant="outlined"
                              />
                            )}
                            onChange={(evt, value) => {
                              if (value.length === 0) {
                                return setState((prevState) => ({
                                  ...prevState,
                                  nearKamInstate: value,
                                  showingKamInfoWindow: false,
                                  activeKamMarker: null,
                                  nearKamInCity: null,
                                  filters: {
                                    ...prevState.filters,
                                    kamState: [],
                                  },
                                }));
                              }
                              const stateName = value?.map((item) => item);

                              setState((prevState) => ({
                                ...prevState,
                                nearKamInstate: value,
                                showingKamInfoWindow: false,
                                activeKamMarker: null,
                                nearKamInCity: null,
                                filters: {
                                  ...prevState.filters,
                                  kamState: [
                                    {
                                      field: "state",
                                      type: "iRegexp",
                                      value: stateName,
                                    },
                                  ],
                                  kamCity: [],
                                },
                              }));
                            }}
                          />
                          <Autocomplete
                            className={classes.fields}
                            disableCloseOnSelect={true}
                            size="small"
                            ChipProps={{
                              component: (a) => {
                                return (
                                  <div>
                                    <Tooltip
                                      placement={"top-start"}
                                      title={
                                        a.children[1]?.props?.children || ""
                                      }
                                    >
                                      <Chip
                                        size="small"
                                        className={classes.chip}
                                        label={
                                          <Typography variant="body3" noWrap>
                                            {a.children[1]?.props?.children
                                              ?.length > 4
                                              ? a.children[1]?.props?.children.slice(
                                                  0,
                                                  4
                                                ) + "..."
                                              : a.children[1]?.props?.children}
                                          </Typography>
                                        }
                                        deleteIcon
                                        onDelete={(evt) => {
                                          a.children[2]?.props?.onClick(evt);
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                );
                              },
                            }}
                            value={state?.nearKamInCity || []}
                            multiple
                            options={
                              state.nearKamInstate.length > 0
                                ? [...new Set(kamMatchedLocations)].sort(
                                    (a, b) =>
                                      Intl.Collator().compare(a || "", b || "")
                                  ) || []
                                : [
                                    ...new Set(
                                      Object.values(
                                        state.kamLocationList
                                      ).flatMap((cityList) => cityList)
                                    ),
                                  ].sort((a, b) =>
                                    Intl.Collator().compare(a || "", b || "")
                                  )
                            }
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon />}
                                  checkedIcon={<CheckBoxIcon />}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </React.Fragment>
                            )}
                            getOptionLabel={(option) => option || ""}
                            getOptionSelected={(option, value) =>
                              option === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="City"
                                variant="outlined"
                              />
                            )}
                            onChange={(evt, value) => {
                              if (value.length === 0) {
                                return setState((prevState) => ({
                                  ...prevState,
                                  showingKamInfoWindow: false,
                                  activeKamMarker: null,
                                  nearKamInCity: value,
                                  filters: {
                                    ...prevState.filters,
                                    kamCity: [],
                                  },
                                }));
                              }

                              setState((prevState) => ({
                                ...prevState,
                                nearKamInCity: value,
                                showingKamInfoWindow: false,
                                activeKamMarker: null,
                                filters: {
                                  ...prevState.filters,
                                  kamCity: [
                                    {
                                      field: "city",
                                      type: "iRegexp",
                                      value: value,
                                    },
                                  ],
                                },
                              }));
                            }}
                          />
                        </div>
                      ) : (
                        <div>
                          <TextField
                            required
                            className={classes.field}
                            classes={{ root: classes.inputTextFieldBorder }}
                            size="small"
                            type="number"
                            name="radius"
                            label="Enter Miles"
                            variant="outlined"
                            value={state.radius}
                            onChange={(evt) => {
                              const value = evt.currentTarget.value;
                              setState((prevState) => ({
                                ...prevState,
                                radius: value,
                                showingKamInfoWindow: false,
                                activeKamMarker: null,
                              }));
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <Button
                        className="mb-3 mr-2"
                        variant="contained"
                        color="primary"
                        onClick={
                          state.kamFilterType === "state"
                            ? () => {
                                handleKamByLocation(
                                  state.filters,
                                  state.search
                                );
                                setState((prevState) => ({
                                  ...prevState,
                                  isStateFilterApplied: true,
                                  showingKamInfoWindow: false,
                                  kamMapZoomEnabled: false,
                                  activeKamMarker: null,
                                  isKamGoButtonIsClicked: true,
                                  showClusters: true,
                                }));
                              }
                            : () => {
                                if (state.radius === "") {
                                  toast.error("Radius is required.");
                                  return;
                                }
                                handleNearbyKamByRadius();
                                setState((prevState) => ({
                                  ...prevState,
                                  isRadiusFilterApplied: true,
                                  showingKamInfoWindow: false,
                                  kamMapZoomEnabled: false,
                                  activeKamMarker: null,
                                  isKamGoButtonIsClicked: true,
                                  showClusters: true,
                                }));
                              }
                        }
                      >
                        GO
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  height: `${
                    state.seeKamLocation
                      ? "calc(100vh - 300px)"
                      : "calc(100vh - 342px)"
                  }`,
                  position: "relative",
                }}
              >
                {state.isKamLoading && (
                  <div
                    className="loader-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      zIndex: 1000,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}

                {state.kamAddressByRadius.length === 0 &&
                  state.isRadiusFilterApplied && (
                    <div
                      className="loader-overlay"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        zIndex: state.isKamLoading ? 0 : 1000,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography
                        variant="body2"
                        text="bold"
                        className="d-flex f-justify-center"
                        style={{ height: "28rem" }}
                      >
                        <img
                          className={classes.noRecordsImg}
                          alt="No Records"
                          src={NoRecords}
                        />
                      </Typography>
                    </div>
                  )}

                <Map
                  google={window.google}
                  initialCenter={{
                    lat: 39.3455632,
                    lng: -91.2947461,
                  }}
                  ref={state.showClusters ? kamMapRef : null}
                  zoom={kamViewport.zoom}
                  maxZoom={kamViewport.zoom + 14}
                  minZoom={kamViewport.zoom - 2}
                  className={classes.mapStyles}
                  {...(!state.kamMapZoomEnabled &&
                    allKAMMarkers.length > 0 && { bounds: kamBounds })}
                >
                  {state.isViewingByRadius
                    ? state.kamFilterType !== "state" &&
                      kamLocationByStateCity?.map((locations, index) => {
                        return (
                          <Marker
                            key={index}
                            title={`KAM name - ${`${locations.kam_first_name} ${locations.kam_last_name} (${locations.kam_username})`},\nAddress - ${[
                              locations?.street_address,
                              locations?.city,
                              locations?.state,
                              locations?.zip_code,
                              locations?.country,
                            ].join(", ")}`}
                            address={[
                              locations?.street_address,
                              locations?.city,
                              locations?.state,
                              locations?.zip_code,
                              locations?.country,
                            ].join(", ")}
                            customer_name={Array.from(
                              new Set(
                                locations.customerBranches?.map(
                                  (name) => name.customerName
                                )
                              )
                            )?.join(", ")}
                            branch_location={Array.from(
                              new Set(
                                locations.customerBranches?.map(
                                  (name) => name.location
                                )
                              )
                            )?.join(", ")}
                            kamName={`${locations.kam_first_name} ${locations.kam_last_name} (${locations.kam_username})`}
                            location={locations?.address}
                            onClick={onKamMarkerClick}
                            position={{
                              lat: locations.geo_location?.geometry.location
                                .lat,
                              lng: locations?.geo_location?.geometry.location
                                .lng,
                            }}
                            address_type={locations.address_type?.label}
                            phoneNumber={locations?.phone}
                            primaryContact={locations?.primary_contact}
                            options={{
                              icon:
                                state.activeKamMarker &&
                                state.activeKamMarker?.location ===
                                  locations?.address
                                  ? {
                                      url: kamLocationPinPoint,
                                      scaledSize: new window.google.maps.Size(
                                        55,
                                        55
                                      ),
                                    }
                                  : {
                                      url: kamLocation,
                                      scaledSize: new window.google.maps.Size(
                                        55,
                                        55
                                      ),
                                    },
                            }}
                          />
                        );
                      })
                    : !state.isViewingByStateCity &&
                      state.seeKamLocation &&
                      state.kamLocation
                        ?.filter((kamLocation) => {
                          const matchingLocation =
                            kamLocation.customerBranch?.find(
                              (item) =>
                                item.customer_branch_id ===
                                state.customer_branch_id
                            );
                          return matchingLocation;
                        })
                        ?.map((locations, index) => {
                          return (
                            <Marker
                              key={index}
                              title={`KAM name - ${`${locations.first_name} ${locations.last_name} (${locations.username})`}\nAddress - ${[
                                locations.kamAddress?.street_address,
                                locations.kamAddress?.city,
                                locations.kamAddress?.state,
                                locations.kamAddress?.zip_code,
                                locations.kamAddress?.country,
                              ].join(", ")}`}
                              address={[
                                locations.kamAddress?.street_address,
                                locations.kamAddress?.city,
                                locations.kamAddress?.state,
                                locations.kamAddress?.zip_code,
                                locations.kamAddress?.country,
                              ].join(", ")}
                              customerName={locations.customerName}
                              branchLocation={locations.locations}
                              kamName={`${locations.first_name} ${locations.last_name} (${locations.username})`}
                              location={locations.kamAddress?.address}
                              onClick={onKamMarkerClick}
                              position={{
                                lat: locations.kamAddress?.geo_location
                                  ?.geometry.location.lat,
                                lng: locations.kamAddress?.geo_location
                                  ?.geometry.location.lng,
                              }}
                              address_type={locations.type?.label}
                              phoneNumber={locations.kamAddress?.phone}
                              primaryContact={
                                locations.kamAddress?.primary_contact
                              }
                              options={{
                                icon:
                                  state.activeKamMarker &&
                                  state.activeKamMarker?.location ===
                                    locations.kamAddress?.address
                                    ? {
                                        url: kamLocationPinPoint,
                                        scaledSize: new window.google.maps.Size(
                                          55,
                                          55
                                        ),
                                      }
                                    : {
                                        url: kamLocation,
                                        scaledSize: new window.google.maps.Size(
                                          55,
                                          55
                                        ),
                                      },
                              }}
                            />
                          );
                        })}
                  {state.isViewingByStateCity &&
                    state.kamFilterType === "state" &&
                    state.kamAddressByStateCity?.map((locations, index) => {
                      return (
                        <Marker
                          key={index}
                          title={`KAM name - ${`${locations.first_name} ${locations.last_name} (${locations.username})`}\nAddress - ${[
                            locations.kamAddress?.street_address,
                            locations.kamAddress?.city,
                            locations.kamAddress?.state,
                            locations.kamAddress?.zip_code,
                            locations.kamAddress?.country,
                          ]?.join(", ")}`}
                          address={[
                            locations.kamAddress?.street_address,
                            locations.kamAddress?.city,
                            locations.kamAddress?.state,
                            locations.kamAddress?.zip_code,
                            locations.kamAddress?.country,
                          ]?.join(", ")}
                          customer_name={locations.customerBranches
                            ?.map((name) => name.customerName)
                            ?.join(", ")}
                          branch_location={locations.customerBranches
                            ?.map((name) => name.location)
                            ?.join(", ")}
                          kamName={`${locations.first_name} ${locations.last_name} (${locations.username})`}
                          location={locations.kamAddress?.address}
                          onClick={onKamMarkerClick}
                          position={{
                            lat: locations.kamAddress?.geo_location?.geometry
                              .location.lat,
                            lng: locations.kamAddress?.geo_location?.geometry
                              .location.lng,
                          }}
                          address_type={locations.first_name}
                          phoneNumber={locations.kamAddress?.phone}
                          primaryContact={locations.kamAddress?.primary_contact}
                          options={{
                            icon:
                              state.activeKamMarker &&
                              state.activeKamMarker?.location ===
                                locations.kamAddress?.address
                                ? {
                                    url: kamLocationPinPoint,
                                    scaledSize: new window.google.maps.Size(
                                      55,
                                      55
                                    ),
                                  }
                                : {
                                    url: kamLocation,
                                    scaledSize: new window.google.maps.Size(
                                      55,
                                      55
                                    ),
                                  },
                          }}
                        />
                      );
                    })}
                  {state.allBranchLocation?.map(
                    (locations, index) =>
                      (state.customer_id
                        ? state.customer_id === locations.customer_id
                        : state.customer_branch_id
                        ? state.customer_branch_id ===
                          locations.customer_branch_id
                        : false) && (
                        <Marker
                          key={index}
                          type="Customer Branch"
                          title={`Customer name - ${
                            locations.customerName
                          }\nCustomer branch location - ${
                            locations.locations
                          }\nAddress - ${[
                            locations.branchAddress?.street_address,
                            locations.branchAddress?.city,
                            locations.branchAddress?.state,
                            locations.branchAddress?.zip_code,
                            locations.branchAddress?.country,
                          ].join(", ")}`}
                          name={
                            locations?.branchAddress?.geo_location.geometry
                              ?.location_type
                          }
                          description={locations.customerName}
                          kam_id={locations.default_kam}
                          locations={locations.locations}
                          location={locations.branchAddress.address}
                          address_type={locations.type?.label}
                          phoneNumber={locations.branchAddress?.phone}
                          primaryContact={
                            locations.branchAddress.primary_contact
                          }
                          address={[
                            locations.branchAddress?.street_address,
                            locations.branchAddress?.city,
                            locations.branchAddress?.state,
                            locations.branchAddress?.zip_code,
                            locations.branchAddress?.country,
                          ].join(", ")}
                          position={{
                            lat: locations.branchAddress.geo_location.geometry
                              .location.lat,
                            lng: locations.branchAddress.geo_location.geometry
                              .location.lng,
                          }}
                          customer_branch_id={locations.customer_branch_id}
                          onClick={onKamMarkerClick}
                          options={{
                            icon: getMarkerIcon(
                              locations,
                              state.activeKamMarker,
                              state.kamZoomLevel
                            ),
                          }}
                        />
                      )
                  )}

                  <InfoWindow
                    marker={state.activeKamMarker}
                    visible={state.showingKamInfoWindow}
                    onClose={onKamMarkerClose}
                  >
                    <div>
                      {!state.selectedKamPlace?.customerDescription ? null : (
                        <p>
                          <b style={{ color: "#fc4800" }}>Customer Name: </b>
                          {state.selectedKamPlace?.customerDescription}
                        </p>
                      )}
                      {!state.selectedKamPlace?.description ? null : (
                        <p>
                          <b style={{ color: "#fc4800" }}>Customer Name: </b>
                          {state.selectedKamPlace?.description}
                        </p>
                      )}
                      {!state.selectedKamPlace?.customerDescription ? null : (
                        <p>
                          <b>Customer Address: </b>
                          {state.selectedKamPlace?.address}
                        </p>
                      )}
                      {!state.selectedKamPlace?.description ? null : (
                        <p>
                          <b style={{ color: "#6b32cc" }}>
                            {" "}
                            Customer branch location:{" "}
                          </b>
                          {state.selectedKamPlace?.locations}
                        </p>
                      )}
                      {!state.selectedKamPlace?.description ? null : (
                        <p>
                          <b> Customer branch Address: </b>
                          {state.selectedKamPlace?.address}
                        </p>
                      )}
                      {!state.selectedKamPlace?.customerName ? null : (
                        <p>
                          <b style={{ color: "#fc4800" }}>Customer Name: </b>
                          {state.selectedKamPlace?.customerName}
                        </p>
                      )}
                      {!state.selectedKamPlace?.branchLocation ? null : (
                        <p>
                          <b style={{ color: "#6b32cc" }}>Branch Location: </b>
                          {state.selectedKamPlace?.branchLocation}
                        </p>
                      )}
                      {!state.selectedKamPlace?.customer_name ? null : (
                        <>
                          {" "}
                          <p>
                            <b style={{ color: "#fc4800" }}>
                              Customer Name(s):{" "}
                            </b>
                            {state.selectedKamPlace?.customer_name}
                          </p>
                          <p>
                            <b style={{ color: "#6b32cc" }}>
                              Customer branch location(s):{" "}
                            </b>
                            {state.selectedKamPlace?.branch_location}
                          </p>
                        </>
                      )}
                      {!state.selectedKamPlace?.kamName ? null : (
                        <p>
                          <b style={{ color: "#095C6B" }}>KAM Name: </b>
                          {state.selectedKamPlace?.kamName}
                        </p>
                      )}
                      {!state.selectedKamPlace?.kamName ? null : (
                        <p>
                          <b>KAM Address: </b>
                          {state.selectedKamPlace?.address}
                        </p>
                      )}
                      {state.selectedKamPlace?.phoneNumber === null ||
                      state.selectedKamPlace?.phoneNumber === "" ? null : (
                        <p>
                          <b>Phone: </b>
                          {state.selectedKamPlace?.phoneNumber || "-"}
                        </p>
                      )}
                      {state.selectedKamPlace?.primaryContact === null ||
                      state.selectedKamPlace?.primaryContact === "" ? null : (
                        <p>
                          <b>Primary Contact Name: </b>
                          {state.selectedKamPlace?.primaryContact || "-"}
                        </p>
                      )}
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </Dialog.Content>
          </Dialog>
        )}
        {state.openKamNoAddressDialog && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.paperSize,
            }}
            open={!!state.openKamNoAddressDialog}
            contentText={
              <>
                <Typography variant="body2" className="mt-2">
                  {!state.selectedPlace?.locations
                    ? " There's no KAM address for this customer."
                    : " There's no KAM address for this customer branch."}
                </Typography>
              </>
            }
            onConfirm={() =>
              setState((prevState) => ({
                ...prevState,
                openKamNoAddressDialog: false,
                showClusters: false,
              }))
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                openKamNoAddressDialog: false,
                showClusters: false,
              }))
            }
            positiveActionLabel="OK"
            negativeActionLabel={false}
          />
        )}
        {state.openICAddressDialog && (
          <Dialog
            open={true}
            onClose={handleICAddressClose}
            classes={{ paper: classes.paperWidthSm }}
          >
            <Dialog.Title hasClose>
              <div>
                <Typography variant="h4" color="primary" className="ml-2">
                  IC Locations
                </Typography>
                <Typography variant="body2" style={{ marginLeft: "10px" }}>
                  View IC Locations.
                </Typography>
              </div>
            </Dialog.Title>
            <Dialog.Content>
              <div
                style={{
                  width: "100%",
                  height: "calc(100vh - 300px)",
                  position: "relative",
                }}
              >
                <Map
                  google={window.google}
                  initialCenter={{
                    lat: 39.3455632,
                    lng: -91.2947461,
                  }}
                  ref={state.showClusters ? icMapRef : null}
                  zoom={icViewport.zoom}
                  maxZoom={icViewport.zoom + 14}
                  minZoom={icViewport.zoom - 2}
                  className={classes.mapStyles}
                  {...(!state.kamICMapZoomEnabled &&
                    allICKAMMarkers.length > 0 && { bounds: icBounds })}
                >
                  {state.icKAMLocation
                    ?.filter((ele) => {
                      const matchingLocation =
                        ele.kam_id === state.selectedPlace?.kamID;

                      return matchingLocation;
                    })
                    ?.map((locations, index) => {
                      return (
                        <Marker
                          key={index}
                          title={`IC name - ${`${locations.first_name} ${locations.last_name} (${locations.icid})`}\nAddress - ${[
                            locations.contractorAddress?.street_address,
                            locations.contractorAddress?.city,
                            locations.contractorAddress?.state,
                            locations.contractorAddress?.zip_code,
                            locations.contractorAddress?.country,
                          ].join(", ")}`}
                          icAddress={[
                            locations.contractorAddress?.street_address,
                            locations.contractorAddress?.city,
                            locations.contractorAddress?.state,
                            locations.contractorAddress?.zip_code,
                            locations.contractorAddress?.country,
                          ].join(", ")}
                          customerName={locations.customerName}
                          icName={`${locations.first_name} ${locations.last_name} (${locations.icid})`}
                          kamName={`${locations.kamDetails.first_name} ${locations.kamDetails.last_name} (${locations.kamDetails.username})`}
                          location={locations.contractorAddress?.address}
                          onClick={onICMarkerClick}
                          position={{
                            lat: locations.contractorAddress?.geo_location
                              ?.geometry.location.lat,
                            lng: locations.contractorAddress?.geo_location
                              ?.geometry.location.lng,
                          }}
                          address_type={locations.type?.label}
                          phoneNumber={locations.contractorAddress?.phone}
                          primaryContact={
                            locations.contractorAddress?.primary_contact
                          }
                          options={{
                            icon:
                              state.activeICMarker &&
                              state.activeICMarker?.location ===
                                locations.contractorAddress?.address
                                ? {
                                    url: ICLocationPinPoint,
                                    scaledSize: new window.google.maps.Size(
                                      55,
                                      55
                                    ),
                                  }
                                : {
                                    url: ICLocation,
                                    scaledSize: new window.google.maps.Size(
                                      55,
                                      55
                                    ),
                                  },
                          }}
                        />
                      );
                    })}
                  {state.kamLocation
                    ?.filter(
                      (kamLocation) =>
                        state.selectedKamId === kamLocation.kam_id
                    )
                    ?.map((locations, index) => {
                      return (
                        <Marker
                          key={index}
                          title={`KAM name - ${`${locations.first_name} ${locations.last_name} (${locations.username})`}\nAddress - ${[
                            locations.kamAddress?.street_address,
                            locations.kamAddress?.city,
                            locations.kamAddress?.state,
                            locations.kamAddress?.zip_code,
                            locations.kamAddress?.country,
                          ]?.join(", ")}`}
                          address={[
                            locations.kamAddress?.street_address,
                            locations.kamAddress?.city,
                            locations.kamAddress?.state,
                            locations.kamAddress?.zip_code,
                            locations.kamAddress?.country,
                          ]?.join(", ")}
                          customer_name={locations.customerBranches
                            ?.map((name) => name.customerName)
                            ?.join(", ")}
                          branch_location={locations.customerBranches
                            ?.map((name) => name.location)
                            ?.join(", ")}
                          kamName={`${locations.first_name} ${locations.last_name} (${locations.username})`}
                          location={locations.kamAddress?.address}
                          onClick={onICMarkerClick}
                          position={{
                            lat: locations.kamAddress?.geo_location?.geometry
                              .location.lat,
                            lng: locations.kamAddress?.geo_location?.geometry
                              .location.lng,
                          }}
                          address_type={locations.first_name}
                          phoneNumber={locations.kamAddress?.phone}
                          primaryContact={locations.kamAddress?.primary_contact}
                          options={{
                            icon:
                              state.activeICMarker &&
                              state.activeICMarker?.location ===
                                locations.kamAddress?.address
                                ? {
                                    url: kamLocationPinPoint,
                                    scaledSize: new window.google.maps.Size(
                                      55,
                                      55
                                    ),
                                  }
                                : {
                                    url: kamLocation,
                                    scaledSize: new window.google.maps.Size(
                                      55,
                                      55
                                    ),
                                  },
                          }}
                        />
                      );
                    })}

                  <InfoWindow
                    marker={state.activeICMarker}
                    visible={state.showingICInfoWindow}
                    onClose={onICMarkerClose}
                  >
                    <div>
                      {!state.selectedICPlace?.customerName ? null : (
                        <p>
                          <b style={{ color: "#fc4800" }}>Customer Name: </b>
                          {state.selectedICPlace?.customerName}
                        </p>
                      )}
                      {!state.selectedICPlace?.customer_name ? null : (
                        <>
                          {" "}
                          <p>
                            <b style={{ color: "#fc4800" }}>
                              Customer Name(s):{" "}
                            </b>
                            {state.selectedICPlace?.customer_name}
                          </p>
                          <p>
                            <b style={{ color: "#6b32cc" }}>
                              Customer branch location(s):{" "}
                            </b>
                            {state.selectedICPlace?.branch_location}
                          </p>
                        </>
                      )}
                      {!state.selectedICPlace?.kamName ? null : (
                        <p>
                          <b style={{ color: "#095C6B" }}>KAM Name: </b>
                          {state.selectedICPlace?.kamName}
                        </p>
                      )}
                      {!state.selectedICPlace?.icName ? null : (
                        <p>
                          <b style={{ color: "#0087ED" }}>IC Name: </b>
                          {state.selectedICPlace?.icName}
                        </p>
                      )}
                      {!state.selectedICPlace?.address ? null : (
                        <p>
                          <b>KAM Address: </b>
                          {state.selectedICPlace?.address}
                        </p>
                      )}
                      {!state.selectedICPlace?.icAddress ? null : (
                        <p>
                          <b>IC Address: </b>
                          {state.selectedICPlace?.icAddress}
                        </p>
                      )}
                      {state.selectedICPlace?.phoneNumber === null ||
                      state.selectedICPlace?.phoneNumber === "" ? null : (
                        <p>
                          <b>Phone: </b>
                          {state.selectedICPlace?.phoneNumber || "-"}
                        </p>
                      )}
                      {state.selectedICPlace?.primaryContact === null ||
                      state.selectedICPlace?.primaryContact === "" ? null : (
                        <p>
                          <b>Primary Contact Name: </b>
                          {state.selectedICPlace?.primaryContact || "-"}
                        </p>
                      )}
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </Dialog.Content>
          </Dialog>
        )}
        {state.openICNoAddressDialog && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.paperSize,
            }}
            open={!!state.openICNoAddressDialog}
            contentText={
              <>
                <Typography variant="body2" className="mt-2">
                  There's no IC address for this KAM.
                </Typography>
              </>
            }
            onConfirm={() =>
              setState((prevState) => ({
                ...prevState,
                openICNoAddressDialog: false,
                showClusters: false,
              }))
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                openICNoAddressDialog: false,
                showClusters: false,
              }))
            }
            positiveActionLabel="OK"
            negativeActionLabel={false}
          />
        )}
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyDqd-JnU7PMIEZWnZiXsBHJFVcTelOlAiA",
})(CustomerLocation);
