import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import SharedService from "modules/shared/service";
import { AppContext } from "shared/contexts";
import { PAGE_KEYS } from "shared/constants";
import { PERMISSION } from "modules/shared/constants";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Form from "./form";
import {
  queryStringBuilder,
  getPageConfig,
  setPageConfig,
  exportBase64ToFile,
  hasFeatureAccess,
  getTokenData,
  updatePagination,
  updateLayout,
} from "utils";
import Chip from "@material-ui/core/Chip";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import { ROLE } from "modules/shared/constants";

// import BulkUpload from "../bulk-upload";
// import ErrorModal from "../error-modal";
import { ActionDialog, Grid } from "shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@material-ui/icons/Search";

import useStyles from "./style";
import LookupService from "../service";

let gridHelper = null,
  timeout = null;
const defaultSortObj = { field: "from_price", order: "ASC" };
const RECORD_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};
const defaultState = {
  lookupTypes: [],
  selectedLookupType: null,
  entry: {
    status: [],
  },
  isNew: false,
  active: true,
  inactive: true,
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  entries: [],
  totalEntries: 0,
  search: "",
  isFetching: false,
  isExchanging: false,
  openUpload: false,
  sheetError: null,
  scheduleList: [],
  schedule: null,
  isFormOpen: false,
  isScheduleFormOpen: false,
  isAddingSchedule: false,
  deletingEntryIds: null,
  selectedRows: [],
  renameScheduleOpen: false,
  isTemplateDownloading: false,

  openForceSubmitDialog: false,
  isFetchingList: {
    lookupTypes: false,
  },
  isLookupValueFound: false,
  openProceedDialog: false,
};

const ViewLookup = ({ history, match }) => {
  const { appData, updateContextData, setHeaderElements } =
    useContext(AppContext);
  const pageConfig = getPageConfig(PAGE_KEYS.LOOKUP);
  const classes = useStyles();
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    entry: {
      status: [RECORD_STATUS.ACTIVE, RECORD_STATUS.INACTIVE],
    },
  });
  const currentUser = getTokenData() || {};

  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleView = (currentUser?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const isRoleADMIN = (currentUser?.role || "").toLowerCase() === ROLE.ADMIN;
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;
  const kamId = isRoleKAM ? currentUser?.id : state.selectedKAM?.id;

  const hasWritePermission = hasFeatureAccess(PERMISSION.FUEL_SURCHARGE_WRITE);
  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.LOOKUP, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
    }));
  }, []);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.LOOKUP, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
    }));
  }, []);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        selectedRows: defaultState.selectedRows,
        pageNumber: defaultState.pageNumber,
        isFormOpen: false,
      }));
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const handleDelete = async (entryIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.deleteFuelSurchargeSchedule(entryIds);

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      deletingEntryIds: null,
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      //fetchFuelSurchargeSchedule();
      fetchEntries(
        state.search,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.selectedLookupType,
        state.active,
        state.inactive
      );
    }
  };

  const fetchEntries = useCallback(
    async (
      search,
      pageSize,
      pageNumber,
      order,
      orderBy,
      selectedLookupType,
      active,
      inactive,

      hasLoader = true
    ) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      const sortObj =
        orderBy && order ? { field: "value", order: order } : null;
      let queryString = queryStringBuilder(
        pageSize,
        pageSize * (pageNumber - 1),
        [{ field: "value", value: search }],
        [
          {
            field: "type",
            type: "=",
            value: selectedLookupType?.includes("%")
              ? selectedLookupType.replace(/%/, "{{percent}}")
              : selectedLookupType,
          },
        ],
        sortObj,
        search && search.length > 0
      );

      const is_active =
        active && !inactive
          ? true
          : !active && inactive
          ? false
          : active === false && inactive === false
          ? false
          : null;

      const finalQueryString =
        is_active !== null
          ? `${queryString}&filter[where][is_active]=${is_active}`
          : queryString;

      const { data, error } = await Service.getAllLookupData(finalQueryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: data.rows || defaultState.entries,
        totalEntries: data.count,
      }));
      return data;
    },
    []
  );

  const handleDownload = async (
    search,
    pageSize,
    pageNumber,
    order,
    orderBy,
    selectedLookupType,
    active,
    inactive,
    hasLoader = true
  ) => {
    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: true,
    }));

    const sortObj = orderBy && order ? { field: "value", order: order } : null;
    let queryString = queryStringBuilder(
      pageSize,
      pageSize * (pageNumber - 1),
      [{ field: "value", value: search }],
      [
        {
          field: "type",
          type: "=",
          value: selectedLookupType?.includes("%")
            ? selectedLookupType.replace(/%/, "{{percent}}")
            : selectedLookupType,
        },
      ],
      sortObj,
      search && search.length > 0
    );

    const is_active =
      active && !inactive ? true : !active && inactive ? false : null;

    const finalQueryString =
      is_active !== null
        ? `${queryString}&filter[where][is_active]=${is_active}`
        : queryString;

    const { data, error } = await Service.downloadLookupData(finalQueryString);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
      setState((prevState) => ({
        ...prevState,
        isDownloadLoading: false,
      }));
    } else {
      toast.success("Lookup records downloaded successfully.");
      exportBase64ToFile(data, `${state.selectedLookupType} Lookup`);
    }

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: false,
    }));
  };

  const fetchLookupTypes = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        lookupTypes: true,
      },
    }));

    const { data, error } = await LookupService.getLookupTypes();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          lookupTypes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      lookupTypes: data?.types || defaultState.lookupTypes,
      isFetchingList: {
        ...prevState.isFetchingList,
        lookupTypes: false,
      },
    }));
  };

  const handleFormClose = (row, rowIndex, isNewRecord = false) => {
    const entriesClone = state.entries.slice();
    entriesClone[rowIndex] = row;
    setState((prevState) => {
      return {
        ...prevState,
        isFormOpen: false,
        isAddingSchedule: isNewRecord ? false : prevState.isAddingSchedule,
        entries: entriesClone,
      };
    });
    if (isNewRecord) {
      if (state.active === false && state.inactive === false) {
        return setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
      } else {
        fetchEntries(
          state.search,
          state.pageSize,
          state.pageNumber,
          state.order,
          state.orderBy,
          state.selectedLookupType,
          state.active,
          state.inactive
        );
      }
    }
  };

  const handleFormOpen = (row, rowIndex) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows: defaultState.selectedRows,
      isFormOpen: true,
    }));
    gridHelper && gridHelper.resetSelection();
    const entriesClone = state.entries.slice();
    const isNew = !row;

    const form = {
      renderer: () => (
        <Form
          entry={row}
          isNew={isNew}
          selectedLookupType={state.selectedLookupType}
          onClose={(event) => handleFormClose(row, rowIndex)}
          onSave={(isSubmitted = false, newRow) => {
            handleFormClose(newRow, rowIndex, true);
            if (typeof isSubmitted === "boolean" && isSubmitted) {
              fetchEntries(
                state.search,
                state.pageSize,
                state.pageNumber,
                state.order,
                state.orderBy,
                state.selectedLookupType,
                state.active,
                state.inactive
              );
            }
          }}
        />
      ),
    };

    isNew ? entriesClone.unshift(form) : (entriesClone[rowIndex] = form);
    setState((prevState) => ({
      ...prevState,
      entries: entriesClone,
    }));
  };

  useEffect(() => {
    setTimeout(updateLayout, 2000);
  }, [state.entries]);

  useEffect(() => {
    if (!state.active && !state.inactive) {
      return;
    }
    fetchEntries(
      state.search,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.selectedLookupType,
      state.active,
      state.inactive
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.search,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.selectedLookupType,
    state.active,
    state.inactive,
  ]);

  useEffect(() => {
    if (state.active || state.inactive) {
      fetchEntries(
        state.search,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.selectedLookupType,
        state.active,
        state.inactive
      );
    } else {
      if (!state.active && !state.inactive) {
        return setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
      }
    }
  }, [state.active, state.inactive]);

  useEffect(() => {
    fetchLookupTypes();
  }, []);

  useEffect(() => {
    if (state.selectedLookupType) {
      fetchEntries(
        state.search,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.selectedLookupType,
        state.active,
        state.inactive
      );
    }
  }, [state.selectedLookupType]);

  const fetchList = async (listType = "pendingItems", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
      default:
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];

    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      fetchList("pendingItems", kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => updateContextData("pendingItems", []);
  }, []);

  const handleActiveInactiveStatus = async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const payload = {
      id: state.row?.id,
      type: state.row?.type,
      value: state.row?.value,
      is_active: state.lookupStatus,
    };

    const { error } = await Service.editLookup(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Lookup value status updated successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      openConfirmDialog: false,
      openProceedDialog: defaultState.openProceedDialog,
    }));

    fetchEntries(
      state.search,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.selectedLookupType,
      state.active,
      state.inactive
    );
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const moreActions = (row, rowIndex) => {
    return (
      <div
        className={clsx("d-flex f-align-center f-justify-end", {
          disabled: state.isFormOpen,
        })}
      >
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className="ml-2 mr-2 c-pointer"
            onClick={() => handleFormOpen(row, rowIndex)}
          />
        </Tooltip>
        <Tooltip
          title={`Mark ${row.is_active ? "Inactive" : "Active"}`}
          placement="top-start"
        >
          <Switch
            size="small"
            // disabled={currentUser?.email === row.email}
            value={state.lookupStatus}
            checked={row?.is_active}
            onChange={(evt) => {
              setState((prevState) => ({
                ...prevState,
                lookupStatus: evt.target.checked,
                row: row,
                openConfirmDialog: true,
              }));
            }}
            color="primary"
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      id: "lookup_value",
      label: "Lookup Value",
      field: "lookup_value",
      canSort: true,
      hasEllipses: true,
      render: (row) => {
        const isDeductionSelected =
          state.selectedLookupType === "Deduction Reason";

        const name = isDeductionSelected
          ? `${row?.value} (${row?.description ?? "Misc2Deduction"})`
          : row?.value;
        return (
          <Tooltip title={name || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {name}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  if (hasWritePermission) {
    columnConfig.unshift({
      headerClassName: classes.editLookup,
      id: "col-edit",
      noResize: true,
      render: (row, rowIndex) =>
        hasWritePermission && moreActions(row, rowIndex),
    });
  }

  if (isRoleView) {
    columnConfig.splice(0, 0, {
      headerClassName: classes.editLookup,
      id: "col-edit",
      noResize: true,
      render: (row, rowIndex) => (
        <Tooltip
          title={`${!row.is_active ? "Inactive" : "Active"}`}
          placement="top-start"
          style={{ cursor: "default" }}
        >
          <Switch
            size="small"
            className="none-events"
            value={state.lookupStatus}
            checked={row?.is_active}
            color="primary"
          />
        </Tooltip>
      ),
    });
  }

  const handleProceedInactiveStatus = () => {
    setState((prevState) => ({
      ...prevState,
      openConfirmDialog: defaultState.openConfirmDialog,
      openProceedDialog: true,
    }));
  };

  useEffect(() => {
    if (state.row) {
      const isLookupValueFound = appData?.icList?.some(
        (item) =>
          item.StandByRates &&
          item.StandByRates.length > 0 &&
          item.StandByRates?.some(
            (rate) => rate?.rateType?.id === state.row?.id
          )
      );
      setState((prevState) => ({
        ...prevState,
        isLookupValueFound,
      }));
    }
  }, [appData, state.row]);

  return (
    <div id="numbers-page-wrapper">
      <div className="mr-5">
        <div className={clsx("mb-2 mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className="ml-2">
            Lookup
            <Button
              startIcon={<ArrowBackIcon />}
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
            >
              Back To Home
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {!isRoleView
              ? "Add, Edit and View Lookups information."
              : "View Lookups information."}
          </Typography>
        </div>
        <div
          className={clsx({
            "d-flex f-justify-between f-align-center": !appData.isTabletView,
          })}
        >
          <div className="d-flex f-align-center">
            <Autocomplete
              disabled={state.isFormOpen}
              fullWidth
              size="small"
              classes={{
                root: classes.lookuptypeInput,
              }}
              value={state.selectedLookupType}
              options={state.lookupTypes}
              renderInput={(params) =>
                state.isFetchingList.lookupTypes ? (
                  <Skeleton variant="rect" width="100%" height={40} />
                ) : (
                  <TextField
                    {...params}
                    label={
                      !state.selectedLookupType
                        ? "Select Lookup Type"
                        : "Lookup Type"
                    }
                    variant="outlined"
                  />
                )
              }
              onChange={(evt, lookup) => {
                if (lookup === null) {
                  window.location.reload();
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    selectedLookupType: lookup,
                    active: defaultState.active,
                    inactive: defaultState.inactive,
                  }));
                }

                if (lookup === "Stand By Rate Type") {
                  fetchList("icList");
                }
                //fetchEntries(lookup);
              }}
            />
          </div>
          {state.selectedLookupType && (
            <div
              className={clsx("d-flex f-align-center", {
                "f-justify-end mt-4": appData.isTabletView,
              })}
            >
              <Typography variant="body1">Enter to search by:</Typography>
              <TextField
                disabled={state.isFetchingList.selectedLookupType}
                type="text"
                variant="outlined"
                className="ml-2"
                size="small"
                placeholder="lookup value"
                InputProps={{ endAdornment: <SearchIcon /> }}
                onChange={(evt) =>
                  handleSearch((evt.target.value || "").trim())
                }
              />
            </div>
          )}
        </div>
        {!state.selectedLookupType && (
          <Paper
            elevation={2}
            className={clsx(
              "d-flex f-align-center f-justify-center mt-10 p-2 pl-4 pr-4 p-relative",
              classes.paper
            )}
          >
            <div className="d-flex flex-column f-justify-center f-align-center">
              <Typography variant="h6">
                No Lookup type selected, Please Select Lookup Type.
              </Typography>
            </div>
          </Paper>
        )}
        <Paper
          elevation={2}
          className={clsx("p-4 mt-8 h-100", classes.paperSpacing, {
            "d-none": !state.selectedLookupType && !state.isScheduleFormOpen,
          })}
        >
          {state.selectedLookupType ? (
            <>
              {!!isRoleView && (
                <div className="d-flex f-align-center f-justify-start">
                  <FormControlLabel
                    label="Active"
                    className="ml-2"
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.active}
                        value={state.active}
                        onChange={(evt) => {
                          setState((prevState) => ({
                            ...prevState,
                            active: evt.target.checked,
                            isFormOpen: false,
                          }));
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Inactive"
                    control={
                      <Checkbox
                        value={state.inactive}
                        color="primary"
                        checked={state.inactive}
                        onChange={(evt) => {
                          setState((prevState) => ({
                            ...prevState,
                            inactive: evt.target.checked,
                            isFormOpen: false,
                          }));
                        }}
                      />
                    }
                  />
                </div>
              )}
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-between pb-4",
                  classes.actionsWrapper
                )}
              >
                <div>
                  {hasWritePermission && (
                    <>
                      {state.isFetching ||
                      state.isFetchingList.fuelSurchargeSchedule ? (
                        <Skeleton variant="rect" width={85} height={36} />
                      ) : (
                        <>
                          <Button
                            startIcon={<PlaylistAddIcon />}
                            variant="outlined"
                            color="primary"
                            disabled={state.isFormOpen}
                            onClick={() => handleFormOpen(null, -1)}
                            className={"mr-2"}
                          >
                            New
                          </Button>
                          <FormControlLabel
                            label="Active"
                            className="ml-2"
                            control={
                              <Checkbox
                                color="primary"
                                checked={state.active}
                                value={state.active}
                                onChange={(evt) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    active: evt.target.checked,
                                    isFormOpen: false,
                                  }));
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            label="Inactive"
                            control={
                              <Checkbox
                                value={state.inactive}
                                color="primary"
                                checked={state.inactive}
                                onChange={(evt) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    inactive: evt.target.checked,
                                    isFormOpen: false,
                                  }));
                                }}
                              />
                            }
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            className="ml-2 mr-2 mt-2"
                            disabled={
                              !state.entries.length ||
                              (state.entries.length === 1 &&
                                state.isFormOpen) ||
                              state.isDownloadLoading
                            }
                            startIcon={<CloudDownloadIcon />}
                            onClick={() =>
                              handleDownload(
                                state.search,
                                state.pageSize,
                                state.pageNumber,
                                state.order,
                                state.orderBy,
                                state.selectedLookupType,
                                state.active,
                                state.inactive
                              )
                            }
                          >
                            Download
                            {state.isDownloadLoading && (
                              <CircularProgress
                                size={24}
                                className={classes.progressBtn}
                              />
                            )}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="d-flex f-align-center">
                  {state.isFetching ||
                  state.isFetchingList.fuelSurchargeSchedule ? (
                    <>
                      <Skeleton
                        variant="rect"
                        width={104}
                        height={30}
                        className="mr-4"
                      />
                      <Skeleton variant="rect" width={104} height={30} />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <Grid
                columns={columnConfig}
                rows={state.entries
                  // .filter(
                  //   (entry) =>
                  //     state.entry.status.includes(entry.is_active) ||
                  //     entry.renderer
                  // )
                  .map((entry) => ({
                    ...entry,
                    className: clsx({
                      disabled: state.isFormOpen,
                    }),
                  }))}
                actionBarConfig={null}
                isLoading={state.isFetching}
                isFormVisible={state.isFormOpen}
                onReady={(instance) => (gridHelper = instance)}
                totalRows={state.totalEntries}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                order={state.order}
                orderBy={state.orderBy}
                search={state.search}
                hasSelection={false}
                //   noRecordLabel={
                //     (!state.search || state.isAddingSchedule) && (
                //       <Typography variant="h6" className="align-center">
                //         Please add/upload at least one price range to create
                //         schedule {appData.isTabletView ? <br /> : ""}'
                //         {state.schedule}'.
                //       </Typography>
                //     )
                //   }
                onSelectionChange={handleSelectionChange}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSortChange={handleSortChange}
                hasPagination={!state.isFormOpen}
                rowEvents={[
                  {
                    type: "onDoubleClick",
                    handler: (row, rowIndex) =>
                      hasWritePermission && handleFormOpen(row, rowIndex),
                  },
                ]}
                classes={{
                  gridActions: "f-justify-end",
                }}
              />
            </>
          ) : null}
        </Paper>

        {!!state.openConfirmDialog && (
          <ActionDialog
            classes={{
              confirm: state.openConfirmDialog && "bg-primary",
            }}
            open={!!state.openConfirmDialog}
            contentText={`Are you sure you want to mark the entry ${
              !state.lookupStatus ? "inactive" : "active"
            }?`}
            onConfirm={() =>
              !state.lookupStatus && state.isLookupValueFound
                ? handleProceedInactiveStatus()
                : handleActiveInactiveStatus()
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                openConfirmDialog: false,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Mark {!state.lookupStatus ? "inactive" : "active"}
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {!!state.openProceedDialog && (
          <ActionDialog
            classes={{
              confirm: state.openProceedDialog && "bg-danger",
            }}
            open={!!state.openProceedDialog}
            contentText={`The lookup value '${state.row?.value}' is already present in some existing contractor entries. Marking it as inactive, it will remain visible in those entries but won't be available for selection when creating new entries. Do you want to proceed?`}
            onConfirm={() => handleActiveInactiveStatus()}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                openProceedDialog: false,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Ok
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

export default ViewLookup;
