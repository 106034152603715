import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    width: 200,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
  },
  paper: {
    "& .MuiDialogActions-root": {
      paddingBottom: "26px",
    },
  },
  descriptionPaper: {
    "& .MuiDialogActions-root": {
      paddingBottom: "26px",
    },
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  extraMilesheader: {
    minWidth: 148,
  },
}));

export default useStyles;
