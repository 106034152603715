/* eslint-disable react-hooks/exhaustive-deps */
import { isTransferScheduleEnable, noop } from "shared/constants";
import clsx from "clsx";
import { toast } from "react-toastify";
import { getDateString, setPageConfig } from "utils";
import { Datepicker, Dropdown } from "shared/components";
import { CollapsibleGrid, CollapsibleTable } from "../../shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Tab, Tabs } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import HistoryIcon from "@material-ui/icons/History";
import { useMemo } from "react";

const TAB_LIST = [
  { id: 1, label: "Daily Schedule" },
  { id: 2, label: "Transfer Schedule" },
];

const DesktopView = ({
  kamId = null,
  tabIndex = 1,
  canViewAllSchedules = false,
  state = {},
  classes = {},
  history = {},
  PAGE_KEYS = {},
  RECORD_STATUS = {},
  ROLE = {},
  columnConfig = {},
  appDataList = {},
  rosterValue = null,
  isRoleADMIN = false,
  isRoleKAM = false,
  isRoleView = false,
  testId = "",
  locationOPtions,
  selectedLocation = [],
  isTransferredScheduledReceived = false,
  isScheduledTransferred = false,
  userData = null,
  // selectedRef = null,
  tableHeadRef = null,
  inputRef,
  setState = noop,
  expandLocation = noop,
  handleTabChange = noop,
  handleCalendarClose = noop,
  handleCalendarOpen = noop,
  handleSearch = noop,
  getDayClassName = noop,
  getDayClassNamePrevious = noop,
  renderDayContents = noop,
  handleSelectionChange = noop,
  handleGridHelper = noop,
  getDatesInRange = noop,
  getPzriceMatrix = noop,
  handleTransferStatusDialog = noop,
  syncChildGridsScroll = noop,
  wrapRef1,
  wrapRef2,
  gridRefs,
  handleScroll = noop,
  clearAllEntries = noop,
  handleRowOpen = noop,
}) => {
  const kamOptions = appDataList.kamUsers?.sort(
    (a, b) => b.is_active - a.is_active
  );

  const updatedRowEntries = useMemo(() => {
    return Object.keys(state.rowEntries).reduce((acc, key) => {
      acc[key] = state.rowEntries[key].map((entry, rowIndex) => ({
        ...entry,
        rowIndex,
        preventSelection:
          entry.status === RECORD_STATUS.SUBMITTED ||
          entry.status === RECORD_STATUS.UNDER_REVIEW ||
          (!!entry.KAM && !entry.KAM.is_active) ||
          (!!entry.kamUser && !entry.kamUser.is_active),
        classes: {
          "individual_contractor.first_name":
            entry.ic_inactive && classes.inactiveICID,
        },
        className: clsx({
          [classes.icidTitleColor]: entry?.individual_contractor_changed,
          [classes.highlightDisable]:
            !entry?.individual_contractor_changed &&
            entry?.status === RECORD_STATUS.SUBMITTED,
          disabled:
            (!!entry.KAM && !entry.KAM.is_active) ||
            (!!entry.kamUser && !entry.kamUser.is_active),
          [classes.highlightDeleted]: entry?.is_bs_deleted,
          [classes.notification]: entry?.id === testId,
          [classes.transferSchedule]:
            isTransferScheduleEnable &&
            !!entry.transfer_by &&
            !!entry.transfer_to,
          [classes.isTransferRejected]: !!entry.is_rejected,
          [classes.isAccepted]: !!entry.is_accepted && !entry.is_rejected,
        }),
      }));

      return acc;
    }, {});
  }, [
    state.rowEntries,
    state.filters.status,
    classes,
    testId,
    isTransferScheduleEnable,
  ]);

  const transferScheduleIsAccepted = state.transferEntries
    .filter((entry) => {
      const originalKAMId = state.selectOriginalKAM?.id;
      const entryKAMId = entry?.KAM?.id;

      return (
        state.filters.status.includes(entry.status) &&
        originalKAMId === entryKAMId
      );
    })
    .some((entry) => entry.is_accepted);

  const transferRecord = Object.values(state.rowEntries).some((entries) =>
    entries.some(
      (entry) =>
        state.filters.status.includes(entry.status) &&
        entry.transfer_by &&
        entry.transfer_to &&
        entry.status !== RECORD_STATUS.SUBMITTED &&
        !entry.is_accepted &&
        !entry.is_rejected
    )
  );

  const transferRejected = Object.values(state.rowEntries).some((entries) =>
    entries.some(
      (entry) =>
        state.filters.status.includes(entry.status) && entry.is_rejected
    )
  );

  const transferRecordApproved = Object.values(state.rowEntries).some(
    (entries) =>
      entries.some(
        (entry) =>
          state.filters.status.includes(entry.status) && entry.is_accepted
      )
  );

  return (
    <>
      <div className="mr-5">
        {(state.tabIndex === 1 &&
          (state.isBsApproved || state.scheduleSubmitted)) ||
        (state.tabIndex === 2 &&
          !!state.selectOriginalKAM &&
          (state.transferScheduleSubmitted || state.isTransferBsApproved)) ? (
          <div
            className={clsx("mb-2 mr-10", classes.fixedHeader)}
            style={{
              top: "104px",
            }}
          >
            <Typography variant="h4" color="primary" className="ml-2">
              Daily Schedule
              <Button
                style={{ float: "right" }}
                className="mt-1 mr-2"
                startIcon={<ArrowBackIcon />}
                variant="contained"
                color="primary"
                onClick={() => history.push("/home")}
              >
                Back To Home
              </Button>
              {!isRoleKAM && (
                <Button
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  startIcon={<HistoryIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push({
                      pathname: "/daily-schedule/audit-history",
                      state: {
                        startDate: state.scheduleDate,
                        endDate: state.endDate,
                        dateFilterType: state.isMultiSchedule,
                        kamFilter: state.selectedKAM,
                      },
                    })
                  }
                >
                  View Audit History
                </Button>
              )}
            </Typography>
            <Typography variant="body2" style={{ marginLeft: "10px" }}>
              {canViewAllSchedules
                ? `${
                    !isRoleView
                      ? "Add/Edit Schedule for KAM's."
                      : "View Schedule for KAM's."
                  }`
                : "Add/Edit your schedule."}
            </Typography>
          </div>
        ) : (
          <div
            className={clsx("mb-2 mr-10", classes.fixedHeader)}
            style={{
              top: "64px",
            }}
          >
            <Typography variant="h4" color="primary" className="ml-2">
              Daily Schedule
              <Button
                style={{ float: "right" }}
                className="mt-1 mr-2"
                startIcon={<ArrowBackIcon />}
                variant="contained"
                color="primary"
                onClick={() => history.push("/home")}
              >
                Back To Home
              </Button>
              {!isRoleKAM && (
                <Button
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  startIcon={<HistoryIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push({
                      pathname: "/daily-schedule/audit-history",
                      state: {
                        startDate: state.scheduleDate,
                        endDate: state.endDate,
                        dateFilterType: state.isMultiSchedule,
                        kamFilter: state.selectedKAM,
                      },
                    })
                  }
                >
                  View Audit History
                </Button>
              )}
            </Typography>
            <Typography variant="body2" style={{ marginLeft: "10px" }}>
              {canViewAllSchedules
                ? `${
                    !isRoleView
                      ? "Add/Edit Schedule for KAM's."
                      : "View Schedule for KAM's."
                  }`
                : "Add/Edit your schedule."}
            </Typography>
          </div>
        )}
        <div className="d-flex f-justify-between f-align-start flex-05 ml-1">
          <div className="d-flex f-wrap pr-8">
            <FormControl>
              <RadioGroup
                row
                value={state.isMultiSchedule ? "multiple" : "single"}
                onChange={(evt) => {
                  const isMultiSchedule = evt.target.value !== "single";
                  let date = getDateString(state.scheduleDate);
                  date = date.split("/");
                  date = `${date[0]}-${date[1]}-${date[2]}`;

                  if (isMultiSchedule) {
                    date += `/${date}`;
                  }
                  history.push(
                    `/daily-schedule/day/${date}${kamId ? `/kam/${kamId}` : ""}`
                  );

                  setState((prevState) => ({
                    ...prevState,

                    isMultiSchedule,
                    endDate: isMultiSchedule ? prevState.scheduleDate : null,
                  }));
                }}
              >
                <FormControlLabel
                  value="single"
                  className={"mb-2"}
                  control={<Radio size="small" color="primary" />}
                  label="Specific date"
                />
                <FormControlLabel
                  value="multiple"
                  className={"mb-2"}
                  control={<Radio size="small" color="primary" />}
                  label="Date range"
                />
              </RadioGroup>
            </FormControl>
            <Datepicker
              preventClear
              mask
              renderDayContents={renderDayContents}
              maxDate={new Date(state.endDate)}
              selected={state.scheduleDate}
              label={state.isMultiSchedule ? "From date" : "Schedule Date"}
              onCalendarClose={handleCalendarClose}
              onCalendarOpen={() => handleCalendarOpen("scheduleDate")}
              dayClassName={getDayClassName}
              classes={{
                input: {
                  root: classes.datepickerWrapper,
                },
              }}
              className={clsx({
                "mr-4": state.isMultiSchedule,
              })}
              onMonthChange={(previousScheduleActiveDate) =>
                setState((prevState) => ({
                  ...prevState,
                  previousScheduleActiveDate,
                }))
              }
              onChange={(previousDate) => {
                if (!previousDate) return;
                const reasonForcedSubmitDate =
                  state.submissionHistory.forcedlySubmitted.find(
                    (item) => item.date === getDateString(previousDate)
                  );
                let date = getDateString(previousDate);
                date = date.split("/");
                date = `${date[0]}-${date[1]}-${date[2]}`;
                getDatesInRange(previousDate, state.endDate);
                if (state.isMultiSchedule) {
                  let endDateString = getDateString(state.endDate);
                  endDateString = endDateString.split("/");
                  date += `/${endDateString[0]}-${endDateString[1]}-${endDateString[2]}`;
                }

                history.push(
                  `/daily-schedule/day/${date}${kamId ? `/kam/${kamId}` : ""}`
                );

                setState((prevState) => ({
                  ...prevState,
                  scheduleDate: getDateString(previousDate),
                  openRevertForcedSubmitDialog:
                    isRoleADMIN && reasonForcedSubmitDate,
                  forcedSubmitDate: getDateString(previousDate),
                }));
              }}
            />
            {state.isMultiSchedule && (
              <Datepicker
                preventClear
                mask
                minDate={new Date(state.scheduleDate)}
                selected={state.endDate}
                label="To date"
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={() => handleCalendarOpen("endDate")}
                dayClassName={getDayClassName}
                renderDayContents={renderDayContents}
                classes={{
                  input: {
                    root: classes.datepickerWrapper,
                  },
                }}
                onMonthChange={(previousScheduleActiveDate) =>
                  setState((prevState) => ({
                    ...prevState,
                    previousScheduleActiveDate,
                  }))
                }
                onChange={(previousDate) => {
                  if (!previousDate) return;
                  const reasonForcedSubmitDate =
                    state.submissionHistory.forcedlySubmitted.find(
                      (item) => item.date === getDateString(previousDate)
                    );
                  let date = getDateString(state.scheduleDate);
                  let endDateString = getDateString(previousDate);
                  date = date.split("/");
                  endDateString = endDateString.split("/");
                  date = `${date[0]}-${date[1]}-${date[2]}`;
                  date += `/${endDateString[0]}-${endDateString[1]}-${endDateString[2]}`;
                  getDatesInRange(state.scheduleDate, previousDate);
                  history.push(
                    `/daily-schedule/day/${date}${kamId ? `/kam/${kamId}` : ""}`
                  );

                  setState((prevState) => ({
                    ...prevState,
                    endDate: getDateString(previousDate),
                    openRevertForcedSubmitDialog:
                      isRoleADMIN && reasonForcedSubmitDate,
                    forcedSubmitDate: getDateString(previousDate),
                  }));
                }}
              />
            )}

            {canViewAllSchedules && (
              <Autocomplete
                disableClearable
                size="small"
                value={state.selectedKAM}
                fullWidth
                style={{ marginLeft: 18 }}
                classes={{
                  root: classes.kamDropdownWidth,
                  listbox: classes.kamDropdownWidth,
                }}
                options={kamOptions}
                getOptionLabel={(option) =>
                  option.name ||
                  `${option.first_name || ""} ${option.last_name || ""}-${
                    option.email || ""
                  }-(${option.username || ""})`
                }
                renderOption={({
                  first_name,
                  last_name,
                  email,
                  is_active,
                  username,
                }) => (
                  <Typography
                    variant="body1"
                    className={clsx({
                      "color-text-disabled": !is_active,
                    })}
                  >{`${first_name || " "} ${
                    last_name || ""
                  }-${email}-(${username})`}</Typography>
                )}
                renderInput={(params) =>
                  state.isFetchingList.users ? (
                    <Skeleton variant="rect" width="100%" height="36px" />
                  ) : (
                    <TextField {...params} label="KAM" variant="outlined" />
                  )
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedKAM: value,
                  }));
                }}
              />
            )}
            <Autocomplete
              classes={{
                root: classes.kamDropdownWidth,
                listbox: classes.kamDropdownWidth,
              }}
              style={{
                width: "244px",
                marginLeft: 18,
              }}
              size="small"
              fullWidth
              value={selectedLocation || []}
              multiple
              options={locationOPtions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) =>
                state.isFetchingList.users ? (
                  <Skeleton variant="rect" width="100%" height="36px" />
                ) : (
                  <TextField {...params} label="Location" variant="outlined" />
                )
              }
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  selectedLocation: value,
                }));
              }}
            />
          </div>
          {((canViewAllSchedules &&
            !!state.selectedKAM &&
            !state.isNoSchedule) ||
            !canViewAllSchedules) && (
            <div className="d-flex">
              <FormControlLabel
                label="Customer"
                control={
                  <Checkbox
                    color="primary"
                    disabled={state.tabIndex === 2 && !state.selectOriginalKAM}
                    value="customer_name"
                    checked={state.searchColumns.includes("customer_name")}
                    onChange={(evt) =>
                      setState((prevState) => {
                        const { checked, value } = evt.target;
                        const searchColumns = checked
                          ? [...prevState.searchColumns, value]
                          : prevState.searchColumns.filter(
                              (item) => item !== value
                            );
                        return {
                          ...prevState,
                          searchColumns,
                        };
                      })
                    }
                  />
                }
              />
              <FormControlLabel
                label="IC"
                control={
                  <Checkbox
                    color="primary"
                    value="contractor_first_name"
                    disabled={state.tabIndex === 2 && !state.selectOriginalKAM}
                    checked={state.searchColumns.includes(
                      "contractor_first_name"
                    )}
                    onChange={(evt) =>
                      setState((prevState) => {
                        const { checked, value } = evt.target;
                        const searchColumns = checked
                          ? [...prevState.searchColumns, value, "icid"]
                          : prevState.searchColumns.filter(
                              (item) => item !== value && item !== "icid"
                            );
                        return {
                          ...prevState,
                          searchColumns,
                        };
                      })
                    }
                  />
                }
              />
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Search"
                disabled={state.tabIndex === 2 && !state.selectOriginalKAM}
                inputRef={inputRef}
                InputProps={{ endAdornment: <SearchIcon /> }}
                onChange={(evt) =>
                  handleSearch((evt.target.value || "").trim())
                }
              />
            </div>
          )}
        </div>
        {isTransferScheduleEnable && state.userRole === ROLE.KAM && (
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor={"#eeeeee"}
            textColor={"primary"}
            className={classes.tabs}
          >
            {TAB_LIST.map((tab) => (
              <Tab
                value={tab.id}
                // disabled={isFetching || state.isLoading}
                key={tab.id}
                name={tab.id}
                className={classes.tab}
                style={{
                  borderTop:
                    tab.id === tabIndex ? "2px solid #80808045" : "none",
                  borderLeft:
                    tab.id === tabIndex ? "2px solid #80808045" : "none",
                  borderRight:
                    tab.id === tabIndex ? "2px solid #80808045" : "none",
                  background: tab.id === tabIndex ? "white" : "#ececec",
                }}
                label={
                  <Typography
                    variant="subtitle2"
                    color={tab.id === tabIndex ? "primary.dark" : "grey.300"}
                  >
                    {tab.label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        )}
        {state.selectedKAM == null &&
          !state.isNoSchedule &&
          canViewAllSchedules && (
            <Paper elevation={2} className="p-10 h-100">
              <Typography variant="body1">
                Please select a KAM to view schedule for{" "}
                {`${getDateString(state.scheduleDate)}${
                  state.isMultiSchedule
                    ? ` - ${getDateString(state.endDate)}`
                    : ""
                }`}
              </Typography>
            </Paper>
          )}
        {state.isNoSchedule && canViewAllSchedules && (
          <Paper
            elevation={2}
            className={clsx(
              "d-flex f-align-center f-justify-center p-2 pl-4 pr-4 p-relative",
              classes.paper
            )}
          >
            <div className="d-flex flex-column f-justify-center f-align-center">
              {state.isForceSubmitted ? (
                <Typography variant="h6">
                  Schedule marked as forced submitted for{" "}
                  {`${getDateString(state.scheduleDate)}${
                    state.isMultiSchedule
                      ? ` - ${getDateString(state.endDate)}`
                      : ""
                  }`}
                </Typography>
              ) : (
                <>
                  <Typography variant="h6">
                    No schedule exists for{" "}
                    {`"${state.selectedKAM?.first_name || ""} ${
                      state.selectedKAM?.last_name || ""
                    }"`}{" "}
                    for{" "}
                    {`${getDateString(state.scheduleDate)}${
                      state.isMultiSchedule
                        ? ` - ${getDateString(state.endDate)}`
                        : ""
                    }`}
                  </Typography>
                  {!isRoleView && (
                    <Button
                      className="mt-5 w-50 f-align-center"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        let date = getDateString(state.scheduleDate)?.split(
                          "/"
                        );
                        date = `${date[2]}-${date[0]}-${date[1]}`;
                        if (state.isMultiSchedule) {
                          let endDateString = getDateString(
                            state.endDate
                          )?.split("/");
                          date += `/${endDateString[2]}-${endDateString[0]}-${endDateString[1]}`;
                        }
                        history.push({
                          pathname: `/daily-schedule/create/day/${date}${
                            state.selectedKAM?.id
                              ? `/kam/${state.selectedKAM.id}`
                              : ""
                          }`,
                          state: { isMultiSchedule: state.isMultiSchedule },
                        });
                      }}
                    >
                      Create Schedule
                    </Button>
                  )}
                </>
              )}
              {state.isCalenderOpen && (
                <div className={clsx("d-flex p-absolute", classes.dateLegend)}>
                  {[
                    { label: "Submitted", className: "completeSubmittedDay" },
                    {
                      label: "Partial Submitted",
                      className: "partialSubmittedDay",
                    },
                    { label: "Not Submitted", className: "notSubmittedDay" },
                    { label: "Not Created", className: "notCreatedDay" },
                    { label: "Forced Submit", className: "forcedSubmitDay" },
                    { label: "No Schedule", className: "weekoff" },
                    { label: "Under Review", className: "underReview" },
                    { label: "Approved", className: "bsApproved" },
                  ].map((item) => (
                    <div className="d-flex f-align-center ml-2">
                      <div
                        className={clsx(
                          "mr-2",
                          classes.legendHint,
                          classes[item.className]
                        )}
                      ></div>
                      <Typography variant="body2">{item.label}</Typography>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Paper>
        )}
        {(state.userRole === ROLE.KAM || state.selectedKAM) &&
          !state.isNoSchedule && (
            <Paper elevation={2} className="p-2 h-100 mb-4">
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                <div
                  className={tabIndex === 2 && "d-flex f-align-center f-wrap"}
                >
                  {!isRoleView && tabIndex === 1 && (
                    <>
                      {state.isFetchingList.users ||
                      state.isFetchingList.customers ||
                      state.isFetchingList.IC ? (
                        <Skeleton variant="rect" width={90} height={35} />
                      ) : (
                        <Button
                          className="mr-5"
                          startIcon={<PlaylistAddIcon />}
                          variant="outlined"
                          color="primary"
                          disabled={state.scheduleSubmitted}
                          onClick={() => {
                            getPzriceMatrix();
                            setState((prevState) => ({
                              ...prevState,
                              isNew: true,
                            }));
                          }}
                        >
                          New
                        </Button>
                      )}
                    </>
                  )}

                  <FormControlLabel
                    label="Submitted"
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.filters.status.some(
                          (item) =>
                            item === RECORD_STATUS.SUBMITTED ||
                            item === RECORD_STATUS.UNDER_REVIEW
                        )}
                        onChange={(evt) =>
                          setState((prevState) => ({
                            ...prevState,
                            filters: {
                              ...prevState.filters,
                              status: evt.target.checked
                                ? [
                                    ...prevState.filters.status,
                                    RECORD_STATUS.SUBMITTED,
                                    RECORD_STATUS.UNDER_REVIEW,
                                  ]
                                : prevState.filters.status.filter(
                                    (item) =>
                                      item != RECORD_STATUS.SUBMITTED &&
                                      item !== RECORD_STATUS.UNDER_REVIEW
                                  ),
                            },
                          }))
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label="Not Submitted"
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.filters.status.includes(
                          RECORD_STATUS.PENDING
                        )}
                        onChange={(evt) =>
                          setState((prevState) => ({
                            ...prevState,
                            filters: {
                              ...prevState.filters,
                              status: evt.target.checked
                                ? [
                                    ...prevState.filters.status,
                                    RECORD_STATUS.PENDING,
                                  ]
                                : prevState.filters.status.filter(
                                    (item) => item !== RECORD_STATUS.PENDING
                                  ),
                            },
                          }))
                        }
                      />
                    }
                  />
                  {state.userRole === ROLE.KAM && tabIndex === 2 && (
                    <div className="d-flex f-align-center">
                      <Autocomplete
                        disableClearable
                        size="small"
                        value={state.selectOriginalKAM}
                        fullWidth
                        className="mr-5"
                        classes={{
                          root: classes.kamDropdownWidth,
                          listbox: classes.kamDropdownWidth,
                        }}
                        options={state.originalKAM}
                        getOptionLabel={(option) =>
                          option && option
                            ? `${option.first_name || ""} ${
                                option.last_name || ""
                              }-${option.email || ""}-(${
                                option.username || ""
                              })`
                            : []
                        }
                        renderInput={(params) =>
                          state.isFetchingOriginalKam ? (
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height="36px"
                            />
                          ) : (
                            <TextField
                              {...params}
                              label="Original KAM"
                              variant="outlined"
                            />
                          )
                        }
                        getOptionSelected={(option, value) =>
                          option?.id === value?.id
                        }
                        onChange={(evt, value) => {
                          setState((prevState) => ({
                            ...prevState,
                            selectOriginalKAM: value,
                          }));
                        }}
                      />
                    </div>
                  )}
                  {!!state.selectedRows.length && (
                    <>
                      {tabIndex === 1 && !state.scheduleSubmitted && (
                        <Button
                          startIcon={
                            <DeleteForeverOutlinedIcon color="error" />
                          }
                          classes={{
                            root: "border-error ml-2 mr-4",
                            label: "color-error",
                          }}
                          variant="outlined"
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              deletingEntryId: state.selectedRows.join(","),
                            }))
                          }
                        >
                          Delete
                        </Button>
                      )}
                      {isTransferScheduleEnable &&
                        !state.scheduleSubmitted &&
                        tabIndex === 1 && (
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              if (state.transferRecordStatus.length > 0) {
                                return toast.error(
                                  "There are existing transfer records for the dates you've selected. To start a new transfer, you'll have to reject the current transfer records first."
                                );
                              }
                              if (
                                Object.values(state.rowEntries).some(
                                  (entries) =>
                                    entries.some(
                                      (entry) =>
                                        state.selectedRows.includes(entry.id) &&
                                        entry.is_accepted &&
                                        !entry.is_rejected
                                    )
                                )
                              ) {
                                return toast.error(
                                  "One or more selected records have already been transferred and accepted by the KAM."
                                );
                              }
                              setState((prevState) => ({
                                ...prevState,
                                isTransferSchedule: true,
                              }));
                            }}
                          >
                            Transfer Schedule
                          </Button>
                        )}
                      {isTransferScheduleEnable &&
                        tabIndex === 2 &&
                        !state.transferScheduleSubmitted && (
                          <div className="d-flex f-align-center mb-1">
                            <Button
                              className="mt-1 mr-4"
                              s
                              variant="outlined"
                              classes={{
                                root: "border-error",
                                label: "color-error",
                              }}
                              onClick={() =>
                                handleTransferStatusDialog(true, "reject")
                              }
                            >
                              Reject
                            </Button>
                            <Button
                              className="mt-1 mr-2"
                              s
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                handleTransferStatusDialog(true, "approve")
                              }
                            >
                              Accept
                            </Button>
                          </div>
                        )}
                    </>
                  )}
                </div>
                <div
                  className={
                    tabIndex === 2
                      ? "d-flex f-justify-center f-align-center"
                      : "d-flex"
                  }
                >
                  {!isRoleView && tabIndex === 1 && (
                    <>
                      <Button
                        startIcon={<ListAltOutlinedIcon />}
                        className="mr-4"
                        color="bg-primary"
                        disabled={
                          state.scheduleSubmitted || state.isPartialSubmitted
                        }
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            confirmDialog: {
                              ...prevState.confirmDialog,
                              contentText:
                                "Are you sure you want to copy from Base Roster?",
                              positiveActionLabel: "Confirm",
                              payload: {
                                fromScheduleDate: state.scheduleDate,
                                toScheduleDate:
                                  state.endDate || state.scheduleDate,
                                isOfPreviousDate: false,
                                deleteExisting: true,
                                kam_id: state.selectedKAM?.id,
                                is_weekday:
                                  rosterValue === "weekdays" ? true : false,
                              },
                            },
                          }))
                        }
                      >
                        Copy from Base Roster
                      </Button>
                      {/* <Datepicker
                        preventClear
                        mask
                        disabled
                        // disabled={
                        //   state.scheduleSubmitted || state.isPartialSubmitted
                        // }
                        selected={(() => {
                          const yesterdayDate = new Date();
                          return yesterdayDate.setDate(
                            yesterdayDate.getDate() - 1
                          );
                        })()}
                        className="mr-2"
                        label="Copy From Previous Date"
                        classes={{
                          input: {
                            root: clsx(
                              classes.datepickerWrapper,
                              classes.datepickerWrapperDesktop
                            ),
                          },
                        }}
                        dayClassName={getDayClassNamePrevious}
                        onCalendarClose={handleCalendarClose}
                        onCalendarOpen={() =>
                          handleCalendarOpen("copyCalender")
                        }
                        onMonthChange={(previousScheduleActiveDate) =>
                          setState((prevState) => ({
                            ...prevState,
                            previousScheduleActiveDate,
                          }))
                        }
                        onChange={(previousDate) => {
                          if (previousDate) {
                            setState((prevState) => ({
                              ...prevState,
                              confirmDialog: {
                                ...prevState.confirmDialog,
                                contentText: `Copying from ${getDateString(
                                  previousDate
                                )}`,
                                positiveActionLabel: "Confirm",
                                payload: {
                                  fromScheduleDate: state.scheduleDate,
                                  toScheduleDate:
                                    state.endDate || state.scheduleDate,
                                  previousDate: getDateString(previousDate),
                                  isOfPreviousDate: true,
                                  deleteExisting: true,
                                  kam_id: state.selectedKAM?.id,
                                },
                              },
                            }));
                          }
                        }}
                      /> */}
                    </>
                  )}

                  {tabIndex === 2 && (
                    <Dropdown
                      classes={{
                        paper: clsx("mr-1", classes.tunePaper),
                      }}
                      disablePortal={false}
                      labelEllipses
                      hasEllipses
                      placement={"bottom-end"}
                      options={[
                        { label: "All", value: null },
                        { label: "Accepted", value: true },
                        { label: "Not Accepted", value: false },
                      ]}
                      label={`Response Type: ${
                        state.filters?.acceptedType?.length
                          ? state.filters?.acceptedType[0]?.value
                            ? "Accepted"
                            : "Not Accepted"
                          : "All"
                      }`}
                      onChange={(option) => {
                        setState((prevState) => ({
                          ...prevState,
                          filters: {
                            ...prevState.filters,
                            acceptedType:
                              option.value === null
                                ? []
                                : [
                                    {
                                      field: "is_accepted",
                                      type: "=",
                                      value: option.value,
                                    },
                                  ],
                          },
                        }));
                      }}
                    />
                  )}

                  <Dropdown
                    classes={{
                      paper: classes.tunePaper,
                    }}
                    disablePortal={false}
                    remainOpen
                    isMultiSelect
                    placement="bottom-end"
                    options={Object.keys(state.dynamicColumns).map(
                      (key) => state.dynamicColumns[key]
                    )}
                    customToggle={({ anchorRef, onClick }) => (
                      <Tooltip
                        title="Show/Hide column(s)"
                        placement="top-start"
                      >
                        <IconButton
                          color="primary"
                          ref={anchorRef}
                          onClick={onClick}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    onChange={(options) => {
                      const isSelectAll = options.some(
                        (item) => item.value === "select_all"
                      );
                      const isCurrentSelectAll =
                        state.dynamicColumns?.select_all?.isSelected;

                      const isClickedOnSelectAll =
                        isSelectAll !== isCurrentSelectAll;

                      const isAllOptionSelected =
                        Object.keys(state.dynamicColumns).length - 1 ===
                        options.filter((item) => item.value !== "select_all")
                          .length;
                      const updatedDynamicColumns = Object.keys(
                        state.dynamicColumns
                      ).reduce((acc, key) => {
                        const isSelected = isClickedOnSelectAll
                          ? isSelectAll
                          : key === "select_all"
                          ? isAllOptionSelected
                          : !!options.some((item) => item.value === key);
                        return {
                          ...acc,
                          [key]: {
                            ...state.dynamicColumns[key],
                            isSelected,
                          },
                        };
                      }, {});
                      setPageConfig(PAGE_KEYS.DAILY_SCHEDULE, {
                        dynamicColumns: updatedDynamicColumns,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        dynamicColumns: {
                          ...prevState.dynamicColumns,
                          ...updatedDynamicColumns,
                        },
                      }));
                    }}
                  />
                  <Tooltip placement="top-start" title="Filter">
                    <IconButton
                      color="primary"
                      disabled={
                        state.isFetching || state.isFetchingTransferEntries
                      }
                      className={clsx({
                        "bg-primary": !!state.pageFilters?.length,
                      })}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          isFiltering: true,
                        }));
                      }}
                    >
                      <FilterListIcon
                        className={clsx({
                          "color-white": !!state.pageFilters?.length,
                        })}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              {state.userRole === ROLE.KAM &&
              tabIndex === 1 &&
              isTransferredScheduledReceived === true &&
              isScheduledTransferred === false &&
              state.entries.length === 0 ? (
                <div
                  className="d-flex flex-column f-justify-center f-align-center"
                  style={{ height: "22rem" }}
                >
                  <Typography variant="h6">
                    No schedule exists for{" "}
                    {`"${userData?.first_name || ""} ${
                      userData?.last_name || ""
                    }"`}{" "}
                    for{" "}
                    {`${getDateString(state.scheduleDate)}${
                      state.isMultiSchedule
                        ? ` - ${getDateString(state.endDate)}`
                        : ""
                    }`}
                  </Typography>
                  <Button
                    className="mt-5 f-align-center"
                    style={{ width: "15rem" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let date = getDateString(state.scheduleDate)?.split("/");
                      date = `${date[2]}-${date[0]}-${date[1]}`;
                      if (state.isMultiSchedule) {
                        let endDateString = getDateString(state.endDate)?.split(
                          "/"
                        );
                        date += `/${endDateString[2]}-${endDateString[0]}-${endDateString[1]}`;
                      }
                      history.push({
                        pathname: `/daily-schedule/create/day/${date}${
                          kamId?.id ? `/kam/${kamId?.id}` : ""
                        }`,
                        state: { isMultiSchedule: state.isMultiSchedule },
                      });
                    }}
                  >
                    Create Schedule
                  </Button>
                </div>
              ) : tabIndex === 2 && !state.selectOriginalKAM ? (
                <div
                  className="d-flex flex-column f-justify-center f-align-center"
                  style={{ height: "22rem" }}
                >
                  <Typography variant="h6">
                    Please select the original KAM from the dropdown to view
                    transfer schedule records.
                  </Typography>
                </div>
              ) : (
                <>
                  {tabIndex === 1 && (
                    <CollapsibleTable
                      rows={state.customerEntries}
                      columns={columnConfig}
                      wrapRef1={wrapRef1}
                      syncChildGridsScroll={syncChildGridsScroll}
                      isTableLoading={state.isTableLoading}
                      expandLocation={expandLocation}
                      clearAllEntries={clearAllEntries}
                      entries={updatedRowEntries}
                      isLoading={state.isFetching}
                      search={state.search}
                      onReady={handleGridHelper}
                      hasSelection={!isRoleView}
                      onSelectionChange={handleSelectionChange}
                      wrapRef2={wrapRef2}
                      handleScroll={handleScroll}
                      handleRowOpen={handleRowOpen}
                      gridRefs={gridRefs}
                      tableHeadRef={tableHeadRef}
                      gridActions={
                        !isRoleView
                          ? [
                              <div>
                                <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const isICEmpty = Object.values(
                                      state.rowEntries
                                    ).some((entries) =>
                                      entries.some(
                                        (entry) =>
                                          state.selectedRows.includes(
                                            entry.id
                                          ) && !entry.individual_contractor
                                      )
                                    );

                                    if (isICEmpty) {
                                      return toast.error(
                                        "ICID/IC Name Required for the submission."
                                      );
                                    }
                                    let payload = {
                                      fromSubmissionDate: state.scheduleDate,
                                      toSubmissionDate:
                                        state.endDate || state.scheduleDate,
                                      dailyScheduleIds: state.selectedRows,
                                      isPartialSubmit: true,
                                    };
                                    if (state.userRole !== ROLE.KAM) {
                                      payload["kam_id"] = state.selectedKAM?.id;
                                    } else if (state.userRole === ROLE.KAM) {
                                      payload["kam_id"] = kamId;
                                    }
                                    const exceededEntries = Object.values(
                                      state.rowEntries
                                    )
                                      .flat()
                                      .filter(
                                        (data) =>
                                          payload.dailyScheduleIds?.includes(
                                            data.id
                                          ) &&
                                          data.pay_rate_type === "Daily" &&
                                          +data.pay_quantity > 1.99
                                      );

                                    if (exceededEntries.length > 0) {
                                      const locationDetails =
                                        exceededEntries.map((entry) => ({
                                          customer:
                                            entry.customer_branch.customer.name,
                                          route: entry.route,
                                          schedule_date: entry.schedule_date,
                                          location:
                                            entry.customer_branch.location,
                                        }));

                                      setState((prevState) => ({
                                        ...prevState,
                                        payQuantityExceedDaily: locationDetails,
                                      }));

                                      return;
                                    }
                                    setState((prevState) => ({
                                      ...prevState,
                                      confirmScheduleSubmit: { payload },
                                    }));
                                  }}
                                  disabled={
                                    state.scheduleSubmitted ||
                                    !state.selectedRows.length
                                  }
                                >
                                  Partial Submit
                                </Button>

                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const isICEmpty =
                                      state.entriesToSubmit.some(
                                        (entry) => !entry.individual_contractor
                                      );

                                    if (isICEmpty) {
                                      return toast.error(
                                        "ICID/IC Name Required for the submission."
                                      );
                                    }
                                    let payload = {
                                      fromSubmissionDate: state.scheduleDate,
                                      toSubmissionDate:
                                        state.endDate || state.scheduleDate,
                                      isPartialSubmit: false,
                                    };
                                    if (state.userRole !== ROLE.KAM) {
                                      payload["kam_id"] = state.selectedKAM?.id;
                                    } else if (state.userRole === ROLE.KAM) {
                                      payload["kam_id"] = kamId;
                                    }
                                    const exceededEntries =
                                      state.entriesToSubmit.filter(
                                        (data) =>
                                          data.pay_rate_type === "Daily" &&
                                          +data.pay_quantity > 1.99
                                      );

                                    if (exceededEntries.length > 0) {
                                      const locationDetails =
                                        exceededEntries.map((entry) => ({
                                          customer:
                                            entry.customer_branch.customer.name,
                                          route: entry.route,
                                          schedule_date: entry.schedule_date,
                                          location:
                                            entry.customer_branch.location,
                                        }));

                                      setState((prevState) => ({
                                        ...prevState,
                                        payQuantityExceedDaily: locationDetails,
                                      }));

                                      return;
                                    }

                                    setState((prevState) => ({
                                      ...prevState,
                                      confirmScheduleSubmit: { payload },
                                    }));
                                  }}
                                  disabled={
                                    state.scheduleSubmitted ||
                                    state.isFetchingSubmitEntries ||
                                    (isTransferScheduleEnable &&
                                      state.entriesToSubmit.some(
                                        (item) =>
                                          item.transfer_by && item.transfer_to
                                      ))
                                  }
                                >
                                  Submit
                                </Button>
                              </div>,
                            ]
                          : []
                      }
                      rowEvents={[
                        {
                          type: "onDoubleClick",
                          handler: (row) => {
                            if (isRoleView) {
                              return false;
                            }
                            setState((prevState) => {
                              const isDisabled =
                                prevState.scheduleSubmitted ||
                                row.status === RECORD_STATUS.SUBMITTED ||
                                row.status === RECORD_STATUS.UNDER_REVIEW ||
                                (!!row.KAM && !row.KAM.is_active) ||
                                (!!row.kamUser && !row.kamUser.is_active);

                              return {
                                ...prevState,
                                rowBeingEdited: isDisabled
                                  ? prevState.rowBeingEdited
                                  : row,
                              };
                            });
                            getPzriceMatrix();
                          },
                        },
                      ]}
                      gridClasses={{
                        gridActions: "f-justify-end mt-2",
                      }}
                    />
                  )}
                  {tabIndex === 2 && (
                    <CollapsibleGrid
                      wrapRef2={wrapRef2}
                      handleScroll={handleScroll}
                      tableHeadRef={tableHeadRef}
                      handleRowOpen={handleRowOpen}
                      columns={columnConfig}
                      rows={state.transferEntries
                        .filter((entry) => {
                          const originalKAMId = state.selectOriginalKAM?.id;
                          const entryKAMId = entry?.KAM?.id;

                          return (
                            state.filters.status.includes(entry.status) &&
                            originalKAMId === entryKAMId
                          );
                        })
                        .map((entry, rowIndex) => ({
                          ...entry,
                          rowIndex,
                          customer_name: entry?.customer_branch?.customer?.name,
                          preventSelection:
                            entry.status === RECORD_STATUS.SUBMITTED ||
                            entry.status === RECORD_STATUS.UNDER_REVIEW ||
                            !entry.KAM.is_active,
                          className: clsx({
                            [classes.isAccepted]: !!entry.is_accepted,
                          }),
                        }))}
                      isLoading={state.isFetchingTransferEntries}
                      uniqueBy="customer_name"
                      search={state.search}
                      onReady={handleGridHelper}
                      hasSelection={!isRoleView}
                      onSelectionChange={handleSelectionChange}
                      gridActions={
                        !isRoleView
                          ? [
                              <div>
                                <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const isICEmpty = state.transferEntries
                                      ?.filter((entry) =>
                                        state.selectedRows.includes(entry.id)
                                      )
                                      .some(
                                        (entry) => !entry.individual_contractor
                                      );
                                    if (isICEmpty) {
                                      return toast.error(
                                        "ICID/IC Name Required for the submission."
                                      );
                                    }
                                    let payload = {
                                      fromSubmissionDate: state.scheduleDate,
                                      toSubmissionDate:
                                        state.endDate || state.scheduleDate,
                                      dailyScheduleIds: state.selectedRows,
                                      isPartialSubmit: true,
                                    };
                                    if (state.userRole !== ROLE.KAM) {
                                      payload["kam_id"] = state.selectedKAM?.id;
                                    } else if (state.userRole === ROLE.KAM) {
                                      payload["kam_id"] =
                                        state.selectOriginalKAM?.id;
                                    }
                                    const exceededEntries =
                                      state.transferEntries.filter(
                                        (data) =>
                                          payload.dailyScheduleIds?.includes(
                                            data.id
                                          ) &&
                                          data.pay_rate_type === "Daily" &&
                                          +data.pay_quantity > 1.99
                                      );

                                    if (exceededEntries.length > 0) {
                                      const locationDetails =
                                        exceededEntries.map((entry) => ({
                                          customer:
                                            entry.customer_branch.customer.name,
                                          route: entry.route,
                                          schedule_date: entry.schedule_date,
                                          location:
                                            entry.customer_branch.location,
                                        }));

                                      setState((prevState) => ({
                                        ...prevState,
                                        payQuantityExceedDaily: locationDetails,
                                      }));

                                      return;
                                    }
                                    setState((prevState) => ({
                                      ...prevState,
                                      confirmScheduleSubmit: { payload },
                                    }));
                                  }}
                                  disabled={
                                    state.transferScheduleSubmitted ||
                                    (state.selectedRows.length > 0 &&
                                      state.transferEntries.some(
                                        (item) =>
                                          state.selectedRows.includes(
                                            item.id
                                          ) && !item.is_accepted
                                      )) ||
                                    !state.selectedRows.length
                                  }
                                >
                                  Partial Submit
                                </Button>
                              </div>,
                            ]
                          : []
                      }
                      rowEvents={[
                        {
                          type: "onDoubleClick",
                          handler: (row) => {
                            if (isRoleView) {
                              return false;
                            }
                            setState((prevState) => {
                              const isDisabled =
                                row.status === RECORD_STATUS.SUBMITTED ||
                                row.status === RECORD_STATUS.UNDER_REVIEW ||
                                !row.KAM?.is_active ||
                                (isRoleKAM && !row.is_accepted);
                              return {
                                ...prevState,
                                rowBeingEdited: isDisabled
                                  ? prevState.rowBeingEdited
                                  : row,
                              };
                            });
                            getPzriceMatrix();
                          },
                        },
                      ]}
                      classes={{
                        gridActions: "f-justify-end",
                      }}
                    />
                  )}
                </>
              )}

              <div
                className={clsx("d-flex p-absolute", classes.dateLegendCopy)}
              >
                {state.isCalenderOpen &&
                  [
                    { label: "Submitted", className: "completeSubmittedDay" },
                    {
                      label: "Partial Submitted",
                      className: "partialSubmittedDay",
                    },
                    { label: "Not Submitted", className: "notSubmittedDay" },
                    { label: "Not Created", className: "notCreatedDay" },
                    { label: "Forced Submit", className: "forcedSubmitDay" },
                    { label: "No Schedule", className: "weekoff" },
                    { label: "Under Review", className: "underReview" },
                    { label: "Approved", className: "bsApproved" },
                  ].map((item) => (
                    <div
                      className="d-flex f-align-center ml-2 mb-3"
                      key={item.label}
                    >
                      <div
                        className={clsx(
                          "mr-2",
                          classes.legendHint,
                          classes[item.className]
                        )}
                      ></div>
                      <Typography variant="body2">{item.label}</Typography>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    {isTransferScheduleEnable && transferRecord && (
                      <div
                        className="d-flex f-align-center ml-2 mb-3"
                        key="Transfer Record"
                      >
                        <div
                          className={clsx(
                            "mr-2",
                            classes.legendHint,
                            classes["transferSchedule"]
                          )}
                        ></div>
                        <Typography variant="body2">Transfer Record</Typography>
                      </div>
                    )}
                    {transferRejected && (
                      <div
                        className="d-flex f-align-center ml-2 mb-3"
                        key="Transfer Record Rejected"
                      >
                        <div
                          className={clsx(
                            "mr-2",
                            classes.legendHint,
                            classes["isTransferRejected"]
                          )}
                        ></div>
                        <Typography variant="body2">
                          Transfer Record Rejected
                        </Typography>
                      </div>
                    )}
                    {transferRecordApproved && (
                      <div
                        className="d-flex f-align-center ml-2 mb-3"
                        key="Transfer Record Accepted"
                      >
                        <div
                          className={clsx(
                            "mr-2",
                            classes.legendHint,
                            classes["isAccepted"]
                          )}
                        ></div>
                        <Typography variant="body2">
                          Transfer Record Accepted
                        </Typography>
                      </div>
                    )}
                  </>
                )}
                {tabIndex === 2 && transferScheduleIsAccepted && (
                  <div
                    className="d-flex f-align-center ml-2 mb-3"
                    key="Transfer Record Accepted"
                  >
                    <div
                      className={clsx(
                        "mr-2",
                        classes.legendHint,
                        classes["isAccepted"]
                      )}
                    ></div>
                    <Typography variant="body2">
                      Transfer Record Accepted
                    </Typography>
                  </div>
                )}
              </div>
            </Paper>
          )}
      </div>
      {state.isRowOpen.length > 0 && (
        <div
          ref={wrapRef1}
          onScroll={handleScroll}
          style={{
            overflowX: "auto",
            width: "calc(100vw - 110px)",
            height: "2%",
            bottom: 0,
            position: "fixed",
          }}
        >
          <div
            style={{
              width: state.dimensions,
            }}
          >
            &nbsp;
          </div>
        </div>
      )}
    </>
  );
};

export default DesktopView;
