import { useCallback, useEffect, useState } from "react";
import React from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import SharedService from "../../shared/service";
import Service from "../service";
import { isTransferScheduleEnable, noop } from "shared/constants";
import Tooltip from "@material-ui/core/Tooltip";
import { ERROR_CODES, PERMISSION, ROLE } from "../../shared/constants";
import { ActionDialog, Datepicker, Dropdown, Dialog } from "shared/components";
import FormLabel from "@material-ui/core/FormLabel";
import ScheduleCalendar from "./../display-calender";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Chip from "@material-ui/core/Chip";
import {
  hasFeatureAccess,
  getTokenData,
  getDateString,
  queryStringBuilder,
  getUTCDateString,
} from "utils";
import { AppContext } from "shared/contexts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import BaseRosterIcon from "../../../assets/images/base-roster.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";
import ForceSubmitDialog from "../forceSubmit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HistoryIcon from "@material-ui/icons/History";
import TransferWhiteIcon from "../../../assets/icons/transfer-white.svg";
import { Badge, IconButton } from "@material-ui/core";
const legendList = [
  { label: "Submitted", className: "completeSubmittedDay" },
  { label: "Partial Submitted", className: "partialSubmittedDay" },
  { label: "Not Submitted", className: "notSubmittedDay" },
  { label: "Not Created", className: "notCreatedDay" },
  { label: "Forced Submit", className: "forcedSubmitDay" },
  { label: "No Schedule", className: "weekoff" },
  { label: "Under Review", className: "underReview" },
  { label: "Approved", className: "bsApproved" },
];

const defaultState = {
  fromScheduleDate: new Date(),
  toScheduleDate: new Date(),
  previousDate: new Date(),
  isLoading: false,
  isMultiSchedule: false,
  isFetchingList: {
    users: false,
    submissionHistory: false,
    pendingItems: false,
    forceSubmitReasonList: false,
  },
  submissionHistory: {
    completeSubmitted: [],
    bsApproved: [],
    notCreated: [],
    notSubmitted: [],
    partialSubmitted: [],
    forcedlySubmitted: [],
    underReview: [],
  },
  overridePayload: null,
  previousDateConfirmation: false,
  selectedKAM: null,
  userRole: null,
  viewScheduleConfirmation: false,
  previousScheduleActiveDate: null,
  openForceSubmitDialog: false,
  shouldShowWarning: false,
  weekend: [],
  forceSubmitReasonList: [],
  is_weekday: true,
  value: "weekdays",
  isAllDates: false,
  dateRange: [],
  shouldEnableWeekend: false,
  shouldEnableWeekdays: false,
  confirmTransfer: false,
};

const CreateSchedule = ({ history, match, location }) => {
  const classes = useStyles();
  const userData = getTokenData();
  const isRoleKAM = (userData?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleView = (userData?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const [state, setState] = useState({
    ...defaultState,
    userRole: (userData.role || "").toLowerCase(),
  });

  const kamId =
    state.userRole == ROLE.KAM ? userData?.id : state.selectedKAM?.id;

  const { appData, setHeaderElements, updateContextData } =
    React.useContext(AppContext);

  const canViewAllSchedules = hasFeatureAccess(PERMISSION.VIEW_ALL_SCHEDULES);
  const canCreateMemberSchedule = hasFeatureAccess(
    PERMISSION.CREATE_MEMBER_SCHEDULE
  );

  const fetchList = async (listType = "users", callback = noop, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: true,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getUsersList";
        responseKey = "users";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          submissionHistory: false,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    updateContextData(listType, data[responseKey] || []);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: false,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const fetchdateRecordsList = async (fromDate, toDate, kamId) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getdateWithoutRecordsList(
      fromDate,
      toDate,
      kamId
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      creatableWeekdayDates: data?.creatableWeekdayDates || [],
      creatableWeekendDates: data?.creatableWeekendDates || [],
    }));
  };

  const handleConfirmTransfer = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmTransfer: isCheckBoxChecked,
    }));
  };

  const fetchSubmissionHistory = async (kamId, month, year) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: true,
      },
    }));

    const { data, error } = await Service.getSubmissionHistory(
      kamId,
      month,
      year
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          submissionHistory: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      submissionHistory: {
        ...prevState.submissionHistory,
        completeSubmitted: (data?.completeSubmitted || []).map((date) =>
          getDateString(date)
        ),
        bsApproved: (data?.bsApproved || []).map((date) => getDateString(date)),
        notCreated: (data?.notCreated || []).map((date) => getDateString(date)),
        notSubmitted: (data?.notSubmitted || []).map((date) =>
          getDateString(date)
        ),
        partialSubmitted: (data?.partialSubmitted || []).map((date) =>
          getDateString(date)
        ),
        forcedlySubmitted: (data?.forcedlySubmitted || []).map((data) =>
          getDateString(data.date)
        ),
        underReview: (data?.underReview || []).map((date) =>
          getDateString(date)
        ),
      },
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: false,
      },
    }));
  };

  const handleSubmit = async (payload = {}) => {
    if (!payload.fromScheduleDate || !payload.toScheduleDate) {
      return toast.error(
        `Please select ${!payload.fromScheduleDate ? "from " : "to "} date.`
      );
    }
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (canCreateMemberSchedule) {
      payload = {
        ...payload,
        toScheduleDate: getDateString(
          state.isMultiSchedule ? state.toScheduleDate : state.fromScheduleDate
        ),
        kam_id: state.selectedKAM?.id,
      };
    }

    if (!payload.isOfPreviousDate) {
      payload = {
        ...payload,
        toScheduleDate: getDateString(
          state.isMultiSchedule ? state.toScheduleDate : state.fromScheduleDate
        ),
        is_weekday: state.is_weekday,
      };
    }

    const { error, data, message } = await Service.generateSchedule(payload);

    if (error) {
      if (
        Array.isArray(error) &&
        error[0]?.code === ERROR_CODES.OVERRIDE_SCHEDULE
      ) {
        setState((prevState) => ({
          ...prevState,
          overridePayload: {
            ...payload,
            existingDates: Array.isArray(error) ? error[0]?.existingDates : [],
          },
        }));
      } else {
        toast.error(
          Array.isArray(error) ? (
            <>
              {error.map((item) => (
                <>
                  {item.message}
                  <ul>
                    {(
                      item.submittedDates ||
                      item.forcedSubmittedDates ||
                      []
                    ).map((date) => (
                      <li>
                        <Typography variant="body2" className="text-bold">
                          {getUTCDateString(date)}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </>
              ))}
            </>
          ) : (
            error.message
          )
        );
      }
    } else {
      toast.success(message || "Schedule created successfully.");
      try {
        let fromScheduleDate = payload.fromScheduleDate?.split("/").join("-");
        let toScheduleDate = getDateString(
          state.isMultiSchedule
            ? state.toScheduleDate
            : payload.fromScheduleDate
        )
          .split("/")
          .join("-");
        history.push({
          pathname: `/daily-schedule/day/${fromScheduleDate}/${toScheduleDate}${
            payload.kam_id ? `/kam/${payload.kam_id}` : ""
          }`,
          state: { wantToTransfer: state.confirmTransfer },
        });
      } catch (err) {
        console.error(`Error in converting schedule date: ${err}`);
      }
    }

    setState((prevState) => ({
      ...prevState,
      previousDate: prevState.previousScheduleActiveDate,
      isLoading: false,
      showWarningPopup: false,
    }));
  };

  const handleViewSchedule = async () => {
    let fromScheduleDate = getDateString(state.fromScheduleDate)
      .split("/")
      .join("-");
    let toScheduleDate = getDateString(
      state.isMultiSchedule ? state.toScheduleDate : state.fromScheduleDate
    )
      .split("/")
      .join("-");
    // history.push(
    //   `/daily-schedule/day/${fromScheduleDate}/${toScheduleDate}/kam/${
    //     state.userRole === ROLE.KAM ? userData?.id : state.selectedKAM?.id
    //   }`
    // );
    history.push({
      pathname: `/daily-schedule/day/${fromScheduleDate}/${toScheduleDate}/kam/${
        state.userRole === ROLE.KAM ? userData?.id : state.selectedKAM?.id
      }`,
    });
  };

  const handleGetSchedule = async (
    fromScheduleDate,
    toScheduleDate,
    kamId = null
  ) => {
    if (!(fromScheduleDate && toScheduleDate && kamId)) {
      return toast.error(
        `Please select ${
          !kamId ? "kam user" : !fromScheduleDate ? '"From Date"' : '"To Date"'
        }.`
      );
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const queryString = queryStringBuilder(
      0,
      0,
      [],
      [
        {
          field: "schedule_date",
          type: "gte",
          value: fromScheduleDate,
        },
        {
          field: "schedule_date",
          type: "lte",
          value: toScheduleDate,
        },
        { field: "kam_id", type: "=", value: kamId },
      ]
    );

    const { error, data } = await Service.get(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        previousDate: prevState.previousScheduleActiveDate,
      }));
    } else {
      const datesWithSchedule = data.dailyRecord.map(
        (schedule) => schedule.schedule_date
      );

      const scheduleDates = [...new Set(datesWithSchedule)];
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        viewScheduleConfirmation: scheduleDates.length > 0 ? true : false,
        previousDate: prevState.previousScheduleActiveDate,
        scheduleDates,
      }));
    }

    // const today = new Date();
    // fetchSubmissionHistory(kamId, today.getMonth() + 1, today.getFullYear());
  };

  const getDatesInRange = (startDate, endDate) => {
    let d1 = new Date(startDate);
    let d2 = new Date(endDate);

    const date = d1.getTime();

    const data = new Date(date);
    data.setDate(data.getDate());

    const dates = [];

    while (data <= d2) {
      dates.push(new Date(data));
      data.setDate(data.getDate() + 1);
    }

    const weekendDates = dates.filter(
      (date) => date.getDay() === 6 || date.getDay() === 0
    );

    const weekdaysDates = dates.filter(
      (date) => date.getDay() !== 6 && date.getDay() !== 0
    );

    const isAllWeekend = weekendDates.length === dates.length;
    const isAllWeekday = weekendDates.length === 0;

    setState((prevState) => ({
      ...prevState,
      value: isAllWeekend ? "weekend" : "weekdays",
      is_weekday: isAllWeekend ? false : true,
      dateRange: state.value === "weekdays" ? weekdaysDates : weekendDates,
      isBothDateRange: !isAllWeekend && !isAllWeekday,
      // shouldShowWarning: !isAllWeekend && !isAllWeekday,
      shoulddisableWeekdays: isAllWeekend,

      shouldShowWarning: true,
      weekdaysDates,
      weekendDates,
      allDates: dates,
    }));
  };

  useEffect(() => {
    fetchLeaveTypeList();
    getDatesInRange(
      state.fromScheduleDate,
      state.isMultiSchedule ? state.toScheduleDate : state.fromScheduleDate
    );

    const today = new Date();

    if (kamId) {
      fetchdateRecordsList(
        getDateString(state.fromScheduleDate),
        getDateString(
          state.isMultiSchedule ? state.toScheduleDate : state.fromScheduleDate
        ),
        state.selectedKAM?.id || kamId
      );
      fetchSubmissionHistory(
        kamId,
        state.previousScheduleActiveDate
          ? state.previousScheduleActiveDate.getMonth() + 1
          : today.getMonth() + 1,
        state.previousScheduleActiveDate
          ? state.previousScheduleActiveDate.getFullYear()
          : today.getFullYear()
      );
      fetchKamSetting();
    }
  }, [appData.pendingItems]);

  useEffect(() => {
    setHeaderElements([
      isTransferScheduleEnable &&
        canViewAllSchedules &&
        !!appData.transferRecords &&
        appData.transferRecords.length > 0 && (
          <div className="d-flex f-align-center" key="transferRecords">
            <Tooltip title="TRANSFER REQUEST RECEIVED" placement="top-start">
              <IconButton
                className="color-white"
                onClick={() => history.push("/transfer-schedule")}
              >
                <Badge
                  color="error"
                  badgeContent={
                    appData.transferRecords.filter(
                      (item) => item.status === "PENDING"
                    ).length
                  }
                  className="c-pointer"
                >
                  <img
                    src={TransferWhiteIcon}
                    alt="reports"
                    height={30}
                    width={30}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        ),
      !!appData.pendingItems.length && (
        <div className="d-flex f-align-center m-2" key="pendingItems">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>
      ),
    ]);

    return () => setHeaderElements([]);
  }, [
    appData.pendingItems,
    state.forceSubmitReasonList,
    state.isFetchingList.forceSubmitReasonList,
    state.isLoading,
    appData.transferRecords,
  ]);

  useEffect(() => {
    const { schedule_date, end_date } = match.params;
    const schedule = location.state;
    if (schedule_date || end_date) {
      if (
        isNaN(Date.parse(schedule_date)) ||
        isNaN(Date.parse(end_date || schedule_date))
      ) {
        return toast.error("Invalid date.");
      }
      setState((prevState) => ({
        ...prevState,
        fromScheduleDate: getUTCDateString(schedule_date),
        toScheduleDate: getUTCDateString(end_date || schedule_date),
        isMultiSchedule: schedule?.isMultiSchedule || false,
      }));
    }
  }, [match.params.schedule_date, match.params.end_date, location.state]);

  useEffect(() => {
    const today = new Date();
    if (canViewAllSchedules) {
      fetchList("users", (data) => {
        const KAMUsers = (data || []).filter(
          (user) => (user.role || "").toLowerCase() === ROLE.KAM
        );
        const kamIndex = KAMUsers.findIndex(
          (item) => item.id == match.params.kam_id
        );

        const selectedKAM = !match.params.kam_id ? null : KAMUsers[kamIndex];
        //const selectedKAM = KAMUsers[kamIndex < 0 ? 0 : kamIndex];
        if (!!kamId) {
          fetchSubmissionHistory(
            selectedKAM?.id,
            today.getMonth() + 1,
            today.getFullYear()
          );
        }

        setState((prevState) => ({
          ...prevState,
          selectedKAM,
        }));
      });
    } else {
      fetchSubmissionHistory(
        userData?.id,
        today.getMonth() + 1,
        today.getFullYear()
      );
    }
  }, []);

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        forceSubmitReasonList: true,
      },
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          forceSubmitReasonList: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      forceSubmitReasonList: data?.rows || [],
      isFetchingList: {
        ...prevState.isFetchingList,
        forceSubmitReasonList: false,
      },
    }));
  };

  useEffect(() => {
    if (kamId) {
      if (isTransferScheduleEnable && !isRoleKAM) {
        fetchList("transferRecords", noop, "", kamId);
      }
      fetchKamSetting();
      fetchList("pendingItems", noop, kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    fetchList("users");
  }, [match.params.path]);

  useEffect(() => {
    if (state.previousScheduleActiveDate) {
      fetchSubmissionHistory(
        state.userRole == ROLE.KAM ? userData?.id : state.selectedKAM?.id,
        state.previousScheduleActiveDate.getMonth() + 1,
        state.previousScheduleActiveDate.getFullYear()
      );
    }
  }, [state.previousScheduleActiveDate]);

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const fetchKamSetting = async () => {
    const { data, error } = await Service.getKamSetting(kamId);
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      weekend: data.settings.week_off_days,
    }));
  };

  const getDayClassName = useCallback(
    (date) => {
      const activeDay = state.previousScheduleActiveDate || new Date();
      const firstDay = new Date(
        activeDay.getFullYear(),
        activeDay.getMonth(),
        1
      );
      const lastDay = new Date(
        activeDay.getFullYear(),
        activeDay.getMonth() + 1,
        0
      );

      const isFutureDate = date > new Date();

      const isWeeklyOff = state.weekend.includes(date.getDay().toString());
      let dayClass = clsx("disabled  color-grey", {
        // [classes.notCreatedDay]: !isFutureDate,
        [classes.backgroundWhite]: isFutureDate || !isFutureDate,
        [classes.weekend]: isWeeklyOff,
        // [classes.weekoff]: isWeeklyOff && isFutureDate,
      });

      const dateString = getDateString(date);
      if (state.submissionHistory.notSubmitted.includes(dateString)) {
        dayClass = clsx("disabled color-grey", classes.backgroundWhite, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.partialSubmitted.includes(dateString)
      ) {
        dayClass = clsx("disabled color-grey", classes.backgroundWhite, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.bsApproved.includes(dateString)) {
        dayClass = clsx(classes.clickableDate, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.completeSubmitted.includes(dateString)
      ) {
        dayClass = clsx(classes.clickableDate, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.forcedlySubmitted.includes(dateString)
      ) {
        dayClass = clsx("disabled color-grey", classes.backgroundWhite, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.underReview.includes(dateString)) {
        dayClass = clsx("disabled color-grey", classes.backgroundWhite, {
          [classes.weekend]: isWeeklyOff,
        });
      }

      return date > lastDay || date < firstDay
        ? clsx("disabled", classes.bgColorNone)
        : state.isFetchingList.submissionHistory
        ? clsx("loading disabled", classes.colorNone)
        : clsx(dayClass, classes.calendarDay);
    },
    [
      state.submissionHistory,
      state.isFetchingList.submissionHistory,
      state.weekend,
      classes,
    ]
  );

  if (state.isLoading) {
    return <CircularProgress className={classes.pageLoader} />;
  }
  const kamOptions = appData.users
    .filter((user) => (user.role || "").toLowerCase() === ROLE.KAM)
    .sort((a, b) => b.is_active - a.is_active);

  return (
    <>
      <Grid
        container
        component={Paper}
        elevation={2}
        className="p-4 h-100 p-relative"
      >
        {/* {appData.isTabletView && ( */}
        <Grid xs={12}>
          <Box className="d-flex f-justify-end f-align-center">
            <div className="w-25"></div>
            {appData.isTabletView && (
              <Box className={"d-flex f-justify-center"}>
                <Typography variant="h5">View schedule</Typography>
              </Box>
            )}
            {!isRoleKAM && (
              <Button
                style={{ float: "right" }}
                className="mt-1 mr-2"
                startIcon={<HistoryIcon />}
                variant="contained"
                color="primary"
                onClick={() => history.push("/daily-schedule/audit-history")}
              >
                View Audit History
              </Button>
            )}
            <Button
              startIcon={<ArrowBackIcon />}
              className="ml-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
            >
              Back To Home
            </Button>
          </Box>
        </Grid>
        {/* )} */}
        <Grid xs={12}>
          <Box
            className={clsx("d-flex f-justify-center", {
              "flex-column": appData.isTabletView,
              "f-align-center mt-8": !appData.isTabletView,
            })}
          >
            {canViewAllSchedules && (
              <Box
                className={clsx("d-flex f-align-center f-justify-center", {
                  "mb-4": appData.isTabletView,
                })}
              >
                <Typography
                  variant="h6"
                  className={clsx({ "mr-2": appData.isTabletView })}
                >
                  {appData.isTabletView
                    ? "Of"
                    : isRoleView
                    ? "View schedule of"
                    : "Create schedule of"}
                  :{" "}
                </Typography>
                <Autocomplete
                  className={clsx("ml-4", classes.kam)}
                  size="small"
                  value={state.selectedKAM}
                  options={kamOptions}
                  getOptionLabel={(option) =>
                    option.name ||
                    `${option.first_name || ""} ${option.last_name || ""}-${
                      option.email || ""
                    }-(${option.username || ""})`
                  }
                  renderOption={({
                    first_name,
                    last_name,
                    email,
                    is_active,
                    username,
                  }) => (
                    <Typography
                      variant="body1"
                      className={clsx({
                        "color-text-disabled": !is_active,
                      })}
                    >{`${first_name || " "} ${
                      last_name || ""
                    }-${email}-(${username})`}</Typography>
                  )}
                  renderInput={(params) =>
                    state.isFetchingList.users ? (
                      <Skeleton variant="rect" width="100%" height={40} />
                    ) : (
                      <TextField
                        {...params}
                        label={
                          state.selectedKAM === null
                            ? "Please select KAM"
                            : "KAM"
                        }
                        variant="outlined"
                      />
                    )
                  }
                  onChange={(evt, selectedKAM) => {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        selectedKAM,
                      };
                    });

                    if (selectedKAM !== null) {
                      handleGetSchedule(
                        getDateString(state.fromScheduleDate),
                        getDateString(
                          state.isMultiSchedule
                            ? state.toScheduleDate
                            : state.fromScheduleDate
                        ),
                        selectedKAM?.id
                      );
                    }
                  }}
                />
              </Box>
            )}
            <Box
              className={clsx("d-flex f-align-center f-justify-center", {
                "ml-4 mr-4": !appData.isTabletView,
                "mb-2 mt-4": appData.isTabletView,
              })}
            >
              <Typography variant="h6" className="mr-4">
                {!(canViewAllSchedules || appData.isTabletView) &&
                  "Create schedule"}
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  value={state.isMultiSchedule ? "multiple" : "single"}
                  onChange={(evt) => {
                    const isMultiSchedule = evt.target.value !== "single";
                    if (!!state.selectedKAM) {
                      handleGetSchedule(
                        getDateString(state.fromScheduleDate),
                        getDateString(state.fromScheduleDate),
                        state.userRole == ROLE.KAM
                          ? userData?.id
                          : state.selectedKAM?.id
                      );
                    }
                    setState((prevState) => ({
                      ...prevState,
                      isMultiSchedule,
                      toScheduleDate: state.fromScheduleDate,
                    }));
                  }}
                >
                  <div
                    className={clsx(
                      "d-flex f-align-center f-justify-center mt-2"
                    )}
                  >
                    <FormControlLabel
                      value="single"
                      className={"mb-2"}
                      control={<Radio size="small" color="primary" />}
                      label="Specific date"
                    />
                    <FormControlLabel
                      value="multiple"
                      className={"mb-2"}
                      control={<Radio size="small" color="primary" />}
                      label="Date range"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
              <Datepicker
                mask
                preventClear
                label={state.isMultiSchedule ? "From date" : "Schedule date"}
                maxDate={
                  state.isMultiSchedule && new Date(state.toScheduleDate)
                }
                selected={state.fromScheduleDate}
                classes={{
                  input: {
                    root: clsx("ml-2", classes.datepickerWrapper),
                  },
                }}
                onChange={(fromScheduleDate) => {
                  if (
                    fromScheduleDate > new Date(state.toScheduleDate) &&
                    state.isMultiSchedule
                  ) {
                    return toast.error(
                      '"From date" cannot be greater than "To date".'
                    );
                  }
                  setState((prevState) => {
                    return {
                      ...prevState,
                      fromScheduleDate,
                    };
                  });
                  getDatesInRange(
                    fromScheduleDate,
                    state.isMultiSchedule
                      ? state.toScheduleDate
                      : fromScheduleDate
                  );
                  handleGetSchedule(
                    getDateString(fromScheduleDate),
                    getDateString(
                      state.isMultiSchedule
                        ? state.toScheduleDate
                        : fromScheduleDate
                    ),
                    state.userRole == ROLE.KAM
                      ? userData?.id
                      : state.selectedKAM?.id
                  );
                }}
              />
              {state.isMultiSchedule && (
                <div className="ml-6">
                  <Datepicker
                    mask
                    preventClear
                    label="To date"
                    minDate={new Date(state.fromScheduleDate)}
                    selected={state.toScheduleDate}
                    classes={{
                      input: {
                        root: clsx("ml-2", classes.datepickerWrapper),
                      },
                    }}
                    onChange={(toScheduleDate) => {
                      if (toScheduleDate < new Date(state.fromScheduleDate)) {
                        return toast.error(
                          '"To date" cannot be less than "From date".'
                        );
                      }
                      setState((prevState) => {
                        return {
                          ...prevState,
                          toScheduleDate,
                        };
                      });
                      getDatesInRange(state.fromScheduleDate, toScheduleDate);
                      handleGetSchedule(
                        getDateString(state.fromScheduleDate),
                        getDateString(toScheduleDate),
                        state.userRole == ROLE.KAM
                          ? userData?.id
                          : state.selectedKAM?.id
                      );
                    }}
                  />
                </div>
              )}
            </Box>
            {isTransferScheduleEnable && (
              <Box className="d-flex f-align-center f-justify-center">
                <FormControlLabel
                  value="top"
                  disabled={state.route?.price_type === "Matrix"}
                  control={
                    <Checkbox
                      style={{
                        padding: "0px 9px",
                        marginBottom: "0px !important",
                      }}
                      checked={state.confirmTransfer}
                      onChange={(evt) =>
                        handleConfirmTransfer(evt.target.checked)
                      }
                      name="Transfer"
                    />
                  }
                  className="ml-1"
                  label="Want To Transfer"
                  labelPlacement="end"
                />
              </Box>
            )}
          </Box>
        </Grid>

        {!isRoleView && (
          <>
            <Grid xs={12} md={16}>
              <Box className="d-flex flex-column f-align-center">
                <Typography variant="h6" className="mb-2">
                  {appData.isTabletView
                    ? "By copying Base Roster"
                    : "Copy from Base Roster"}
                </Typography>
                <Box className="d-flex f-align-center">
                  <FormControl>
                    <RadioGroup
                      row
                      name="roster_type"
                      value={state.value}
                      onChange={(evt) => {
                        const value = evt.target.value;

                        setState((prevState) => ({
                          ...prevState,
                          is_weekday: value === "weekdays" ? true : false,
                          value,
                          dateRange:
                            value === "weekdays"
                              ? state.weekdaysDates
                              : state.weekendDates,
                        }));
                      }}
                    >
                      <FormControlLabel
                        value="weekdays"
                        control={<Radio size="small" color="primary" />}
                        label="Weekdays"
                        disabled={
                          state.value === "weekend" && !state.isBothDateRange
                        }
                      />
                      <FormControlLabel
                        value="weekend"
                        control={<Radio size="small" color="primary" />}
                        label="Weekend"
                        disabled={
                          state.value === "weekdays" && !state.isBothDateRange
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <img
                  alt="Base roster icon"
                  src={BaseRosterIcon}
                  className={clsx(
                    (!!state.selectedKAM || isRoleKAM) && "c-pointer",
                    classes.baseRosterIcon
                  )}
                  onClick={() => {
                    if (!isRoleKAM && state.selectedKAM === null) {
                      return false;
                    }
                    if (state.shouldShowWarning) {
                      setState((prevState) => ({
                        ...prevState,
                        showWarningPopup: true,
                      }));
                    } else {
                      handleSubmit({
                        fromScheduleDate: getDateString(state.fromScheduleDate),
                        toScheduleDate: getDateString(
                          state.isMultiSchedule
                            ? state.toScheduleDate
                            : state.fromScheduleDate
                        ),
                        isOfPreviousDate: false,
                      });
                    }
                  }}
                />
              </Box>
            </Grid>

            {/* {appData.isTabletView && (
              <Grid xs={12}>
                <Box className="d-flex f-justify-center f-align-center">
                  <Typography variant="h5">or</Typography>
                </Box>
              </Grid>
            )} */}

            {/* <Grid xs={12} md={6}>
              <Box className="d-flex flex-column f-align-center">
                <Typography variant="h6" className={classes.iconMargin}>
                  {appData.isTabletView
                    ? "By copying Submitted Schedule"
                    : "Copy from previous date"}
                </Typography>
                <Datepicker
                  mask
                  preventClear
                  inline
                  label="Date"
                  selected={state.previousDate}
                  classes={{
                    wrapper: "disabled",
                    // state.isFetchingList.submissionHistory && "disabled",
                    input: {
                      root: classes.datepickerWrapper,
                    },
                  }}
                  dayClassName={getDayClassName}
                  onMonthChange={(previousScheduleActiveDate) =>
                    setState((prevState) => ({
                      ...prevState,
                      previousScheduleActiveDate,
                    }))
                  }
                  onChange={(previousDate) => {
                    setState((prevState) => ({
                      ...prevState,
                      previousDate,
                      previousDateConfirmation: true,
                    }));
                  }}
                /> */}

            {/* {appData.isTabletView && (
              <Box className="d-flex f-justify-center f-align-center mt-2 f-wrap">
                {legendList.map((legend) => (
                  <Box className="d-flex f-align-center ml-2">
                    <Box
                      className={clsx(
                        "mr-2",
                        classes.legendHint,
                        classes[legend.className]
                      )}
                    ></Box>
                    <Typography variant="body2">{legend.label}</Typography>
                  </Box>
                ))}
              </Box>
            )} */}
            {/* </Box>
            </Grid> */}
          </>
        )}

        {/* {!appData.isTabletView && (
          <Grid xs={12}>
            <Box className="d-flex h-100 f-justify-between">
              <Box className="d-flex mt-auto">
                {legendList.map((legend) => (
                  <Box className="d-flex f-align-center ml-2">
                    <Box
                      className={clsx(
                        "mr-2",
                        classes.legendHint,
                        classes[legend.className]
                      )}
                    ></Box>
                    <Typography variant="body2">{legend.label}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        )} */}

        {!!kamId && (
          <Grid xs={12}>
            <Box
              className={clsx("d-flex f-justify-center f-align-center mt-4", {
                "flex-column mt-2": appData.isTabletView,
              })}
            >
              <ScheduleCalendar history={history} selectedkamId={kamId} />
            </Box>
          </Grid>
        )}
      </Grid>
      {!!state.overridePayload && (
        <ActionDialog
          open={!!state.overridePayload}
          positiveActionLabel="Yes"
          negativeActionLabel="No"
          contentText={
            <div className="mt-2">
              <Typography variant="body1">
                Existing data found for below dates. Do you want to override?
              </Typography>
              <ul>
                {state.overridePayload.existingDates.map((date) => (
                  <li>
                    <Typography variant="body1" className="text-bold">
                      {getUTCDateString(date)}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          }
          onConfirm={() => {
            const { existingDates, ...payload } = state.overridePayload;
            handleSubmit({
              ...payload,
              deleteExisting: true,
            });
            setState((prevState) => ({ ...prevState, overridePayload: null }));
          }}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, overridePayload: null }))
          }
          classes={{
            paper: classes.overrideDialog,
            confirm: "bg-primary",
          }}
        />
      )}
      {state.previousDateConfirmation && (
        <ActionDialog
          open={state.previousDateConfirmation}
          positiveActionLabel="OK"
          contentText={
            <div>
              Copying from <b>{getDateString(state.previousDate)}</b>?
            </div>
          }
          onConfirm={() => {
            handleSubmit({
              fromScheduleDate: getDateString(state.fromScheduleDate),
              toScheduleDate: getDateString(
                state.isMultiSchedule
                  ? state.toScheduleDate
                  : state.fromScheduleDate
              ),
              previousDate: getDateString(state.previousDate),
              isOfPreviousDate: true,
            });
            setState((prevState) => ({
              ...prevState,
              previousDateConfirmation: false,
            }));
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              previousDateConfirmation: false,
            }))
          }
          classes={{
            paper: classes.confirmationPaper,
            confirm: "bg-primary",
          }}
        />
      )}
      {state.viewScheduleConfirmation && (
        <ActionDialog
          open={state.viewScheduleConfirmation}
          positiveActionLabel="Yes"
          negativeActionLabel="No"
          contentText={
            <div>
              <Typography variant="body1">
                Schedule exists for the following dates :
                {state.scheduleDates.map((date) => (
                  <li>{getUTCDateString(date)}</li>
                ))}
              </Typography>
              <Typography variant="body1">Would you like to view?</Typography>
            </div>
          }
          onConfirm={handleViewSchedule}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              viewScheduleConfirmation: false,
            }))
          }
          classes={{
            paper: classes.confirmationPaper,
            confirm: "bg-primary",
          }}
        />
      )}
      {state.showWarningPopup && (
        <ActionDialog
          open={state.showWarningPopup}
          positiveActionLabel="Yes"
          negativeActionLabel="No"
          contentText={
            <div>
              <Typography>
                The selected Roster type is {state.value}, hence roster will be
                copied to the following dates:
                {state.value === "weekdays"
                  ? (state.creatableWeekdayDates || []).map((date) => (
                      <li>{getDateString(date)}</li>
                    ))
                  : (state.creatableWeekendDates || []).map((date) => (
                      <li>{getDateString(date)}</li>
                    ))}
                {/* {(state.dateRange || [])
                  .filter((date) => {
                    const dateString = getDateString(date);
                    return (
                      // state.submissionHistory.notCreated.includes(dateString) &&
                      !(state.weekend || []).includes(
                        String(new Date(dateString).getDay())
                      )
                    );
                  })
                  .map((date) => (
                    <li>{getDateString(date)}</li>
                  ))}{" "} */}
              </Typography>

              {/* {state.isBothDateRange && (
                <div>
                  Include All Dates:{" "}
                  <Checkbox
                    checked={state.isAllDates}
                    onChange={(evt) => {
                      setState((prevState) => ({
                        ...prevState,
                        isAllDates: evt.target.checked,
                        dateRange:
                          evt.target.checked === true
                            ? state.allDates
                            : state.dateRange,
                      }));
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
              )} */}
            </div>
          }
          onConfirm={() =>
            handleSubmit({
              fromScheduleDate: getDateString(state.fromScheduleDate),
              toScheduleDate: getDateString(
                state.isMultiSchedule
                  ? state.toScheduleDate
                  : state.fromScheduleDate
              ),
              isOfPreviousDate: false,
              is_weekday: state.value === "weekdays" ? true : false,
            })
          }
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              showWarningPopup: false,
              isAllDates: defaultState.isAllDates,
            }))
          }
          classes={{
            paper: classes.confirmationPaper,
            confirm: "bg-primary",
          }}
        />
      )}
      {state.openForceSubmitDialog && (
        <ForceSubmitDialog
          open={state.openForceSubmitDialog}
          pendingItems={appData.pendingItems.map((date, index) => ({
            date,
            id: index,
          }))}
          onClose={handleClose}
          kamId={kamId}
          forceSubmitReasonList={state.forceSubmitReasonList}
          onForceSubmit={() => fetchList("pendingItems", noop, kamId)}
        />
      )}
    </>
  );
};

export default CreateSchedule;
