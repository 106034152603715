import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  Tooltip,
  IconButton,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import useStyles from "./style";
import { AppContext, INVOICE_HEADER, noop } from "shared";
import NoRecords from "assets/images/norecord.svg";
import { preventInputKeyCodes } from "utils";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import NoteIcon from "@material-ui/icons/Note";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Service from "../service";
import { toast } from "react-toastify";

const defaultState = {
  combinedRows: [],
};

function formatToExponential(input) {
  const number = Number(input);

  if (isNaN(number)) {
    return "0";
  }

  if (number >= 10000000) {
    return number.toExponential(2);
  }

  return number.toFixed(2);
}

const InvoiceTable = ({
  rows = [],
  rowDescription = [],
  discount = 0,
  discountAmount = 0,
  usedCreditAmount = 0,
  errors = {},
  finalize = false,
  isDiscountAmount = false,
  handleFieldChange = noop,
  getDraftInvoice = noop,
  handleDeleteDialog = noop,
  handleDiscount = noop,
  handleEditDialog = noop,
  handleRowDescriptionEditClick = noop,
  handleDescriptionNotesDialog = noop,
  handleTotalAmountValidate = noop,
  isAvailableCredit = false,
}) => {
  const { appData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView, rows });
  const [state, setState] = useState(defaultState);

  const reOrdering = async (ordering) => {
    const order = ordering.reduce((acc, ele) => {
      if (!acc.invoice_id || acc.invoice_id !== ele.invoice_id) {
        acc.invoice_id = ele.invoice_id;
        acc.itemPositionList = [];
        acc.notesPositionList = [];
      }

      if (ele.isDescription) {
        acc.notesPositionList.push({
          id: ele.id,
          position: ele.position,
        });
      } else {
        acc.itemPositionList.push({
          id: ele.id,
          position: ele.position,
        });
      }

      return acc;
    }, {});

    const { error } = await Service.reOrdering(order);

    if (error) {
      getDraftInvoice();
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    getDraftInvoice(false, true);
  };

  const transformedRows = useMemo(() => {
    return rows.map((row) => {
      let description = `${row.route_name} - ${row.ic_name}`;
      const mainDescription = row?.description_names?.main_description?.trim();
      if (mainDescription && description?.trim() !== mainDescription) {
        description += ` (${row.description_names.main_description})`;
      }
      let quantity = `${row.bill_quantity} (Days)`;
      let rate = `${row.bill_rate}`;
      let amount = `${row.bill_amount}`;
      let notes = row.unbilled_notes || "";

      if (row.bill_fuel_surcharge && row.bill_fuel_surcharge !== "0.00") {
        description += `\nFuel Surcharge`;
        const fuelDescription =
          row?.description_names?.fuel_surcharge_description?.trim();
        if (fuelDescription && fuelDescription !== "Fuel Surcharge") {
          description += ` (${row.description_names.fuel_surcharge_description})`;
        }
        quantity += `\n-`;
        rate += `\n${row.bill_fuel_surcharge}%`;
        amount += `\n${row.bill_fuel_amount}`;
      }

      if (row.bill_extra_miles && row.bill_extra_miles !== "0.00") {
        description += `\nExtra Miles`;
        const extraMilesDescription =
          row?.description_names?.extra_miles_description?.trim();
        if (extraMilesDescription && extraMilesDescription !== "Extra Miles") {
          description += ` (${row.description_names.extra_miles_description})`;
        }
        quantity += `\n${row.bill_extra_miles} (Miles)`;
        rate += `\n${row.bill_rate_per_extra_mile}`;
        amount += `\n${row.bill_extra_mile_amount}`;
      }

      if (row.toll_amount && row.toll_amount !== "0.00") {
        description += `\nTolls`;
        const tollsDescription =
          row?.description_names?.tolls_description?.trim();
        if (tollsDescription && tollsDescription !== "Tolls") {
          description += ` (${row.description_names.tolls_description})`;
        }
        quantity += `\n-`;
        rate += `\n${row.toll_amount}`;
        amount += `\n${row.toll_amount}`;
      }

      return {
        row,
        description,
        quantity,
        rate,
        amount,
        notes,
        manual_entry: row.manual_entry,
        id: row.id,
        invoice_id: row.invoice_id,
        position: row.position,
        isDescription: false,
        bsIds: row.bs_ids,
      };
    });
  }, [rows]);

  const transformDescriptionRows = useMemo(() => {
    return rowDescription.map((row) => {
      return {
        description: row.customer_note,
        quantity: "",
        rate: "",
        amount: "",
        notes: "",
        id: row.id,
        invoice_id: row.invoice_id,
        position: row.position,
        isDescription: true,
      };
    });
  }, [rowDescription]);

  useEffect(() => {
    const combinedRowsData = [
      ...(transformedRows || []),
      ...(transformDescriptionRows || []),
    ];
    const rowsData = combinedRowsData.sort((a, b) => {
      if (a.position === null) return 1;
      if (b.position === null) return -1;
      return a.position - b.position;
    });

    setState((prevState) => ({
      ...prevState,
      combinedRows: rowsData || defaultState.combinedRows,
    }));
  }, [transformedRows, transformDescriptionRows]);

  const initialTotalAmount = useMemo(() => {
    return transformedRows
      .reduce((sum, row) => {
        const amounts = row.amount
          .split("\n")
          .map((value) => parseFloat(value) || 0);
        return sum + amounts.reduce((acc, curr) => acc + curr, 0);
      }, 0)
      .toFixed(2);
  }, [transformedRows]);

  const totalAmount = useMemo(() => {
    const newAmount = (
      parseFloat(initialTotalAmount) - parseFloat(discountAmount)
    ).toFixed(2);
    const amount = parseFloat(newAmount) - parseFloat(usedCreditAmount);

    return amount.toFixed(2);
  }, [initialTotalAmount, discountAmount, usedCreditAmount]);

  useEffect(() => {
    if (totalAmount < 0) {
      handleTotalAmountValidate(true);
    } else {
      handleTotalAmountValidate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmount]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const items = Array.from(state.combinedRows);
    const [movedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, movedItem);

    const updatedRows = items.map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    setState((prevState) => ({
      ...prevState,
      combinedRows: updatedRows,
    }));
    reOrdering(updatedRows);
  };

  if (!state.combinedRows?.length) {
    return (
      <div className="d-flex f-justify-center">
        <img className={classes.noRecordsImg} src={NoRecords} alt="noRecord" />
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable
        isDropDisabled={finalize || state.combinedRows.length === 1}
        droppableId="droppable"
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.grid}
          >
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    {INVOICE_HEADER.map((header) => (
                      <TableCell
                        key={header.id}
                        className={!header.label && classes.tableCell}
                      >
                        {header.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.combinedRows.map((row, index) => (
                    <Draggable
                      key={row.id}
                      draggableId={row.id.toString()}
                      index={index}
                      isDragDisabled={
                        finalize || state.combinedRows.length === 1
                      }
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor:
                              index % 2 === 0 ? "#80808017" : "inherit",
                          }}
                        >
                          <TableCell>
                            <div {...provided.dragHandleProps}>
                              <DragHandleIcon
                                style={{
                                  cursor:
                                    !finalize && state.combinedRows.length > 1
                                      ? "grab"
                                      : "default",
                                  color:
                                    (finalize ||
                                      state.combinedRows.length === 1) &&
                                    "#e0e0e0",
                                }}
                                color="primary"
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Edit Row" placement="top-start">
                              <IconButton
                                disabled={
                                  ((!row.manual_entry || finalize) &&
                                    (!row.isDescription || finalize)) ||
                                  (row.manual_entry &&
                                    row.bsIds &&
                                    row.bsIds.length > 0)
                                }
                                color="primary"
                                style={{
                                  cursor: row.manual_entry && "pointer",
                                }}
                                onClick={() =>
                                  row.isDescription
                                    ? handleDescriptionNotesDialog(
                                        true,
                                        row.id,
                                        row.description
                                      )
                                    : handleEditDialog(row.id)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Row" placement="top-start">
                              <IconButton
                                disabled={
                                  (!row.manual_entry || finalize) &&
                                  (!row.isDescription || finalize)
                                }
                                style={{
                                  color:
                                    (row.manual_entry && !finalize) ||
                                    (row?.isDescription && !finalize) ||
                                    (row.manual_entry &&
                                      row.bsIds &&
                                      row.bsIds.length > 0 &&
                                      !finalize)
                                      ? "#e53935"
                                      : null,
                                  cursor: row.manual_entry && "pointer",
                                }}
                                onClick={() => {
                                  const { id, isDescription, bsIds } = row;
                                  handleDeleteDialog(id, isDescription, bsIds);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            {!!row.notes && (
                              <Tooltip title={row.notes} placement="top-start">
                                <IconButton style={{ cursor: "default" }}>
                                  <NoteIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          {["description", "quantity", "rate", "amount"].map(
                            (field) => (
                              <TableCell
                                key={field}
                                className={
                                  field === "description" &&
                                  !finalize &&
                                  !row.isDescription &&
                                  classes.cell
                                }
                                style={{
                                  padding: "6px 16px",
                                  cursor:
                                    field === "description" &&
                                    !finalize &&
                                    !row.isDescription
                                      ? "pointer"
                                      : "default",
                                  maxWidth:
                                    field === "description" &&
                                    row.isDescription &&
                                    "200px",
                                  whiteSpace:
                                    field === "description" &&
                                    row.isDescription &&
                                    "normal",
                                  wordWrap:
                                    field === "description" &&
                                    row.isDescription &&
                                    "break-word",
                                }}
                                onClick={() =>
                                  field === "description" &&
                                  !finalize &&
                                  !row.isDescription &&
                                  handleRowDescriptionEditClick(row)
                                }
                              >
                                {row[field].split("\n").map((line, i) => (
                                  <div key={i}>{line}</div>
                                ))}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              </Table>
            </TableContainer>
            {state.combinedRows?.length > 0 && (
              <div className={classes.totalAmount}>
                <div
                  className="d-flex f-align-center f-justify-between"
                  style={{ marginBottom: "8px" }}
                >
                  <Typography variant="h6">Sub Total</Typography>
                  <Typography variant="h6" className="ml-2">
                    {initialTotalAmount || 0}
                  </Typography>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex f-align-center f-justify-between">
                    <div className="d-flex f-align-center f-justify-between mb-1">
                      <Typography variant="body1">Discount by:</Typography>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                        className={clsx("ml-2", finalize && "no-events")}
                        style={{ cursor: finalize ? "default" : "pointer" }}
                      >
                        <Tooltip
                          title={"Calculate discount with percentage"}
                          placement="top"
                        >
                          <Button
                            className={
                              isDiscountAmount === false
                                ? classes.selectedButton
                                : ""
                            }
                            onClick={() => handleDiscount(false)}
                          >
                            %
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title={"Calculate discount with amount"}
                          placement="top"
                        >
                          <Button
                            className={
                              isDiscountAmount === true
                                ? classes.selectedButton
                                : ""
                            }
                            onClick={() => handleDiscount(true)}
                          >
                            $
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                      {!isDiscountAmount && (
                        <TextField
                          type="number"
                          size="small"
                          name="discount"
                          variant="outlined"
                          value={discount}
                          onWheel={(event) => event.target.blur()}
                          error={!!errors.discount?.trim()}
                          onKeyDown={preventInputKeyCodes}
                          onChange={handleFieldChange}
                          disabled={finalize}
                          style={{ marginLeft: 44, width: 120 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      )}
                      {isDiscountAmount && (
                        <TextField
                          type="number"
                          size="small"
                          name="discountAmount"
                          variant="outlined"
                          value={discountAmount}
                          onWheel={(event) => event.target.blur()}
                          error={!!errors.discountAmount?.trim()}
                          onKeyDown={preventInputKeyCodes}
                          onChange={handleFieldChange}
                          disabled={
                            finalize ||
                            initialTotalAmount === "0.00" ||
                            initialTotalAmount === "0"
                          }
                          style={{ marginLeft: 44, width: 120 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </div>
                    <Typography variant="body1" className="ml-2">
                      {+discountAmount > 0
                        ? `- ${formatToExponential(discountAmount)}`
                        : formatToExponential(discountAmount) || 0}
                    </Typography>
                  </div>
                  {!!errors.discountAmount?.trim() && (
                    <Typography variant="body1" align="left" color="error">
                      {errors.discountAmount}
                    </Typography>
                  )}
                  {!!errors.discount?.trim() && (
                    <Typography variant="body1" align="left" color="error">
                      {errors.discount}
                    </Typography>
                  )}
                </div>
                {isAvailableCredit && (
                  <div className="d-flex flex-column mt-3">
                    <div className="d-flex f-align-center f-justify-between mb-1">
                      <div className="d-flex f-align-center f-justify-between">
                        <Typography variant="body1">Credit:</Typography>

                        <TextField
                          type="number"
                          size="small"
                          name="usedCreditAmount"
                          variant="outlined"
                          value={usedCreditAmount}
                          error={!!errors.usedCreditAmount?.trim()}
                          onKeyDown={preventInputKeyCodes}
                          onWheel={(event) => event.target.blur()}
                          onChange={handleFieldChange}
                          disabled={
                            finalize ||
                            initialTotalAmount === "0.00" ||
                            initialTotalAmount === "0"
                          }
                          style={{ marginLeft: 48, width: 245 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <Typography variant="body1" className="ml-2">
                        {+usedCreditAmount > 0
                          ? `- ${formatToExponential(usedCreditAmount)}`
                          : formatToExponential(usedCreditAmount) || 0}
                      </Typography>
                    </div>

                    {!!errors.usedCreditAmount?.trim() && (
                      <Typography variant="body1" color="error" align="left">
                        {errors.usedCreditAmount}
                      </Typography>
                    )}
                  </div>
                )}
                <Divider className="mt-4 mb-2" />
                <div className="d-flex f-align-center f-justify-between">
                  <Typography variant="h6">Total ( $ )</Typography>
                  <Typography
                    variant="h6"
                    style={{ color: totalAmount < 0 ? "red" : "inherit" }}
                  >
                    {totalAmount || 0}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default InvoiceTable;
