import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "calc(100vh - 550px)",
  },
  griddddd: {
    [theme.breakpoints.down("sm")]: {
      height: (props) => (props.isRoleKAM ? 232 : 146),
    },
    height: 240,
  },
  pageBtnwrapper: {},
  iconButton: {
    borderColor: "#58585a",
    borderRadius: 8,
    width: 128,
    [theme.breakpoints.down("sm")]: {
      width: (props) => props.isRoleKAM && 198,
    },
  },
  icon: {
    [theme.breakpoints.down("sm")]: {
      height: (props) => props.isRoleKAM && "148px",
    },
    height: "75px",
  },
  dahsboardItemTitle: {
    color: grey[900],
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: (props) => props.isRoleKAM && 22,
    },
  },
  paperSpacing: {
    marginTop: "2px",
    height: "calc(100vh - 12.6rem)",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
      borderRadius: "60px",
    },
  },
  buttonGroup: { marginTop: "1rem" },
  button: {
    border: "none",
    borderBottom: "2px solid rgb(250 250 250)",
    borderRadius: 0,
  },
  selectedButton: {
    borderBottom: "2px solid #004700",
    color: "#004700",
  },
}));

export default useStyles;
