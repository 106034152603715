import { useEffect, useState } from "react";
import useStyles from "./style";
import { Datepicker, Dialog, VALIDATIONS, noop } from "shared";
import {
  getDateString,
  getUTCDateString,
  preventInputKeyCodes,
  validator,
} from "utils";
import clsx from "clsx";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Service from "../service";
import { modeOfPaymentList } from "modules/shared/constants";

let isFieldChange = false;

const defaultState = {
  customer: null,
  location: null,
  pdxCompany: null,
  modeOfPayment: null,
  invoiceNumber: null,
  isRecordPayment: false,
  referenceNumber: "",
  errors: {
    referenceNumber: " ",
    amount: " ",
    modeOfPayment: " ",
    notes: " ",
  },
  balanceAmount: 0,
  amount: 0,
  paymentDate: getDateString(new Date()),
  message: null,
  updatePaidPaymentPopup: false,
  isPaid: false,
  creditErrorMessage: " ",
  hideAvailCreditInfo: false,
  notes: "",
};

const RecordPayment = ({
  customerList,
  entry = {},
  open = false,
  handleClose = noop,
  handleFetch = noop,
  getInvoice = noop,
  isEdit = false,
  isPaymentSummary = false,
  onUpdateClose = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const availableCredit = isEdit
    ? entry?.customer_branch?.available_credit
    : entry?.invoiceDetails?.customer_branch?.available_credit;

  useEffect(() => {
    if (!!entry && Object.keys(entry).length > 0) {
      setState((prevState) => ({
        ...prevState,
        pdxCompany:
          customerList
            .map((item) =>
              item.customer_branches.find((branch) =>
                isEdit
                  ? branch.id === entry?.branch_id
                  : branch.id === entry.invoiceDetails?.customer_branch_id
              )
            )
            .find((branch) => branch !== undefined) || defaultState.pdxCompany,
        customer:
          customerList.find((item) =>
            item.customer_branches.some((branch) =>
              isEdit
                ? branch.id === entry?.branch_id
                : branch.id === entry.invoiceDetails?.customer_branch_id
            )
          ) || defaultState.customer,
        location:
          customerList
            .map((item) =>
              item.customer_branches.find((branch) =>
                isEdit
                  ? branch.id === entry?.branch_id
                  : branch.id === entry.invoiceDetails?.customer_branch_id
              )
            )
            .find((branch) => branch !== undefined) || defaultState.location,
        invoiceNumber: isEdit
          ? entry?.invoice_number
          : entry.invoiceDetails.serial_no || defaultState.invoiceNumber,
        balanceAmount: isEdit
          ? entry?.invoice?.balance_amt
          : entry.invoiceDetails?.balance_amt || defaultState.balanceAmount,
        modeOfPayment:
          (isEdit &&
            modeOfPaymentList?.find((mode) => mode.value === entry?.mode)) ||
          defaultState.modeOfPayment,
        amount: (isEdit && entry?.amount) || defaultState.amount,
        referenceNumber:
          (isEdit && entry?.reference_number) || defaultState.referenceNumber,
        paymentDate:
          (isEdit && getUTCDateString(entry?.date)) || defaultState.paymentDate,
        notes: (isEdit && entry?.payment_notes) || defaultState.notes,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, open, isEdit]);

  const handleSubmit = async (payload) => {
    setState((prevState) => ({ ...prevState, isRecordPayment: true }));

    let serviceMethod = isEdit ? "updateRecordPayment" : "recordPayment";

    const { error } = await Service[serviceMethod](payload.data);

    if (error && !!error.message) {
      setState((prevState) => ({ ...prevState, isRecordPayment: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    if (error && error[0].code === "PDX-PMT-001") {
      setState((prevState) => ({
        ...prevState,
        isRecordPayment: false,
        message: error[0].message,
        // updatePaidPaymentPopup: true,
        isPaid: true,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    } else {
      toast.success(
        isEdit
          ? "Payment updated successfully."
          : "Payment recorded successfully."
      );
      setState((prevState) => ({
        ...prevState,
        isRecordPayment: false,
        isPaid: false,
      }));
      if (isPaymentSummary) {
        handleFetch();
      } else {
        handleFetch("invoiceLoading");
        getInvoice({ id: payload.data?.invoice_id }, true);
      }
      onClose();
      if (isEdit && !isPaymentSummary) {
        onUpdateClose();
      }
    }
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      amount: [
        {
          type: VALIDATIONS.REQUIRED,
          value: true,
        },
      ],
      referenceNumber: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      notes: [
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
        {
          type: VALIDATIONS.REQUIRED,
          value: state.modeOfPayment && state.modeOfPayment.value === "credits",
        },
        {
          type: VALIDATIONS.REQUIRED,
          value: isEdit,
        },
        {
          type: VALIDATIONS.REQUIRED,
          value: +state.amount > +state.balanceAmount,
        },
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (field === "amount" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }
    if (field === "amount" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    if (field === "amount") {
      const inputCreditAmount = parseFloat(value || 0);

      if (
        state.modeOfPayment &&
        state.modeOfPayment?.value === "credits" &&
        +availableCredit > 0
      ) {
        if (
          (isEdit
            ? inputCreditAmount <= +availableCredit - +entry?.credit_amt
            : inputCreditAmount <= +availableCredit) &&
          (isEdit
            ? inputCreditAmount <= +state.balanceAmount + +entry?.amount
            : inputCreditAmount <= +state.balanceAmount)
        ) {
          setState((prevState) => ({
            ...prevState,
            [field]: value || 0,
            creditErrorMessage: defaultState.creditErrorMessage,
            errors: {
              ...prevState.errors,
              [field]: errorMessage,
            },
          }));
        } else if (
          (isEdit
            ? inputCreditAmount > +availableCredit - +entry?.credit_amt
            : inputCreditAmount > +availableCredit) ||
          (isEdit
            ? inputCreditAmount > +state.balanceAmount + +entry?.amount
            : inputCreditAmount > +state.balanceAmount)
        ) {
          setState((prevState) => ({
            ...prevState,
            [field]: value || 0,
            errors: {
              ...prevState.errors,
              [field]: "creditError",
            },
            creditErrorMessage:
              "Payment amount can't exceed the available credit or balance amount",
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          [field]: value || 0,
          errors: {
            ...prevState.errors,
            [field]: errorMessage,
          },
        }));
      }
    }

    if (field !== "amount") {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    }
  };

  const onClose = () => {
    isFieldChange = false;
    handleClose(false);
    setTimeout(() => {
      setState(defaultState);
    }, 200);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <Dialog.Title hasClose>Record Payment</Dialog.Title>
        <Dialog.Content>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="PDX Company"
              variant="outlined"
              size="small"
              value={state.pdxCompany?.pdxCompany?.value || "-"}
              helperText=" "
            />
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="Customer"
              variant="outlined"
              size="small"
              value={state.customer?.name || "-"}
              helperText=" "
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="Location"
              variant="outlined"
              size="small"
              value={state.location?.location || "-"}
              helperText=" "
            />
            <Datepicker
              mask
              preventClear
              label="Payment date"
              required
              selected={state.paymentDate}
              maxDate={new Date()}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
              onChange={(paymentDate) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  paymentDate: getDateString(paymentDate),
                }));
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="Invoice No."
              variant="outlined"
              size="small"
              value={state.invoiceNumber || "-"}
              helperText=" "
            />
            <Autocomplete
              disableClearable
              fullWidth
              className="mr-4 mt-4"
              size="small"
              required
              value={state.modeOfPayment}
              disabled={isEdit && state.modeOfPayment?.value === "credits"}
              options={
                isEdit || +availableCredit <= 0
                  ? modeOfPaymentList.filter((mode) => mode.value !== "credits")
                  : modeOfPaymentList
              }
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Mode"
                  variant="outlined"
                  helperText=" "
                />
              )}
              onChange={(evt, value) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  modeOfPayment: value,
                  hideAvailCreditInfo: value.value === "credits" ? true : false,
                  amount:
                    value.value === "credits"
                      ? defaultState.amount
                      : prevState.amount,
                  errors: {
                    ...prevState.errors,
                    amount:
                      state.errors.amount === "creditError"
                        ? defaultState.errors.amount
                        : prevState.errors.amount,
                  },
                  creditErrorMessage: defaultState.creditErrorMessage,
                }));
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="Balance Amount"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              variant="outlined"
              size="small"
              value={state.balanceAmount}
              helperText=" "
            />
            <TextField
              className={clsx("mr-4 mt-4")}
              required
              fullWidth
              label="Amount"
              type="number"
              variant="outlined"
              size="small"
              name="amount"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              value={state.amount}
              onKeyDown={preventInputKeyCodes}
              error={!!state.errors.amount?.trim()}
              helperText={state.errors.amount === "Required" ? "Required" : " "}
              onChange={handleFieldChange}
              disabled={
                state.modeOfPayment &&
                state.modeOfPayment.value === "credits" &&
                +availableCredit === 0
                  ? true
                  : false
              }
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              fullWidth
              style={{ width: "47%" }}
              label="Reference No."
              name="referenceNumber"
              variant="outlined"
              size="small"
              value={state.referenceNumber}
              error={!!state.errors.referenceNumber?.trim()}
              helperText={state.errors.referenceNumber}
              onChange={handleFieldChange}
            />
          </div>

          <TextField
            className="mb-1"
            fullWidth
            multiline
            name="notes"
            required={
              (state.modeOfPayment &&
                state.modeOfPayment.value === "credits") ||
              +state.amount > +state.balanceAmount ||
              isEdit
            }
            rows={4}
            label="Notes"
            variant="outlined"
            size="small"
            value={state.notes}
            error={!!state.errors.notes?.trim()}
            helperText={state.errors.notes}
            onChange={handleFieldChange}
          />

          {+availableCredit > 0 && !isEdit && !state.hideAvailCreditInfo && (
            <div>
              <Typography color="error">
                {`Choose 'Credits' as the payment mode to use available credit of `}
                <span
                  style={{ color: "green" }}
                >{`$${+availableCredit}.`}</span>
              </Typography>
            </div>
          )}
          {+availableCredit > 0 &&
            state.modeOfPayment &&
            state.modeOfPayment?.value === "credits" && (
              <div>
                <Typography variant="body1" color="error">
                  {`Total Available Credit: `}
                  <span
                    style={{ color: "green" }}
                  >{`$${+availableCredit}.`}</span>
                </Typography>
              </div>
            )}
          {!!state.creditErrorMessage && (
            <div>
              <Typography color="error">{state.creditErrorMessage}</Typography>
            </div>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              disabled={
                state.isRecordPayment ||
                validate() ||
                state.amount == 0 ||
                !!state.errors?.amount.trim() ||
                !state.modeOfPayment ||
                !isFieldChange
              }
              onClick={() =>
                handleSubmit({
                  data: {
                    date: state.paymentDate,
                    invoice_id: isEdit
                      ? entry?.invoice_id
                      : entry.invoiceDetails?.id,
                    amount: state.amount,
                    reference_number: state.referenceNumber || null,
                    mode: state.modeOfPayment.value,
                    isRecordPayment: state.isPaid ? true : false,
                    id: (isEdit && entry?.id) || null,
                    payment_notes: state.notes || null,
                  },
                })
              }
            >
              {isEdit && !state.isPaid
                ? "Update"
                : state.isPaid
                ? "Continue"
                : "Create"}
              {state.isRecordPayment && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default RecordPayment;
