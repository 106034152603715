export const ROLE = {
  KAM: "kam",
  AA: "aa",
  MGR: "mgr",
  ADMIN: "admin",
  MAPS_VIEW: "map view",
  SALES_VIEW: "sales view",
};

export const USER_ROLES = [
  "KAM",
  "MGR",
  "AA",
  "ADMIN",
  "MAP VIEW",
  "SALES VIEW",
];

export const STATUS = ["Only Active", "Only Inactive", "All"];

export const PERMISSION = {
  VIEW_ALL_SCHEDULES: "view-all-schedule",
  MANAGE_BILLING: "manage-billing",
  CREATE_MEMBER_SCHEDULE: "create-member-schedule",
  MODIFY_USERS: "upload-download-users",
  MODIFY_IC: "upload-download-ic",
  MODIFY_CUSTOMER: "upload-download-customer",
  MODIFY_BASE_ROSTER: "upload-download-roster",
  VIEW_MEMEBER_BASE_ROSTER: "view-member-base-roster",
  MANAGE_REPORT: "manage-report",
  MANAGE_CONTRACTOR: "manage-contractor",
  MANAGE_USERS: "manage-users",
  MANAGE_CUSTOMERS: "manage-customers",
  DELETE_CUSTOMERS: "delete-customers",
  REVIEWER: "reviewer",
  FUEL_SURCHARGE_WRITE: "fuel-surcharge-write",
  MANAGE_LOOKUP: "manage-lookup",
  MANAGE_DCN: "manage-dcn",
  MANAGE_PRICE_MATRIX: "price-matrix",
  MANAGE_TRANSFER_SCHEDULE: "transfer-schedule",
  MANAGE_INVOICE: "invoice",
};

export const ROLE_PERMISSIONS = {
  [ROLE.KAM]: [
    PERMISSION.MODIFY_BASE_ROSTER,
    PERMISSION.MANAGE_REPORT,
    PERMISSION.MANAGE_CONTRACTOR,
    PERMISSION.MANAGE_CUSTOMERS,
    PERMISSION.MANAGE_TRANSFER_SCHEDULE,
  ],
  [ROLE.AA]: [
    PERMISSION.VIEW_ALL_SCHEDULES,
    PERMISSION.CREATE_MEMBER_SCHEDULE,
    PERMISSION.MODIFY_IC,
    PERMISSION.MODIFY_BASE_ROSTER,
    PERMISSION.MODIFY_CUSTOMER,
    PERMISSION.MANAGE_BILLING,
    PERMISSION.VIEW_MEMEBER_BASE_ROSTER,
    PERMISSION.MANAGE_REPORT,
    PERMISSION.MANAGE_CONTRACTOR,
    PERMISSION.MANAGE_CUSTOMERS,
    PERMISSION.FUEL_SURCHARGE_WRITE,
    PERMISSION.MANAGE_LOOKUP,
    PERMISSION.MANAGE_DCN,
    PERMISSION.MANAGE_PRICE_MATRIX,
    PERMISSION.MANAGE_TRANSFER_SCHEDULE,
    PERMISSION.MANAGE_INVOICE,
  ],
  [ROLE.MGR]: [
    PERMISSION.VIEW_ALL_SCHEDULES,
    PERMISSION.CREATE_MEMBER_SCHEDULE,
    PERMISSION.MODIFY_BASE_ROSTER,
    PERMISSION.MODIFY_CUSTOMER,
    PERMISSION.MANAGE_BILLING,
    PERMISSION.MANAGE_CONTRACTOR,
    PERMISSION.VIEW_MEMEBER_BASE_ROSTER,
    PERMISSION.MANAGE_REPORT,
    PERMISSION.MANAGE_CUSTOMERS,
    PERMISSION.REVIEWER,
    PERMISSION.MANAGE_TRANSFER_SCHEDULE,
    PERMISSION.MANAGE_INVOICE,
  ],
  [ROLE.ADMIN]: [...Object.values(PERMISSION)],
  [ROLE.MAPS_VIEW]: [],
  [ROLE.SALES_VIEW]: [PERMISSION.MANAGE_CUSTOMERS],
};

export const ENTRY_STATUS = {
  NEW: "New",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const MAX_ALLOWED_FILE_SIZE = {
  USER_PROFILE: 4,
  FEEDBACK: 5,
  COMMON: 5,
  ICDOCS: 5,
  ATTACH_FILES: 4,
};

export const MAX_FILE_UPLOAD_LIMIT = 30;

export const FEEDBACK_TYPES = ["Feature", "Enhancement", "Bug"];

export const SUPPORTED_FILE_FORMATS = {
  FEEDBACK: [
    "mov",
    "avi",
    "mp4",
    "mkv",
    "webm",
    "mpg",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "ogg",
    "m4p",
    "m4v",
    "wmv",
    "qt",
    "flv",
    "swf",
    "avchd",
    "jpg",
    "jpeg",
    "png",
    "gif",
    "xlsx",
    "csv",
    "pdf",
  ],
  COMMON: ["xlsx", "xls", "csv"],
  ICDOCS: ["doc", "docx", "jpg", "jpeg", "pdf", "txt"],
  ATTACH_FILES: [
    "doc",
    "docx",
    "jpg",
    "jpeg",
    "pdf",
    "txt",
    "xlsx",
    "xls",
    "png",
  ],
};

export const RATE_TYPES = [
  "Daily",
  "Hourly",
  "Per Miles",
  "Per Tire",
  "Per Run",
];
export const ERROR_CODES = {
  OVERRIDE_SCHEDULE: "PDX-DS-007",
  SCHEDULE_NOT_FOUND: "PDX-DS-009",
  RESET_PASSWORD: "SIGN-001",
  OVERRIDE_BASEROSTER_RECORD: "PDX-ICUS-004",
  CUSTOMER_OVERRIDE_BASEROSTER_RECORD: "PDX-CUS-006",
  BRANCH_OVERRIDE_BASEROSTER_RECORD: "PDX-CBUS-006",
  DELETE_CUSTOMER_CHILD: "PDX-CD-002",
  CUSTOMER_CANNOT_DELETE: "PDX-CD-001",
  FORCE_SUBMITTED: "PDX-DS-075",
  CAN_DELETE_CUSTOMER: "PDX-CD-003",
  CAN_DELETE_BRANCH: "PDX-CBD-009",
  CAN_DELETE_ROUTE: "PDX-RD-005",
  CAN_OVERRIDE_ICID: "PDX-ICCU-065",
};

export const DOCUMENT_TYPES = [
  "Background Check",
  "Contract",
  "DOT Medical Card",
  "Driver License",
  "Insurance Declaration Page(s)",
  "Motor Vehicle Report (MVR)",
  "Other",
  "Paid Under Company (PUC)",
  "SS-4",
  "SSN",
  "Subcontractor Undertaking Agreement (SUA)",
  "Vehicle Registration",
];

export const AGREEMENT_DATA = [
  "Contract",
  "Direct Deposit",
  "Background Check",
  "Motor Vehicle Report (MVR)",
  "SS-4",
  "Paid Under Company (PUC)",
  "Subcontractor Undertaking Agreement (SUA)",
];

export const DOCUMENT_DATA = [
  "Driver's License",
  "Insurance Declaration Page(s)",
  "Vehicle Registration",
  "Yearly MVR",
  "Other",
];

export const VEHICLE_TYPES = [
  "CAR",
  "SUV",
  "VAN",
  "CARGO_VAN",
  "MINI_VAN",
  "SPRINTER",
  "STANDARD_SPRINTER",
  "HIGH_TOP_EXTENDED_SPRINTER",
  "12'BOX_TRUCK",
  "14'BOX_TRUCK",
  "16'BOX_TRUCK",
  "18'BOX_TRUCK",
  "20'BOX_TRUCK",
  "24'BOX_TRUCK",
  "26'BOX_TRUCK",
  "PICKUP_TRUCK",
  "TRACTOR_TRAILER",
  "OTHER",
];

export const INVOICE_TYPES = [
  { id: "E", label: "Email" },
  { id: "F", label: "Fax" },
  { id: "M", label: "Mail" },
  { id: "U", label: "Upload into customers system" },
  { id: "S&E", label: "Scan all the invoices and email as one file" },
];

export const DEDUCTION_REASONS = [
  "Cash Advance",
  "Claim - Damage",
  "Claim - Missing Item",
  "Claim - Payment",
  "Equipment",
  "Fuel Advance",
  "Rental Damage",
  "Rental Fee",
  "Rental Fuel",
  "Rental Tolls",
  "Misc / Other",
];

export const CONTRACTOR_TYPES = [
  "Independent",
  "Lumper",
  "Master",
  "Subcontractor",
];

export const REDUCTION_TYPES = [
  "Current",
  "1% Less",
  "2% Less",
  "3% Less",
  "4% Less",
  "5% Less",
  "None",
];

export const BULLET_POINTS_DCN = [
  "Excluded records neither be displayed in the downloaded sheet nor will be added in the total.",
];

export const dcnFields = [
  "Settlement",
  "FuelReimbursement",
  "TollReimbursement",
  "MileageReimbursement",
  "Reimbursement",
  "MiscDeduction",
  "Misc2Deduction",
  "FuelAdvanceDeduction",
  "FuelAdvance2",
  "CashAdvanceDeduction",
  "CashAdvance2Deduction",
  "ClaimDeduction",
  "Claim2Deduction",
  "Claim3Deduction",
  "Rental1Deduction",
  "Rental2Deduction",
  "Rental3Deduction",
  "Rental4Deduction",
  "EOR",
];

export const INVOICE_STATUS = [
  { label: "All", value: null },
  { label: "Draft", value: "draft" },
  { label: "Approved", value: "approved" },
  { label: "Pending Approval", value: "pending-approval" },
  { label: "Paid", value: "paid" },
  { label: "Overdue", value: "overdue" },
  { label: "Late Payment Received", value: "isLatePayment" },
  { label: "Partially Paid", value: "partially-paid" },
];

export const modeOfPaymentList = [
  { label: "Bank Remittance", value: "bank-remittance" },
  { label: "Bank Transfer", value: "bank-transfer" },
  { label: "Cash", value: "cash" },
  { label: "Check", value: "check" },
  { label: "Credit Card", value: "credit-card" },
  { label: "Debit Card", value: "debit-card" },
  { label: "Credits", value: "credits" },
];

export const Date_Range = [
  { label: "Last 1 month" },
  { label: "Last 3 months" },
  { label: "Last 12 months" },
  { label: "This Quarter" },
  { label: "Previous Quarter" },
  { label: "This Year" },
  { label: "Previous Year" },
];

export const Select_Date_Range = [
  { label: "1 month" },
  { label: "2 months" },
  { label: "3 months" },
];

export const CATEGORIES = [
  "current",
  "1-15 days",
  "16-30 days",
  "31-45 days",
  "greater than 45 days",
];

export const CATEGORIES_XAXIS_Label = {
  current: "Current",
  "1-15 days": "1-15 Days",
  "16-30 days": "16-30 Days",
  "31-45 days": "31-45 Days",
  "greater than 45 days": ">45 Days",
};
