import { http, responseFormatter } from "utils";

const getUserDetails = () => {
  return responseFormatter(
    http.get("user/me", {
      setAuthHeader: true,
    })
  );
};

const updateThemePreference = (data) => {
  return responseFormatter(
    http.patch("user/theme", data, {
      setAuthHeader: true,
    })
  );
};

const submitFeedback = async (payload) => {
  return responseFormatter(
    http.post("/feedback", payload, {
      setAuthHeader: true,
    })
  );
};

function getRoles(query) {
  let endpoint = "/role";
  return responseFormatter(http.get(endpoint, { setAuthHeader: true }));
}

const getUsersList = (type = "") => {
  return responseFormatter(
    http.get(`/users/${type}`, {
      setAuthHeader: true,
    })
  );
};

const getCustomerList = (query = "", includeBranches = true) => {
  if (includeBranches) {
    query += query
      ? "&filter[includeBranches]=true"
      : "?filter[includeBranches]=true";
  }
  return responseFormatter(
    http.get(`/customers${query}`, {
      setAuthHeader: true,
    })
  );
};

const getContractorList = (query) => {
  return responseFormatter(
    http.get(`/individual-contractors/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getCustomerTypeList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Industry Type", {
      setAuthHeader: true,
    })
  );
};
const getICAgreementStatus = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=IC Agrement Status", {
      setAuthHeader: true,
    })
  );
};
const getstandByRateTypeList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Stand By Rate Type", {
      setAuthHeader: true,
    })
  );
};
const getICDocumentStatus = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=IC Document Status", {
      setAuthHeader: true,
    })
  );
};
const getICWatchlistStatus = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=File Retention Status", {
      setAuthHeader: true,
    })
  );
};

const getFlagTypeList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=IC Flag Reason", {
      setAuthHeader: true,
    })
  );
};

const getCautionFlagTypeList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Caution Flag Reason", {
      setAuthHeader: true,
    })
  );
};

const getLeaveTypeList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Force Submit Reason", {
      setAuthHeader: true,
    })
  );
};

const getdateWithoutRecordsList = (fromDate, toDate, kamId) => {
  let query = `?filter[where][from_date]=${fromDate}&filter[where][to_date]=${toDate}&filter[where][kam_id]=${kamId}`;

  return responseFormatter(
    http.get(`/creatable-dates${query}`, {
      setAuthHeader: true,
    })
  );
};
const getVehicleTypeList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Vehicle", {
      setAuthHeader: true,
    })
  );
};

const getDeductionReasonList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Deduction Reason", {
      setAuthHeader: true,
    })
  );
};

const getRouteBillFsList = () => {
  const type = encodeURIComponent("Route Bill F/S{{percent}}");
  return responseFormatter(
    http.get(`/all-lookup?filter[where][type]=${type}`, {
      setAuthHeader: true,
    })
  );
};

const getRoutePayFsList = () => {
  const type = encodeURIComponent("Route Pay F/S{{percent}}");
  return responseFormatter(
    http.get(`/all-lookup?filter[where][type]=${type}`, {
      setAuthHeader: true,
    })
  );
};

const getPDXCompanyList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=PDX Company", {
      setAuthHeader: true,
    })
  );
};

const getReviewNotesList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Review Notes", {
      setAuthHeader: true,
    })
  );
};

const getAAMGRNotesList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=AA/MGR Notes", {
      setAuthHeader: true,
    })
  );
};

const getCustomerAddressTypeList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Address", {
      setAuthHeader: true,
    })
  );
};

const getDCNUsersList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=DCN Email Recipients", {
      setAuthHeader: true,
    })
  );
};

const getContractorTypeList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Contractor Type", {
      setAuthHeader: true,
    })
  );
};

const getSettlementCompanyList = () => {
  return responseFormatter(
    http.get("/all-lookup?filter[where][type]=Settlement Company", {
      setAuthHeader: true,
    })
  );
};

const getLocationList = (customerId) => {
  return responseFormatter(
    http.get(`/customer/${customerId}/locations`, {
      setAuthHeader: true,
    })
  );
};

const updateUserSettings = (data) => {
  return responseFormatter(
    http.patch("user/update-settings", data, {
      setAuthHeader: true,
    })
  );
};

const getPendingItems = (kamId) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  let query = `?kamId=${kamId}`;
  if (timeZoneOffset >= 0) {
    query += `&offSet=${timeZoneOffset}`;
  }

  return responseFormatter(
    http.get(`/pending-items${query}`, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "" });
};

const getFuelSurchargeScheduleNames = () => {
  return responseFormatter(
    http.get("/custom-fuel-surcharge/names", {
      setAuthHeader: true,
    })
  );
};

const getAAAPriceList = () => {
  return responseFormatter(
    http.get("/aaa-price-list", {
      setAuthHeader: true,
    })
  );
};

const getNotificationCount = () => {
  return responseFormatter(
    http.get("/unread-notifications-count", {
      setAuthHeader: true,
    })
  );
};

const revertForcedSubmit = (data) => {
  return responseFormatter(
    http.patch("/revert-forced-submit", data, {
      setAuthHeader: true,
    })
  );
};

const getTransferScheduleRequests = (orgKamId, TransferredKamId) => {
  let query = "";
  if (!!orgKamId && !!TransferredKamId) {
    query = `?filter[where][and][0][or][0][kam_id]=${orgKamId}&filter[where][and][0][or][1][transfer_to]=${TransferredKamId}`;
  } else if (!!orgKamId) {
    query = `?filter[where][kam_id]=${orgKamId}`;
  } else if (!!TransferredKamId) {
    query = `?filter[where][transfer_to]=${TransferredKamId}`;
  }

  return responseFormatter(
    http.get(`get-all-transferred-schedule/${query}`, {
      setAuthHeader: true,
    })
  );
};

const getSubmissionHistory = (kamId) => {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let query = `?kamId=${kamId}&timeZoneString=${timeZoneString}`;

  return responseFormatter(
    http.get(`/not-submitted-dates${query}`, {
      setAuthHeader: true,
    })
  );
};

const getIcList = () => {
  return responseFormatter(
    http.get("/get-ic-list", {
      setAuthHeader: true,
    })
  );
};

const SharedService = {
  getdateWithoutRecordsList,
  getIcList,
  getTransferScheduleRequests,
  getUserDetails,
  updateThemePreference,
  getRouteBillFsList,
  getRoutePayFsList,
  submitFeedback,
  getRoles,
  getReviewNotesList,
  getAAMGRNotesList,
  getCustomerAddressTypeList,
  getUsersList,
  getCustomerList,
  getContractorList,
  getPDXCompanyList,
  getDCNUsersList,
  getSettlementCompanyList,
  getLocationList,
  updateUserSettings,
  getPendingItems,
  getLocalResources,
  getFuelSurchargeScheduleNames,
  getAAAPriceList,
  getNotificationCount,
  getCustomerTypeList,
  getVehicleTypeList,
  getContractorTypeList,
  getFlagTypeList,
  getCautionFlagTypeList,
  getLeaveTypeList,
  revertForcedSubmit,
  getICAgreementStatus,
  getICDocumentStatus,
  getICWatchlistStatus,
  getDeductionReasonList,
  getSubmissionHistory,
  getstandByRateTypeList,
};

export default SharedService;
