import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  fileInputIcon: {
    marginRight: theme.spacing(1),
    marginTop: 4,
  },
  infoIcon: {
    marginRight: theme.spacing(4),
    marginTop: 5,
    fontSize: 17,
    color: "green",
  },
  hiddenInput: {
    display: "none",
  },
  attachLabel: {
    fontWeight: "bold",
    marginTop: 4,
    cursor: "pointer",
    color: "black",
    textDecoration: "underline",
    width: "6rem",
    height: "fit-content",
  },
  margin8: {
    marginRight: theme.spacing(8),
  },
  fileListContainer: {
    width: "100%",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.5, 1),
    marginBottom: theme.spacing(2),
    backgroundColor: "aliceblue",
  },
  fileName: {
    color: "#1a73e8",
    fontWeight: "bold",
  },
  fileSize: {
    color: "#000",
    fontWeight: "bold",
  },
}));

export default useStyles;
