import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { noop } from "shared";
import {
  getDateString,
  preventInputKeyCodes,
  preventInputSpace,
  validator,
  getUTCDateString,
  getTokenData,
  getFormattedTime,
  LoadingOverlay,
} from "utils";
import { ActionDialog, Datepicker, Dialog, Grid } from "shared/components";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import InputAdornment from "@material-ui/core/InputAdornment";
import { VALIDATIONS } from "shared/constants";
import {
  ROLE,
  DOCUMENT_TYPES,
  AGREEMENT_DATA,
  DOCUMENT_DATA,
  ERROR_CODES,
} from "modules/shared/constants";
import Service from "../service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "./style";
import DeleteForeverIcon from "@material-ui/icons/DeleteForeverOutlined";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  Chip,
  Paper,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { InlineFieldForm } from "modules/shared/components";
import BulkUpload from "../bulk-upload";
import ErrorModal from "../error-modal";
import CollapsibleGrid from "../collapsibleGrid";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

let gridHelper = null;

const propertiesToCheck = [
  "icid",
  "first_name",
  "last_name",
  "city",
  "state",
  "phone",
  "email",
  "isAutoGeneratedICID",
  "contractor_type",
  "ic_company_name",
  "pdx_company",
  "settlement_company",
  "customer",
  "default_vehicle_type",
  "kamUser",
  "ein",
  "ssn",
  "date_of_birth",
  "notes",
];

const updatePropertiesToCheck = [
  "icid",
  "first_name",
  "last_name",
  "city",
  "phone",
  "email",
  "ic_company_name",
  "ein",
  "ssn",
  "notes",
];

let isFieldChange = false;
let isStandbyFieldChange = false;
let isDocsFieldChange = false;

const defaultState = {
  icid: "",
  first_name: "",
  last_name: "",
  ic_company_name: "",
  resetKey: 0,
  selectedAgreementRows: [],
  selectedDocsRows: [],
  documentType: "",
  tabIndex: 1,
  openUpload: false,
  deepCopiedEntry: {},
  deleteDocument: false,
  addStandbyRate: false,
  isDeletingStandby: false,
  isDefaultStandby: null,
  isDefault: false,
  deletingStandBy: null,
  isContractorType: false,
  showRedFlagWarning: false,
  removeRedFlag: false,
  documentStatusChanged: false,
  rowId: null,
  sheetError: null,
  agreementsEntries: [],
  documentsEntries: [],
  documentStatus: [],
  agreementStatus: [],
  expiration: null,
  submitDate: null,
  fileUpdate: "",
  isDocument: false,
  showUnsavedChangesPopup: false,
  isAgreement: false,
  ic_watchlist: {},
  isFileRetentionMarked: false,
  fileRetentionStatus: null,
  isWatchlistMarked: false,
  docsData: [],
  city: null,
  state: null,
  phone: "",
  email: "",
  contractor_type: "",
  pdx_company: null,
  settlement_company: null,
  customer: null,
  default_vehicle_type: "",
  stand_by_rate_type: "",
  stand_by_rate: "",
  standbyRateType: "",
  standbyRate: "",
  kamUser: null,
  isOverridingICID: false,
  uncheckingWatchlist: false,
  isFetchingStandby: false,
  standByIdsForDeletion: [],
  checkingWatchlist: false,
  ein: "",
  ssn: "",
  date_of_birth: "",
  notes: "",
  add_doc_notes: "",
  fileUpdated: {},
  updatedAt: "",
  isLoading: false,
  isAutoGeneratedICID: false,
  SSNForm: {},
  wcpMasterContractorList: [],
  contractorList: "",
  maskedSSN: false,
  errors: {
    icid: " ",
    first_name: " ",
    settlement_company: " ",
    last_name: " ",
    ic_company_name: " ",
    stand_by_rate: " ",
    standbyRate: " ",
    stand_by_rate_type: " ",
    standbyRateType: " ",
    city: " ",
    phone: " ",
    email: " ",
    ein: " ",
    ssn: " ",
    notes: " ",
    add_doc_notes: " ",
  },
};

const Form = ({
  open = false,
  isNew = false,
  isTabletView = false,
  isFetching = false,
  documentStatusList = [],
  agreementStatusList = [],
  standByRateTypeList = [],
  fileRetentionStatusList = [],
  entry = null,
  customers = [],
  kamUsers = [],
  pdxCompanyList = [],
  settlementCompanyList = [],
  vehicleTypeList = [],
  contractorTypeList = [],
  usersList = [],
  stateList = [],
  locationList = [],
  onClose = noop,
  onUpdateEntry = noop,
  newICCreated = noop,
  fetchEntries = noop,
}) => {
  const [state, setState] = useState(defaultState);
  const classes = useStyles({ isTabletView });
  const currentUserRole = (
    (getTokenData() || {})?.role || ROLE.ADMIN
  ).toLowerCase();

  useEffect(() => {
    if (entry) {
      entry.icDocsStatus =
        !!entry.icDocsStatus &&
        entry.icDocsStatus.map((row) => {
          if (row.type === "Agreement") {
            const contractorType = entry?.contractorType?.value;

            const shouldAssignNull =
              (contractorType === "Independent" &&
                (row.name === "SS-4" ||
                  row.name === "Paid Under Company (PUC)" ||
                  row.name === "Subcontractor Undertaking Agreement (SUA)")) ||
              (contractorType === "Master" &&
                (row.name === "Paid Under Company (PUC)" ||
                  row.name === "Subcontractor Undertaking Agreement (SUA)")) ||
              (contractorType === "Subcontractor" && row.name === "SS-4") ||
              contractorType === "Employee" ||
              contractorType === "Lumper" ||
              contractorType === "SLC";

            if (shouldAssignNull) {
              return {
                ...row,
                is_archived: true,
              };
            }
          }

          return row;
        });

      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState?.id,
        icid: entry.icid || defaultState.icid,
        first_name: entry.first_name || defaultState.first_name,
        last_name: entry.last_name || defaultState.last_name,
        ic_company_name: entry.ic_company_name || defaultState.ic_company_name,
        city: entry.city || defaultState.city,
        state: entry.state || defaultState.state,
        phone: entry.phone || defaultState.phone,
        email: entry.email || defaultState.email,
        contractor_type: entry.contractorType || defaultState.contractor_type,
        pdx_company: entry.pdxCompany || defaultState.pdx_company,
        settlement_company:
          entry.settlementCompany || defaultState.settlement_company,
        customer: entry.customer || defaultState.customer,
        default_vehicle_type:
          entry.defaultVehicleType || defaultState.default_vehicle_type,
        isAutoGeneratedICID:
          entry.isAutoGeneratedICID || defaultState.isAutoGeneratedICID,
        kamUser: entry.kamUser || defaultState.kamUser,
        ein: entry.ein || defaultState.ein,
        ssn: entry.ssn || defaultState.ssn,
        date_of_birth: entry.date_of_birth
          ? getDateString(entry.date_of_birth)
          : defaultState.date_of_birth,
        notes: entry.notes || defaultState.notes,
        fileUpdated: entry.updatedBy || defaultState.fileUpdated,
        updatedAt:
          `${getUTCDateString(entry.updated_at)}, ${getFormattedTime(
            new Date(entry.updated_at)
          )}` || defaultState.updatedAt,
        add_doc_notes:
          (!!entry?.add_doc_notes && entry?.add_doc_notes) ||
          defaultState.add_doc_notes,
        agreementStatus:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.agreementStatus,
        documentStatus:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.documentStatus,
        agreementsEntries:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.agreementsEntries,
        documentsEntries:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.documentsEntries,
        fileRetentionStatus:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.find(
              (item) =>
                item.type === "File Retention Status" &&
                item.is_archived === false
            )?.lookupStatus?.id) ||
          defaultState.fileRetentionStatus,
        isFileRetentionMarked:
          (!!entry.icDocsStatus &&
            !!entry.icDocsStatus.find(
              (item) =>
                item.type === "File Retention Status" &&
                item.is_archived === false
            )) ||
          defaultState.isFileRetentionMarked,
        isWatchlistMarked:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus?.some(
              (item) =>
                item.type === "Ic Watchlist" && item.is_archived === false
            )) ||
          defaultState.isWatchlistMarked,
        submitDate:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.submitDate,
        expiration:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.expiration,
      }));
    }
  }, [entry]);

  const updatedICAgreement = [];
  const updatedICDocument = [];

  for (const agreement of AGREEMENT_DATA) {
    const matchingItem =
      !!entry &&
      isFetching &&
      !!entry?.icDocsStatus &&
      entry?.icDocsStatus?.find((icDoc) => icDoc.name === agreement);
    if (matchingItem) {
      updatedICAgreement.push(matchingItem);
    } else {
      const newObject = {
        created_at: null,
        created_by: null,
        dated: null,
        expire_date: null,
        ic_id: null,
        id: null,
        lookupStatus: null,
        name: agreement,
        status: null,
        type: "Agreement",
        updated_at: null,
        updated_by: null,
        is_archived: true,
      };
      updatedICAgreement.push(newObject);
    }
  }

  updatedICAgreement.sort((a, b) => {
    const indexA = AGREEMENT_DATA.indexOf(a.name);
    const indexB = AGREEMENT_DATA.indexOf(b.name);
    return indexA - indexB;
  });

  for (const document of DOCUMENT_DATA) {
    const matchingItem =
      !!entry &&
      isFetching &&
      !!entry?.icDocsStatus &&
      entry?.icDocsStatus?.find((icDoc) => icDoc.name === document);
    if (matchingItem) {
      updatedICDocument.push(matchingItem);
    } else {
      const newObject = {
        created_at: null,
        created_by: null,
        dated: null,
        expire_date: null,
        ic_id: null,
        id: null,
        lookupStatus: null,
        name: document,
        status: null,
        type: "Document",
        updated_at: null,
        updated_by: null,
        is_archived: true,
      };
      updatedICDocument.push(newObject);
    }
  }

  updatedICDocument.sort((a, b) => {
    const indexA = DOCUMENT_DATA.indexOf(a.name);
    const indexB = DOCUMENT_DATA.indexOf(b.name);
    return indexA - indexB;
  });

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      first_name: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 25 },
      ],
      settlement_company: [{ type: VALIDATIONS.REQUIRED, value: true }],
      last_name: [{ type: VALIDATIONS.MAX_LENGTH, value: 25 }],
      standbyRate: [{ type: VALIDATIONS.LIMIT, value: 10.2 }],
      ic_company_name: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      city: [
        { type: VALIDATIONS.LOCATION, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
      phone: [{ type: VALIDATIONS.PHONE, value: true }],
      email: [
        { type: VALIDATIONS.EMAIL },
        { type: VALIDATIONS.MAX_LENGTH, value: 50 },
      ],
      ein: [{ type: VALIDATIONS.EIN, value: true }],
      notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      add_doc_notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 1000 }],
    };

    if (!entry?.ssn) {
      fieldValidatorMap["ssn"] = [{ type: VALIDATIONS.SSN, value: true }];
    }

    if (
      (isNew && !state.isAutoGeneratedICID) ||
      (!isNew && currentUserRole === ROLE.ADMIN)
    ) {
      fieldValidatorMap["icid"] = [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 20 },
      ];
    }

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleDelete = async (entryIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { data, error } = await Service.ICDocsDelete(entryIds);

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      if (data && data.updatedICRecord) {
        const updatedEntry = data.updatedICRecord;
        onUpdateEntry(updatedEntry);
      } else {
        fetchEntries();
        if (isNew) {
          handleClose(true);
        }
      }
    }
  };

  const checkShowText = (data, entries, status, date) => {
    return data.some(
      (agreement) =>
        entries?.includes(agreement.name) &&
        (!status?.some((item) => item.name === agreement.name) ||
          !date?.some((item) => item.name === agreement.name))
    );
  };

  const showText = checkShowText(
    updatedICAgreement,
    state.agreementsEntries,
    state.agreementStatus,
    state.submitDate
  );

  const showDocsText = checkShowText(
    updatedICDocument,
    state.documentsEntries,
    state.documentStatus,
    state.expiration
  );

  const handleSelectedRows = (rowName, tableType) => {
    isDocsFieldChange = true;
    setState((prevState) => {
      const selectedRowsKey =
        tableType === "agreement" ? "agreementsEntries" : "documentsEntries";
      const existingSelection = prevState[selectedRowsKey];
      const selectedRows = existingSelection.slice();

      if (selectedRows.includes(rowName)) {
        const index = selectedRows.indexOf(rowName);
        selectedRows.splice(index, 1);
      } else {
        selectedRows.push(rowName);
      }

      const selectedRowsKeys =
        tableType === "agreement"
          ? "selectedAgreementRows"
          : "selectedDocsRows";

      const filteredState = {
        ...prevState,
        [selectedRowsKey]: selectedRows,
        [selectedRowsKeys]: selectedRows,
      };

      if (tableType === "document" && !selectedRows.includes(rowName)) {
        filteredState.expiration = state.expiration?.filter((item) =>
          selectedRows.includes(item.name)
        );
        filteredState.documentStatus = state.documentStatus?.filter((item) =>
          selectedRows.includes(item.name)
        );
      } else if (tableType === "agreement" && !selectedRows.includes(rowName)) {
        filteredState.submitDate = state.submitDate?.filter((item) =>
          selectedRows.includes(item.name)
        );
        filteredState.agreementStatus = state.agreementStatus?.filter((item) =>
          selectedRows.includes(item.name)
        );
      }

      return filteredState;
    });
  };

  const handleSelection = (rowName, tableType) => {
    if (
      rowName === "Driver's License" &&
      state.documentsEntries?.includes("Driver's License") &&
      state.documentStatus?.some(
        (status) =>
          status?.lookupStatus?.value === "Not Eligible" &&
          !status.is_archived &&
          status?.name === "Driver's License"
      ) &&
      !!entry &&
      !!entry.icDocsStatus &&
      !!entry.icDocsStatus.find(
        (status) =>
          status?.lookupStatus?.value === "Not Eligible" &&
          !status.is_archived &&
          status?.name === "Driver's License"
      )
    ) {
      return setState((prevState) => ({
        ...prevState,
        removeRedFlag: true,
      }));
    }
    handleSelectedRows(rowName, tableType);
  };

  const selectedRow =
    !!entry && !!entry.icDocsStatus
      ? entry.icDocsStatus
          ?.filter(
            (row) => row.type === "Agreement" && row.is_archived === false
          )
          ?.map((item) => item.name)
      : [];
  const selectedDocumentRow =
    !!entry && !!entry.icDocsStatus
      ? entry.icDocsStatus
          ?.filter(
            (row) => row.type === "Document" && row.is_archived === false
          )
          ?.map((item) => item.name)
      : [];

  const initiallySelectedRows = selectedRow?.filter((name) => {
    if (!entry || !entry.icDocsStatus || !entry?.contractorType?.value) {
      return true;
    }
    const criteria = (row) =>
      !(
        (entry?.contractorType?.value === "Independent" &&
          (row.name === "SS-4" ||
            row.name === "Paid Under Company (PUC)" ||
            row.name === "Subcontractor Undertaking Agreement (SUA)")) ||
        (entry?.contractorType?.value === "Master" &&
          (row.name === "Paid Under Company (PUC)" ||
            row.name === "Subcontractor Undertaking Agreement (SUA)")) ||
        (entry?.contractorType.value === "Subcontractor" &&
          row.name === "SS-4") ||
        entry?.contractorType?.value === "Employee" ||
        entry?.contractorType?.value === "Lumper" ||
        entry?.contractorType?.value === "SLC"
      );

    return entry.icDocsStatus?.some(
      (row) => criteria(row) && row.name === name
    );
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedAgreementRows: [...initiallySelectedRows],
    }));
  }, [entry]);

  const initiallySelectedDocumentRows = selectedDocumentRow?.filter((name) =>
    state.documentsEntries?.filter((entry) => entry.name === name)
  );

  useEffect(() => {
    if (!!initiallySelectedRows && initiallySelectedRows?.length) {
      setState((prevState) => ({
        ...prevState,
        agreementsEntries: [...initiallySelectedRows],
      }));
    }
    if (
      !!initiallySelectedDocumentRows &&
      initiallySelectedDocumentRows?.length
    ) {
      setState((prevState) => ({
        ...prevState,
        documentsEntries: [...initiallySelectedDocumentRows],
      }));
    }
  }, [state.resetKey, isNew, entry]);

  const markData = (rows, stateEntries) => {
    return rows.map((row) => {
      const isMarked = stateEntries.includes(row.name);
      return { ...row, is_archived: !isMarked };
    });
  };

  const userData =
    !!entry && entry.icDocs?.length > 0
      ? entry.icDocs[0].updated_by === null
        ? usersList.find((data) => data.id === entry.icDocs[0].created_by)
        : usersList.find((data) => data.id === entry.icDocs[0].updated_by)
      : null;

  const documentUpdatedByuser = !!userData
    ? entry.icDocs[0].updated_by === null
      ? `${getUTCDateString(entry.icDocs[0].created_at)}, ${getFormattedTime(
          new Date(entry.icDocs[0].created_at)
        )}, ${userData.first_name} ${userData.last_name} (${userData.username})`
      : `${getUTCDateString(entry.icDocs[0].updated_at)}, ${getFormattedTime(
          new Date(entry.icDocs[0].updated_at)
        )}, ${userData.first_name} ${userData.last_name} (${userData.username})`
    : null;

  const mapData = (rows, type, stateStatus, stateDate, property) => {
    return rows.map((row) => ({
      type: type,
      name: row.name,
      status:
        stateStatus?.find((status) => status.name === row.name)?.lookupStatus
          ?.id ||
        (!!entry &&
          !!entry.icDocsStatus &&
          entry.icDocsStatus
            .filter((item) => item.type === type && item.is_archived === false)
            ?.find((status) => status.name === row.name)?.lookupStatus?.id),
      [property]:
        stateDate?.find((date) => date.name === row.name)?.[property] ||
        (!!entry &&
          !!entry.icDocsStatus &&
          getUTCDateString(
            entry.icDocsStatus
              .filter(
                (item) => item.type === type && item.is_archived === false
              )
              ?.find((date) => date.name === row.name)?.[property]
          )),
      is_archived: row.is_archived,
    }));
  };

  const retentionMapData = (rows, type, stateStatus) => {
    return rows.map((row) => ({
      type: type,
      name: row.name,
      status:
        stateStatus ||
        (!!entry &&
          !!entry.icDocsStatus &&
          entry.icDocsStatus?.find(
            (item) => item.type === type && item.is_archived === false
          )?.lookupStatus?.id),
      dated: null,
      is_archived: !row.is_archived,
    }));
  };

  const filteredRows = markData(
    updatedICAgreement,
    state.agreementsEntries,
    state.agreementStatus,
    state.submitDate
  );
  const filteredDocsRows = markData(
    updatedICDocument,
    state.documentsEntries,
    state.documentStatus,
    state.expiration
  );

  const updatedDocsData = mapData(
    filteredRows,
    "Agreement",
    state.agreementStatus,
    state.submitDate,
    "dated"
  );
  const updatedICDocsData = mapData(
    filteredDocsRows,
    "Document",
    state.documentStatus,
    state.expiration,
    "expire_date"
  );
  const updatedFileRetentionData = retentionMapData(
    [
      {
        name: "File Retention Status",
        is_archived: state.isFileRetentionMarked,
      },
    ],
    "File Retention Status",
    state.fileRetentionStatus
  );

  useEffect(() => {
    setState((prevState) => {
      const newDocsData = [
        ...updatedDocsData,
        ...updatedICDocsData,
        ...updatedFileRetentionData,
      ];

      const ic_watchlistIsMarked = !state.isWatchlistMarked;

      const ic_watchlist = {
        status: null,
        is_archived: ic_watchlistIsMarked,
      };

      return {
        ...prevState,
        docsData: newDocsData,
        ic_watchlist: ic_watchlist,
      };
    });
  }, [
    state.agreementStatus,
    state.agreementsEntries,
    state.submitDate,
    state.expiration,
    state.documentStatus,
    state.fileRetentionStatus,
    state.isFileRetentionMarked,
    state.isWatchlistMarked,
    state.documentsEntries,
  ]);

  const renderDatepicker = (
    dataArray,
    entriesArray,
    label,
    nameProperty,
    row
  ) => {
    const date =
      Array.isArray(state[dataArray]) && state[dataArray]?.length > 0
        ? state[dataArray].find((item) => item.name === row.name)
        : null;

    return (
      <Datepicker
        disabled={!entriesArray.includes(row.name)}
        mask
        required={entriesArray.includes(row.name)}
        label={label}
        selected={
          new Date().toString().includes("India")
            ? getDateString(date?.[nameProperty])
            : getUTCDateString(date?.[nameProperty])
        }
        classes={{
          input: {
            root: classes.datepickerWrapper,
          },
        }}
        showYearDropdown
        onChange={(previousDate) => {
          isDocsFieldChange = true;
          setState((prevState) => {
            const updatedState = { ...prevState };

            if (state[dataArray]?.find((item) => item.name === row.name)) {
              updatedState[dataArray] = updatedState[dataArray].map((item) => {
                if (item.name === row.name) {
                  return {
                    ...item,
                    [nameProperty]: getDateString(previousDate),
                  };
                }
                return item;
              });
            } else {
              updatedState[dataArray] = [
                ...(updatedState[dataArray] || []),
                {
                  [nameProperty]: getDateString(previousDate),
                  name: row.name,
                },
              ];
            }

            return updatedState;
          });
        }}
      />
    );
  };

  const renderStatusColumn = (dataArray, entriesArray, statusList, row) => {
    const statusType =
      Array.isArray(state[dataArray]) && state[dataArray]?.length > 0
        ? state[dataArray].find((item) => item.name === row.name)
        : null;

    const label = statusType ? statusType?.lookupStatus?.value : "";

    return (
      <InlineFieldForm
        disabled={!entriesArray?.includes(row.name)}
        dropdownLabel={
          entriesArray?.includes(row.name)
            ? label || <span style={{ color: "red" }}>Select *</span>
            : label || "-"
        }
        name={dataArray}
        initialValue={{
          label: label || "",
        }}
        type="dropdown"
        options={statusList.map((data) => ({
          value: data.id,
          label: data.value,
        }))}
        onUpdate={(updatedData) => {
          isDocsFieldChange = true;
          setState((prevState) => {
            const nextState = JSON.parse(JSON.stringify(prevState));

            const { [dataArray]: statusArray, ...rest } = nextState;
            const existingStatusIndex = statusArray.findIndex(
              (item) => item.name === row.name
            );

            const updatedStatusArray = [...statusArray];

            if (existingStatusIndex !== -1) {
              updatedStatusArray[existingStatusIndex].lookupStatus =
                statusList.find((item) => item.id === updatedData.value);
            } else {
              updatedStatusArray.push({
                lookupStatus: statusList.find(
                  (item) => item.id === updatedData.value
                ),
                name: row.name,
              });
            }

            nextState[dataArray] = updatedStatusArray;
            const shouldMarkRedFlag = updatedStatusArray?.some((item) => {
              return (
                item.name === "Driver's License" &&
                item.lookupStatus.value === "Not Eligible" &&
                row.name === "Driver's License" &&
                !!entry &&
                ((!!entry.icDocsStatus &&
                  entry.icDocsStatus?.some(
                    (item) =>
                      item.type === "Ic Watchlist" && item.is_archived === false
                  )) ||
                  !!entry.is_cautious_flagged ||
                  !!entry.is_flagged) &&
                !entry.icDocsStatus.find(
                  (status) =>
                    status?.status === updatedData?.value && !status.is_archived
                )
              );
            });
            const shouldRemoveRedFlag = updatedStatusArray?.some((item) => {
              return (
                item.name === "Driver's License" &&
                state.documentsEntries?.includes("Driver's License") &&
                state.documentStatus?.some(
                  (status) =>
                    status?.lookupStatus?.value === "Not Eligible" &&
                    !status.is_archived &&
                    status?.name === "Driver's License"
                ) &&
                item.lookupStatus.value !== "Not Eligible" &&
                row.name === "Driver's License" &&
                !!entry &&
                !!entry.icDocsStatus &&
                !!entry.icDocsStatus.find(
                  (status) =>
                    status?.lookupStatus?.value === "Not Eligible" &&
                    status.name === "Driver's License" &&
                    !status.is_archived
                )
              );
            });

            return {
              ...rest,
              [dataArray]: updatedStatusArray,
              showRedFlagWarning: shouldMarkRedFlag,
              documentStatusChanged: shouldRemoveRedFlag,
            };
          });
        }}
      />
    );
  };

  const renderFileUpdatedColumn = (row, icDocsStatus) => {
    const fileUpdate =
      !!entry &&
      !!icDocsStatus &&
      icDocsStatus.find((icDoc) => icDoc.name === row.name);
    if (fileUpdate === undefined) {
      return (
        <Typography variant="body2" noWrap>
          {"-"}
        </Typography>
      );
    }
    const userData =
      fileUpdate?.updated_by === null
        ? usersList.find((data) => data.id === fileUpdate.created_by)
        : usersList.find((data) => data.id === fileUpdate.updated_by);

    const fileUpdateDetails = `${getUTCDateString(
      fileUpdate?.updated_by === null
        ? fileUpdate?.created_at
        : fileUpdate?.updated_at
    )}, ${getFormattedTime(
      new Date(
        fileUpdate?.updated_by === null
          ? fileUpdate?.created_at
          : fileUpdate?.updated_at
      )
    )}, ${userData?.first_name} ${userData?.last_name} (${userData?.username})`;

    return (
      <Tooltip title={fileUpdateDetails} placement="top-start">
        <Typography variant="body2" noWrap>
          {fileUpdateDetails}
        </Typography>
      </Tooltip>
    );
  };

  const UploadedDocumentstColumnConfig = useMemo(
    () => [
      {
        id: "documentType",
        label: "Document Type",
        field: "documentType",
        headerClassName: classes.documentType,
        render: (row) => (
          <Tooltip title={row.doc_type || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.doc_type || "-"}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: "documentName",
        label: "Document Name",
        field: "documentName",
        headerClassName: classes.documentType,
        render: (row) => (
          <Tooltip title={row.doc_name || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.doc_name || "-"}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: "dateOfUpload",
        label: "Date Of Upload",
        field: "dateOfUpload",
        render: (row) => (
          <Tooltip
            title={getDateString(row.created_at) || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {getDateString(row.created_at) || "-"}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: "downloadDocument",
        label: "Download Document",
        field: "downloadDocument",
        render: (row) => (
          <a href={row.doc_path} target="_blank" rel="noopener noreferrer">
            <Tooltip title="Download Document" placement="top-start">
              <CloudDownloadIcon color="primary" />
            </Tooltip>
          </a>
        ),
      },
      {
        id: "deleteDocument",
        label: "Delete Document",
        field: "deleteDocument",
        render: (row) => (
          <Tooltip title="Delete Document" placement="top-start">
            <IconButton
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  rowId: row.id,
                  deleteDocument: true,
                }))
              }
            >
              <DeleteForeverIcon color="error" />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    []
  );

  const agreementColumnConfig = useMemo(() => {
    const commonColumns = [
      {
        id: "agreement",
        label: "Agreement",
        headerClassName: classes.agreement,
        field: "agreement",
        render: (row) => {
          const stateAgreements = state.agreementsEntries;

          const missingItems = state.selectedAgreementRows.filter(
            (item) => !stateAgreements.includes(item)
          );

          if (missingItems?.length > 0) {
            state.agreementsEntries = stateAgreements.concat(missingItems);
          }
          return (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={
                    (entry?.contractorType?.value === "Independent" &&
                      (row.name === "SS-4" ||
                        row.name === "Paid Under Company (PUC)" ||
                        row.name ===
                          "Subcontractor Undertaking Agreement (SUA)")) ||
                    (entry?.contractorType?.value === "Master" &&
                      (row.name === "Paid Under Company (PUC)" ||
                        row.name ===
                          "Subcontractor Undertaking Agreement (SUA)")) ||
                    (entry?.contractorType?.value === "Subcontractor" &&
                      row.name === "SS-4") ||
                    entry?.contractorType?.value === "Employee" ||
                    entry?.contractorType?.value === "Lumper" ||
                    entry?.contractorType?.value === "SLC"
                  }
                  size="small"
                  className={classes.checkbox}
                  checked={state.agreementsEntries.includes(row.name)}
                  onChange={() => handleSelection(row.name, "agreement")}
                  color="primary"
                />
              }
              label={<Typography variant="body2">{row.name}</Typography>}
            />
          );
        },
      },
      {
        id: "date",
        label: "Date",
        headerClassName: classes.datepicker,
        field: "date",
        render: (row) =>
          renderDatepicker(
            "submitDate",
            state.agreementsEntries,
            "Date",
            "dated",
            row
          ),
      },
      {
        headerClassName: classes.status,
        id: "status",
        label: "Status",
        field: "status",
        render: (row) =>
          renderStatusColumn(
            "agreementStatus",
            state.agreementsEntries,
            agreementStatusList,
            row
          ),
      },
    ];

    const additionalColumns = [
      {
        label: "File Updated",
        headerClassName: classes.agreement,
        noResize: true,
        render: (row) => renderFileUpdatedColumn(row, entry.icDocsStatus),
      },
    ];

    return isNew ? commonColumns : [...commonColumns, ...additionalColumns];
  }, [
    entry,
    isNew,
    state.agreementsEntries,
    state.submitDate,
    state.agreementStatus,
    classes.datepicker,
    classes.agreement,
    state.selectedAgreementRows,
  ]);

  const documentColumnConfig = useMemo(() => {
    const commonColumns = [
      {
        id: "document",
        label: "Document",
        headerClassName: classes.agreement,
        field: "document",
        render: (row) => {
          const stateDocuments = state.documentsEntries;

          const missingItems = state.selectedDocsRows.filter(
            (item) => !stateDocuments.includes(item)
          );

          if (missingItems?.length > 0) {
            state.documentsEntries = stateDocuments.concat(missingItems);
          }
          return (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  className={classes.checkbox}
                  checked={state.documentsEntries.includes(row.name)}
                  onChange={() => handleSelection(row.name, "document")}
                  color="primary"
                />
              }
              label={<Typography variant="body2">{row.name}</Typography>}
            />
          );
        },
      },
      {
        id: "date",
        label: "Expiration",
        headerClassName: classes.datepicker,
        field: "date",
        render: (row) =>
          renderDatepicker(
            "expiration",
            state.documentsEntries,
            "Expiration",
            "expire_date",
            row
          ),
      },
      {
        id: "status",
        headerClassName: classes.status,
        label: "Status",
        field: "status",
        render: (row) =>
          renderStatusColumn(
            "documentStatus",
            state.documentsEntries,
            documentStatusList,
            row
          ),
      },
    ];

    const additionalColumns = [
      {
        label: "File Updated",
        headerClassName: classes.agreement,
        noResize: true,
        render: (row) => renderFileUpdatedColumn(row, entry.icDocsStatus),
      },
    ];

    return isNew ? commonColumns : [...commonColumns, ...additionalColumns];
  }, [
    entry,
    isNew,
    state.expiration,
    state.documentStatus,
    state.documentsEntries,
    classes.datepicker,
    classes.agreement,
    state.selectedDocsRows,
  ]);

  const fileRetentionColumnConfig = useMemo(() => {
    const commonColumns = [
      {
        id: "fileRetention",
        label: "File Retention",
        field: "fileRetention",
        render: (row) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  style={{ marginLeft: 20 }}
                  className={classes.checkbox}
                  checked={state.isFileRetentionMarked}
                  onChange={(evt) => {
                    const { checked } = evt.target;
                    isDocsFieldChange = true;
                    setState((prevState) => ({
                      ...prevState,
                      isFileRetentionMarked: checked,
                      fileRetentionStatus: null,
                    }));
                  }}
                  color="primary"
                />
              }
            />
          );
        },
      },
      {
        id: "fileRetentionStatus",
        label: "File Retention Status",
        headerClassName: classes.fileRetentionStatus,
        field: "fileRetentionStatus",
        render: (row) => {
          const label =
            fileRetentionStatusList.find(
              (item) =>
                item.type === "File Retention Status" &&
                item.id === state.fileRetentionStatus
            )?.value || "";
          return (
            <InlineFieldForm
              disabled={!state.isFileRetentionMarked}
              dropdownLabel={
                state.isFileRetentionMarked
                  ? label || <span style={{ color: "red" }}>Select *</span>
                  : label || "-"
              }
              name={"fileRetentionStatus"}
              initialValue={{
                label: label || "",
              }}
              type="dropdown"
              options={fileRetentionStatusList.map((data) => ({
                value: data.id,
                label: data.value,
              }))}
              onUpdate={(updatedData) => {
                isDocsFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  fileRetentionStatus: updatedData?.value,
                }));
              }}
            />
          );
        },
      },
    ];

    const additionalColumns = [
      {
        label: "File Updated",
        noResize: true,
        render: (row) => {
          return renderFileUpdatedColumn(row, entry.icDocsStatus);
        },
      },
    ];

    return isNew ? commonColumns : [...commonColumns, ...additionalColumns];
  }, [
    entry,
    isNew,
    classes.agreement,
    state.isFileRetentionMarked,
    state.fileRetentionStatus,
  ]);

  const watchlistColumnConfig = useMemo(() => {
    const commonColumns = [
      {
        id: "Watchlist",
        label: "Watchlist",
        field: "Watchlist",
        headerClassName: classes.Watchlist,
        render: (row) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  style={{ marginLeft: 20 }}
                  className={classes.checkbox}
                  checked={state.isWatchlistMarked}
                  onChange={(evt) => {
                    const { checked } = evt.target;
                    if (
                      !!entry?.is_cautious_flagged &&
                      !!entry.icDocsStatus &&
                      entry.icDocsStatus?.some(
                        (item) =>
                          item.type === "Ic Watchlist" &&
                          item.is_archived === false
                      ) &&
                      state.isWatchlistMarked
                    ) {
                      return setState((prevState) => ({
                        ...prevState,
                        uncheckingWatchlist: true,
                      }));
                    }
                    if (
                      !!entry?.is_cautious_flagged &&
                      (entry?.is_flagged === false ||
                        entry?.is_flagged === null) &&
                      !!entry.icDocsStatus &&
                      !entry.icDocsStatus?.some(
                        (item) =>
                          item.type === "Ic Watchlist" &&
                          item.is_archived === false
                      ) &&
                      !state.isWatchlistMarked
                    ) {
                      return setState((prevState) => ({
                        ...prevState,
                        checkingWatchlist: true,
                      }));
                    }
                    isDocsFieldChange = true;

                    setState((prevState) => ({
                      ...prevState,
                      isWatchlistMarked: checked,
                    }));
                  }}
                  color="primary"
                />
              }
            />
          );
        },
      },
    ];

    const additionalColumns = [
      {
        label: "File Updated",
        noResize: true,
        render: (row) => {
          return renderFileUpdatedColumn(row, entry.icDocsStatus);
        },
      },
    ];

    return isNew ? commonColumns : [...commonColumns, ...additionalColumns];
  }, [entry, isNew, classes.agreement, state.isWatchlistMarked]);

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    if (
      evt.currentTarget?.name === "add_doc_notes" ||
      evt.target?.name === "add_doc_notes"
    ) {
      isDocsFieldChange = true;
    }
    if (
      evt.currentTarget?.name === "standbyRate" ||
      evt.target?.name === "standbyRate"
    ) {
      isStandbyFieldChange = true;
    }

    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";
    if (
      (type === "number" &&
        value.indexOf(".") !== -1 &&
        field !== "ein" &&
        field !== "ssn") ||
      field === "pay_toll_amount" ||
      field === "bill_toll_amount"
    ) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }

    if (
      type === "number" &&
      value?.length > 1 &&
      value.indexOf(".") === -1 &&
      field !== "icid"
    ) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handlePatch = async (id, payload) => {
    setState((prevState) => ({
      ...prevState,
      SSNForm: {
        ...prevState.SSNForm,
        isLoading: true,
      },
    }));

    const { data, error } = await Service.patch(payload, id);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        SSNForm: {
          ...prevState.SSNForm,
          isLoading: false,
        },
      }));
      return false;
    }
    if (entry.ssn) {
      toast.success("SSN Updated Successfully.");
    }
    if (data && data.updatedICRecord) {
      const updatedEntry = data.updatedICRecord;
      onUpdateEntry(updatedEntry);
    } else {
      fetchEntries();
      if (isNew) {
        handleClose(true);
      }
    }

    setState((prevState) => ({
      ...prevState,
      SSNForm: {},
    }));
    return true;
  };

  const handleSubmit = async (override = false) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: !override ? true : false,
      isOverridingICID: override ? true : false,
    }));

    const {
      pdx_company,
      settlement_company,
      customer,
      kamUser,
      isLoading,
      state: stateObj,
      errors,
      isAutoGeneratedICID,
      overrideICID,
      default_vehicle_type,
      contractor_type,
      docsData,
      ic_watchlist,
      deepCopiedEntry,
      ...payload
    } = state;

    const serviceMethod = isNew && state.tabIndex === 1 ? "post" : "put";

    let standByRates = [];
    let standByRate = null;
    let standByRateType = null;

    if (!!deepCopiedEntry?.StandByRates?.length) {
      standByRates = deepCopiedEntry.StandByRates.map((rate) => ({
        ...(typeof rate.id === "number" && { id: rate.id }),
        rate_type_id: rate.rateType.id,
        rate_value: rate.rate_value,
        is_default: rate.is_default,
      }));
    }

    if (deepCopiedEntry?.StandByRates?.length) {
      const defaultRate = deepCopiedEntry.StandByRates.find(
        (rate) => rate.is_default
      );
      if (defaultRate) {
        standByRate = defaultRate.rate_value;
        standByRateType = defaultRate.rateType?.value;
      }
    }

    const requestData = {
      ...payload,
      contractor_type: contractor_type?.id || null,
      default_vehicle_type: default_vehicle_type?.id || null,
      pdx_company_id: pdx_company?.id || null,
      default_settlement_company_id: settlement_company?.id || null,
      default_customer_id: customer?.id || null,
      default_kam_id: kamUser?.id || null,
      state: stateObj?.abbreviation || null,
      auto_generate: isNew && state.isAutoGeneratedICID,
      icid: isNew && state.isAutoGeneratedICID ? null : payload.icid,
      stand_by_rate: standByRate,
      stand_by_rate_type: standByRateType,
      standByRateRecord: standByRates,
      notes: payload.notes?.trim(),
      docsData:
        isNew && state.tabIndex === 1
          ? []
          : docsData?.filter((item) => {
              return (
                item.status !== undefined &&
                (item.dated !== undefined || item.expire_date !== undefined)
              );
            }),
      ic_watchlist: isNew && state.tabIndex === 1 ? null : ic_watchlist,
      override,
      ...(isNew && state.tabIndex === 2 ? { id: entry?.id } : {}),
    };

    if (isNew && state.tabIndex === 1) {
      delete requestData.id;
    }

    if (state.contractor_type?.value !== "Stand-by") {
      delete requestData.stand_by_rate_type;
      delete requestData.stand_by_rate;
      delete requestData.standByRateRecord;
    }

    const { data, error } = await Service[serviceMethod](
      requestData,
      entry?.id
    );

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));

      if (
        Array.isArray(error) &&
        error[0].code === ERROR_CODES.CAN_OVERRIDE_ICID
      ) {
        const IC_Details = error[0].ic ? (
          <div>
            <Typography variant="body2">
              <b>
                {`Name: ${error[0].ic.last_name || ""} ${
                  error[0].ic.first_name
                }`}
              </b>
              <br></br>
              <b>ICID: {error[0].ic.icid}</b>
              <br></br>
              <b>Email: {error[0].ic.email || "-"}</b>
              <br></br>
              <b>Date of Birth: {error[0].ic.date_of_birth}</b>
              <br></br>
              <b>
                Default Vehicle Type: {error[0].ic.default_vehicle_type || "-"}
              </b>
              <br></br>
              <b>IC Company Name: {error[0].ic.ic_company_name || "-"}</b>
              <br></br>
              <b>Stand-by Rate Type: {error[0].ic.stand_by_rate_type || "-"}</b>
              <br></br>
              <b>Stand-by Rate: ${error[0].ic.stand_by_rate || "-"}</b>
              <br></br>
              <b>City: {error[0].ic.city || "-"}</b>
              <br></br>
              <b>State: {error[0].ic.state || "-"}</b>
              <br></br>
              <b>Contractor Type: {error[0].ic.contractor_type || "-"}</b>
              <br></br>
              <b>Phone: {error[0].ic.phone || "-"}</b>
              <br></br>
              <b>EIN: {error[0].ic.ein || "-"}</b>
              <br></br>
              <b>SSN: {error[0].ic.ssn || "-"}</b>
              <br></br>
              <b>Notes: {error[0].ic.notes || "-"}</b>
            </Typography>

            {}
          </div>
        ) : (
          ""
        );

        return setState((prevState) => ({
          ...prevState,
          overrideICID: true,
          overrideID: error[0].ic?.id,
          override_ICID_details: IC_Details,
        }));
      } else {
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }
    }

    toast.success(
      `Independent Contractor ${isNew ? "created" : "updated"} successfully.`
    );

    if (data && (data.newICRecord || data.updatedICRecord)) {
      const updatedEntry =
        isNew && state.tabIndex === 1 ? data.newICRecord : data.updatedICRecord;

      onUpdateEntry(updatedEntry);
    } else {
      fetchEntries();
      if (isNew) {
        handleClose(true);
      }
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      standByIdsForDeletion: defaultState.standByIdsForDeletion,
      isOverridingICID: false,
      documentType: "",
    }));

    if (!isNew && !entry.ssn) {
      handlePatch(state.overrideID || entry.id, {
        icid: payload.icid,
        ssn: payload.ssn,
        default_settlement_company_id: settlement_company?.id || null,
      });
    }
    if (isNew && state.tabIndex === 1) {
      newICCreated();
      setState((prevState) => ({
        ...prevState,
        agreementsEntries: [],
        documentsEntries: [],
        isFileRetentionMarked: false,
        isWatchlistMarked: false,
        selectedAgreementRows: [],
        selectedDocsRows: [],
        documentType: "",
      }));
    }
    isFieldChange = false;
    isDocsFieldChange = false;
  };

  const handleClose = (isSubmitted = false) => {
    isFieldChange = false;
    isDocsFieldChange = false;
    setState(defaultState);
    onClose(isSubmitted);
  };
  const handleTabChange = useCallback(
    (event, tabIndex) => {
      if (
        isDocsFieldChange ||
        isFieldChange ||
        (tabIndex !== 1 && !entry?.id)
      ) {
        setState((prevState) => ({
          ...prevState,
          showUnsavedChangesPopup: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          tabIndex,
        }));
      }
    },
    [entry]
  );

  const TAB_LIST = [
    { id: 1, label: "General" },
    { id: 2, label: "Documentation" },
    { id: 3, label: "Upload Documents" },
  ];

  const resetState = () => {
    if (state.tabIndex === 1) {
      isFieldChange = false;
      isDocsFieldChange = false;
      setState((prevState) => ({
        ...prevState,
        tabIndex: state.tabIndex,
        icid: entry?.icid || defaultState.icid,
        first_name: entry?.first_name || defaultState.first_name,
        last_name: entry?.last_name || defaultState.last_name,
        ic_company_name: entry?.ic_company_name || defaultState.ic_company_name,
        isAutoGeneratedICID:
          entry?.isAutoGeneratedICID || defaultState.isAutoGeneratedICID,
        city: entry?.city || defaultState.city,
        state: entry?.state || defaultState.state,
        phone: entry?.phone || defaultState.phone,
        email: entry?.email || defaultState.email,
        contractor_type: entry?.contractorType || defaultState.contractor_type,
        pdx_company: entry?.pdxCompany || defaultState.pdx_company,
        settlement_company:
          entry?.settlementCompany || defaultState.settlement_company,
        customer: entry?.customer || defaultState.customer,
        default_vehicle_type:
          entry?.defaultVehicleType || defaultState.default_vehicle_type,
        deepCopiedEntry: isNew ? defaultState.deepCopiedEntry : entry,
        kamUser: entry?.kamUser || defaultState.kamUser,
        ein: entry?.ein || defaultState.ein,
        ssn: entry?.ssn || defaultState.ssn,
        date_of_birth: entry?.date_of_birth
          ? getDateString(entry?.date_of_birth)
          : defaultState.date_of_birth,
        notes: entry?.notes || defaultState.notes,
        selectedDocsRows: defaultState.selectedDocsRows,
        selectedAgreementRows: defaultState.selectedAgreementRows,
      }));
    } else if (state.tabIndex === 2) {
      isFieldChange = false;
      isDocsFieldChange = false;
      setState((prevState) => ({
        ...prevState,
        tabIndex: state.tabIndex,
        selectedDocsRows: defaultState.selectedDocsRows,
        selectedAgreementRows: defaultState.selectedAgreementRows,
        add_doc_notes:
          (!!entry?.add_doc_notes && entry?.add_doc_notes) ||
          defaultState.add_doc_notes,
        agreementStatus:
          (!!entry?.icDocsStatus &&
            entry?.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.agreementStatus,
        documentStatus:
          (!!entry?.icDocsStatus &&
            entry?.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.documentStatus,
        agreementsEntries:
          (!!entry?.icDocsStatus &&
            entry?.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.agreementsEntries,
        documentsEntries:
          (!!entry?.icDocsStatus &&
            entry?.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.documentsEntries,
        submitDate:
          (!!entry?.icDocsStatus &&
            entry?.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.submitDate,
        expiration:
          (!!entry?.icDocsStatus &&
            entry?.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.expiration,
        isWatchlistMarked:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus?.some(
              (item) =>
                item.type === "Ic Watchlist" && item.is_archived === false
            )) ||
          defaultState.isWatchlistMarked,
        fileRetentionStatus:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.find(
              (item) =>
                item.type === "File Retention Status" &&
                item.is_archived === false
            )?.lookupStatus?.id) ||
          defaultState.fileRetentionStatus,
        isFileRetentionMarked:
          (!!entry.icDocsStatus &&
            !!entry.icDocsStatus.find(
              (item) =>
                item.type === "File Retention Status" &&
                item.is_archived === false
            )) ||
          defaultState.isFileRetentionMarked,
        resetKey: prevState,
      }));
    }
  };

  const arePropertiesEqual = (value1, value2) => {
    return (
      value1 === value2 ||
      (value1 === "" && value2 === null) ||
      (value1 === "Daily" && value2 === "")
    );
  };

  const arePropertiesObjectsEqual = (obj1, obj2) => {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;

      return obj1.every((item1, index) => {
        const item2 = obj2[index];
        return (
          item1?.id === item2?.id &&
          +item1?.rate_value === +item2?.rate_value &&
          item1?.is_default === item2?.is_default &&
          item1?.rateType?.id === item2?.rateType?.id
        );
      });
    }

    return (
      obj1 === obj2 ||
      (obj1?.id === obj2?.id && obj1?.id !== undefined) ||
      (obj1?.abbreviation === obj2?.abbreviation &&
        obj1?.abbreviation !== undefined) ||
      (obj1 === "" && obj2 === null) ||
      obj2 === ""
    );
  };

  const areDocsPropertiesEqual = (value1, value2) => {
    const sortedValue1 = Array.isArray(value1) ? value1.slice().sort() : value1;
    const sortedValue2 = Array.isArray(value2) ? value2.slice().sort() : value2;

    return (
      sortedValue1 === sortedValue2 ||
      (sortedValue1 === "" && sortedValue2 === null) ||
      (sortedValue1 === false && sortedValue2 === null) ||
      (sortedValue1 === false && sortedValue2 === true) ||
      JSON.stringify(sortedValue1) === JSON.stringify(sortedValue2)
    );
  };
  const areWatchlistPropEqual = (value1, value2) => {
    return value1 === value2;
  };

  const isDateInUTCFormat = (dateString) => {
    const utcDateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    return utcDateRegex.test(dateString);
  };

  const mapDates = (data) =>
    isDateInUTCFormat(data) ? data : getUTCDateString(data);
  const filterAndMapDocs = (docs, type, date) =>
    !!docs &&
    docs
      ?.filter((item) => item.type === type && item.is_archived === false)
      ?.map((data) => getUTCDateString(data[date]));

  const extractIdsByType = (docsStatus, type) => {
    return (
      !!docsStatus &&
      docsStatus
        .filter((item) => item.type === type && item.is_archived === false)
        .map((item) => item?.lookupStatus?.id)
    );
  };

  const documentIds = extractIdsByType(
    !!entry && !!entry.icDocsStatus && entry.icDocsStatus,
    "Document"
  );
  const agreementIds = extractIdsByType(
    !!entry && !!entry.icDocsStatus && entry.icDocsStatus,
    "Agreement"
  );

  const areDocumentPropertiesEqual = areDocsPropertiesEqual(
    state.documentStatus
      ?.map((data) => data.lookupStatus?.id)
      ?.filter((id) => id !== undefined),
    documentIds
  );
  const areFileRetentionEqual = areDocsPropertiesEqual(
    state.fileRetentionStatus,
    !!entry &&
      !!entry.icDocsStatus &&
      (entry.icDocsStatus.find(
        (item) =>
          item.type === "File Retention Status" && item.is_archived === false
      )?.lookupStatus?.id ??
        null)
  );

  const areAgreementPropertiesEqual = areDocsPropertiesEqual(
    state.agreementStatus
      ?.map((data) => data.lookupStatus?.id)
      ?.filter((id) => id !== undefined),
    agreementIds
  );

  const areBothEqual =
    areDocumentPropertiesEqual &&
    areAgreementPropertiesEqual &&
    areFileRetentionEqual;

  if (
    state.tabIndex === 1 &&
    (!isNew || (isNew && !!entry)) &&
    updatePropertiesToCheck.every((property) =>
      arePropertiesEqual(state[property], entry[property])
    ) &&
    arePropertiesObjectsEqual(state.state, entry?.state) &&
    arePropertiesObjectsEqual(state.contractor_type, entry?.contractorType) &&
    arePropertiesObjectsEqual(state.pdx_company, entry?.pdxCompany) &&
    arePropertiesObjectsEqual(
      state.settlement_company,
      entry?.settlementCompany
    ) &&
    arePropertiesEqual(
      state.date_of_birth,
      getDateString(entry.date_of_birth)
    ) &&
    arePropertiesObjectsEqual(state.customer, entry?.customer) &&
    arePropertiesObjectsEqual(
      state.default_vehicle_type,
      entry?.defaultVehicleType
    ) &&
    arePropertiesObjectsEqual(state.kamUser, entry?.kamUser) &&
    arePropertiesObjectsEqual(
      !!state.deepCopiedEntry?.StandByRates?.length
        ? state.deepCopiedEntry?.StandByRates
        : [],
      entry?.StandByRates
    )
  ) {
    isFieldChange = false;
  } else if (
    state.tabIndex === 1 &&
    isNew &&
    !entry &&
    propertiesToCheck.every(
      (property) =>
        state[property] === defaultState[property] || state[property] === null
    )
  ) {
    isFieldChange = false;
  } else if (
    state.tabIndex === 2 &&
    !!entry &&
    areDocsPropertiesEqual(state.add_doc_notes, entry.add_doc_notes) &&
    areDocsPropertiesEqual(
      state.selectedAgreementRows?.length > 0
        ? state.selectedAgreementRows
        : state.agreementsEntries,
      !!entry.icDocsStatus &&
        entry.icDocsStatus
          .filter(
            (item) => item.type === "Agreement" && item.is_archived === false
          )
          .map((item) => item.name)
    ) &&
    areDocsPropertiesEqual(
      state.submitDate
        ?.map((data) => mapDates(data.dated))
        ?.filter((date) => date !== ""),
      filterAndMapDocs(
        !!entry && !!entry.icDocsStatus && entry.icDocsStatus,
        "Agreement",
        "dated"
      )
    ) &&
    areDocsPropertiesEqual(
      state.expiration
        ?.map((data) => mapDates(data.expire_date))
        ?.filter((date) => date !== ""),
      filterAndMapDocs(
        !!entry && !!entry.icDocsStatus && entry.icDocsStatus,
        "Document",
        "expire_date"
      )
    ) &&
    areBothEqual &&
    areDocsPropertiesEqual(
      state.selectedDocsRows?.length > 0
        ? state.selectedDocsRows
        : state.documentsEntries,
      !!entry.icDocsStatus &&
        entry.icDocsStatus
          .filter(
            (item) => item.type === "Document" && item.is_archived === false
          )
          .map((item) => item.name)
    ) &&
    areWatchlistPropEqual(
      state.isWatchlistMarked,
      !!entry.icDocsStatus
        ? entry.icDocsStatus?.some(
            (item) => item.type === "Ic Watchlist" && item.is_archived === false
          )
        : false
    ) &&
    areDocsPropertiesEqual(
      state.isFileRetentionMarked,
      !!entry.icDocsStatus &&
        !!entry.icDocsStatus.find(
          (item) =>
            item.type === "File Retention Status" && item.is_archived === false
        )
    )
  ) {
    isDocsFieldChange = false;
    isFieldChange = false;
  }

  const contentText = useMemo(() => {
    if (isNew && state.tabIndex === 1 && !entry?.id) {
      if (
        !state.icid ||
        !state.first_name ||
        !state.settlement_company ||
        (state.contractor_type?.value === "Stand-by" &&
          !state.deepCopiedEntry?.StandByRates?.length)
      ) {
        return "Please fill the mandatory fields and save the general information for an independent contractor first before moving to the other tab.";
      } else {
        return "Please save the general information for an independent contractor first before moving to the other tab.";
      }
    } else if (state.tabIndex === 1) {
      if (
        !state.icid ||
        !state.first_name ||
        !state.settlement_company ||
        (state.contractor_type?.value === "Stand-by" &&
          !state.deepCopiedEntry?.StandByRates?.length)
      ) {
        return "First, please revert the changes or fill the mandatory fields and save the general form.";
      }
    } else if (state.tabIndex === 2 && (showText || showDocsText)) {
      return "First, please revert the changes or fill the mandatory fields and save the documentation form.";
    } else {
      return `Please save the information for an independent contractor first as you have made some changes in ${
        state.tabIndex === 1 ? "general" : "documentation"
      } form.`;
    }
  }, [
    isNew,
    state.tabIndex,
    entry,
    state.icid,
    state.first_name,
    state.settlement_company,
    state.contractor_type,
    state.deepCopiedEntry,
    showText,
    showDocsText,
  ]);

  useEffect(() => {
    if (entry) {
      const deepCopiedEntry = JSON.parse(JSON.stringify(entry));
      setState((prevState) => ({
        ...prevState,
        deepCopiedEntry,
      }));
    }
  }, [entry]);

  const updateStandByRates = (standByRates, newRecord) => {
    const exists = standByRates.some((rate) => rate.id === newRecord.id);
    return exists
      ? standByRates.map((rate) =>
          rate.id === newRecord.id ? newRecord : rate
        )
      : [...standByRates, newRecord];
  };

  const updateStandByRatesWithDefaultStatus = (standByRates, newRecord) => {
    const exists = standByRates.some((rate) => rate.id === newRecord.recordId);
    return exists
      ? standByRates.map((rate) =>
          rate.id === newRecord.recordId
            ? { ...rate, is_default: newRecord.status }
            : rate
        )
      : [...standByRates];
  };

  const removeStandByRate = (standByRates, idToRemove) => {
    return standByRates.filter((rate) => rate.id !== idToRemove);
  };

  const columnConfig = [
    [
      {
        headerClassName: classes.colEdit,
        id: "col-edit",
        noResize: true,
        render: (row) => (
          <div className="d-flex f-align-center">
            <Tooltip title="New Record" placement="top-start">
              <PlaylistAddIcon
                color="primary"
                className="ml-2 mr-2 c-pointer"
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    isNewStandby: true,
                    addStandbyRate: isNew ? true : { ic_id: row.id },
                  }));
                }}
              />
            </Tooltip>
          </div>
        ),
      },
      {
        id: "standbyRateType",
        label: "Stand-by Rate Type *",
        fieldName: "standbyRateType",
      },
      {
        id: "standbyRate",
        label: "Stand-by Rate *",
        fieldName: "standbyRate",
      },
    ],
    [
      {
        headerClassName: classes.colEdit,
        id: "col-edit",
        noResize: true,
        render: (row) => (
          <div className="d-flex f-align-center">
            <Tooltip title="Edit" placement="top-start">
              <EditOutlinedIcon
                className="ml-2 mr-2 c-pointer"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isNewStandby: false,
                    addStandbyRate: row,
                  }))
                }
              />
            </Tooltip>
            <Tooltip title="Delete" placement="top-start">
              <DeleteForeverOutlinedIcon
                className="ml-2 mr-2 c-pointer"
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    deletingStandBy: row?.id,
                  }));
                }}
                color="error"
              />
            </Tooltip>
            <Tooltip
              title={`${
                row.is_default ? "Unset as Default" : "Set as Default"
              }`}
              placement="top-start"
            >
              <Switch
                size="small"
                checked={row.is_default}
                onChange={(evt) => {
                  const checked = evt.target.checked;
                  setState((prevState) => ({
                    ...prevState,
                    isDefaultStandby: {
                      recordId: row?.id,
                      status: checked,
                    },
                  }));
                }}
                color="primary"
              />
            </Tooltip>
          </div>
        ),
      },
      {
        id: "standbyRateTypes",
        fieldName: "StandByRates",
        render: (row) => {
          return (
            <Typography variant="body2" noWrap>
              {row?.rateType?.value || "-"}
            </Typography>
          );
        },
      },
      {
        id: "standbyRates",
        fieldName: "StandByRates",
        render: (row) => {
          return (
            <Typography variant="body2" noWrap className="ml-10">
              {!!row?.rate_value ? (+row?.rate_value).toFixed(2) : "-"}
            </Typography>
          );
        },
      },
    ],
  ];

  useEffect(() => {
    if (!state.isNewStandby && state.addStandbyRate) {
      setState((prevState) => ({
        ...prevState,
        standbyRateType: state.addStandbyRate?.rateType,
        standbyRate: state.addStandbyRate.rate_value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        standbyRateType: defaultState.standbyRateType,
        standbyRate: defaultState.standbyRate,
      }));
    }
  }, [state.addStandbyRate, state.isNewStandby]);

  return (
    <>
      <Dialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleClose}
      >
        <Dialog.Title hasClose>
          {isNew
            ? "Create Entry"
            : `Update Entry - ${state.first_name} ${state.last_name} (${state.icid})`}
        </Dialog.Title>
        <Dialog.Content>
          {(isFetching || state.isLoading) && (
            <LoadingOverlay
              message={
                isFetching
                  ? "Processing, please wait..."
                  : "Saving changes, please wait..."
              }
            />
          )}
          <Tabs
            value={state.tabIndex}
            onChange={handleTabChange}
            indicatorColor={"#eeeeee"}
            textColor={"primary"}
            className={classes.tabs}
          >
            {TAB_LIST.map((tab) => (
              <Tab
                value={tab.id}
                disabled={isFetching || state.isLoading}
                key={tab.id}
                name={tab.id}
                className={classes.tab}
                style={{
                  borderTop:
                    tab.id === state.tabIndex ? "2px solid #80808045" : "none",
                  borderLeft:
                    tab.id === state.tabIndex ? "2px solid #80808045" : "none",
                  borderRight:
                    tab.id === state.tabIndex ? "2px solid #80808045" : "none",
                  background: tab.id === state.tabIndex ? "white" : "#ececec",
                }}
                label={
                  <Typography
                    variant="subtitle2"
                    color={
                      tab.id === state.tabIndex ? "primary.dark" : "grey.300"
                    }
                  >
                    {tab.label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
          {state.tabIndex === 1 && (
            <Paper className={classes.general}>
              <div className="d-flex f-align-center f-justify-between mb-1">
                {(isNew ||
                  currentUserRole === ROLE.ADMIN ||
                  currentUserRole === ROLE.AA) && (
                  <TextField
                    required={!state.isAutoGeneratedICID}
                    fullWidth={!isNew}
                    className={clsx({
                      "w-75": isNew,
                      "w-50": !isNew,
                    })}
                    name="icid"
                    label="ICID"
                    variant="outlined"
                    size="small"
                    value={state.icid}
                    error={!!state.errors.icid.trim()}
                    helperText={state.errors.icid}
                    onChange={handleFieldChange}
                    disabled={state.isAutoGeneratedICID || isFetching}
                  />
                )}
                {isNew && (
                  <FormControlLabel
                    className="mb-5"
                    control={
                      <Checkbox
                        disabled={isFetching}
                        size="small"
                        style={{ padding: "10px" }}
                        checked={state.isAutoGeneratedICID}
                        onChange={(evt) => {
                          const { checked } = evt.target;
                          isFieldChange = true;
                          setState((prevState) => ({
                            ...prevState,
                            isAutoGeneratedICID: checked,
                            icid: "",
                            errors: {
                              ...prevState.errors,
                              icid: " ",
                            },
                          }));
                        }}
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">Auto</Typography>}
                  />
                )}
                {!isNew && (
                  <TextField
                    fullWidth={true}
                    disabled={isFetching}
                    className="ml-2 w-50 mb-6 pl-1"
                    label="File Updated"
                    variant="outlined"
                    size="small"
                    value={`${state.updatedAt}, ${state.fileUpdated.first_name} ${state.fileUpdated.last_name} (${state.fileUpdated.username} - ${state.fileUpdated.role})`}
                  />
                )}
              </div>
              <div className="d-flex f-align-center mb-1">
                <TextField
                  className="mr-2 w-50"
                  required
                  name="first_name"
                  label="First Name"
                  variant="outlined"
                  disabled={isFetching}
                  size="small"
                  value={state.first_name}
                  error={!!state.errors.first_name.trim()}
                  helperText={state.errors.first_name}
                  onChange={handleFieldChange}
                />
                <TextField
                  className="ml-2 w-50"
                  name="last_name"
                  label="Last Name"
                  variant="outlined"
                  disabled={isFetching}
                  size="small"
                  value={state.last_name}
                  error={!!state.errors.last_name.trim()}
                  helperText={state.errors.last_name}
                  onChange={handleFieldChange}
                />
              </div>
              <TextField
                fullWidth
                className="mb-1"
                name="ic_company_name"
                label="IC Company Name"
                disabled={isFetching}
                variant="outlined"
                size="small"
                value={state.ic_company_name}
                error={!!state.errors.ic_company_name.trim()}
                helperText={state.errors.ic_company_name}
                onChange={handleFieldChange}
              />
              <div className="mb-1 d-flex f-align-center">
                <Autocomplete
                  className="w-50 mr-2"
                  size="small"
                  disableClearable
                  disabled={isFetching}
                  value={state?.state || ""}
                  options={stateList}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      helperText={" "}
                    />
                  )}
                  onChange={(evt, state) => {
                    isFieldChange = true;
                    setState((prevState) => ({
                      ...prevState,
                      state: state,
                      city: null,
                    }));
                  }}
                />
                <Autocomplete
                  className="w-50 ml-2"
                  size="small"
                  freeSolo
                  value={state?.city}
                  disabled={!state?.state || isFetching}
                  options={locationList[state.state?.name] || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      variant="outlined"
                      error={!!state.errors.city.trim()}
                      helperText={state.errors.city}
                      onChange={(evt) => {
                        const value = evt.currentTarget.value;
                        isFieldChange = true;
                        let errorMessage = validate("city", value) || " ";
                        setState((prevState) => ({
                          ...prevState,
                          city: value,
                          errors: {
                            ...prevState.errors,
                            city: errorMessage,
                          },
                        }));
                      }}
                    />
                  )}
                  onChange={(evt, city) => {
                    isFieldChange = true;
                    let errorMessage = validate("city", city) || " ";
                    setState((prevState) => ({
                      ...prevState,
                      city,
                      errors: {
                        ...prevState.errors,
                        city: errorMessage,
                      },
                    }));
                  }}
                />
              </div>
              <div className="d-flex f-align-center mb-1">
                <TextField
                  className="mr-2 w-50"
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  disabled={isFetching}
                  size="small"
                  value={state.phone}
                  error={!!state.errors.phone.trim()}
                  helperText={state.errors.phone}
                  onChange={handleFieldChange}
                />
                <TextField
                  className="ml-2 w-50"
                  name="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  disabled={isFetching}
                  value={state.email}
                  error={!!state.errors.email.trim()}
                  helperText={state.errors.email}
                  onChange={handleFieldChange}
                />
              </div>
              <Autocomplete
                className="mb-1"
                size="small"
                value={state.contractor_type}
                getOptionLabel={(option) => option?.value}
                options={contractorTypeList}
                disabled={isFetching}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contractor Type"
                    variant="outlined"
                    helperText=" "
                    onChange={(evt) => {
                      handleFieldChange(evt);
                    }}
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    contractor_type: value,
                      standbyRate: "",
                      standbyRateType: "",
                  }));
                }}
              />

              <div className="d-flex f-align-center mb-1">
                <Autocomplete
                  className="mr-2 w-50"
                  size="small"
                  value={state.pdx_company}
                  options={pdxCompanyList}
                  disabled={isFetching}
                  getOptionLabel={(option) => option.value || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="PDX co"
                      variant="outlined"
                      helperText=" "
                    />
                  )}
                  onChange={(evt, value) => {
                    isFieldChange = true;
                    setState((prevState) => ({
                      ...prevState,
                      pdx_company: value,
                    }));
                  }}
                />
                <Autocomplete
                  className="ml-2 w-50"
                  size="small"
                  value={state.settlement_company}
                  options={settlementCompanyList}
                  getOptionLabel={(option) => option.value || ""}
                  disabled={isFetching}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Settlement Co"
                      variant="outlined"
                      required
                      error={!!state.errors.settlement_company.trim()}
                      helperText={state.errors.settlement_company}
                    />
                  )}
                  onChange={(evt, value) => {
                    isFieldChange = true;
                    let errorMessage =
                      validate("settlement_company", value) || " ";
                    setState((prevState) => ({
                      ...prevState,
                      settlement_company: value,
                      errors: {
                        ...prevState.errors,
                        settlement_company: errorMessage,
                      },
                    }));
                  }}
                />
              </div>
              <div className="d-flex f-align-center mb-1">
                <Autocomplete
                  className="mr-2 w-50"
                  size="small"
                  value={state.customer}
                  options={customers}
                  disabled={isFetching}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Default Customer"
                      variant="outlined"
                      helperText=" "
                    />
                  )}
                  onChange={(evt, value) => {
                    isFieldChange = true;
                    setState((prevState) => ({
                      ...prevState,
                      customer: value,
                    }));
                  }}
                />
                <Autocomplete
                  className="ml-2 w-50"
                  size="small"
                  value={state.default_vehicle_type}
                  options={vehicleTypeList}
                  getOptionLabel={(option) => option.value}
                  disabled={isFetching}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Default Vehicle Type"
                      variant="outlined"
                      helperText=" "
                      onChange={(evt) => {
                        handleFieldChange(evt);
                      }}
                    />
                  )}
                  onChange={(evt, value) => {
                    isFieldChange = true;
                    setState((prevState) => ({
                      ...prevState,
                      default_vehicle_type: value,
                    }));
                  }}
                />
              </div>
              {state.contractor_type?.value === "Stand-by" && (
                <div className="d-flex f-align-center mb-6">
                  <CollapsibleGrid
                    columns={columnConfig}
                    rows={state.deepCopiedEntry}
                    childIdentifier={["StandByRates"]}
                    rowEvents={[
                      {
                        type: "onDoubleClick",
                        handler: (row, rowIndex, level = 0) => {
                          setState((prevState) => {
                            return {
                              ...prevState,
                              rowBeingEdited: row,
                            };
                          });
                        },
                      },
                    ]}
                    onReady={(instance) => (gridHelper = instance)}
                    classes={{
                      gridActions: "f-justify-end",
                    }}
                  />
                </div>
              )}

              <Autocomplete
                className="mb-1"
                size="small"
                value={state.kamUser}
                disabled={isFetching}
                options={kamUsers}
                getOptionLabel={(option) =>
                  option.name ||
                  `${option.first_name || ""} ${option.last_name || ""}-${
                    option.email || ""
                  }${option.username ? `-(${option.username})` : ""}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Default KAM"
                    variant="outlined"
                    helperText=" "
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({ ...prevState, kamUser: value }));
                }}
              />
              <div className="d-flex f-align-center mb-1">
                <TextField
                  className="mr-2 w-50"
                  name="ein"
                  label="EIN"
                  variant="outlined"
                  size="small"
                  disabled={isFetching}
                  value={state.ein}
                  error={!!state.errors.ein.trim()}
                  helperText={state.errors.ein}
                  onChange={handleFieldChange}
                  onKeyDown={preventInputSpace}
                />

                <Box className="ml-2 w-50 p-relative">
                  <TextField
                    disabled={(!isNew && entry.ssn) || isFetching}
                    name="ssn"
                    label="SSN"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={state.ssn}
                    error={!!state.errors.ssn.trim()}
                    helperText={state.errors.ssn}
                    onChange={handleFieldChange}
                    onKeyDown={preventInputSpace}
                  />
                  {currentUserRole === ROLE.ADMIN && !isNew && entry.ssn && (
                    <Typography
                      variant="body2"
                      disabled={isFetching}
                      className={clsx(
                        "c-pointer color-text-link p-absolute",
                        classes.changeSSNBtn
                      )}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          SSNForm: {
                            id: state.id,
                            icid: state.icid,
                            settlementCompanyId:
                              state.settlement_company?.id || null,
                            open: true,
                            value: "",
                            error: " ",
                            isEdited: false,
                          },
                        }))
                      }
                    >
                      Change SSN
                    </Typography>
                  )}
                </Box>
              </div>
              <Datepicker
                mask
                className="mb-6"
                label="Date Of Birth"
                maxDate={(() => {
                  const yesterdayDate = new Date();
                  return yesterdayDate.setFullYear(
                    yesterdayDate.getFullYear() - 18
                  );
                })()}
                selected={state.date_of_birth}
                classes={{
                  input: {
                    root: classes.datepickerWrapper,
                  },
                }}
                placement="top"
                showYearDropdown
                onChange={(previousDate) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    date_of_birth: getDateString(previousDate),
                  }));
                }}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                name="notes"
                label="Notes"
                disabled={isFetching}
                variant="outlined"
                size="small"
                value={state.notes}
                error={!!state.errors.notes.trim()}
                helperText={state.errors.notes}
                onChange={handleFieldChange}
              />
            </Paper>
          )}
          {state.tabIndex === 2 && (
            <div className={classes.document}>
              <Paper className={classes.agreementPaper}>
                <Grid
                  columns={agreementColumnConfig}
                  rows={updatedICAgreement.map((agreement) => {
                    const mandatoryField =
                      state.agreementsEntries?.includes(agreement.name) &&
                      (!state.agreementStatus?.some(
                        (item) => item.name === agreement.name
                      ) ||
                        !state.submitDate?.some(
                          (item) => item.name === agreement.name
                        ));

                    const isAgreementUnchecked =
                      !!entry &&
                      !!entry.icDocsStatus &&
                      entry.icDocsStatus
                        .filter((data) => data.type === "Agreement")
                        .find((item) => item.name === agreement.name);
                    const shouldHighlightUncheckedField =
                      isAgreementUnchecked?.is_archived === true &&
                      !state.agreementsEntries.includes(
                        isAgreementUnchecked.name
                      );

                    return {
                      ...agreement,
                      className: clsx({
                        [classes.highlightMandatoryField]: mandatoryField,
                        [classes.highlightUncheckedField]:
                          shouldHighlightUncheckedField,
                      }),
                    };
                  })}
                  actionBarConfig={null}
                  hasSelection={false}
                  isLoading={isFetching}
                  hasPagination={false}
                  classes={{
                    container: classes.addressGridridPaper,
                  }}
                />
              </Paper>
              <Paper className={classes.docsPaper}>
                <Grid
                  columns={documentColumnConfig}
                  rows={updatedICDocument.map((documents) => {
                    const mandatoryField =
                      state.documentsEntries?.includes(documents.name) &&
                      (!state.documentStatus?.some(
                        (item) => item.name === documents.name
                      ) ||
                        !state.expiration?.some(
                          (item) => item.name === documents.name
                        ));

                    const isDocsUnchecked =
                      !!entry &&
                      !!entry.icDocsStatus &&
                      entry.icDocsStatus
                        .filter((data) => data.type === "Document")
                        .find((item) => item.name === documents.name);
                    const shouldHighlightUncheckedField =
                      isDocsUnchecked?.is_archived === true &&
                      !state.documentsEntries?.includes(isDocsUnchecked.name);

                    return {
                      ...documents,
                      className: clsx({
                        [classes.highlightMandatoryField]: mandatoryField,
                        [classes.highlightUncheckedField]:
                          shouldHighlightUncheckedField,
                      }),
                    };
                  })}
                  actionBarConfig={null}
                  hasSelection={false}
                  isLoading={isFetching}
                  hasPagination={false}
                  classes={{
                    container: classes.addressGridridPaper,
                  }}
                />
              </Paper>
              <Paper className={classes.docsPaper}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="add_doc_notes"
                  label="Additional Notes"
                  variant="outlined"
                  size="small"
                  value={state.add_doc_notes}
                  error={!!state.errors.add_doc_notes.trim()}
                  helperText={state.errors.add_doc_notes}
                  onChange={handleFieldChange}
                />
              </Paper>
              <Paper className={classes.paperGrid}>
                <Grid
                  columns={fileRetentionColumnConfig}
                  rows={["File Retention Status"].map((name) => {
                    const shouldHighlightUncheckedField =
                      !!entry &&
                      !!entry.icDocsStatus &&
                      entry.icDocsStatus.some(
                        (data) =>
                          data.type === "File Retention Status" &&
                          !!data?.updated_at &&
                          data.is_archived === true
                      ) &&
                      state.isFileRetentionMarked === false;

                    return {
                      name,
                      className: clsx({
                        [classes.highlightMandatoryField]:
                          state.isFileRetentionMarked &&
                          (state.fileRetentionStatus === null ||
                            typeof state.fileRetentionStatus === "object"),
                        [classes.highlightUncheckedField]:
                          shouldHighlightUncheckedField,
                      }),
                    };
                  })}
                  actionBarConfig={null}
                  isLoading={isFetching}
                  hasSelection={false}
                  hasPagination={false}
                  classes={{
                    container: classes.watchlistGridridPaper,
                  }}
                />
              </Paper>
              <Paper className={classes.paperGrid}>
                <Grid
                  columns={watchlistColumnConfig}
                  rows={["Ic Watchlist"].map((name) => ({
                    name,
                    className: clsx({
                      [classes.highlightUncheckedField]:
                        !!entry?.icDocsStatus &&
                        state.isWatchlistMarked === false &&
                        entry.icDocsStatus?.some(
                          (item) =>
                            item.type === "Ic Watchlist" &&
                            item.is_archived === true &&
                            !!item.updated_at
                        ),
                    }),
                  }))}
                  actionBarConfig={null}
                  isLoading={isFetching}
                  hasSelection={false}
                  hasPagination={false}
                  classes={{
                    container: classes.watchlistGridridPaper,
                  }}
                />
              </Paper>
            </div>
          )}
          {state.tabIndex === 3 && (
            <div className={classes.document}>
              <Paper
                style={{
                  padding: 22,
                }}
              >
                <div className="d-flex f-align-center f-justify-between">
                  <Autocomplete
                    style={{ width: "275px" }}
                    className="mr-2"
                    size="small"
                    disableClearable
                    value={state.documentType || ""}
                    options={DOCUMENT_TYPES}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Document Types"
                        variant="outlined"
                      />
                    )}
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        documentType: value,
                      }));
                    }}
                  />
                  <Button
                    startIcon={<CloudUploadIcon />}
                    className="mr-4"
                    color="primary"
                    variant="contained"
                    disabled={
                      !state.documentType ||
                      (!state.isAutoGeneratedICID && !state.icid) ||
                      !state.first_name ||
                      (state.contractor_type?.value === "Stand-by" &&
                        !state.deepCopiedEntry?.StandByRates?.length)
                    }
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        openUpload: true,
                      }))
                    }
                  >
                    Upload
                  </Button>
                </div>
              </Paper>
              {!!entry && entry.icDocs?.length > 0 ? (
                <>
                  <Paper className={classes.docsPaper}>
                    <Typography variant="h6" color="primary">
                      Uploaded Documents
                    </Typography>
                    <Grid
                      columns={UploadedDocumentstColumnConfig}
                      rows={entry.icDocs.map((entry) => ({
                        ...entry,
                      }))}
                      actionBarConfig={null}
                      isLoading={isFetching}
                      hasSelection={false}
                      hasPagination={false}
                      classes={{
                        container: classes.watchlistGridridPaper,
                      }}
                    />
                  </Paper>
                  <Paper className={classes.paperGrid}>
                    <div className="d-flex f-align-center f-wrap">
                      <Typography variant="h6" color="primary">
                        File Updated:
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.documentUpdatedByuser}
                      >
                        {documentUpdatedByuser}
                      </Typography>
                    </div>
                  </Paper>
                </>
              ) : (
                <Paper className={classes.uploadDocument}>
                  <div className={classes.circularProgress}>
                    {isFetching ? (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    ) : (
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        No documents are uploaded. Please upload a document.
                      </Typography>
                    )}
                  </div>
                </Paper>
              )}
            </div>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <div style={{ padding: "8px 10px 8px 16px" }}>
            {state.tabIndex !== 3 && (
              <div className="d-flex f-align-center f-justify-between">
                {state.tabIndex === 2 &&
                  (showText ||
                    showDocsText ||
                    (state.isFileRetentionMarked &&
                      (state.fileRetentionStatus === null ||
                        typeof state.fileRetentionStatus === "object"))) && (
                    <Typography
                      variant="body2"
                      className="mr-5"
                      style={{ color: "red" }}
                    >
                      {"Please fill the mandatory fields."}
                    </Typography>
                  )}

                {!(
                  state.isFileRetentionMarked &&
                  (state.fileRetentionStatus === null ||
                    typeof state.fileRetentionStatus === "object")
                ) &&
                  !showText &&
                  !showDocsText &&
                  (isDocsFieldChange || isFieldChange) && (
                    <Typography
                      variant="body2"
                      className="mr-5"
                      style={{ color: "red" }}
                    >
                      {state.tabIndex === 1 &&
                      ((!state.isAutoGeneratedICID && !state.icid) ||
                        !state.first_name ||
                        !state.settlement_company ||
                        (state.contractor_type?.value === "Stand-by" &&
                          !state.deepCopiedEntry?.StandByRates?.length))
                        ? "Please fill the mandatory fields."
                        : !!state.deepCopiedEntry?.StandByRates?.length
                        ? !state.deepCopiedEntry?.StandByRates?.some(
                            (rate) => rate.is_default
                          ) && "At least one rate must be set as default."
                        : "You have unsaved changes."}
                    </Typography>
                  )}
                {(isDocsFieldChange || isFieldChange) && (
                  <Button
                    startIcon={<RotateLeftIcon />}
                    variant="outlined"
                    onClick={resetState}
                    style={{ color: "red" }}
                    className="mr-4"
                  >
                    Reset
                  </Button>
                )}
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                {state.tabIndex === 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-4"
                    disabled={
                      state.isLoading ||
                      !isFieldChange ||
                      validate() ||
                      (state.contractor_type?.value === "Stand-by" &&
                        !state.deepCopiedEntry?.StandByRates?.length) ||
                      (!!state.deepCopiedEntry?.StandByRates?.length &&
                        !state.deepCopiedEntry?.StandByRates?.some(
                          (rate) => rate.is_default
                        ))
                    }
                    onClick={() => {
                      if (
                        !isNew &&
                        state.contractor_type?.id !== entry.contractorType?.id
                      ) {
                        return setState((prevState) => ({
                          ...prevState,
                          isContractorType: true,
                        }));
                      } else {
                        handleSubmit();
                      }
                    }}
                  >
                    SAVE
                  </Button>
                )}
                {state.tabIndex === 2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-4"
                    disabled={
                      state.isLoading ||
                      showText ||
                      showDocsText ||
                      (state.isFileRetentionMarked &&
                        (state.fileRetentionStatus === null ||
                          typeof state.fileRetentionStatus === "object")) ||
                      !isDocsFieldChange ||
                      validate()
                    }
                    onClick={() => handleSubmit()}
                  >
                    SAVE
                  </Button>
                )}
              </div>
            )}
            {state.showUnsavedChangesPopup && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                }}
                open={!!state.showUnsavedChangesPopup}
                contentText={
                  contentText ||
                  `Please save the information for an independent contractor first as you have made some changes in ${
                    state.tabIndex === 1 ? "general" : "documentation"
                  } form.`
                }
                onConfirm={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showUnsavedChangesPopup: false,
                  }))
                }
                onClose={() => {
                  setState((prevState) => ({
                    ...prevState,
                    showUnsavedChangesPopup: false,
                  }));
                }}
              />
            )}
            {state.showRedFlagWarning && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                }}
                open={!!state.showRedFlagWarning}
                contentText={
                  "Marking the License status as not eligible will override existing flag and its reasons for this IC."
                }
                onConfirm={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showRedFlagWarning: false,
                  }))
                }
                onCancel={() => {
                  setState((prevState) => ({
                    ...prevState,
                    showRedFlagWarning: false,
                    documentStatus:
                      (!!entry &&
                        !!entry.icDocsStatus &&
                        entry.icDocsStatus.filter(
                          (item) =>
                            item.type === "Document" &&
                            item.is_archived === false
                        )) ||
                      defaultState.documentStatus,
                  }));
                }}
                positiveActionLabel={
                  <>
                    Confirm
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                }
              />
            )}
            {state.removeRedFlag && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                }}
                open={!!state.removeRedFlag}
                contentText={
                  <Typography variant="body2" color="textPrimary">
                    The driver's license status has been updated from 'not
                    eligible' to a different status.
                    <br />
                    <br />
                    <b>Note: </b>Switching the status from 'not eligible' to a
                    different status for the driver's license will clear the red
                    flag associated with the provided IC.
                  </Typography>
                }
                onConfirm={() => {
                  handleSelectedRows("Driver's License", "document");
                  setState((prevState) => ({
                    ...prevState,
                    removeRedFlag: false,
                  }));
                }}
                onCancel={() => {
                  setState((prevState) => ({
                    ...prevState,
                    removeRedFlag: false,
                  }));
                }}
                positiveActionLabel={
                  <>
                    Confirm
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                }
              />
            )}
            {state.documentStatusChanged && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                }}
                open={!!state.documentStatusChanged}
                contentText={
                  <Typography variant="body2" color="textPrimary">
                    The driver's license status has been updated from 'not
                    eligible' to a different status.
                    <br />
                    <br />
                    <b>Note: </b>Switching the status from 'not eligible' to a
                    different status for the driver's license will clear the red
                    flag associated with the provided IC.
                  </Typography>
                }
                onConfirm={() =>
                  setState((prevState) => ({
                    ...prevState,
                    documentStatusChanged: false,
                  }))
                }
                onCancel={() => {
                  setState((prevState) => ({
                    ...prevState,
                    documentStatusChanged: false,
                    documentStatus:
                      (!!entry &&
                        !!entry.icDocsStatus &&
                        entry.icDocsStatus.filter(
                          (item) =>
                            item.type === "Document" &&
                            item.is_archived === false
                        )) ||
                      defaultState.documentStatus,
                  }));
                }}
                positiveActionLabel={
                  <>
                    Confirm
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                }
              />
            )}
            {state.deleteDocument && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                }}
                open={!!state.deleteDocument}
                contentText="Are you sure you want to delete this document?"
                onConfirm={() => {
                  handleDelete(state.rowId);
                  setTimeout(() => {
                    setState((prevState) => ({
                      ...prevState,
                      deleteDocument: false,
                    }));
                  }, 2000);
                }}
                onCancel={() => {
                  setState((prevState) => ({
                    ...prevState,
                    deleteDocument: false,
                  }));
                }}
                positiveActionLabel={
                  <>
                    Confirm
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                }
                negativeActionLabel="Cancel"
                isConfirmDisabled={state.isLoading}
              />
            )}
            {state.isContractorType && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                }}
                open={!!state.isContractorType}
                contentText="The contractor type has been changed. Changes in the contractor type will result in modifications to the documentation tab. Are you sure you want to make these changes?"
                onConfirm={() => {
                  handleSubmit();
                  setState((prevState) => ({
                    ...prevState,
                    isContractorType: false,
                  }));
                }}
                onCancel={() => {
                  setState((prevState) => ({
                    ...prevState,
                    isContractorType: false,
                  }));
                }}
                positiveActionLabel={
                  <>
                    Confirm
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                }
                negativeActionLabel="Cancel"
                isConfirmDisabled={state.isLoading}
              />
            )}
            {state.uncheckingWatchlist && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                }}
                open={!!state.uncheckingWatchlist}
                contentText={
                  <Typography variant="body2" color="textPrimary">
                    Are you sure you want to uncheck this watchlist IC?
                    <br />
                    <br />
                    <b>Note: </b>This action will also remove the caution flag
                    from this IC.
                  </Typography>
                }
                onConfirm={() => {
                  isDocsFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    isWatchlistMarked: false,
                    uncheckingWatchlist: false,
                  }));
                }}
                onCancel={() => {
                  setState((prevState) => ({
                    ...prevState,
                    uncheckingWatchlist: false,
                  }));
                }}
                positiveActionLabel={
                  <>
                    Confirm
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                }
                negativeActionLabel="Cancel"
                isConfirmDisabled={state.isLoading}
              />
            )}
            {state.checkingWatchlist && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                }}
                open={!!state.checkingWatchlist}
                contentText="This action will override the caution flag reason for this IC. Are you sure you want to check the watchlist?"
                onConfirm={() => {
                  isDocsFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    isWatchlistMarked: true,
                    checkingWatchlist: false,
                  }));
                }}
                onCancel={() => {
                  setState((prevState) => ({
                    ...prevState,
                    checkingWatchlist: false,
                  }));
                }}
                positiveActionLabel={
                  <>
                    Confirm
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                }
                negativeActionLabel="Cancel"
                isConfirmDisabled={state.isLoading}
              />
            )}
            {state.openUpload && (
              <BulkUpload
                open={state.openUpload}
                icid={entry.id}
                uploadKey={state.documentType}
                onClose={(isUploaded = false) => {
                  setState((prevState) => ({
                    ...prevState,
                    openUpload: false,
                  }));
                }}
                onSheetError={(sheetError) =>
                  setState((prevState) => ({ ...prevState, sheetError }))
                }
                onUpdateEntry={onUpdateEntry}
                fetchEntries={fetchEntries}
                handleFormClose={handleClose}
                isNew={isNew}
              />
            )}{" "}
            {!!state.sheetError && (
              <ErrorModal
                open={!!state.sheetError}
                error={state.sheetError}
                onClose={() =>
                  setState((prevState) => ({
                    ...prevState,
                    sheetError: null,
                  }))
                }
              />
            )}
            {state.overrideICID && (
              <ActionDialog
                classes={{
                  confirm: "bg-primary",
                  paper: classes.dialogSize,
                }}
                open={state.overrideICID}
                contentText={
                  <div>
                    <Typography variant="body2">
                      Independent contractor already exist with this ID with the
                      following details: <br />
                      <br />
                    </Typography>
                    {state.override_ICID_details}
                    <br />
                    <Typography variant="body2">
                      Do you want to merge current record with this IC?
                      <br />
                      <br />
                      <b>Note(s): </b>Once the change has been made, it cannot
                      be revert back.
                    </Typography>
                  </div>
                }
                onConfirm={() => handleSubmit(true)}
                onCancel={() =>
                  setState((prevState) => ({
                    ...prevState,
                    overrideICID: false,
                  }))
                }
                positiveActionLabel="Confirm"
                negativeActionLabel="Cancel"
              />
            )}
          </div>
        </Dialog.Actions>
      </Dialog>

      {state.SSNForm.open && (
        <ActionDialog
          open={state.SSNForm.open}
          classes={{
            paper: classes.SSNForm,
            confirm: !(state.SSNForm.error || "").trim() && "bg-primary",
          }}
          heading="Update SSN"
          positiveActionLabel="Update"
          content={
            <Box>
              <TextField
                fullWidth
                label="SSN"
                name="ssn"
                variant="outlined"
                size="small"
                value={state.SSNForm.value}
                error={!!(state.SSNForm.error || "").trim()}
                helperText={state.SSNForm.error}
                onKeyDown={preventInputSpace}
                onChange={(evt) => {
                  const { value } = evt.currentTarget;
                  let error =
                    [{ type: VALIDATIONS.SSN, value: true }]
                      .map((validation) =>
                        validator(validation.type, validation.value, value)
                      )
                      .filter((error) => error?.message)
                      .map((error) => error?.message)[0] || " ";

                  setState((prevState) => ({
                    ...prevState,
                    SSNForm: {
                      ...prevState.SSNForm,
                      isEdited: true,
                      value,
                      error,
                    },
                  }));
                }}
              />
            </Box>
          }
          onConfirm={async () => {
            const { id, icid, value, settlementCompanyId } = state.SSNForm;
            const isUpdated = await handlePatch(id, {
              icid,
              ssn: value,
              default_settlement_company_id: settlementCompanyId,
            });
            if (isUpdated) {
              setState((prevState) => ({
                ...prevState,
                ssn: value ? `***-**-${(value || "").slice(-4)}` : "",
              }));
              entry.ssn = value ? `***-**-${(value || "").slice(-4)}` : "";
            }
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              SSNForm: {},
            }))
          }
          isConfirmDisabled={
            !!(state.SSNForm.error || "").trim() || state.SSNForm.isLoading
          }
        />
      )}
      {state.addStandbyRate && (
        <ActionDialog
          open={state.addStandbyRate}
          classes={{
            paper: classes.SSNForm,
            confirm:
              (!state.standbyRate ||
                !state.standbyRateType ||
                !(state.errors.standbyRate || "").trim()) &&
              "bg-primary",
          }}
          heading={`${state.isNewStandby ? "Add" : "Edit"} Stand-by Details`}
          positiveActionLabel={
            <>
              {state.isNewStandby ? "Add" : "Edit"}
              {state.isFetchingStandby && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          content={
            <>
              <Autocomplete
                className=" w-100"
                size="small"
                value={state.standbyRateType.value}
                options={standByRateTypeList}
                disabled={isFetching}
                disableClearable={true}
                getOptionLabel={(option) => option?.value || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stand-by Rate Type"
                    variant="outlined"
                    helperText=" "
                  />
                )}
                onChange={(evt, value) => {
                  isStandbyFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    standbyRateType: value,
                  }));
                }}
              />
              <TextField
                className=" w-100"
                type="number"
                name="standbyRate"
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                label="Stand-by Rate"
                disabled={isFetching}
                variant="outlined"
                size="small"
                value={state.standbyRate}
                error={!!state.errors.standbyRate.trim()}
                helperText={state.errors.standbyRate}
                onWheel={(event) => event.target.blur()}
                onChange={handleFieldChange}
                onKeyDown={preventInputKeyCodes}
              />
            </>
          }
          onConfirm={async () => {
            const {
              standbyRateType,
              standbyRate,
              deepCopiedEntry,
              isNewStandby,
            } = state;

            const existingType = deepCopiedEntry?.StandByRates?.find(
                (type) =>
                type?.rate_type_id === standbyRateType?.id ||
                type.rateType.id === standbyRateType?.id
            );

            if (!isNewStandby) {
              const currentType = deepCopiedEntry?.StandByRates?.find(
                (type) => +type.id === state.addStandbyRate.id
              )?.rateType.id;

              if (currentType !== standbyRateType?.id && existingType) {
                return toast.error(
                  "The selected stand-by rate type already exists. Please choose a different one."
                );
              }
            }

            if (
              isNewStandby &&
              !!deepCopiedEntry?.StandByRates?.length &&
              existingType
            ) {
              return toast.error(
                "The selected stand-by rate type already exists. Please choose a different one."
              );
            }
            const { id, is_default } = state.addStandbyRate;
            isFieldChange = true;
            setState((prevState) => {
              const newId = `${Date.now()}${Math.floor(
                Math.random() * 100000
              )}`;
              const updatedStandByRates = updateStandByRates(
                prevState.deepCopiedEntry.StandByRates ?? [],
                {
                  id: isNewStandby ? newId : id,
                  rateType: standbyRateType,
                  rate_value: standbyRate,
                  is_default: isNewStandby
                    ? !prevState.deepCopiedEntry?.StandByRates?.length
                      ? true
                      : false
                    : is_default,
                }
              );

              const updatedEntry = {
                ...prevState.deepCopiedEntry,
                StandByRates: updatedStandByRates,
              };

              return {
                ...prevState,
                deepCopiedEntry: updatedEntry,
                addStandbyRate: false,
              };
            });
            gridHelper && gridHelper.toggle(true);
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              addStandbyRate: false,
            }))
          }
          isConfirmDisabled={
            !isStandbyFieldChange ||
            !state.standbyRate ||
            !state.standbyRateType ||
            !!(state.errors.standbyRate || "").trim() ||
            state.isFetchingStandby
          }
        />
      )}
      {state.deletingStandBy && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.deletingStandBy}
          contentText="Are you sure you want to delete?"
          onConfirm={() => {
            if (
              !!state.deepCopiedEntry?.StandByRates?.length &&
              state.deepCopiedEntry?.StandByRates.some(
                (type) =>
                  state.deletingStandBy === +type.id &&
                  type?.is_default === true
              )
            ) {
              return toast.error("Cannot delete records marked as default.");
            }
            isFieldChange = true;
            setState((prevState) => {
              const updatedStandByRates = removeStandByRate(
                prevState.deepCopiedEntry.StandByRates ?? [],
                state.deletingStandBy
              );

              const updatedEntry = {
                ...prevState.deepCopiedEntry,
                StandByRates: updatedStandByRates,
              };

              const isCreatedAtPresent =
                prevState.deepCopiedEntry.StandByRates?.some(
                  (rate) =>
                    +rate.id === state.deletingStandBy && rate.created_at
                );

              return {
                ...prevState,
                deepCopiedEntry: updatedEntry,
                standByIdsForDeletion: isCreatedAtPresent
                  ? [
                      ...(prevState.standByIdsForDeletion ?? []),
                      state.deletingStandBy,
                    ]
                  : prevState.standByIdsForDeletion,
                deletingStandBy: null,
              };
            });
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              deletingStandBy: null,
            }))
          }
          isConfirmDisabled={state.isDeletingStandby}
          positiveActionLabel={
            <>
              Delete
              {state.isDeletingStandby && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.isDefaultStandby && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.isDefaultStandby}
          contentText={`Are you sure you want to ${
            state.isDefaultStandby.status ? "set" : "unset"
          } it as default?`}
          onConfirm={() => {
            if (
              !!state.deepCopiedEntry?.StandByRates?.length &&
              state.deepCopiedEntry?.StandByRates.some(
                (type) =>
                  type?.is_default === true &&
                  state.isDefaultStandby.status === true
              )
            ) {
              return toast.error(
                "Please unset the current default entry before setting a new default."
              );
            }
            isFieldChange = true;
            setState((prevState) => {
              const updatedStandByRates = updateStandByRatesWithDefaultStatus(
                prevState.deepCopiedEntry.StandByRates ?? [],
                state.isDefaultStandby
              );

              const updatedEntry = {
                ...prevState.deepCopiedEntry,
                StandByRates: updatedStandByRates,
              };

              return {
                ...prevState,
                deepCopiedEntry: updatedEntry,
                isDefaultStandby: null,
              };
            });
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isDefaultStandby: null,
            }))
          }
          isConfirmDisabled={state.isDefault}
          positiveActionLabel={
            <>
              {state.isDefaultStandby.status
                ? "Set as Default"
                : "Unset as Default"}
              {state.isDefault && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default Form;
