/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  TablePagination,
  Typography,
} from "@material-ui/core";
import useStyles from "./style";
import clsx from "clsx";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ActionDialog, AppContext, Dropdown, noop } from "shared";
import ClearIcon from "@material-ui/icons/Clear";

import { getUTCDateString } from "utils";

import GridLoader from "shared/components/grid/loader";
import NoRecords from "assets/images/norecord.svg";
import { modeOfPaymentList } from "modules/shared/constants";

import CustomerSummary from "./summaryCard";
import PaymentSummaryGrid from "./summaryGrid";
import Service from "modules/invoice/service";
import { toast } from "react-toastify";
import GenerateInvoicePDF from "modules/invoice/generateInvoicePdf";
import EditIcon from "@material-ui/icons/Edit";
import UndoIcon from "@material-ui/icons/Undo";
import RecordPayment from "modules/invoice/recordPayment";

const defaultState = {
  selectedOption: null,
  open: false,
  selectedInvoices: [],
  anchorEl: null,
  anchorE2: null,
  entries: [],
  email: "",
  newEmail: [],
  errors: {
    email: " ",
  },
  isAddButtonEnabled: false,
  isFetchingData: false,
  showPaymentPopup: false,
  isInvoiceSending: false,
  showSendInvoicePopup: false,
  isInvoiceDeleteDialog: false,
  isInvoiceDeleteLoading: false,
  pdfViewerDialog: false,
  pdfUrl: "",
  isInvoiceLoading: false,
  isRevertLoading: false,
  showRevertInvoicePaymentPopup: false,
  stateList: [],
};

const PaymentSummary = ({
  rows = [],
  selectedPayment = null,
  isLoading = false,
  totalEntries = 0,
  pageSize = 100,
  pageNumber = 1,
  onPageSizeChange = noop,
  onPageNumberChange = noop,
  handleInvoiceFilter = noop,
  handleItemClick = noop,
  handleClose = noop,
  handleFetch = noop,
  handleUpdatePayment = noop,
  invoiceLoading = false,
  modeOfPaymentFilters = [],
  customerList,
}) => {
  const classes = useStyles();
  const { appData } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const selectedRef = useRef(null);

  const modeOfPaymentStatus = [
    { label: "All", value: null },
    ...modeOfPaymentList,
  ];

  const getInvoice = async (row, preview) => {
    setState((prevState) => ({ ...prevState, isInvoiceLoading: true }));

    let queryString = `?invoiceIds=${row?.invoice_id}`;

    const { data, error } = await Service.getAllInvoiceDetails(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, isInvoiceLoading: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const invoiceEntries = data?.invoiceDataArray?.[0];

    const blob = GenerateInvoicePDF({
      invoiceDetails: invoiceEntries?.lineItems,
      invoiceDescriptionDetails: invoiceEntries?.lineItemNotes,
      invoiceNo: invoiceEntries.invoiceDetails.serial_no,
      terms: invoiceEntries.invoiceDetails.terms,
      termsAndCondition: invoiceEntries.invoiceDetails.terms_and_condition,
      invoiceDate: invoiceEntries.invoiceDetails.invoice_date,
      fromDate: invoiceEntries.invoiceDetails.from_date,
      toDate: invoiceEntries.invoiceDetails.to_date,
      customerNotes: invoiceEntries.invoiceDetails.customer_notes,
      invoiceStatus: invoiceEntries.invoiceDetails.status,
      discount: invoiceEntries.invoiceDetails.discount_amt,
      usedCreditAmount: invoiceEntries.invoiceDetails?.used_credit_amt,
      pdxCompany:
        invoiceEntries.invoiceDetails.customer_branch?.pdxCompany?.value,
      balanceAmount: invoiceEntries?.invoiceDetails?.balance_amt,
      paidAmount: invoiceEntries?.invoiceDetails?.paid_amt,
      isOverdue: invoiceEntries?.invoiceDetails?.is_overdue,
      completePaymentDoneOn:
        invoiceEntries?.invoiceDetails?.complete_payment_done_on,
      isLatePayment:
        getUTCDateString(
          invoiceEntries?.invoiceDetails?.payment_completed_date
        ) > getUTCDateString(invoiceEntries?.invoiceDetails?.payment_due_date)
          ? true
          : false,
      billTo: {
        customerName: invoiceEntries.invoiceDetails.bill_to?.customerName,
        branch: invoiceEntries.invoiceDetails.bill_to?.branch,
        streetAddress: invoiceEntries.invoiceDetails.bill_to?.street_address,
        state: invoiceEntries.invoiceDetails.bill_to?.state,
        city: invoiceEntries.invoiceDetails.bill_to?.city,
        country: invoiceEntries.invoiceDetails.bill_to?.country,
        zipCode: invoiceEntries.invoiceDetails.bill_to?.zip_code,
        phone: invoiceEntries.invoiceDetails.bill_to?.phone,
        primary_contact:
          invoiceEntries.invoiceDetails.bill_to?.primary_contact ||
          invoiceEntries.invoiceDetails.bill_to?.contact,
      },
      isPreview: preview,
    });

    setState((prevState) => ({
      ...prevState,
      isInvoiceLoading: false,
      pdfUrl: blob,
      pdfViewerDialog: true,
    }));
  };

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedPayment, rows]);

  const handleChange = (option) => {
    handleInvoiceFilter(option);
    setState((prevState) => ({
      ...prevState,
      selectedOption: option,
    }));
  };

  const handlePageChange = (event, pageNumber) => {
    event.stopPropagation();
    onPageNumberChange(pageNumber + 1);
  };

  const handlePageSizeChange = (event) => {
    onPageSizeChange(event.target.value);
  };

  const getModeInfo = (mode) => {
    switch (mode) {
      case "check":
        return { text: "Check", className: classes.draft };
      case "cash":
        return { text: "Cash", className: classes.partiallyPaid };
      case "credit-card":
        return { text: "Credit Card", className: classes.approved };
      case "debit-card":
        return { text: "Debit Card", className: classes.open };
      case "bank-transfer":
        return { text: "Bank Transfer", className: classes.paid };
      case "bank-remittance":
        return { text: "Bank Remittance", className: classes.overdue };
      default:
        return { text: "", className: "" };
    }
  };

  const fetchEntries = useCallback(async (selectedPaymentGrid) => {
    setState((prevState) => ({ ...prevState, isFetchingData: true }));

    let queryString = `?filter[where][and][0][or][0][payment_number][neq]=${selectedPaymentGrid?.payment_number}&filter[where][and][1][or][0][invoice_no][eq]=${selectedPaymentGrid?.invoice_number}`;

    const { data, error } = await Service.getInvoicesPayments(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, isFetchingData: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingData: false,
      entries: data.rows || defaultState.entries,
    }));
  }, []);

  const handleViewerClose = () => {
    setState((prevState) => ({ ...prevState, pdfViewerDialog: false }));
  };

  const handleRevertPayment = useCallback(async (id) => {
    setState((prevState) => ({
      ...prevState,
      isRevertLoading: true,
    }));

    const { error } = await Service.revertInvoicePayment(id);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isRevertLoading: false,
        showRevertInvoicePaymentPopup: false,
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    setState((prevState) => ({
      ...prevState,
      isRevertLoading: false,
      showRevertInvoicePaymentPopup: false,
    }));
    toast.success("Payment cancelled successfully.");
    handleFetch();
  }, []);

  const handlePaymentClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      showPaymentPopup: close,
    }));
  };

  const fetchLocalResources = useCallback(async () => {
    const response = await Service.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => Intl.Collator().compare(a.name || "", b.name || "")
      ),
    }));
  }, []);

  useEffect(() => {
    fetchEntries(selectedPayment);
    fetchLocalResources();
  }, [selectedPayment]);

  return (
    <>
      <div
        className={clsx("d-flex", appData.isTabletView && classes.tabletView)}
      >
        <Paper
          elevation={2}
          className={clsx(
            "p-4 mt-10",
            classes.paperSpacing,
            appData.isTabletView && classes.tableWidth
          )}
        >
          <div
            className="d-flex f-align-center f-justify-between"
            style={{
              padding: "18px 0px",
            }}
          >
            <div className="d-flex flex-column f-align-left f-justify-center">
              {!!modeOfPaymentFilters.length ? (
                <Typography variant="h6" color="primary">
                  Mode of Payment(s)
                </Typography>
              ) : (
                <Dropdown
                  classes={{
                    paper: clsx(classes.tunePaper),
                    dropdownIcon: classes.dropdownIcon,
                    labelContent: classes.labelContent,
                  }}
                  disablePortal={false}
                  labelEllipses
                  hasEllipses
                  placement="bottom-end"
                  options={modeOfPaymentStatus}
                  labelClassName={classes.label}
                  label={`Mode of Payment ${
                    state.selectedOption ? state.selectedOption.label : "All"
                  }`}
                  onChange={handleChange}
                  renderOption={(option) => (
                    <div className={classes.dropdownItem}>{option.label}</div>
                  )}
                />
              )}
            </div>
          </div>
          <Divider />
          {isLoading ? (
            <GridLoader pageSize={8} columns={2} />
          ) : !!rows && rows.length > 0 ? (
            <>
              <List className={classes.list}>
                {rows.map((payment) => (
                  <Fragment key={payment.id}>
                    <div
                      className={clsx("d-flex f-align-center", {
                        [classes.listItemSelected]:
                          selectedPayment?.id === payment?.id,
                      })}
                      ref={
                        selectedPayment?.id === payment?.id ? selectedRef : null
                      }
                    >
                      <ListItem
                        button
                        className={classes.listItem}
                        onClick={() => {
                          handleItemClick(payment);
                        }}
                      >
                        <div className={classes.listItemText}>
                          <Typography variant="body1">
                            {payment.customer_branch?.customer?.name}
                          </Typography>

                          <div className="d-flex f-align-center">
                            <Typography
                              variant="body1"
                              style={{ color: "#775edc" }}
                            >
                              {payment.payment_number}
                            </Typography>

                            <div style={{ height: "20px", padding: "0px 5px" }}>
                              <Divider orientation="vertical" />
                            </div>
                            <Typography variant="body2" color="textSecondary">
                              {getUTCDateString(payment.date)}
                            </Typography>
                          </div>
                        </div>
                        <div className={classes.statusListItemText}>
                          <Typography
                            variant="body1"
                            className={classes.amount}
                          >
                            {payment.amount}
                          </Typography>
                          <div className="d-flex f-align-center">
                            {payment.status === "reverted" && (
                              <Chip
                                label="Cancelled"
                                variant="outlined"
                                size="small"
                                classes={{
                                  root: "border-error mr-2",
                                  label: "color-error",
                                }}
                              />
                            )}
                            <Typography
                              variant="body2"
                              className={`${classes.status} ${
                                getModeInfo(payment.mode).className
                              }`}
                            >
                              {getModeInfo(payment.mode).text}
                            </Typography>
                          </div>
                        </div>
                      </ListItem>
                    </div>
                    <Divider />
                  </Fragment>
                ))}
              </List>
              <Divider />
              <div
                className="d-flex f-align-center f-justify-center"
                style={{ margin: "2px 0px" }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={totalEntries}
                  rowsPerPage={pageSize}
                  colSpan={3}
                  page={pageNumber - 1}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePageSizeChange}
                  classes={{
                    select: "d-flex f-align-center f-justify-end",
                  }}
                />
              </div>
            </>
          ) : (
            <div
              className="d-flex f-justify-center"
              style={{ paddingTop: "30%" }}
            >
              <img
                className={classes.noRecordsImg}
                src={NoRecords}
                alt="noRecord"
              />
            </div>
          )}
        </Paper>

        <Paper
          elevation={2}
          className={clsx("p-4 mt-10 ml-2", classes.summaryPaperSpacing)}
        >
          {invoiceLoading ? (
            <div
              className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8"
              style={{ height: "100%" }}
            >
              <CircularProgress />
            </div>
          ) : rows.some((item) => item.id === selectedPayment?.id) ? (
            <>
              <div className="d-flex f-align-center f-justify-between">
                <Typography
                  style={{
                    padding: "18px 0px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  color="primary"
                >
                  {`Payment #${selectedPayment.payment_number}`}
                </Typography>
                <div className="d-flex f-align-center f-justify-between">
                  {selectedPayment &&
                    selectedPayment?.status !== "reverted" && (
                      <>
                        <Button
                          variant="outlined"
                          className="ml-2 mr-2"
                          classes={{
                            root: "border-error ml-4",
                            label: "color-error",
                          }}
                          startIcon={<UndoIcon color="error" />}
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              showRevertInvoicePaymentPopup: true,
                            }))
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="ml-2 mr-2"
                          startIcon={<EditIcon />}
                          onClick={() => handlePaymentClose(true)}
                        >
                          Edit
                        </Button>
                      </>
                    )}

                  <IconButton onClick={() => handleClose(false)}>
                    <ClearIcon />
                  </IconButton>
                </div>
              </div>

              <div className={classes.root}>
                <CustomerSummary
                  data={selectedPayment}
                  getInvoice={getInvoice}
                  isInvoiceLoading={state.isInvoiceLoading}
                  stateList={state.stateList}
                />
              </div>
              {state.isFetchingData ? (
                <div className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8">
                  <CircularProgress />
                </div>
              ) : state.entries.length > 0 ? (
                <PaymentSummaryGrid
                  isFetchingData={state.isFetchingData}
                  entries={state.entries}
                  handleUpdatePayment={handleUpdatePayment}
                />
              ) : (
                <Typography
                  variant="h6"
                  className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8"
                  style={{ marginTop: 195 }}
                  color="primary"
                >
                  No Other Payments Related to This Invoice.
                </Typography>
              )}
            </>
          ) : (
            <div
              className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8"
              style={{ height: "100%" }}
            >
              <Typography variant="h6" color="primary">
                Please Select the Payment to view.
              </Typography>
            </div>
          )}
        </Paper>
      </div>
      {state.pdfViewerDialog && (
        <Dialog
          onClose={handleViewerClose}
          aria-labelledby="customized-dialog-title"
          open={state.pdfViewerDialog}
          classes={{ paper: classes.paper }}
        >
          <DialogContent dividers>
            <iframe
              type="application/pdf"
              src={state.pdfUrl}
              title="Invoice PDF"
              width="1150px"
              height="700px"
              style={{ border: "none" }}
              // onLoad={handleIframeLoad}
            ></iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {state.showRevertInvoicePaymentPopup && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paper_Sizes,
          }}
          open={!!state.showRevertInvoicePaymentPopup}
          contentText="Once cancelled, this action cannot be undone. Are you sure you want to proceed?"
          onConfirm={() => handleRevertPayment(selectedPayment?.id)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              showRevertInvoicePaymentPopup: false,
            }))
          }
          isConfirmDisabled={state.isRevertLoading}
          positiveActionLabel={
            <>
              Confirm
              {state.isRevertLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}

      <RecordPayment
        handleClose={handlePaymentClose}
        open={state.showPaymentPopup}
        customerList={customerList}
        entry={selectedPayment}
        handleFetch={handleFetch}
        getInvoice={getInvoice}
        isEdit={true}
        isPaymentSummary={true}
      />
    </>
  );
};

export default PaymentSummary;
