/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { noop } from "shared";
import { getDateString, getUTCDateString, getFormattedTime } from "utils";
import { Datepicker, Dialog, Grid } from "shared/components";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  RATE_TYPES,
  AGREEMENT_DATA,
  DOCUMENT_DATA,
} from "modules/shared/constants";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import useStyles from "./style";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import CollapsibleGrid from "modules/contractors/collapsibleGrid";

const defaultState = {
  icid: "",
  first_name: "",
  last_name: "",
  ic_company_name: "",
  selectedAgreementRows: [],
  selectedDocsRows: [],
  documentType: "",
  tabIndex: 1,
  agreementsEntries: [],
  documentsEntries: [],
  documentStatus: [],
  agreementStatus: [],
  expiration: null,
  submitDate: null,
  fileUpdate: "",
  isFileRetentionMarked: false,
  fileRetentionStatus: null,
  isWatchlistMarked: false,
  city: null,
  state: null,
  phone: "",
  email: "",
  contractor_type: "",
  pdx_company: null,
  settlement_company: null,
  customer: null,
  default_vehicle_type: "",
  stand_by_rate_type: RATE_TYPES[0],
  stand_by_rate: "",
  kamUser: null,
  ein: "",
  ssn: "",
  date_of_birth: "",
  notes: "",
  add_doc_notes: "",
  fileUpdated: {},
  updatedAt: "",
  isLoading: false,
  isAutoGeneratedICID: false,
};

const IcForm = ({
  open = false,
  isTabletView = false,
  isFetching = false,
  entry = null,
  usersList = [],
  onClose = noop,
}) => {
  const [state, setState] = useState(defaultState);
  const classes = useStyles({ isTabletView });

  useEffect(() => {
    if (entry) {
      entry.icDocsStatus =
        !!entry.icDocsStatus &&
        entry.icDocsStatus.map((row) => {
          if (row.type === "Agreement") {
            const contractorType = entry?.contractorType?.value;

            const shouldAssignNull =
              (contractorType === "Independent" &&
                (row.name === "SS-4" ||
                  row.name === "Paid Under Company (PUC)" ||
                  row.name === "Subcontractor Undertaking Agreement (SUA)")) ||
              (contractorType === "Master" &&
                (row.name === "Paid Under Company (PUC)" ||
                  row.name === "Subcontractor Undertaking Agreement (SUA)")) ||
              (contractorType === "Subcontractor" && row.name === "SS-4") ||
              contractorType === "Employee" ||
              contractorType === "Lumper" ||
              contractorType === "SLC";

            if (shouldAssignNull) {
              return {
                ...row,
                is_archived: true,
              };
            }
          }

          return row;
        });
      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState?.id,
        icid: entry.icid || defaultState.icid,
        first_name: entry.first_name || defaultState.first_name,
        last_name: entry.last_name || defaultState.last_name,
        ic_company_name: entry.ic_company_name || defaultState.ic_company_name,
        city: entry.city || defaultState.city,
        state: entry.state || defaultState.state,
        phone: entry.phone || defaultState.phone,
        email: entry.email || defaultState.email,
        contractor_type: entry.contractorType || defaultState.contractor_type,
        pdx_company: entry.pdxCompany || defaultState.pdx_company,
        settlement_company:
          entry.settlementCompany || defaultState.settlement_company,
        customer: entry.customer || defaultState.customer,
        default_vehicle_type:
          entry.defaultVehicleType || defaultState.default_vehicle_type,
        stand_by_rate_type:
          entry.stand_by_rate_type || defaultState.stand_by_rate_type,
        stand_by_rate: entry.stand_by_rate || defaultState.stand_by_rate,
        isAutoGeneratedICID:
          entry.isAutoGeneratedICID || defaultState.isAutoGeneratedICID,
        kamUser: entry.kamUser || defaultState.kamUser,
        ein: entry.ein || defaultState.ein,
        ssn: entry.ssn || defaultState.ssn,
        date_of_birth: entry.date_of_birth
          ? getDateString(entry.date_of_birth)
          : defaultState.date_of_birth,
        notes: entry.notes || defaultState.notes,
        fileUpdated: entry.updatedBy || defaultState.fileUpdated,
        updatedAt:
          `${getUTCDateString(entry.updated_at)}, ${getFormattedTime(
            new Date(entry.updated_at)
          )}` || defaultState.updatedAt,
        add_doc_notes:
          (!!entry?.add_doc_notes && entry?.add_doc_notes) ||
          defaultState.add_doc_notes,
        agreementStatus:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.agreementStatus,
        documentStatus:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.documentStatus,
        agreementsEntries:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.agreementsEntries,
        documentsEntries:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.documentsEntries,
        fileRetentionStatus:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.find(
              (item) =>
                item.type === "File Retention Status" &&
                item.is_archived === false
            )?.lookupStatus) ||
          defaultState.fileRetentionStatus,
        isFileRetentionMarked:
          (!!entry.icDocsStatus &&
            !!entry.icDocsStatus.find(
              (item) =>
                item.type === "File Retention Status" &&
                item.is_archived === false
            )) ||
          defaultState.isFileRetentionMarked,
        isWatchlistMarked:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus?.some(
              (item) =>
                item.type === "Ic Watchlist" && item.is_archived === false
            )) ||
          defaultState.isWatchlistMarked,
        submitDate:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Agreement" && item.is_archived === false
            )) ||
          defaultState.submitDate,
        expiration:
          (!!entry.icDocsStatus &&
            entry.icDocsStatus.filter(
              (item) => item.type === "Document" && item.is_archived === false
            )) ||
          defaultState.expiration,
      }));
    }
  }, [entry]);

  const updatedICAgreement = [];
  const updatedICDocument = [];

  for (const agreement of AGREEMENT_DATA) {
    const matchingItem =
      !!entry &&
      isFetching &&
      !!entry?.icDocsStatus &&
      entry?.icDocsStatus?.find((icDoc) => icDoc.name === agreement);
    if (matchingItem) {
      updatedICAgreement.push(matchingItem);
    } else {
      const newObject = {
        created_at: null,
        created_by: null,
        dated: null,
        expire_date: null,
        ic_id: null,
        id: null,
        lookupStatus: null,
        name: agreement,
        status: null,
        type: "Agreement",
        updated_at: null,
        updated_by: null,
        is_archived: true,
      };
      updatedICAgreement.push(newObject);
    }
  }

  updatedICAgreement.sort((a, b) => {
    const indexA = AGREEMENT_DATA.indexOf(a.name);
    const indexB = AGREEMENT_DATA.indexOf(b.name);
    return indexA - indexB;
  });

  for (const document of DOCUMENT_DATA) {
    const matchingItem =
      !!entry &&
      isFetching &&
      !!entry?.icDocsStatus &&
      entry?.icDocsStatus?.find((icDoc) => icDoc.name === document);
    if (matchingItem) {
      updatedICDocument.push(matchingItem);
    } else {
      const newObject = {
        created_at: null,
        created_by: null,
        dated: null,
        expire_date: null,
        ic_id: null,
        id: null,
        lookupStatus: null,
        name: document,
        status: null,
        type: "Document",
        updated_at: null,
        updated_by: null,
        is_archived: true,
      };
      updatedICDocument.push(newObject);
    }
  }

  updatedICDocument.sort((a, b) => {
    const indexA = DOCUMENT_DATA.indexOf(a.name);
    const indexB = DOCUMENT_DATA.indexOf(b.name);
    return indexA - indexB;
  });
  const userData =
    !!entry && entry.icDocs?.length > 0
      ? entry.icDocs[0].updated_by === null
        ? usersList.find((data) => data.id === entry.icDocs[0].created_by)
        : usersList.find((data) => data.id === entry.icDocs[0].updated_by)
      : null;

  const documentUpdatedByuser = !!userData
    ? entry.icDocs[0].updated_by === null
      ? `${getUTCDateString(entry.icDocs[0].created_at)}, ${getFormattedTime(
          new Date(entry.icDocs[0].created_at)
        )}, ${userData.first_name} ${userData.last_name} (${userData.username})`
      : `${getUTCDateString(entry.icDocs[0].updated_at)}, ${getFormattedTime(
          new Date(entry.icDocs[0].updated_at)
        )}, ${userData.first_name} ${userData.last_name} (${userData.username})`
    : null;

  const renderDatepicker = (dataArray, label, nameProperty, row) => {
    const date =
      Array.isArray(state[dataArray]) && state[dataArray]?.length > 0
        ? state[dataArray].find((item) => item.name === row.name)
        : null;

    return (
      <Datepicker
        disabled
        mask
        label={label}
        selected={
          new Date().toString().includes("India")
            ? getDateString(date?.[nameProperty])
            : getUTCDateString(date?.[nameProperty])
        }
        classes={{
          input: {
            root:
              (entry?.contractorType?.value === "Independent" &&
                (row.name === "SS-4" ||
                  row.name === "Paid Under Company (PUC)" ||
                  row.name === "Subcontractor Undertaking Agreement (SUA)")) ||
              (entry?.contractorType?.value === "Master" &&
                (row.name === "Paid Under Company (PUC)" ||
                  row.name === "Subcontractor Undertaking Agreement (SUA)")) ||
              (entry?.contractorType?.value === "Subcontractor" &&
                row.name === "SS-4") ||
              entry?.contractorType?.value === "Employee" ||
              entry?.contractorType?.value === "Lumper" ||
              entry?.contractorType?.value === "SLC"
                ? classes.dateWrapper
                : classes.datepickerWrapper,
          },
        }}
      />
    );
  };

  const renderDocumentDatepicker = (dataArray, label, nameProperty, row) => {
    const date =
      Array.isArray(state[dataArray]) && state[dataArray]?.length > 0
        ? state[dataArray].find((item) => item.name === row.name)
        : null;

    return (
      <Datepicker
        disabled
        mask
        label={label}
        selected={
          new Date().toString().includes("India")
            ? getDateString(date?.[nameProperty])
            : getUTCDateString(date?.[nameProperty])
        }
        classes={{
          input: {
            root: classes.datepickerWrapper,
          },
        }}
      />
    );
  };

  const renderStatusColumn = (dataArray, row) => {
    const statusType =
      Array.isArray(state[dataArray]) && state[dataArray]?.length > 0
        ? state[dataArray].find((item) => item.name === row.name)
        : null;

    const label = statusType ? statusType?.lookupStatus?.value : "";

    return (
      <Tooltip title={label ?? ""} placement="top-start">
        <Typography variant="body2" noWrap>
          {label || "-"}
        </Typography>
      </Tooltip>
    );
  };

  const renderFileUpdatedColumn = (row, icDocsStatus) => {
    const fileUpdate =
      !!entry &&
      !!icDocsStatus &&
      icDocsStatus.find((icDoc) => icDoc.name === row.name);
    if (fileUpdate === undefined) {
      return (
        <Typography variant="body2" noWrap>
          {"-"}
        </Typography>
      );
    }
    const userData =
      fileUpdate?.updated_by === null
        ? usersList.find((data) => data.id === fileUpdate.created_by)
        : usersList.find((data) => data.id === fileUpdate.updated_by);

    const fileUpdateDetails = `${getUTCDateString(
      fileUpdate?.updated_by === null
        ? fileUpdate?.created_at
        : fileUpdate?.updated_at
    )}, ${getFormattedTime(
      new Date(
        fileUpdate?.updated_by === null
          ? fileUpdate?.created_at
          : fileUpdate?.updated_at
      )
    )}, ${userData?.first_name} ${userData?.last_name} (${userData?.username})`;

    return (
      <Tooltip title={fileUpdateDetails} placement="top-start">
        <Typography variant="body2" noWrap>
          {fileUpdateDetails}
        </Typography>
      </Tooltip>
    );
  };

  const UploadedDocumentstColumnConfig = useMemo(
    () => [
      {
        id: "documentType",
        label: "Document Type",
        field: "documentType",
        headerClassName: classes.documentType,
        render: (row) => (
          <Tooltip title={row.doc_type || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.doc_type || "-"}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: "documentName",
        label: "Document Name",
        field: "documentName",
        headerClassName: classes.documentType,
        render: (row) => (
          <Tooltip title={row.doc_name || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.doc_name || "-"}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: "dateOfUpload",
        label: "Date Of Upload",
        field: "dateOfUpload",
        render: (row) => (
          <Tooltip
            title={getDateString(row.created_at) || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {getDateString(row.created_at) || "-"}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: "downloadDocument",
        label: "Download Document",
        field: "downloadDocument",
        render: (row) => (
          <a href={row.doc_path} target="_blank" rel="noopener noreferrer">
            <Tooltip title="Download Document" placement="top-start">
              <CloudDownloadIcon color="primary" />
            </Tooltip>
          </a>
        ),
      },
    ],
    []
  );

  const agreementColumnConfig = useMemo(() => {
    const commonColumns = [
      {
        id: "agreement",
        label: "Agreement",
        headerClassName: classes.agreement,
        field: "agreement",
        render: (row) => {
          return (
            <FormControlLabel
              style={{ cursor: "default" }}
              control={
                <Checkbox
                  disabled={
                    (entry?.contractorType?.value === "Independent" &&
                      (row.name === "SS-4" ||
                        row.name === "Paid Under Company (PUC)" ||
                        row.name ===
                          "Subcontractor Undertaking Agreement (SUA)")) ||
                    (entry?.contractorType?.value === "Master" &&
                      (row.name === "Paid Under Company (PUC)" ||
                        row.name ===
                          "Subcontractor Undertaking Agreement (SUA)")) ||
                    (entry?.contractorType?.value === "Subcontractor" &&
                      row.name === "SS-4") ||
                    entry?.contractorType?.value === "Employee" ||
                    entry?.contractorType?.value === "Lumper" ||
                    entry?.contractorType?.value === "SLC"
                  }
                  size="small"
                  className="no-events"
                  checked={state.agreementsEntries.some(
                    (item) => item.name === row.name
                  )}
                  color="primary"
                />
              }
              label={<Typography variant="body2">{row.name}</Typography>}
            />
          );
        },
      },
      {
        id: "date",
        label: "Date",
        headerClassName: classes.datepicker,
        field: "date",
        render: (row) => renderDatepicker("submitDate", "Date", "dated", row),
      },
      {
        headerClassName: classes.status,
        id: "status",
        label: "Status",
        field: "status",
        render: (row) => renderStatusColumn("agreementStatus", row),
      },
    ];

    const additionalColumns = [
      {
        label: "File Updated",
        headerClassName: classes.agreement,
        noResize: true,
        render: (row) => renderFileUpdatedColumn(row, entry?.icDocsStatus),
      },
    ];

    return [...commonColumns, ...additionalColumns];
  }, [
    entry,
    state.agreementsEntries,
    state.submitDate,
    state.agreementStatus,
    classes.datepicker,
    classes.agreement,
    state.selectedAgreementRows,
  ]);

  const documentColumnConfig = useMemo(() => {
    const commonColumns = [
      {
        id: "document",
        label: "Document",
        headerClassName: classes.agreement,
        field: "document",
        render: (row) => {
          return (
            <FormControlLabel
              style={{ cursor: "default" }}
              control={
                <Checkbox
                  size="small"
                  className="no-events"
                  checked={state.documentsEntries.some(
                    (item) => item.name === row.name
                  )}
                  color="primary"
                />
              }
              label={<Typography variant="body2">{row.name}</Typography>}
            />
          );
        },
      },
      {
        id: "date",
        label: "Expiration",
        headerClassName: classes.datepicker,
        field: "date",
        render: (row) =>
          renderDocumentDatepicker(
            "expiration",
            "Expiration",
            "expire_date",
            row
          ),
      },
      {
        id: "status",
        headerClassName: classes.status,
        label: "Status",
        field: "status",
        render: (row) => renderStatusColumn("documentStatus", row),
      },
    ];

    const additionalColumns = [
      {
        label: "File Updated",
        headerClassName: classes.agreement,
        noResize: true,
        render: (row) => renderFileUpdatedColumn(row, entry?.icDocsStatus),
      },
    ];

    return [...commonColumns, ...additionalColumns];
  }, [
    entry,
    state.expiration,
    state.documentStatus,
    state.documentsEntries,
    classes.datepicker,
    classes.agreement,
    state.selectedDocsRows,
  ]);

  const fileRetentionColumnConfig = useMemo(() => {
    const commonColumns = [
      {
        id: "fileRetention",
        label: "File Retention",
        field: "fileRetention",
        render: (row) => {
          return (
            <FormControlLabel
              style={{ cursor: "default" }}
              control={
                <Checkbox
                  size="small"
                  style={{ marginLeft: 20 }}
                  className="no-events"
                  checked={state.isFileRetentionMarked}
                  color="primary"
                />
              }
            />
          );
        },
      },
      {
        id: "fileRetentionStatus",
        label: "File Retention Status",
        headerClassName: classes.fileRetentionStatus,
        field: "fileRetentionStatus",
        render: (row) => {
          return (
            <Tooltip
              title={state.fileRetentionStatus?.value ?? ""}
              placement="top-start"
            >
              <Typography variant="body2" noWrap>
                {state.fileRetentionStatus?.value || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
    ];

    const additionalColumns = [
      {
        label: "File Updated",
        noResize: true,
        render: (row) => {
          return renderFileUpdatedColumn(row, entry?.icDocsStatus);
        },
      },
    ];

    return [...commonColumns, ...additionalColumns];
  }, [
    entry,
    classes.agreement,
    state.isFileRetentionMarked,
    state.fileRetentionStatus,
  ]);

  const watchlistColumnConfig = useMemo(() => {
    const commonColumns = [
      {
        id: "Watchlist",
        label: "Watchlist",
        field: "Watchlist",
        headerClassName: classes.Watchlist,
        render: (row) => {
          return (
            <FormControlLabel
              style={{ cursor: "default" }}
              control={
                <Checkbox
                  size="small"
                  style={{ marginLeft: 20 }}
                  className="no-events"
                  checked={state.isWatchlistMarked}
                  color="primary"
                />
              }
            />
          );
        },
      },
    ];

    const additionalColumns = [
      {
        label: "File Updated",
        noResize: true,
        render: (row) => {
          return renderFileUpdatedColumn(row, entry?.icDocsStatus);
        },
      },
    ];

    return [...commonColumns, ...additionalColumns];
  }, [entry, classes.agreement, state.isWatchlistMarked]);

  const handleTabChange = useCallback(
    (event, tabIndex) => {
      setState((prevState) => ({
        ...prevState,
        tabIndex,
      }));
    },
    [entry]
  );

  const TAB_LIST = [
    { id: 1, label: "General" },
    { id: 2, label: "Documentation" },
    { id: 3, label: "View Documents" },
  ];

  const columnConfig = [
    [
      {
        headerClassName: classes.colEdit,
        id: "col-edit",
        noResize: true,
      },
      {
        id: "standbyRateType",
        label: "Stand-by Rate Type",
        fieldName: "standbyRateType",
      },
      {
        id: "standbyRate",
        label: "Stand-by Rate",
        fieldName: "standbyRate",
      },
    ],
    [
      {
        headerClassName: classes.colEdit,
        id: "col-edit",
        noResize: true,
      },
      {
        id: "standbyRateTypes",
        fieldName: "StandByRates",
        render: (row) => {
          return (
            <Typography variant="body2" noWrap>
              {row?.rateType?.value || "-"}
            </Typography>
          );
        },
      },
      {
        id: "standbyRates",
        fieldName: "StandByRates",
        render: (row) => {
          return (
            <Typography variant="body2" noWrap className="ml-10">
              {row?.rate_value || "-"}
            </Typography>
          );
        },
      },
    ],
  ];

  return (
    <>
      <Dialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
        onClose={onClose}
      >
        <Dialog.Title hasClose>
          {isFetching ? (
            <Skeleton variant="rect" width="50%" height="28px" />
          ) : (
            `View IC Detail - ${state.first_name} ${state.last_name} (${state.icid})`
          )}
        </Dialog.Title>
        <Dialog.Content>
          <Tabs
            value={state.tabIndex}
            onChange={handleTabChange}
            indicatorColor={"#eeeeee"}
            textColor={"primary"}
            className={classes.tabs}
          >
            {TAB_LIST.map((tab) => (
              <Tab
                value={tab.id}
                disabled={isFetching || state.isLoading}
                key={tab.id}
                name={tab.id}
                className={classes.tab}
                style={{
                  borderTop:
                    tab.id === state.tabIndex ? "2px solid #80808045" : "none",
                  borderLeft:
                    tab.id === state.tabIndex ? "2px solid #80808045" : "none",
                  borderRight:
                    tab.id === state.tabIndex ? "2px solid #80808045" : "none",
                  background: tab.id === state.tabIndex ? "white" : "#ececec",
                }}
                label={
                  <Typography
                    variant="subtitle2"
                    color={
                      tab.id === state.tabIndex ? "primary.dark" : "grey.300"
                    }
                  >
                    {tab.label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
          {isFetching ? (
            <Box className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8">
              <CircularProgress />
            </Box>
          ) : (
            state.tabIndex === 1 && (
              <Paper className={classes.general}>
                <div className="d-flex f-align-center f-justify-between mb-1">
                  <TextField
                    fullWidth
                    name="icid"
                    label="ICID"
                    variant="outlined"
                    size="small"
                    value={state.icid}
                    disabled
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                  <TextField
                    fullWidth={true}
                    disabled={isFetching}
                    className="ml-2 w-50 mb-6 pl-1"
                    label="File Updated"
                    variant="outlined"
                    size="small"
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                    value={`${state.updatedAt}, ${state.fileUpdated.first_name} ${state.fileUpdated.last_name} (${state.fileUpdated.username} - ${state.fileUpdated.role})`}
                  />
                </div>
                <div className="d-flex f-align-center mb-1">
                  <TextField
                    className="mr-2 w-50"
                    required
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    disabled
                    size="small"
                    value={state.first_name}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                  <TextField
                    className="ml-2 w-50"
                    name="last_name"
                    label="Last Name"
                    variant="outlined"
                    disabled
                    size="small"
                    value={state.last_name}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                </div>
                <TextField
                  fullWidth
                  className="mb-1"
                  name="ic_company_name"
                  label="IC Company Name"
                  disabled
                  variant="outlined"
                  size="small"
                  value={state.ic_company_name}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                />
                <div className="mb-1 d-flex f-align-center">
                  <TextField
                    fullWidth
                    className="w-50 mr-2"
                    label="State"
                    disabled
                    variant="outlined"
                    size="small"
                    value={state.state?.name}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                  <TextField
                    fullWidth
                    className="w-50 ml-2"
                    label="City"
                    disabled
                    variant="outlined"
                    size="small"
                    value={state?.city}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                </div>
                <div className="d-flex f-align-center mb-1">
                  <TextField
                    className="mr-2 w-50"
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    disabled
                    size="small"
                    value={state.phone}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                  <TextField
                    className="ml-2 w-50"
                    name="email"
                    label="Email"
                    variant="outlined"
                    size="small"
                    disabled
                    value={state.email}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                </div>
                <TextField
                  fullWidth
                  className="mb-1"
                  label="Contractor Type"
                  disabled
                  variant="outlined"
                  size="small"
                  value={state.contractor_type?.value}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                />

                <div className="d-flex f-align-center mb-1">
                  <TextField
                    fullWidth
                    className="mr-2 w-50"
                    label="PDX Co"
                    disabled
                    variant="outlined"
                    size="small"
                    value={state.pdx_company?.value}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />{" "}
                  <TextField
                    fullWidth
                    className="ml-2 w-50"
                    label="Settlement Co"
                    disabled
                    variant="outlined"
                    size="small"
                    value={state.settlement_company?.value}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                </div>
                <div className="d-flex f-align-center mb-1">
                  <TextField
                    fullWidth
                    className="mr-2 w-50"
                    label="Default Customer"
                    disabled
                    variant="outlined"
                    size="small"
                    value={state.customer?.name}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />{" "}
                  <TextField
                    fullWidth
                    className="ml-2 w-50"
                    label="Default Vehicle Type"
                    disabled
                    variant="outlined"
                    size="small"
                    value={state.default_vehicle_type?.value}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />
                </div>
                {state.contractor_type?.value === "Stand-by" && (
                  <div className="d-flex f-align-center mb-6">
                    <CollapsibleGrid
                      columns={columnConfig}
                      rows={entry}
                      childIdentifier={["StandByRates"]}
                      classes={{
                        gridActions: "f-justify-end",
                      }}
                    />
                  </div>
                )}
                <TextField
                  fullWidth
                  className="mb-1"
                  label="Default KAM"
                  disabled
                  variant="outlined"
                  size="small"
                  value={`${state.kamUser?.first_name || ""} ${
                    state.kamUser?.last_name || ""
                  }-${state.kamUser?.email || ""}${
                    state.kamUser?.username
                      ? `-(${state.kamUser?.username})`
                      : ""
                  }`}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                />
                <div className="d-flex f-align-center mb-1">
                  <TextField
                    className="mr-2 w-50"
                    name="ein"
                    label="EIN"
                    variant="outlined"
                    size="small"
                    disabled
                    value={state.ein}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                  />

                  <Box className="ml-2 w-50 p-relative">
                    <TextField
                      disabled
                      name="ssn"
                      label="SSN"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={state.ssn}
                      helperText=" "
                      InputProps={{
                        classes: { disabled: classes.disabledText },
                      }}
                    />
                  </Box>
                </div>
                <Datepicker
                  mask
                  disabled
                  className="mb-6"
                  label="Date Of Birth"
                  selected={state.date_of_birth}
                  classes={{
                    input: {
                      root: classes.datepickerWrapper,
                    },
                  }}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="notes"
                  label="Notes"
                  disabled
                  variant="outlined"
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  size="small"
                  value={state.notes}
                  helperText=" "
                />
              </Paper>
            )
          )}
          {state.tabIndex === 2 && (
            <div className={classes.document}>
              <Paper className={classes.agreementPaper}>
                <Grid
                  columns={agreementColumnConfig}
                  rows={updatedICAgreement.map((agreement) => {
                    const isAgreementUnchecked =
                      !!entry &&
                      !!entry.icDocsStatus &&
                      entry.icDocsStatus
                        .filter((data) => data.type === "Agreement")
                        .find((item) => item.name === agreement.name);
                    const shouldHighlightUncheckedField =
                      isAgreementUnchecked?.is_archived === true &&
                      !state.agreementsEntries.some(
                        (item) => item.name === isAgreementUnchecked.name
                      );

                    return {
                      ...agreement,
                      className: clsx({
                        [classes.highlightUncheckedField]:
                          shouldHighlightUncheckedField,
                      }),
                    };
                  })}
                  actionBarConfig={null}
                  hasSelection={false}
                  isLoading={isFetching}
                  hasPagination={false}
                  classes={{
                    container: classes.addressGridridPaper,
                  }}
                />
              </Paper>
              <Paper className={classes.docsPaper}>
                <Grid
                  columns={documentColumnConfig}
                  rows={updatedICDocument.map((documents) => {
                    const isDocsUnchecked =
                      !!entry &&
                      !!entry.icDocsStatus &&
                      entry.icDocsStatus
                        .filter((data) => data.type === "Document")
                        .find((item) => item.name === documents.name);
                    const shouldHighlightUncheckedField =
                      isDocsUnchecked?.is_archived === true &&
                      !state.documentsEntries?.some(
                        (item) => item.name === isDocsUnchecked.name
                      );

                    return {
                      ...documents,
                      className: clsx({
                        [classes.highlightUncheckedField]:
                          shouldHighlightUncheckedField,
                      }),
                    };
                  })}
                  actionBarConfig={null}
                  hasSelection={false}
                  isLoading={isFetching}
                  hasPagination={false}
                  classes={{
                    container: classes.addressGridridPaper,
                  }}
                />
              </Paper>
              <Paper className={classes.docsPaper}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="add_doc_notes"
                  label="Additional Notes"
                  variant="outlined"
                  size="small"
                  value={state.add_doc_notes}
                  disabled
                  helperText={" "}
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                />
              </Paper>
              <Paper className={classes.paperGrid}>
                <Grid
                  disabled
                  columns={fileRetentionColumnConfig}
                  rows={["File Retention Status"].map((name) => {
                    const shouldHighlightUncheckedField =
                      !!entry &&
                      !!entry.icDocsStatus &&
                      entry.icDocsStatus.some(
                        (data) =>
                          data.type === "File Retention Status" &&
                          !!data?.updated_at &&
                          data.is_archived === true
                      ) &&
                      state.isFileRetentionMarked === false;

                    return {
                      name,
                      className: clsx({
                        [classes.highlightUncheckedField]:
                          shouldHighlightUncheckedField,
                      }),
                    };
                  })}
                  actionBarConfig={null}
                  isLoading={isFetching}
                  hasSelection={false}
                  hasPagination={false}
                  classes={{
                    container: classes.watchlistGridridPaper,
                  }}
                />
              </Paper>
              <Paper className={classes.paperGrid}>
                <Grid
                  columns={watchlistColumnConfig}
                  rows={["Ic Watchlist"].map((name) => ({
                    name,
                    className: clsx({
                      [classes.highlightUncheckedField]:
                        !!entry?.icDocsStatus &&
                        state.isWatchlistMarked === false &&
                        entry.icDocsStatus?.some(
                          (item) =>
                            item.type === "Ic Watchlist" &&
                            item.is_archived === true &&
                            !!item.updated_at
                        ),
                    }),
                  }))}
                  actionBarConfig={null}
                  isLoading={isFetching}
                  hasSelection={false}
                  hasPagination={false}
                  classes={{
                    container: classes.watchlistGridridPaper,
                  }}
                />
              </Paper>
            </div>
          )}
          {state.tabIndex === 3 && (
            <div className={classes.document}>
              {!!entry && entry.icDocs?.length > 0 ? (
                <>
                  <Paper className={classes.docsPaper}>
                    <Typography variant="h6" color="primary" className="pb-2">
                      Uploaded Documents
                    </Typography>
                    <Grid
                      columns={UploadedDocumentstColumnConfig}
                      rows={entry.icDocs.map((entry) => ({
                        ...entry,
                      }))}
                      actionBarConfig={null}
                      isLoading={isFetching}
                      hasSelection={false}
                      hasPagination={false}
                      classes={{
                        container: classes.watchlistGridridPaper,
                      }}
                    />
                  </Paper>
                  <Paper className={classes.paperGrid}>
                    <div className="d-flex f-align-center f-wrap">
                      <Typography variant="h6" color="primary">
                        File Updated:
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.documentUpdatedByuser}
                      >
                        {documentUpdatedByuser}
                      </Typography>
                    </div>
                  </Paper>
                </>
              ) : (
                <Paper className={classes.uploadDocument}>
                  <div className={classes.circularProgress}>
                    {isFetching ? (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    ) : (
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        No documents are uploaded.
                      </Typography>
                    )}
                  </div>
                </Paper>
              )}
            </div>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <div style={{ padding: "8px 10px 8px 16px" }}>
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default IcForm;
