/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { CircularProgress, TextField, Button } from "@material-ui/core";
import { toast } from "react-toastify";
import useStyles from "./style";
import { Dialog, noop } from "shared";
import Service from "modules/invoice/service";

const defaultState = {
  isLoading: false,
  errors: {},
  payload: {},
};

const UdateInvoiceItemDescriptionDialog = ({
  selectedRow = {},
  open = noop,
  onClose = noop,
  handleGetInvoice = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({ ...defaultState });

  const handleInputChange = useCallback((description, value) => {
    let maxLength = 25;
    if (description.includes("Fuel Surcharge")) {
      maxLength = 46;
    } else if (description.includes("Extra Miles")) {
      maxLength = 50;
    } else if (description.includes("Tolls")) {
      maxLength = 55;
    }

    const errorMessage =
      value.length > maxLength ? `Max length is ${maxLength}` : "";

    setState((prevState) => {
      const updatedPayload = { ...prevState.payload };

      switch (true) {
        case description.includes("Fuel Surcharge"):
          updatedPayload.fuel_surcharge_description = value;
          break;
        case description.includes("Extra Miles"):
          updatedPayload.extra_miles_description = value;
          break;
        case description.includes("Tolls"):
          updatedPayload.tolls_description = value;
          break;
        default:
          updatedPayload.main_description = value;
          break;
      }

      return {
        ...prevState,
        payload: updatedPayload,
        errors: {
          ...prevState.errors,
          [description]: errorMessage,
        },
      };
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    if (Object.values(state.errors).some(Boolean)) return;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    const { payload } = state;
    const data = { id: selectedRow.id, description_names: payload };

    try {
      const { error } = await Service.updateLineItemDescription(data);

      if (error) {
        toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      } else {
        toast.success("Invoice Item description updated successfully.");
        onClose();
        handleGetInvoice();
      }
    } catch (err) {
      toast.error("An unexpected error occurred.");
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [state.errors, state.payload, selectedRow.id, onClose, handleGetInvoice]);

  const renderConditionalTextInput = useCallback(
    (row) => {
      const descriptions = row.description.split("\n");

      return descriptions.map((desc, index) => {
        const cleanedDesc = desc.replace(/\s*\(.*?\)\s*/g, "");
        const bracketedValue = desc.match(/\((.*?)\)/);
        const inputLabel = bracketedValue ? bracketedValue[1] : "";
        const errorMessage = state.errors[desc] || " ";
        const showError = errorMessage.trim() !== "";
        const mainDescription =
          row.row?.description_names?.main_description?.trim();

        const isMainDescriptionPresent = mainDescription !== undefined;
        const isFirstIndex = index === 0;

        const label =
          isMainDescriptionPresent && isFirstIndex
            ? `${row.row.route_name} - ${row.row.ic_name}`
            : cleanedDesc;

        let value = inputLabel;

        if (isMainDescriptionPresent && isFirstIndex) {
          if (mainDescription !== desc?.trim()) {
            value = mainDescription;
          } else {
            value = "";
          }
        }

        return (
          <div key={index} style={{ marginBottom: 10, width: 500 }}>
            <TextField
              className="mr-4 mt-4"
              label={label}
              defaultValue={value}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleInputChange(desc, e.target.value)}
              helperText={showError ? errorMessage : " "}
              error={showError}
            />
          </div>
        );
      });
    },
    [state.errors, handleInputChange]
  );

  const isSaveButtonDisabled =
    Object.values(state.errors).some(Boolean) || state.isLoading;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.descriptionPaper }}
    >
      <Dialog.Title hasClose>Edit Description</Dialog.Title>
      <Dialog.Content>
        <div className="d-flex f-align-center flex-column">
          {renderConditionalTextInput(selectedRow)}
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={isSaveButtonDisabled}
            onClick={handleSubmit}
          >
            Save
            {state.isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default UdateInvoiceItemDescriptionDialog;
