import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    minWidth: 208,
    maxWidth: 208,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  addLookupEmailpaperSize: {
    width: 530,
  },
  quantityExceedpaperSize: {
    minWidth: "90%",
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  buttonStyle: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "6px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  notesPaperSize: {
    width: 470,
    height: 270,
  },
  rejectBtn: {
    background: theme.palette.background.error.light,
  },
  highlightYellow: {
    fontColor: yellow[50],
  },
  rightAction: {
    minWidth: 196,
  },
  addNewreviewNotesHeaderCell: {
    minWidth: 400,
    maxWidth: 400,
  },
  wrapTableTitle: {
    minWidth: 125,
    maxWidth: 125,
  },
  pdxCompanyHeader: {
    minWidth: 200,
    maxWidth: 200,
  },
  tableHeaderwidth: {
    minWidth: 144,
    maxWidth: 144,
  },
  tableHeaderNotesWidth: {
    minWidth: 200,
    maxWidth: 200,
  },
  tableExtraMilesWidth: {
    minWidth: 125,
    maxWidth: 125,
  },
  icidFlagTitle: {
    minWidth: 320,
    maxWidth: 320,
    paddingLeft: "48px",
  },

  autocompleteFilters: {
    minWidth: 208,
  },
  icidTitle: {
    minWidth: "450px",
  },
  scheduleDateWrapper: {
    "& .react-datepicker__close-icon": {
      top: 2,
      right: 36,
    },
    "& .react-datepicker__close-icon::after": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 28,
    },
  },
  pointerEventsAuto: {
    background: theme.palette.background.defaultLightGrey,
    color: theme.palette.text.disabled,
    cursor: "default",
  },
  rowChanges: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    border: "solid 1px black",
  },
  bottomNavigation: {
    backgroundColor: "white",
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
}));

export default useStyles;
