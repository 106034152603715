import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1020,
    position: "sticky",
    background: "#fafafa",
    marginBottom: "6px",
  },
  legendHint: {
    width: 16,
    height: 16,
    color: "#fc4800",
  },
  OEMLegendHint: {
    width: 24,
    height: 24,
    color: "#fc4800",
  },
  paperWidthSm: {
    maxWidth: "98%",
    zIndex: "10000",
    minWidth: "98%",
    height: "calc(100vh - 188px)",
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
    },
  },
  PDXGreen: {
    width: 16,
    height: 16,
    background: "green",
  },
  PDXPurple: {
    width: 16,
    height: 16,
    background: "purple",
  },
  PDXBlue: {
    width: 16,
    height: 16,
    background: "blue",
  },
  PDXYellow: {
    width: 16,
    height: 16,
    background: "yellow",
  },
  PDXOrange: {
    width: 16,
    height: 16,
    background: "#ef6c00",
  },
  VisibilityIconColor: { color: "#004700" },
  branchLegendHint: {
    width: 16,
    height: 16,
    color: "#6b32cc",
  },
  ICLegendHint: {
    width: 16,
    height: 16,
    color: "#0087ED",
  },
  paperSize: {
    width: 388,
  },
  kamLegendHint: {
    width: 16,
    height: 16,
    color: "#095C6B",
  },
  mapStyles: {
    width: "94%",
    height: "80%",
    overflowAnchor: "none",
  },
  chip: {
    margin: theme.spacing(0.5),
    height: 22,
  },
  field: {
    width: 224,
    marginBottom: 12,
    marginRight: 10,
  },
  goButtonClass: {
    marginLeft: 98,
  },
  fields: {
    width: 325,
    marginBottom: 12,
    marginRight: 10,
    zIndex: 1001,
  },
  marker: {
    width: "10px",
    height: " 10px",
    backgroundColor: "black",
  },
  dialog: {
    minHeight: 600,
    maxHeight: 600,
    minWidth: 700,
    maxWidth: 700,
  },

  searchBox: {
    position: "absolute",
    top: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "300px",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid gray",
    padding: "5px",
    backgroundColor: "white",
    zIndex: "1",
  },
  drawer: {
    position: "relative",
    "& .MuiBackdrop-root": {
      display: "none",
    },
    "& .MuiDrawer-paper": {
      height: "calc(100vh - 218px)",
      zIndex: 3,
      width: "388px",
      position: "absolute",
      padding: "20px",
      boxShadow: "2px 0 5px rgba(0,0,0,0.3)",
    },
  },
  toogleDrawer: {
    position: "absolute",
    zIndex: 2,
    borderRadius: "0px 12px 12px 0px",
    padding: "2px",
    height: "61px",
  },
  drawerPosition: { position: "relative" },
  fixedGoButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    height: "2.4rem",
    marginTop: 2,
    right: 0,
    bottom: 0,
    marginBottom: "2px",
    position: "relative",
    zIndex: 10,
  },
}));

export default useStyles;
