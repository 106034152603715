import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem",
    paddingTop: "1rem",
  },
  textField: {
    width: "5.2rem",
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "small",
      padding: 8,
      background: "#80808029",
      color: "black",
    },
  },
  formControl: {
    width: "12rem",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  select: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      fontSize: "0.96rem",
      padding: 6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  paperSpacing: (props) => ({
    height: 360,
    margin: "1rem",
    flex: "1 1 auto",
    width: props.isTabletView ? "100%" : "calc(50% - 2rem)",
  }),
  isFetchingData: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  isRevenueLoading: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    position: "relative",
    padding: "10px",
  },
  reportName: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "6px",
    color: "#00000087",
  },
  totalAmount: {
    fontWeight: "bold",
    color: "black",
    fontSize: "1.4rem",
    margin: "6px 0 8px 0",
  },
  totalReceivablesAmount: {
    fontWeight: "bold",
    color: "black",
    fontSize: "1.4rem",
    marginBottom: "8px",
  },
  customTooltip: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: 4,
    padding: 10,
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
  },
  intro: {
    fontWeight: "bold",
    margin: "5px 0",
    color: "#1cae5c",
  },
  customerIntro: {
    fontWeight: "bold",
    margin: "5px 0",
    color: "#4182ff",
  },
  overdueIntro: {
    fontWeight: "bold",
    margin: "5px 0",
  },
  tunePaper: {
    minWidth: 200,
  },
  divider: {
    marginRight: "16px",
    height: "42px",
    width: "2px",
    marginBottom: "2px",
  },
  paper: (props) => ({
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
      color: "#004700",
    },
    minHeight: 550,
    minWidth: props.isTabletView ? "20%" : "20%",
    maxWidth: props.isTabletView ? "98%" : "90%",
  }),
  scroll: {
    marginLeft: 5,
    marginRight: 5,
    "&::-webkit-scrollbar": {
      height: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
      borderRadius: "80px",
    },
  },
}));

export default useStyles;
