import { useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Datepicker, Dialog, Grid } from "shared/components";
import {
  getDateString,
  getPageConfig,
  setPageConfig,
  updatePageConfig,
  getUTCDateString,
} from "utils";
import { AppContext } from "shared/contexts";
import SharedService from "modules/shared/service";
import {
  Box,
  FormControl,
  Radio,
  RadioGroup,
  Slider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Service from "../service";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStyles from "./style";
import { PAGE_KEYS } from "shared";
import {
  ButtonGroup,
  FormControlLabel,
  IconButton,
  Paper,
} from "@material-ui/core";
import html2canvas from "html2canvas";
import StackedBarChart from "../chartReport/stackedBarChartReport";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BarChartIcon from "@material-ui/icons/BarChart";
import moment from "moment-timezone";

const TAB_LIST = [
  { id: 1, label: "Submission Accuracy %" },
  { id: 2, label: "On Time Submission %" },
];
let isOnTime = false;

const defaultState = {
  isFetching: false,
  sortOrder: "none",
  entries: [],
  modifiedEntries: [],
  totalEntries: 0,
  selectRange: [0, 100],
  isDownloading: false,
  isBarClicked: false,
  entry: {},
  startDate: null,
  endDate: null,
  sortingType: "bySubmission",
  sortingOnTime: "byOnTimeSubmission",
  tabIndex: 1,
  filters: {
    kamId: [],
  },
  isFetchingList: {
    kams: false,
  },
};

const KamEfficiencyReport = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT);
  const [state, setState] = useState({
    ...defaultState,
    startDate:
      (pageConfig && pageConfig?.startDateFilter) || getDateString(new Date()),
    endDate:
      (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date()),
    sortingType:
      (pageConfig && pageConfig?.sortingType) || defaultState.sortingType,
    tabIndex: (pageConfig && pageConfig?.tabIndex) || defaultState.tabIndex,
    sortingOnTime:
      (pageConfig && pageConfig?.sortingOnTime) || defaultState.sortingOnTime,
    selectRange:
      (pageConfig && pageConfig?.selectRange) || defaultState.selectRange,
    sortOrder: (pageConfig && pageConfig?.sortOrder) || defaultState.sortOrder,
    filters: {
      ...defaultState.filters,
      kamId:
        (pageConfig && pageConfig?.kamFilter?.value) ||
        defaultState.filters.kamId,
    },
  });

  const { appData, updateContextData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView });

  const isScheduleOnTime = (record) => {
    const scheduleMoment = moment
      .tz(record.schedule_date, "America/New_York")
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

    const submittedMoment = moment().tz("America/New_York");

    const isWeekend =
      moment(getUTCDateString(record.schedule_date), "MM/DD/YYYY").day() ===
        5 ||
      moment(getUTCDateString(record.schedule_date), "MM/DD/YYYY").day() === 6;

    const nextDay = isWeekend
      ? scheduleMoment
          .clone()
          .isoWeekday(8)
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      : scheduleMoment.clone().add(1, "day");

    const isSubmittedAfterNextDay = submittedMoment.isAfter(nextDay);

    if (isSubmittedAfterNextDay) {
      return (isOnTime = false);
    } else {
      return (isOnTime = true);
    }
  };

  const fetchEntries = useCallback(
    async (startDate, endDate, filters, hasLoader = true) => {
      let queryString =
        filters.kamId?.length > 0
          ? `?fromDate=${startDate}&toDate=${endDate}&kamId=${filters.kamId
              ?.map((item) => item.id)
              ?.join(",")}`
          : `?fromDate=${startDate}&toDate=${endDate}`;

      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      const { data, error } = await Service.getKamEfficiencyReport(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      //To separate data of kams
      const separatedData = {};
      data.forEach((item) => {
        const uniqueKamId = item.kam_id;
        if (!separatedData[uniqueKamId]) {
          separatedData[uniqueKamId] = [];
        }
        separatedData[uniqueKamId].push(item);
      });

      //TO MERGE SAME KAMS RECORDS AND FORM ONE SORTED ARRAY
      const result = [];

      for (const key in separatedData) {
        if (separatedData.hasOwnProperty(key)) {
          const dataArray = separatedData[key];

          const updatedArray = dataArray.map((data) => ({
            ...data,
            is_on_time_submission:
              Number(data.on_time_submission_count) !== 0 &&
              Number(data.on_time_submission_count) ===
                Number(data.total_record_count) &&
              data.is_complete_submit === true,
          }));

          const getTotal = (property) =>
            updatedArray.reduce((acc, item) => acc + Number(item[property]), 0);

          const counts = updatedArray.reduce(
            (acc, item) => {
              const isOnTime = isScheduleOnTime(item);
              const isForcedSubmission = item.is_forced_submission;
              const isWeekOff =
                item.is_week_off && item.total_record_count === 0;
              const isOnTimeSubmission = item.is_on_time_submission;

              acc.onTimeSubmissionRoutes +=
                isOnTimeSubmission || isOnTime ? 1 : 0;
              acc.onTimeSubmissionCountes +=
                isOnTimeSubmission || (isOnTime ? isOnTimeSubmission : 0);
              acc.byNotOnTimeSubmissionRoutes +=
                !isOnTimeSubmission &&
                (isOnTime ? isWeekOff || isForcedSubmission : true)
                  ? 1
                  : 0;
              acc.isWeekOff += isWeekOff ? 1 : 0;
              acc.SchedulesCanBeSubmitted +=
                isOnTime &&
                isOnTimeSubmission === false &&
                isWeekOff === false &&
                isForcedSubmission === false
                  ? 1
                  : 0;
              acc.isForcedSubmission += isForcedSubmission ? 1 : 0;
              return acc;
            },
            {
              onTimeSubmissionRoutes: 0,
              onTimeSubmissionCountes: 0,
              byNotOnTimeSubmissionRoutes: 0,
              SchedulesCanBeSubmitted: 0,
              isWeekOff: 0,
              isForcedSubmission: 0,
            }
          );

          const totalSubmitted = getTotal("submitted_records_count");
          const totalReview = getTotal("total_review_records");
          const submittedRecordsCount = getTotal("submitted_records_count");
          const totalUnderReviewCount = getTotal("total_review_records");
          const underReviewCount = getTotal("under_review_count");
          const overallScheduleCount = getTotal("total_record_count");
          const totalScheduleCount = updatedArray.length;
          const totalObjects = totalSubmitted + totalReview;
          const totalRecordCount = totalObjects;

          const submission_percentage = (
            (totalSubmitted / totalObjects) *
            100
          ).toFixed(2);
          const review_percentage = (
            (totalReview / totalObjects) *
            100
          ).toFixed(2);
          const byNotonTime =
            counts.byNotOnTimeSubmissionRoutes -
            counts.isWeekOff -
            counts.isForcedSubmission;
          const byNotonTimeCount = byNotonTime < 0 ? 0 : byNotonTime;
          const totalDailySchedule =
            counts.onTimeSubmissionCountes + byNotonTimeCount;
          const onTimePercentage = (
            (counts.onTimeSubmissionCountes / totalDailySchedule) *
            100
          ).toFixed(2);
          const notOnTimePercentage = (
            (byNotonTimeCount / totalDailySchedule) *
            100
          ).toFixed(2);
          const isSchedulesCanBeSubmitted = (
            (counts.SchedulesCanBeSubmitted / counts.SchedulesCanBeSubmitted) *
            100
          ).toFixed(2);
          const item = {
            kam_id: +updatedArray[0].kam_id,
            totalObjects,
            totalSubmitted,
            overallScheduleCount,
            SchedulesCanBeSubmitted: counts.SchedulesCanBeSubmitted,
            notSubmittedSchedule:
              overallScheduleCount -
              (submittedRecordsCount + totalUnderReviewCount),
            isWeekOffCount: counts.isWeekOff,
            isForcedSubmissionCount: counts.isForcedSubmission,
            totalRecordCount,
            totalScheduleCount:
              totalScheduleCount - counts.isWeekOff - counts.isForcedSubmission,
            submittedRecordsCount,
            totalUnderReviewCount,
            detailedKamRecordsView: updatedArray,
            totalReview,
            byNotOnTimeSubmissionRoutes: byNotonTimeCount,
            onTimeSubmissionRoutes: counts.onTimeSubmissionCountes,
            submission_percentage: isNaN(submission_percentage)
              ? "0.00"
              : submission_percentage,
            review_percentage: isNaN(review_percentage)
              ? "0.00"
              : review_percentage,
            on_time_percentage: isNaN(onTimePercentage)
              ? counts.SchedulesCanBeSubmitted > 0
                ? isSchedulesCanBeSubmitted
                : "0.00"
              : onTimePercentage,
            not_on_time_percentage: isNaN(notOnTimePercentage)
              ? "0.00"
              : notOnTimePercentage,
          };

          result.push(item);
        }
      }

      const kamData =
        result.length > 0
          ? result.map((entry) => {
              const matchingUser = appData.users.find(
                (user) => user.role === "KAM" && user.id === entry.kam_id
              );

              const kamName =
                matchingUser &&
                `${matchingUser.first_name} ${matchingUser.last_name} (${matchingUser.username})`;

              return {
                ...entry,
                kamName: kamName,
              };
            })
          : [];

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: kamData || defaultState.entries,
        totalEntries: data.count,
      }));
    },
    [appData.users]
  );

  const detailedColumnConfig = [
    {
      id: "schedule_date",
      label: "Schedule date",
      field: "schedule_date",
      render: (row) => (
        <Tooltip
          title={
            row.is_week_off && row.total_record_count > 0
              ? `${getUTCDateString(row.schedule_date)} (NO SCHEDULE)`
              : getUTCDateString(row.schedule_date) ?? ""
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {getUTCDateString(row.schedule_date) || "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      id: "total_schedule_count",
      label: "Schedule count",
      field: "total_schedule_count",
      render: (row) =>
        (row.is_week_off && row.total_record_count === 0) ||
        row.is_forced_submission ? (
          ""
        ) : (
          <Tooltip title={row.total_record_count} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.total_record_count}
            </Typography>
          </Tooltip>
        ),
    },
    {
      id: "submitted_records_count",
      label:
        state.tabIndex === 1
          ? "Schedule direct to BS"
          : "Schedule reached to BS",
      field: "submitted_records_count",
      render: (row) =>
        (row.is_week_off && row.total_record_count === 0) ||
        row.is_forced_submission ? (
          ""
        ) : (
          <Tooltip
            title={
              state.tabIndex === 1
                ? row.submitted_records_count
                : row.overall_submitted_count
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {state.tabIndex === 1
                ? row.submitted_records_count
                : row.overall_submitted_count}
            </Typography>
          </Tooltip>
        ),
    },
    {
      id: "total_review_records",
      label:
        state.tabIndex === 1
          ? "Schedule underwent review"
          : "Under review schedule",
      field: "total_review_records",
      render: (row) =>
        (row.is_week_off && row.total_record_count === 0) ||
        row.is_forced_submission ? (
          <Typography variant="body2" noWrap style={{ fontWeight: "bold" }}>
            {row.is_week_off ? "NO SCHEDULE" : "FORCE SUBMIT"}
          </Typography>
        ) : (
          <Tooltip
            title={
              state.tabIndex === 1
                ? row.total_review_records
                : row.under_review_count
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {state.tabIndex === 1
                ? row.total_review_records
                : row.under_review_count}
            </Typography>
          </Tooltip>
        ),
    },
    {
      id: "not_submitted_schedule",
      label: "Not submitted schedule",
      field: "not_submitted_schedule",
      render: (row) => {
        const notSubmittedSchedule =
          state.tabIndex === 1
            ? +row.total_record_count -
              (+row.submitted_records_count + +row.total_review_records)
            : +row.total_record_count -
              (+row.overall_submitted_count + +row.under_review_count);
        return (row.is_week_off && row.total_record_count === 0) ||
          row.is_forced_submission ? (
          ""
        ) : (
          <Tooltip
            title={notSubmittedSchedule < 0 ? 0 : notSubmittedSchedule}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {notSubmittedSchedule < 0 ? 0 : notSubmittedSchedule}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "total_record_count",
      label: "Submitted schedule (Direct + Underwent Review)",
      field: "total_record_count",
      render: (row) =>
        (row.is_week_off && row.total_record_count === 0) ||
        row.is_forced_submission ? (
          ""
        ) : (
          <Tooltip
            title={+row.submitted_records_count + +row.total_review_records}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {+row.submitted_records_count + +row.total_review_records}
            </Typography>
          </Tooltip>
        ),
    },
  ];

  const additionalColumnConfig = {
    id: "on_time_submission_count",
    label: " On time submission of DS",
    field: "on_time_submission_count",
    render: (row) => {
      const isOnTime = isScheduleOnTime(row);

      let displayValue = "No";

      if (
        row.is_on_time_submission === true &&
        row.is_complete_submit === true
      ) {
        displayValue = <span style={{ color: "#72c658" }}>Yes</span>;
      } else if (isOnTime) {
        displayValue = <span style={{ color: "blue" }}>N/A</span>;
      } else {
        displayValue = <span style={{ color: "red" }}>No</span>;
      }

      return (row.is_week_off && row.total_record_count === 0) ||
        row.is_forced_submission ? (
        ""
      ) : (
        <Tooltip title={displayValue} placement="top-start">
          <Typography variant="body2" noWrap>
            {displayValue}
          </Typography>
        </Tooltip>
      );
    },
  };

  if (state.tabIndex === 2) {
    detailedColumnConfig.splice(5, 1, additionalColumnConfig);
  }

  const handleClick = (entry = {}) => {
    setState((prevState) => ({
      ...prevState,
      isBarClicked: true,
      entry,
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      isBarClicked: false,
    }));
  };

  const renderDialog = (tab, entry) => {
    return (
      <Dialog
        open={!!state.isBarClicked}
        onClose={handleCloseDialog}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose>{entry.kamName}</Dialog.Title>
        <Dialog.Content>
          <div className="d-flex f-align-center f-wrap">
            {tab === 2 && (
              <Chip
                label={`Total daily schedules: ${
                  +entry.onTimeSubmissionRoutes +
                  +entry.byNotOnTimeSubmissionRoutes
                }`}
                className={classes.totalDS}
              />
            )}
            {tab === 1 && (
              <>
                <Chip
                  label={`Total schedule count: ${entry.overallScheduleCount}`}
                  className={classes.totalSchedule}
                />
                <br />
              </>
            )}
            {tab === 1 ? (
              <Chip
                label={`Total schedule direct to BS: ${entry.submittedRecordsCount}`}
                className={classes.directSchedule}
              />
            ) : (
              <Chip
                label={`Total on time submission of DS: ${entry.onTimeSubmissionRoutes}`}
                className={classes.totalOnTime}
              />
            )}
            <br />
            {tab === 1 ? (
              <Chip
                label={`Total schedule underwent review: ${entry.totalUnderReviewCount}`}
                className={classes.scheduleUnderwent}
              />
            ) : (
              <Chip
                label={`Total delayed submission of DS: ${entry.byNotOnTimeSubmissionRoutes}`}
                className={classes.delayedSubmission}
              />
            )}
            <br />
            {tab === 1 && (
              <Chip
                label={`Total not submitted schedules: ${entry.notSubmittedSchedule}`}
                className={classes.notSubmitted}
              />
            )}
            <br />
            {tab === 1 && (
              <Chip
                label={`Total submitted schedule: ${entry.totalRecordCount}`}
                className={classes.totalSubmittedSchedule}
              />
            )}
            {tab === 2 && (
              <Chip
                label={`DS can be submitted on time: ${entry.SchedulesCanBeSubmitted}`}
                className={classes.canBeSubmitOnTime}
              />
            )}
          </div>

          <Paper
            elevation={2}
            className={clsx("p-4 mt-10", classes.gridPaperSpacing)}
          >
            <Grid
              columns={detailedColumnConfig}
              rows={entry.detailedKamRecordsView
                .map((item, index) => ({
                  ...item,
                  id: index,
                  className: clsx({
                    [classes.highlightWeekOff]: !!item.is_week_off,
                    [classes.highlightForceSubmit]: !!item.is_forced_submission,
                  }),
                }))
                ?.sort(
                  (a, b) =>
                    new Date(a.schedule_date) - new Date(b.schedule_date)
                )}
              actionBarConfig={null}
              isLoading={false}
              hasSelection={false}
              hasPagination={false}
            />
          </Paper>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="d-flex f-align-center f-justify-end p-4">
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              onClick={handleCloseDialog}
            >
              Close
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    );
  };

  useEffect(() => {
    fetchEntries(state.startDate, state.endDate, state.filters);
  }, [fetchEntries, state.startDate, state.endDate, state.filters]);

  const handleTabChange = useCallback((event, tabIndex) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex,
    }));
    setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
      tabIndex: tabIndex,
    });
  }, []);

  const fetchUsersList = async (...params) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          kams: true,
        },
      }));

      const serviceMethod = "getUsersList";
      const responseKey = "users";

      const { data, error } = await SharedService[serviceMethod](...params);

      if (error) {
        throw error;
      }

      const listData = data[responseKey] || [];

      updateContextData("users", listData);

      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          kams: false,
        },
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          kams: false,
        },
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    }
  };

  useEffect(() => {
    fetchUsersList();
  }, []);

  const handleDownloadChart = async () => {
    setState((prevState) => ({
      ...prevState,
      isDownloading: true,
    }));
    const chartNode = document.querySelector(".recharts-wrapper");
    const canvas = await html2canvas(chartNode, {
      scrollX: 0,
      scrollY: -window.scrollY,
    });

    const dataUrl = canvas.toDataURL();

    const fileName =
      state.tabIndex === 1
        ? "KAM submission/review bar graph"
        : "KAM on time submission bar graph";

    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `${fileName}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setState((prevState) => ({
      ...prevState,
      isDownloading: false,
    }));
  };

  const getValueForTab = (item) => {
    if (state.tabIndex === 1) {
      return state.sortingType === "bySubmission"
        ? item.submission_percentage
        : item.review_percentage;
    } else if (state.tabIndex === 2) {
      return state.sortingOnTime === "byOnTimeSubmission"
        ? item.on_time_percentage
        : item.not_on_time_percentage;
    }
  };

  const sortAndFilterData = (data, range) => {
    const sortedData = [...data];

    if (state.sortOrder === "asc" || state.sortOrder === "desc") {
      sortedData.sort((a, b) => {
        let valueA, valueB;

        if (state.tabIndex === 1) {
          valueA =
            state.sortingType === "bySubmission"
              ? a.submission_percentage
              : a.review_percentage;
          valueB =
            state.sortingType === "bySubmission"
              ? b.submission_percentage
              : b.review_percentage;
        } else if (state.tabIndex === 2) {
          valueA =
            state.sortingOnTime === "byOnTimeSubmission"
              ? a.on_time_percentage
              : a.not_on_time_percentage;
          valueB =
            state.sortingOnTime === "byOnTimeSubmission"
              ? b.on_time_percentage
              : b.not_on_time_percentage;
        }

        return state.sortOrder === "asc" ? valueA - valueB : valueB - valueA;
      });

      const filteredData = sortedData.filter((item) => {
        const value = getValueForTab(item);
        return value >= range[0] && value <= range[1];
      });

      return filteredData;
    } else {
      const filteredData = sortedData.filter((item) => {
        const value = getValueForTab(item);
        return value >= range[0] && value <= range[1];
      });

      return filteredData;
    }
  };

  const handleRadioChange = (evt, key) => {
    const { value } = evt.target;
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
      [key]: value,
    });
  };

  const renderRadio = (value, label, color) => (
    <FormControlLabel
      key={value}
      value={value}
      control={<Radio size="small" color={color} />}
      label={label}
      style={{ color }}
    />
  );

  const handleRangeChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      selectRange: newValue,
    }));
    setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
      selectRange: newValue,
    });
  };

  return (
    <>
      <div className={clsx("mr-10", classes.fixedHeader)}>
        <Typography variant="h4" color="primary" className=" ml-2">
          Schedule Submission Behavior Reports
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/report")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Reports
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          View Schedule Submission Behavior Reports.
        </Typography>
      </div>
      <div className="d-flex f-align-center f-wrap mb-3 mt-4 f-justify-between">
        <div
          className="d-flex f-align-center"
          style={{ paddingBottom: "10px" }}
        >
          <Datepicker
            mask
            preventClear
            label="Start date"
            selected={new Date(state.startDate)}
            maxDate={new Date(state.endDate)}
            classes={{
              input: {
                root: clsx("mr-4 mt-4", classes.datepickerWrapper),
              },
            }}
            onChange={(startDate) => {
              setState((prevState) => ({
                ...prevState,
                startDate: getDateString(startDate),
              }));
              setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
                startDateFilter: getDateString(startDate),
              });
            }}
          />
          <Datepicker
            mask
            preventClear
            label="End date"
            selected={new Date(state.endDate)}
            minDate={new Date(state.startDate)}
            maxDate={new Date()}
            classes={{
              input: {
                root: clsx("mr-4 mt-4", classes.datepickerWrapper),
              },
            }}
            onChange={(endDate) => {
              setState((prevState) => ({
                ...prevState,
                endDate: getDateString(endDate),
              }));
              setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
                endDateFilter: getDateString(endDate),
              });
            }}
          />

          <Autocomplete
            className={clsx("mr-4 mt-4", classes.kamDropdown)}
            classes={{
              paper: "mb-2",
            }}
            size="small"
            ChipProps={{
              component: (a) => {
                return (
                  <div>
                    <Tooltip
                      placement={"top-start"}
                      title={a.children[1]?.props?.children || ""}
                    >
                      <Chip
                        size="small"
                        className={classes.chip}
                        width={15}
                        label={
                          <Typography variant="body3" noWrap>
                            {a.children[1]?.props?.children.length > 4
                              ? a.children[1]?.props?.children.slice(0, 4) +
                                "..."
                              : a.children[1]?.props?.children}
                          </Typography>
                        }
                        deleteIcon
                        onDelete={(evt) => {
                          a.children[2]?.props?.onClick(evt);
                        }}
                      />
                    </Tooltip>
                  </div>
                );
              },
            }}
            defaultValue={state.filters?.kamId}
            options={appData.users.filter(
              (user) => user.role === "KAM" && user.is_active
            )}
            multiple
            getOptionLabel={(option) =>
              option.name ||
              `${option.first_name || ""} ${option.last_name || ""}-${
                option.email || ""
              }-(${option.username || ""})`
            }
            renderInput={(params) =>
              state.isFetchingList.kams ? (
                <Skeleton variant="rect" width="100%" height="42px" />
              ) : (
                <TextField {...params} label="KAM" variant="outlined" />
              )
            }
            onChange={(evt, kam) => {
              setState((prevState) => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  kamId: kam,
                },
              }));
              setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
                kamFilter: {
                  value: kam,
                },
              });
            }}
          />
        </div>
        <Paper
          style={{
            borderRadius: "10px",
            marginTop: appData.isTabletView ? "10px" : "0px",
          }}
        >
          <div className="d-flex f-align-center f-wrap f-justify-end mr-5 ml-5">
            <FormControl>
              {state.tabIndex === 1 && (
                <RadioGroup
                  row
                  value={state.sortingType}
                  onChange={(evt) => handleRadioChange(evt, "sortingType")}
                >
                  {renderRadio(
                    "bySubmission",
                    "Sort by submission %",
                    "#4F7942"
                  )}
                  {renderRadio("byReview", "Sort by review %", "#800080")}
                </RadioGroup>
              )}
              {state.tabIndex === 2 && (
                <RadioGroup
                  row
                  value={state.sortingOnTime}
                  onChange={(evt) => handleRadioChange(evt, "sortingOnTime")}
                >
                  {renderRadio(
                    "byOnTimeSubmission",
                    "Sort by on time submission",
                    "#72c658"
                  )}
                  {renderRadio(
                    "byNotOnTimeSubmission",
                    "Sort by delayed submission",
                    "red"
                  )}
                </RadioGroup>
              )}
            </FormControl>
            <div className="d-flex f-align-center f-justify-start mr-3">
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
              >
                <Tooltip title="Sort in Ascending Order" placement="top-start">
                  <Button
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        sortOrder: "asc",
                      }));
                      setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
                        sortOrder: "asc",
                      });
                    }}
                    disabled={state.sortOrder === "asc"}
                  >
                    <TrendingUpIcon
                      color={state.sortOrder === "asc" ? "grey" : "primary"}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Sort in Descending Order" placement="top-start">
                  <Button
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        sortOrder: "desc",
                      }));
                      setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
                        sortOrder: "desc",
                      });
                    }}
                    disabled={state.sortOrder === "desc"}
                  >
                    <TrendingDownIcon
                      color={state.sortOrder === "desc" ? "grey" : "primary"}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Remove Sorting" placement="top-start">
                  <Button
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        sortOrder: "none",
                      }));
                      setPageConfig(PAGE_KEYS.KAM_SUBMISSION_REPORT, {
                        sortOrder: "none",
                      });
                    }}
                    disabled={state.sortOrder === "none"}
                  >
                    <BarChartIcon
                      color={state.sortOrder === "none" ? "grey" : "primary"}
                    />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </div>
            <div className={clsx("d-flex f-align-center mr-3", classes.slider)}>
              <Typography
                variant="body1"
                className="d-flex f-align-center mr-3 ml-3"
                style={{ marginTop: 6, width: 90 }}
                gutterBottom
              >
                Range :
              </Typography>
              <Slider
                value={state.selectRange}
                onChange={handleRangeChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value}%`}
              />
            </div>
            <Tooltip title={`Export Bar Graph`} placement="top-start">
              <IconButton
                color="primary"
                className="mb-1"
                disabled={state.isDownloading || state.entries.length === 0}
                onClick={handleDownloadChart}
              >
                <SystemUpdateAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top-end" title="Reset All Filters">
              <IconButton
                color="primary"
                className="mb-1"
                onClick={() => {
                  updatePageConfig("kam-submission-report");
                  window.location.reload();
                }}
              >
                <RotateLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      </div>
      <Box>
        <Tabs
          value={state.tabIndex}
          onChange={handleTabChange}
          indicatorColor={"#eeeeee"}
          textColor={"primary"}
          className={classes.tabs}
        >
          {TAB_LIST.map((tab) => (
            <Tab
              value={tab.id}
              key={tab.id}
              name={tab.id}
              style={{
                paddingBottom: 0,
                background: tab.id === state.tabIndex ? "white" : "none",
                borderTop:
                  tab.id === state.tabIndex ? "4px solid #80808045" : "none",
                borderLeft:
                  tab.id === state.tabIndex ? "4px solid #80808045" : "none",
                borderRight:
                  tab.id === state.tabIndex ? "4px solid #80808045" : "none",
              }}
              label={
                <Typography
                  variant="subtitle2"
                  component={"span"}
                  color={
                    tab.id === state.tabIndex ? "primary.dark" : "grey.300"
                  }
                >
                  {tab.label}
                </Typography>
              }
            />
          ))}
        </Tabs>
        {state.tabIndex === 1 && (
          <Paper
            className={classes.paperSpacing}
            style={{
              ...(appData.isTabletView
                ? { overflow: "auto" }
                : { width: "100%" }),
            }}
          >
            <StackedBarChart
              entries={sortAndFilterData(state.entries, state.selectRange)}
              handleClick={handleClick}
              isFetching={state.isFetching}
              isTabletView={appData.isTabletView}
              tab="review"
            />
            {state.isBarClicked && renderDialog(state.tabIndex, state.entry)}
          </Paper>
        )}
        {state.tabIndex === 2 && (
          <Paper
            className={classes.paperSpacing}
            style={{
              ...(appData.isTabletView
                ? { overflow: "auto" }
                : { width: "100%" }),
            }}
          >
            <StackedBarChart
              entries={sortAndFilterData(state.entries, state.selectRange)}
              isTabletView={appData.isTabletView}
              handleClick={handleClick}
              isFetching={state.isFetching}
              tab="onTime"
            />
            {state.isBarClicked && renderDialog(state.tabIndex, state.entry)}
          </Paper>
        )}
      </Box>
    </>
  );
};

export default KamEfficiencyReport;
