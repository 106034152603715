/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useStyles from "./style";
import { ActionDialog, Dialog, Grid, noop, VALIDATIONS } from "shared";

import { getUTCDateString, preventInputKeyCodes, validator } from "utils";
import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import CustomersService from "../service";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const defaultState = {
  entries: [],
  isFetchingData: false,
  paymentId: null,
  invoiceId: null,
  isRevertLoading: false,
  showRevertInvoicePaymentPopup: false,
  selectedPayment: {},
  addCredit: 0,
  removeCredit: 0,
  isRemoveCredit: false,
  patchCreditNotes: "",
  patchCreditDialog: false,
  patchCreditLoading: false,
  errors: {
    addCredit: " ",
    patchCreditNotes: " ",
    removeCredit: " ",
  },
};

const CreditGrid = ({
  open = false,
  handleClose = noop,
  handleFetchCreditUpdate = noop,
  totalAvailableCreditAmount = noop,
  customerBranchId,
  creditAmount,
  isRoleKAM,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const onClose = () => {
    handleClose(false);
    setState(defaultState);
  };

  const fetchEntries = useCallback(async (branchId) => {
    setState((prevState) => ({ ...prevState, isFetchingData: true }));

    const query = `?filter[where][and][0][or][0][customer_branch_id]=${branchId}`;

    const { data, error } = await CustomersService.getCustomerCredits(query);

    if (error) {
      setState((prevState) => ({ ...prevState, isFetchingData: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingData: false,
      entries: data.rows || defaultState.entries,
    }));
  }, []);

  const patchCustomerCredit = useCallback(async (id, amount, credit_notes) => {
    setState((prevState) => ({ ...prevState, patchCreditLoading: true }));

    const payload = {
      id,
      amount,
      credit_notes,
    };

    const { data, error } = await CustomersService.patchCustomerCredits(
      payload
    );

    if (error) {
      setState((prevState) => ({ ...prevState, patchCreditLoading: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const availableCredit = data?.branchRecord?.available_credit || 0;

    setState((prevState) => ({
      ...prevState,
      isFetchingData: false,
    }));
    fetchEntries(id);
    handleOnClose();
    handleFetchCreditUpdate();
    totalAvailableCreditAmount(availableCredit);
  }, []);

  const handlePatchCreditDialog = (close, isRemove = false) => {
    setState((prevState) => ({
      ...prevState,
      patchCreditDialog: close,
      isRemoveCredit: isRemove,
    }));
  };

  const handleOnClose = () => {
    handlePatchCreditDialog(false);
    setState((prevState) => ({
      ...prevState,
      addCredit: defaultState.addCredit,
      removeCredit: defaultState.removeCredit,
      isRemoveCredit: defaultState.isRemoveCredit,
      patchCreditNotes: defaultState.patchCreditNotes,
      patchCreditLoading: defaultState.patchCreditLoading,
    }));
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      addCredit: [
        { type: VALIDATIONS.MIN_LENGTH, value: 1 },
        { type: VALIDATIONS.MAX_LENGTH, value: 8 },
      ],

      removeCredit: [
        { type: VALIDATIONS.MIN_LENGTH, value: 1 },
        { type: VALIDATIONS.MAX_LENGTH, value: 8 },
      ],

      patchCreditNotes: [
        { type: VALIDATIONS.MAX_LENGTH, value: 500 },
        { type: VALIDATIONS.REQUIRED, value: true },
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (
      (field === "addCredit" || field === "removeCredit") &&
      value.indexOf(".") !== -1
    ) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }
    if (
      (field === "addCredit" || field === "removeCredit") &&
      value?.length > 1 &&
      value.indexOf(".") === -1
    ) {
      value = value?.replace(/^0+/, "");
    }

    if (field === "removeCredit") {
      const inputAddCredit = +value || 0;
      if (inputAddCredit <= +creditAmount && inputAddCredit >= 0) {
        return setState((prevState) => ({
          ...prevState,
          removeCredit: value || 0,
          errors: {
            ...prevState.errors,
            removeCredit: errorMessage || defaultState.addCredit,
          },
        }));
      } else if (inputAddCredit > +creditAmount) {
        setState((prevState) => ({
          ...prevState,
          removeCredit: value || 0,
          errors: {
            ...prevState.errors,
            removeCredit: "Credit cannot be more than total credit.",
          },
        }));
      }
    }

    if (field !== "removeCredit") {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    }
  };

  const totalRow = {
    id: "Total",
    credit_amount:
      state.entries &&
      state.entries
        ?.reduce((acc, { credit_amount = "0.00", transaction_type }) => {
          const amount = parseFloat(credit_amount);

          if (transaction_type === "invoice-finalized") {
            return acc - Math.abs(amount);
          }

          return acc + amount;
        }, 0)
        ?.toFixed(2),
  };

  const columnConfig = [
    {
      id: "date",
      label: "Date",
      field: "date",
      render: (row) => {
        const title =
          row?.id === "Total" ? "Total" : getUTCDateString(row?.created_at);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography
              variant="body2"
              style={{
                fontWeight: row?.id === "Total" && 600,
                fontSize: row?.id === "Total" && 22,
              }}
            >
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "payment_number",
      label: "Payment No.",
      field: "payment_number",
      render: (row) => {
        const title =
          row.id === "Total"
            ? null
            : row?.invoice_payment?.payment_number || "-";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "invoice_number",
      label: "Invoice No.",
      field: "invoice_number",
      render: (row) => {
        const title =
          row.id === "Total"
            ? null
            : row?.invoice
            ? row?.invoice?.serial_no || "-"
            : row?.invoice_payment?.invoice_number || "-";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "transaction_type",
      label: "Type",
      field: "transaction_type",
      render: (row) => {
        const title =
          row.id === "Total"
            ? null
            : (row?.transaction_type)
                .split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ") || "-";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "amount",
      label: "Amount",
      field: "amount",
      render: (row) => {
        const amount = parseFloat(row?.credit_amount || "0.00");
        let adjustedAmount = amount;
        let color = amount < 0 ? "red" : "green";

        if (["invoice-finalized"].includes(row?.transaction_type)) {
          adjustedAmount = -Math.abs(amount);
          color = "red";
        }

        const title = `$ ${adjustedAmount.toFixed(2)}`;

        return (
          <Tooltip title={title} placement="top-start">
            <Typography
              variant="body2"
              style={{
                color: color,
                fontWeight: row?.id === "Total" ? 500 : "normal",
                fontSize: row?.id === "Total" ? 18 : "inherit",
              }}
            >
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "credit_note",
      label: "Credit Notes",
      field: "credit_note",
      render: (row) => {
        const title = row.id === "Total" ? null : row?.credit_note || "-";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    fetchEntries(customerBranchId);
  }, [fetchEntries, customerBranchId]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={onClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <Dialog.Title hasClose>
          <Typography variant="h6" color="primary">
            Customer Credit
          </Typography>
        </Dialog.Title>
        <Dialog.Content>
          <Paper>
            <Grid
              columns={columnConfig}
              rows={[...state.entries, totalRow].map((entry, rowIndex) => ({
                ...entry,
                rowIndex,

                className: clsx({
                  [classes.isTotal]: entry.id === "Total",
                }),
              }))}
              actionBarConfig={null}
              hasSelection={false}
              isLoading={state.isFetchingData}
              hasPagination={false}
            />
          </Paper>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="d-flex f-align-center f-justify-between">
            {!state.isFetchingData && !isRoleKAM && (
              <div>
                <Button
                  variant="outlined"
                  className="ml-2 mr-2"
                  classes={{
                    root:
                      +creditAmount <= 0
                        ? ["ml-4", { borderColor: "grey" }]
                        : "border-error ml-4",
                    label:
                      +creditAmount <= 0 ? { color: "grey" } : "color-error",
                  }}
                  startIcon={
                    <RemoveCircleOutlineIcon
                      style={{
                        color: +creditAmount <= 0 ? "lightgrey" : "error",
                      }}
                    />
                  }
                  onClick={() => handlePatchCreditDialog(true, true)}
                  disabled={+creditAmount <= 0}
                >
                  Remove
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="ml-2 mr-2"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => handlePatchCreditDialog(true)}
                >
                  Add
                </Button>
              </div>
            )}
            <div className="p-4">
              <Button
                variant="outlined"
                onClick={onClose}
                className="ml-2 mr-2"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Dialog.Actions>
      </Dialog>
      {state.patchCreditDialog && (
        <ActionDialog
          classes={{
            confirm: state.isRemoveCredit ? "bg-danger" : "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.patchCreditDialog}
          contentText={
            <>
              <Typography variant="h6" color="primary" className="mt-2">
                {`${state.isRemoveCredit ? "Remove" : "Add"} Credit`}
              </Typography>
              <br></br>
              <div className="d-flex flex-column" style={{ gap: 5 }}>
                <TextField
                  className="mb-2"
                  fullWidth
                  required
                  onKeyDown={preventInputKeyCodes}
                  type="number"
                  name={state.isRemoveCredit ? "removeCredit" : "addCredit"}
                  label={`${state.isRemoveCredit ? "Remove" : "Add"} Credit`}
                  variant="outlined"
                  size="small"
                  value={
                    state.isRemoveCredit ? state.removeCredit : state.addCredit
                  }
                  error={
                    state.isRemoveCredit
                      ? !!state.errors?.removeCredit?.trim()
                      : !!state.errors?.addCredit?.trim()
                  }
                  helperText={
                    state.isRemoveCredit
                      ? state.errors?.removeCredit
                      : state.errors?.addCredit
                  }
                  onChange={(evt) => handleFieldChange(evt)}
                />

                <TextField
                  className="mb-1 mt-2"
                  fullWidth
                  required
                  name="patchCreditNotes"
                  label={`Credit Note`}
                  variant="outlined"
                  size="small"
                  value={state.patchCreditNotes}
                  error={!!state.errors.patchCreditNotes?.trim()}
                  helperText={state.errors.patchCreditNotes}
                  onChange={(evt) => handleFieldChange(evt)}
                />
              </div>
            </>
          }
          onConfirm={() =>
            patchCustomerCredit(
              customerBranchId,
              state.isRemoveCredit ? -state.removeCredit : +state.addCredit,
              state.patchCreditNotes
            )
          }
          onCancel={handleOnClose}
          isConfirmDisabled={
            state.patchCreditLoading ||
            validate() ||
            !!state.errors?.removeCredit.trim()
          }
          positiveActionLabel={
            <>
              {`${state.isRemoveCredit ? "Remove" : "Add"}`}
              {state.patchCreditLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default CreditGrid;
