import React from "react";
import { Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom/";
import Link from "@material-ui/core/Link";
import { ROLE } from "modules/shared/constants";
import billingandsettlement from "../../../assets/icons/billingSettlement.svg";
import profitAndLossReports from "../../../assets/icons/profitAndLossReports.svg";
import clsx from "clsx";
import { getTokenData } from "utils";
import Grid from "@material-ui/core/Grid";
import icReportsicon from "./../../../assets/icons/IC_REPORTS.svg";
import onDemandReport from "./../../../assets/icons/onDemandReport.svg";
import pdxCompanyReports from "../../../assets/icons/pdx_company_reports.svg";
import users from "../../../assets/icons/users.svg";
import aaMgrReport from "../../../assets/icons/aaMgrReport.svg";

import useStyles from "./style";

const ReportHomePage = ({ history }) => {
  const classes = useStyles();

  const currentUser = getTokenData() || {};
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;

  const reportsTypes = [
    {
      label: !isRoleKAM
        ? "Billing and Settlement Reports"
        : "Settlement Reports",
      route: "/report/billing_settlement_report",
      icon: (
        <img
          src={billingandsettlement}
          alt="billingandsettlement"
          className={classes.icon}
        />
      ),
    },
  ];

  !isRoleKAM &&
    reportsTypes.push(
      {
        label: "AA/MGR BS Submit Report",
        route: "/report/aa_mgr_report",
        icon: (
          <img src={aaMgrReport} alt="aaMgrReport" className={classes.icon} />
        ),
      },
      {
        label: "IC Reports",
        route: "/report/ic_report",
        icon: (
          <img
            src={icReportsicon}
            alt="independentContractors"
            className={classes.icon}
          />
        ),
      },
      {
        label: "KAM Reports",
        route: "/report/kam_report",
        icon: <img src={users} alt="users" className={classes.icon} />,
      },
      {
        label: "On Demand Reports",
        route: "/report/on_demand_report",
        icon: (
          <img
            src={onDemandReport}
            alt="onDemandReports"
            className={classes.icon}
          />
        ),
      },
      {
        label: "PDX Company Reports",
        route: "/report/pdx_company_report",
        icon: (
          <img
            src={pdxCompanyReports}
            alt="pdxCompanyReports"
            className={classes.icon}
          />
        ),
      },

      {
        label: "Profit and Loss Reports",
        route: "/report/profit_loss_report",
        icon: (
          <img
            src={profitAndLossReports}
            alt="profitAndLossReports"
            className={classes.icon}
          />
        ),
      },
      {
        label: "Schedule Submission Behavior Reports",
        route: "/report/schedule_submission_behavior_report",
        icon: <img src={users} alt="users" className={classes.icon} />,
      }
    );

  return (
    <div>
      <div>
        <Typography variant="h4" color="primary" className=" ml-2">
          Reports
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          Select to view Detailed Reports.
        </Typography>
      </div>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        className={classes.wrapper}
      >
        {reportsTypes.map((page) => {
          return (
            <Grid
              item
              sm={isRoleKAM ? 6 : 3}
              md={4}
              className={classes.griddddd}
            >
              <div
                className="d-flex f-align-center flex-column "
                style={{
                  paddingTop: "98px",
                }}
              >
                <Link to={page.route} component={NavLink}>
                  <Button
                    variant="outlined"
                    size="large"
                    className={classes.iconButton}
                    onClick={() => history.push(page.route)}
                  >
                    {page.icon}
                  </Button>
                </Link>
                <Typography
                  variant={"body1"}
                  className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
                >
                  {page.label}
                </Typography>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ReportHomePage;
