import { Fragment, useState } from "react";
import { noop } from "shared/constants";
import { Grid as DataGrid, Dropdown } from "shared/components";
import useStyles from "./style";
import {
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import WarningIcon from "@material-ui/icons/Warning";
import TuneIcon from "@material-ui/icons/Tune";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import clsx from "clsx";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { getDateString, getFormattedTime } from "utils";

const defaultState = {
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    ExcludedBy: {
      label: "Excluded By",
      value: "ExcludedBy",
      isSelected: true,
    },
    ExcludedAt: {
      label: "Excluded At",
      value: "ExcludedAt",
      isSelected: true,
    },
    ExcludedReason: {
      label: "Excluded Reason",
      value: "ExcludedReason",
      isSelected: true,
    },
    ICID: { label: "ICID", value: "ICID", isSelected: true },
    DeptNum: {
      label: "Dept Num",
      value: "DeptNum",
      isSelected: true,
    },
    LastName: { label: "Last Name", value: "LastName", isSelected: true },
    FirstName: { label: "First Name", value: "FirstName", isSelected: true },
    SSN: {
      label: "SSN",
      value: "SSN",
      isSelected: true,
    },
    EIN: {
      label: "EIN",
      value: "EIN",
      isSelected: true,
    },
    CoName: {
      label: "IC Company Name",
      value: "CoName",
      isSelected: true,
    },
    Settlement: {
      label: "Settlement",
      value: "Settlement",
      isSelected: true,
    },
    FuelReimbursement: {
      label: "Fuel Reimbursement",
      value: "FuelReimbursement",
      isSelected: true,
    },
    TollReimbursement: {
      label: "Toll Reimbursement",
      value: "TollReimbursement",
      isSelected: true,
    },
    MileageReimbursement: {
      label: "Mileage Reimbursement",
      value: "MileageReimbursement",
      isSelected: true,
    },
    Reimbursement: {
      label: "Reimbursement/Incentive",
      value: "Reimbursement",
      isSelected: true,
    },
    MiscDeduction: {
      label: "Misc Deduction",
      value: "MiscDeduction",
      isSelected: true,
    },
    Misc2Deduction: {
      label: "Misc2 Deduction",
      value: "Misc2Deduction",
      isSelected: true,
    },
    FuelAdvanceDeduction: {
      label: "Fuel Advance Deduction",
      value: "FuelAdvanceDeduction",
      isSelected: true,
    },
    FuelAdvance2: {
      label: "Fuel Advance2",
      value: "FuelAdvance2",
      isSelected: true,
    },
    CashAdvanceDeduction: {
      label: "Cash Advance Deduction",
      value: "CashAdvanceDeduction",
      isSelected: true,
    },
    CashAdvance2Deduction: {
      label: "Cash Advance2 Deduction",
      value: "CashAdvance2Deduction",
      isSelected: true,
    },
    ClaimDeduction: {
      label: "Claim Deduction",
      value: "ClaimDeduction",
      isSelected: true,
    },
    Claim2Deduction: {
      label: "Claim2 Deduction",
      value: "Claim2Deduction",
      isSelected: true,
    },
    Claim3Deduction: {
      label: "Claim3 Deduction",
      value: "Claim3Deduction",
      isSelected: true,
    },
    Rental1Deduction: {
      label: "Rental1 Deduction",
      value: "Rental1Deduction",
      isSelected: true,
    },
    Rental2Deduction: {
      label: "Rental2 Deduction",
      value: "Rental2Deduction",
      isSelected: true,
    },
    Rental3Deduction: {
      label: "Rental3 Deduction",
      value: "Rental3Deduction",
      isSelected: true,
    },
    Rental4Deduction: {
      label: "Rental4 Deduction",
      value: "Rental4Deduction",
      isSelected: true,
    },
    EOR: {
      label: "EOR",
      value: "EOR",
      isSelected: true,
    },
  },
  dateFilterType: "single",
  formAlertFields: {},
  fieldAlert: {
    show: false,
    id: null,
    isLoading: false,
    subHeading: "",
    configs: [],
    updateValidations: noop,
    onChange: noop,
  },
  isSendingToDCN: false,
};

const ExcludedRecord = ({
  totalExcludedRecordEntries = 0,
  isFetching = false,
  excludeOrderBy = null,
  excludeOrder = null,
  filterPageConfig = {},
  excludedRecords = [],
  newExcludedEntry = [],
  users,
  setPageConfig = noop,
  PAGE_KEYS = noop,
  updatePageConfig = noop,
  includeExcludedRecords = noop,
  permanentExcludeRecords = noop,
  getExcludedData = noop,
  handleExcludeSortChange = noop,
  handleDownload = noop,
}) => {
  const classes = useStyles();

  const [state, setState] = useState({
    ...defaultState,
    dynamicColumns:
      filterPageConfig?.excludedDynamicColumns || defaultState.dynamicColumns,
  });

  const excludeRecordColumnConfig = [
    {
      id: "includeRecords",
      noResize: true,
      label: "Include Record",
      field: "includeRecords",
      render: (row) => {
        if (row.status === "temp") {
          return (
            <Typography className="text-bold" variant="body1">
              Total
            </Typography>
          );
        }
        return (
          <Tooltip title={"Include Record"} placement="top-start">
            <Switch
              size="small"
              checked={!row.contractorId}
              className={row.isPermExcludedStatus && "c-default no-events"}
              onChange={(evt) => {
                if (evt.target.checked) {
                  includeExcludedRecords(row);
                }
              }}
              color="primary"
            />
          </Tooltip>
        );
      },
    },
    {
      id: "permanentExclude",
      noResize: true,
      label: "Permanent Exclude",
      field: "permanentExclude",
      render: (row) => {
        if (row.status === "temp") {
          return false;
        }
        return (
          <Tooltip
            title={
              row.isPermExcludedStatus ? "Include Record" : "Permanent Exclude"
            }
            placement="top-start"
          >
            <Switch
              size="small"
              className={row.isPermExcludedStatus}
              checked={row.isPermExcludedStatus}
              onChange={(evt) => {
                if (row.isPermExcludedStatus && !evt.target.checked) {
                  includeExcludedRecords(row);
                } else {
                  permanentExcludeRecords(evt.target.checked, row);
                }
              }}
              color="primary"
            />
          </Tooltip>
        );
      },
    },
    {
      id: "hold",
      headerClassName: classes.holdPaddingLeft,
      label: "Hold",
      field: "hold",
      render: (row) => {
        if (row.status === "temp") {
          return (
            <Typography className="text-bold" noWrap variant="body1">
              {""}
            </Typography>
          );
        }
        const holdValue = row?.paydata[0]?.hold === "1";
        return (
          <div className="d-flex f-align-center f-justify-start">
            <Tooltip
              title={`Mark ${holdValue ? "Don't Hold" : "Hold"}`}
              placement="top-start"
            >
              <Switch
                size="small"
                checked={holdValue}
                color="primary"
                className="c-default no-events"
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ExcludedBy?.isSelected,
      id: "excluded_by",
      label: "Excluded By",
      field: "excluded_by",
      render: (row) => {
        if (row.status === "temp") {
          return (
            <Typography className="text-bold" noWrap variant="body1">
              {"-"}
            </Typography>
          );
        }
        const createdBy = (row.tempExcludedData || row.permExcludedData)[0]
          ?.excluded_by;
        const user = users.find((item) => item.id === createdBy);
        return (
          <Tooltip
            title={
              !!user
                ? `${user?.first_name} ${user?.last_name} ${user?.email} (${user?.username})`
                : ""
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {!!user ? `${user?.first_name} ${user?.last_name}` : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ExcludedAt?.isSelected,
      id: "excluded_at",
      label: "Excluded At",
      field: "excluded_at",
      render: (row) => {
        if (row.status === "temp") {
          return (
            <Typography className="text-bold" noWrap variant="body1">
              {"-"}
            </Typography>
          );
        }
        const uploadDate =
          `${getDateString(
            (row.tempExcludedData || row.permExcludedData)[0]?.excluded_at
          )} ${getFormattedTime(
            new Date(
              (row.tempExcludedData || row.permExcludedData)[0]?.excluded_at
            )
          )}` || "";
        return (
          <Tooltip title={uploadDate ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {uploadDate || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ExcludedReason?.isSelected,
      id: "ExcludedReason",
      label: "Excluded Reason",
      field: "ExcludedReason",
      render: (row) => {
        let reason = "-";
        let tooltip = "";
        if (
          (!!row.tempExcludedData && row.tempExcludedData !== "-") ||
          (!!row.permExcludedData && row.permExcludedData !== "-")
        ) {
          tooltip = (row.tempExcludedData || row.permExcludedData)[0]?.reason;
          reason = (row.tempExcludedData ||
            row.permExcludedData)[0]?.reason?.split("||");
        }

        return (
          <Tooltip title={tooltip ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {reason[0]}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ICID?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "ICID",
      label: "ICID",
      field: "ICID",
      canSort: true,
      render: (row) => {
        const flagLabel =
          (row?.isFlagged === "True" && row?.isCautiousFlagged === "True") ||
          row?.isFlagged === "True" ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row?.isCautiousFlagged === "True" ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );

        const flagTitles = `Flagged Reason: ${row.flaggedReason}`;

        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Tooltip title={flagTitles} placement="top-start">
                <Typography noWrap variant="body2">
                  {flagLabel}
                </Typography>
              </Tooltip>
              <>
                <div
                  className={
                    row?.isFlagged === "True" ||
                    row?.isCautiousFlagged === "True"
                      ? ""
                      : "ml-5"
                  }
                >
                  <Tooltip title={row.ICID ?? ""} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.ICID || "-"}
                    </Typography>
                  </Tooltip>
                </div>
              </>
            </div>
          </Fragment>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.DeptNum?.isSelected,
      id: "DeptNum",
      label: "Dept Num",
      field: "DeptNum",
      render: (row) => {
        return (
          <Tooltip title={row.DeptNum ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.DeptNum}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.LastName?.isSelected,
      id: "LastName",
      label: "Last Name",
      field: "LastName",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.LastName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.LastName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.FirstName?.isSelected,
      id: "FirstName",
      label: "First Name",
      field: "FirstName",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.FirstName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.FirstName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.SSN?.isSelected,
      id: "SSN",
      label: "SSN",
      field: "SSN",
      render: (row) => {
        return (
          <Tooltip title={row.SSN ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.SSN || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.EIN?.isSelected,
      id: "EIN",
      label: "EIN",
      field: "EIN",
      render: (row) => {
        return (
          <Tooltip title={row.EIN ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.EIN || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.CoName?.isSelected,
      id: "CoName",
      label: "IC Company Name",
      field: "CoName",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.CoName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.CoName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.Settlement?.isSelected,
      id: "paydata.0.Settlement",
      label: "Settlement",
      field: "paydata.0.Settlement",
      canSort: true,
      render: (row, rowIndex) => {
        const value = Number(row.paydata[0]?.Settlement).toFixed(2);
        if (row.status === "temp") {
          return (
            <Tooltip placement="top-start" title={value} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Settlement}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Settlement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.FuelReimbursement?.isSelected,
      id: "paydata.0.FuelReimbursement",
      label: "Fuel Reimbursement",
      field: "paydata.0.FuelReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        const value = Number(row.paydata[0]?.FuelReimbursement).toFixed(2);
        if (row.status === "temp") {
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.FuelReimbursement}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.FuelReimbursement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.TollReimbursement?.isSelected,
      id: "paydata.0.TollReimbursement",
      label: "Toll Reimbursement",
      field: "paydata.0.TollReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.TollReimbursement).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.TollReimbursement}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.TollReimbursement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.MileageReimbursement?.isSelected,
      id: "paydata.0.MileageReimbursement",
      label: "Mileage Reimbursement",
      field: "paydata.0.MileageReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.MileageReimbursement).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.MileageReimbursement}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.MileageReimbursement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Reimbursement?.isSelected,
      id: "paydata.0.Reimbursement",
      label: "Reimbursement/Incentive",
      field: "paydata.0.Reimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Reimbursement).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Reimbursement}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Reimbursement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.MiscDeduction?.isSelected,
      id: "paydata.0.MiscDeduction",
      label: "Misc Deduction",
      field: "paydata.0.MiscDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.MiscDeduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.MiscDeduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.MiscDeduction}</Typography>
            </Tooltip>
          );
        }
      },
    },

    {
      isHidden: !state.dynamicColumns?.Misc2Deduction?.isSelected,
      id: "paydata.0.Misc2Deduction",
      label: "Misc2 Deduction",
      field: "paydata.0.Misc2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Misc2Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Misc2Deduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Misc2Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.FuelAdvanceDeduction?.isSelected,
      id: "paydata.0.FuelAdvanceDeduction",
      label: "Fuel Advance Deduction",
      field: "paydata.0.FuelAdvanceDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.FuelAdvanceDeduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.FuelAdvanceDeduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.FuelAdvanceDeduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.FuelAdvance2?.isSelected,
      id: "paydata.0.FuelAdvance2",
      label: "Fuel Advance2",
      field: "paydata.0.FuelAdvance2",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.FuelAdvance2).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.FuelAdvance2}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.FuelAdvance2}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.CashAdvanceDeduction?.isSelected,
      id: "paydata.0.CashAdvanceDeduction",
      label: "Cash Advance Deduction",
      field: "paydata.0.CashAdvanceDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.CashAdvanceDeduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.CashAdvanceDeduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.CashAdvanceDeduction}</Typography>
            </Tooltip>
          );
        }
      },
    },

    {
      isHidden: !state.dynamicColumns?.CashAdvance2Deduction?.isSelected,
      id: "paydata.0.CashAdvance2Deduction",
      label: "Cash Advance2 Deduction",
      field: "paydata.0.CashAdvance2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.CashAdvance2Deduction).toFixed(
            2
          );
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.CashAdvance2Deduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.CashAdvance2Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.ClaimDeduction?.isSelected,
      id: "paydata.0.ClaimDeduction",
      label: "Claim Deduction",
      field: "paydata.0.ClaimDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.ClaimDeduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.ClaimDeduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.ClaimDeduction}</Typography>
            </Tooltip>
          );
        }
      },
    },

    {
      isHidden: !state.dynamicColumns?.Claim2Deduction?.isSelected,
      id: "paydata.0.Claim2Deduction",
      label: "Claim2 Deduction",
      field: "paydata.0.Claim2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Claim2Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Claim2Deduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Claim2Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Claim3Deduction?.isSelected,
      id: "paydata.0.Claim3Deduction",
      label: "Claim3 Deduction",
      field: "paydata.0.Claim3Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Claim3Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Claim3Deduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Claim3Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },

    {
      isHidden: !state.dynamicColumns?.Rental1Deduction?.isSelected,
      id: "paydata.0.Rental1Deduction",
      label: "Rental1 Deduction",
      field: "paydata.0.Rental1Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Rental1Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Rental1Deduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Rental1Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Rental2Deduction?.isSelected,
      id: "paydata.0.Rental2Deduction",
      label: "Rental2 Deduction",
      field: "paydata.0.Rental2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Rental2Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Rental2Deduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Rental2Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Rental3Deduction?.isSelected,
      id: "paydata.0.Rental3Deduction",
      label: "Rental3 Deduction",
      field: "paydata.0.Rental3Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Rental3Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Rental3Deduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Rental3Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Rental4Deduction?.isSelected,
      id: "paydata.0.Rental4Deduction",
      label: "Rental4 Deduction",
      field: "paydata.0.Rental4Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Rental4Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.Rental4Deduction}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.Rental4Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.EOR?.isSelected,
      id: "paydata.0.EOR",
      label: "EOR",
      field: "paydata.0.EOR",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.EOR).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              placement="top-start"
              title={row.paydata[0]?.EOR}
              variant="body2"
            >
              <Typography>${row.paydata[0]?.EOR}</Typography>
            </Tooltip>
          );
        }
      },
    },
  ];

  return (
    <>
      <div
        className={clsx(
          "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
          classes.actionsWrapper
        )}
      >
        <div></div>
        <div className="d-flex f-justify-center">
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2 mt-2"
            startIcon={<CloudDownloadIcon />}
            disabled={totalExcludedRecordEntries === 0}
            onClick={() => handleDownload(excludedRecords, newExcludedEntry)}
          >
            Download
            {state.isLoading && (
              <CircularProgress size={24} className={classes.progressBtn} />
            )}
          </Button>
          <Dropdown
            classes={{
              paper: classes.tunePaper,
            }}
            disablePortal={false}
            remainOpen
            isMultiSelect
            placement="bottom-end"
            options={Object.keys(state.dynamicColumns).map(
              (key) => state.dynamicColumns[key]
            )}
            customToggle={({ anchorRef, onClick }) => (
              <Tooltip title="Show/Hide column(s)" placement="top-start">
                <IconButton color="primary" ref={anchorRef} onClick={onClick}>
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            )}
            onChange={(options) => {
              const isSelectAll = options.some(
                (item) => item.value === "select_all"
              );
              const isCurrentSelectAll =
                state.dynamicColumns?.select_all?.isSelected;

              const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;

              const isAllOptionSelected =
                Object.keys(state.dynamicColumns).length - 1 ===
                options.filter((item) => item.value !== "select_all").length;
              const updatedDynamicColumns = Object.keys(
                state.dynamicColumns
              ).reduce((acc, key) => {
                const isSelected = isClickedOnSelectAll
                  ? isSelectAll
                  : key === "select_all"
                  ? isAllOptionSelected
                  : !!options.some((item) => item.value === key);
                return {
                  ...acc,
                  [key]: {
                    ...state.dynamicColumns[key],
                    isSelected,
                  },
                };
              }, {});
              setPageConfig(PAGE_KEYS.DCN_FILTERS, {
                excludedDynamicColumns: updatedDynamicColumns,
              });
              setState((prevState) => ({
                ...prevState,
                dynamicColumns: {
                  ...prevState.dynamicColumns,
                  ...updatedDynamicColumns,
                },
              }));
            }}
          />
          <Tooltip placement="top-end" title="Reset All Filters">
            <IconButton
              color="primary"
              onClick={() => {
                updatePageConfig("dcn-filters");
                window.location.reload();
              }}
            >
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <DataGrid
        columns={excludeRecordColumnConfig}
        rows={getExcludedData()?.preparedData?.map((entry) => ({
          ...entry,
          className: clsx({
            [classes.total]: entry.status === "temp",
            [classes.permExcludedRecords]: entry.isPermExcludedStatus,
          }),
        }))}
        actionBarConfig={null}
        isLoading={isFetching}
        hasSelection={false}
        order={excludeOrder}
        orderBy={excludeOrderBy}
        hasPagination={false}
        totalRows={totalExcludedRecordEntries}
        onSortChange={handleExcludeSortChange}
      />
    </>
  );
};

export default ExcludedRecord;
