import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    width: "100%",
    top: "64px",
    marginBottom: "12px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  scheduleDateWrapper: {
    "& .react-datepicker__close-icon": {
      top: 2,
      right: 36,
    },
    "& .react-datepicker__close-icon::after": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 28,
    },
  },
  paid: {
    backgroundColor: "#d4edda",
  },
  pulseRow: {
    animation: "$pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      backgroundColor: "rgba(0, 0, 139, 0.4)",
    },
    "10%": {
      backgroundColor: "rgba(0, 0, 139, 0.36)",
    },
    "20%": {
      backgroundColor: "rgba(0, 0, 139, 0.32)",
    },
    "30%": {
      backgroundColor: "rgba(0, 0, 139, 0.28)",
    },
    "40%": {
      backgroundColor: "rgba(0, 0, 139, 0.24)",
    },
    "50%": {
      backgroundColor: "rgba(0, 0, 139, 0.2)",
    },
    "60%": {
      backgroundColor: "rgba(0, 0, 139, 0.16)",
    },
    "70%": {
      backgroundColor: "rgba(0, 0, 139, 0.12)",
    },
    "80%": {
      backgroundColor: "rgba(0, 0, 139, 0.08)",
    },
    "90%": {
      backgroundColor: "rgba(0, 0, 139, 0.04)",
    },
    "100%": {
      backgroundColor: "rgba(0, 0, 139, 0)",
    },
  },
  overdue: {
    backgroundColor: "#f8d7da",
  },
  latePayemnt: {
    backgroundColor: "#f5acb3",
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  dropdownPaper: {
    minWidth: 156,
  },
  dropdownBackground: {
    background: "cornflowerblue",
    borderRadius: 8,
  },
  customerDropdown: {
    width: 180,
  },
  autocompleteFilters: {
    minWidth: 208,
  },
  paperSize: {
    width: "400px",
  },
  iconButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    minWidth: "600px !important",
    maxWidth: "1200px !important",
    minHeight: "600px !important",
    maxHeight: "1200px !important",
  },
  messageStyles: {
    textAlign: "center",
  },
  overlayStyles: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1301,
  },
  tableHeaderwidth: {
    minWidth: 130,
    maxWidth: 130,
  },
}));

export default useStyles;
